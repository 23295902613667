// import EventHub from '@/utils/EventHub.js'

import config from '@/config.js'

export default {

    data()
    {
        return {
            content: null
        }
    },

    created()
    {

    },

    methods: {
        /**
         * 
         * Fetch lang content functions
         */
        fetchLang(endpoint, callback)
        {
            fetch(`${config.api.url}${config.api.path}${endpoint}?lang=${this.$i18n.locale}`)
                .then((res) => res.json())
                .then((res) =>
                {
                    this.onLoadedDatas(res, callback)
                })
        },

        fetchMultiple(endpoints, callback)
        {
            endpoints = endpoints.map((endpoint) => {
                return {
                    label: endpoint.label || null,
                    exisitingPath: endpoint.exisitingPath || null,
                    url: `${config.api.url}${config.api.path}${endpoint.url}`
                }
            })

            const allFetch = endpoints.map((endpoint) => {
                return fetch(endpoint.url)
                    .then(res => res.json())
                    .then(res => {
                        let resObj = {}

                        if(endpoint.exisitingPath)
                        {
                            let finalObj = this.content

                            for(let i = 0; i < endpoint.exisitingPath.length; i++)
                            {
                                finalObj = finalObj[endpoint.exisitingPath[i]]
                            }

                            finalObj.acf = res.acf
                        }
                        else if(endpoint.label)
                        {
                            resObj[endpoint.label] = res
                        }
                        else
                        {
                            resObj = res
                        }

                        return resObj
                    })
            })

            Promise.all(allFetch)
            .then(allres => {
                if(this.content)
                {
                    this.content = Object.assign(this.content, ...allres)
                }
                else
                {
                    this.content = Object.assign({}, ...allres)
                }

                // console.log('API MULTIPLE CALL : ', this.content)

                this.$nextTick(() => {

                    if(callback)
                    {
                        callback(this.content)
                    }
                
                })
            })
                
        },

        onLoadedDatas(res, callback)
        {
            this.content = res.acf

            // console.log('API CALL : ', this.content)

            this.$nextTick(() => {

                if(callback)
                {
                    callback(this.content)
                }
            
            })
        },
    }
}