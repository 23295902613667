import { gsap } from 'gsap'

export default {

    data()
    {
        return {
            currentIndex: 0,
            disablePrev: true,
            disableNext: false
        }
    },

    created()
    {
      this.animating = false

      this.transition = {

        out: {
          totalDuration: 0.6,
          scale: {
            ease: 'power3.in',
            duration: 0.6,
            from: 1.05,
            to: 1.1
          },
          text: {
            duration: 0.5,
            ease: 'power3.in',
            fromX: 0,
            toX: -10
          }
        },

        in: {
          totalDuration: 0.6,
          scale: {
            ease: 'power3.out',
            duration: 0.6,
            from: 1,
            to: 1.05
          },
          text: {
            duration: 0.6,
            ease: 'power3.out',
            fromX: 20,
            toX: 0
          }
        }
      }
    },

    mounted()
    {
    
    },

    methods: {

        init()
        {
          this.$images = this.$el.querySelectorAll('.js-carousel-image')
          this.$descriptions = this.$el.querySelectorAll('.js-carousel-description')
          this.$infos = this.$el.querySelectorAll('.js-carousel-info')
        },

        checkDisabled()
        {
            if(this.currentIndex - 1 < 0)
            {
            this.disablePrev = true

            if(this.disableNext)
                this.disableNext = false
            }
            else if(this.currentIndex + 1 >= this.items.length)
            {
            this.disableNext = true

            if(this.disablePrev)
                this.disablePrev = false
            }
            else if(this.disablePrev || this.disableNext)
            {
            this.disablePrev = false
            this.disableNext = false
            }
        },

        onClickPrev()
        {
          if(this.animating)
          {
            return
          }

          const prev = this.currentIndex -1

          this.transition.out.scale.from = 1.05
          this.transition.out.scale.to = 1

          this.transition.in.scale.from = 1.1
          this.transition.in.scale.to = 1.05

          this.transition.out.text.fromX = 0
          this.transition.out.text.toX = -10

          this.transition.in.text.fromX = 20
          this.transition.in.text.toX = 0
  
          if(prev >= 0)
          {
            this.animateOut()

            // After out animation, before in animation
            gsap.delayedCall(this.transition.out.totalDuration, () => {
              this.currentIndex = prev
              this.checkDisabled()
            })
            
            this.animateIn(prev)
          }
        },
  
        onClickNext()
        {
          if(this.animating)
          {
            return
          }

          const next = this.currentIndex + 1

          this.transition.out.scale.from = 1.05
          this.transition.out.scale.to = 1.1

          this.transition.in.scale.from = 1
          this.transition.in.scale.to = 1.05

          this.transition.out.text.fromX = 0
          this.transition.out.text.toX = 10

          this.transition.in.text.fromX = -20
          this.transition.in.text.toX = 0

  
          if(next < this.items.length)
          {
            this.animateOut()

            // After out animation, before in animation
            gsap.delayedCall(this.transition.out.totalDuration, () => {
              this.currentIndex = next
              this.checkDisabled()
            })
            
            this.animateIn(next)
          }
        },

        animateOut()
        {
          this.animating = true

          this.animateOutImages()

          const currentDescription = this.$descriptions[this.currentIndex] 
          this.animateOutTexts(currentDescription)

          if(this.$infos.length > 0)
          {
            const currentInfo = this.$infos[this.currentIndex] 
            this.animateOutTexts(currentInfo)
          }
        },

        animateIn(index)
        {
          this.animateInImages(index)

          const currentDescription = this.$descriptions[index]
          this.animateInTexts(currentDescription)

          if(this.$infos.length > 0)
          {
            const currentInfo = this.$infos[index] 
            this.animateInTexts(currentInfo)
          }

          gsap.delayedCall(this.transition.out.totalDuration + this.transition.in.totalDuration, () => {
            this.animating = false
          })
        },

        animateOutImages()
        {
          const currentImage = this.$images[this.currentIndex]

          gsap.fromTo(currentImage,
          {
            scale: this.transition.out.scale.from,
          },
          {
            duration: this.transition.out.scale.duration,
            ease: this.transition.out.scale.ease,
            scale: this.transition.out.scale.to,
          })
        },

        animateInImages(index)
        {
          const currentImage = this.$images[index]

          gsap.fromTo(currentImage, 
          {
            scale: this.transition.in.scale.from
          },
          {
            duration: this.transition.in.scale.duration,
            ease: this.transition.in.scale.ease,
            scale: this.transition.in.scale.to,
            delay: this.transition.out.totalDuration
          })
        },

        animateOutTexts(text)
        {
          gsap.fromTo(
            text,
            {
              x: this.transition.out.text.fromX,
              opacity: 1
            },
            {
              x: this.transition.out.text.toX,
              opacity: 0,
              duration: this.transition.out.text.duration,
              ease: this.transition.out.text.ease
            }
          )
        },

        animateInTexts(text)
        {
          gsap.fromTo(
            text,
            {
              x: this.transition.in.text.fromX,
              opacity: 0,
            },
            {
              x: this.transition.in.text.toX,
              opacity: 1,
              duration: this.transition.in.text.duration,
              ease: this.transition.in.text.ease,
              delay: this.transition.out.totalDuration,
            }
          )
        }
    }
}