import { gsap } from 'gsap';

import Debugger from '@/webgl/utils/Debugger';
import device from '@/webgl/utils/device';

import Preloader from '../../Preloader';
import Drop from './Drop';
import Events from '../../Events';
// import { Vector2 } from 'three';

// import math from '../../../utils/math';

export default class Rain {
    constructor({ canvas, useCanvasDimensions, amount, wind, speed, autoUpdate } = {}) {
        this._canvas = canvas || document.createElement('canvas');
        this._useCanvasDimensions = useCanvasDimensions;

        this._canvas.style.transformOrigin = 'top center';
        this._context = this._canvas.getContext('2d');

        this._amount = amount || 200;
        this._speed = speed || 0.56;
        this._wind = wind || -7.8;
        this._scale = 1;
        this._autoUpdate = autoUpdate === undefined ? true : autoUpdate;

        this._width = null;
        this._height = null;
        this._texture = null;
        this._dropsCenter = [];
        this._dropsSpread = [];
        this._isMouseDown = false;
        this._mousePosition = { x: 0, y: 0 };
        this._isEnabled = true;

        this._bindHandlers();
        this._setupEventListeners();
        this._resize();
        this._setWind();
        this._getTexture();
    }

    destroy() {
        this._removeEventListeners();
        this._removeDebugGui();
    }

    /**
     * Public
     */
    getElement() {
        return this._canvas;
    }

    show() {
        this._timelineShow = new gsap.timeline();
        this._timelineShow.fromTo(this._canvas, 0.7, { alpha: 0 }, { alpha: 1, ease: 'sine.inOut'}, 0);
    }

    hide(callback) {
        this._timelineShow = new gsap.timeline({ onComplete: callback });
        this._timelineShow.to(this._canvas, 0.7, { alpha: 0, ease: 'sine.inOut'}, 0);
    }

    enable() {
        this._isEnabled = true;
    }

    disable() {
        this._isEnabled = false;
    }

    update() {
        this._update();
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._resizeHandler = this._resizeHandler.bind(this);
        this._tickHandler = this._tickHandler.bind(this);
        this._mouseDownHandler = this._mouseDownHandler.bind(this);
        this._mouseUpHandler = this._mouseUpHandler.bind(this);
        this._mouseMoveHandler = this._mouseMoveHandler.bind(this);
        this._touchStartHandler = this._touchStartHandler.bind(this);
        this._touchMoveHandler = this._touchMoveHandler.bind(this);
        this._touchEndHandler = this._touchEndHandler.bind(this);
    }

    _setupEventListeners() {
        window.addEventListener('resize', this._resizeHandler);
        if (this._autoUpdate) gsap.ticker.add(this._tickHandler);
        this._canvas.addEventListener('mousedown', this._mouseDownHandler);
        this._canvas.addEventListener('mouseup', this._mouseUpHandler);
        this._canvas.addEventListener('mousemove', this._mouseMoveHandler);

        // Touch
        this._canvas.addEventListener('touchstart', this._touchStartHandler);
        this._canvas.addEventListener('touchmove', this._touchMoveHandler);
        this._canvas.addEventListener('touchend', this._touchEndHandler);
    }

    _removeEventListeners() {
        window.addEventListener('resize', this._resizeHandler);
        if (this._autoUpdate) gsap.ticker.remove(this._tickHandler);
        this._canvas.removeEventListener('mousedown', this._mouseDownHandler);
        this._canvas.removeEventListener('mouseup', this._mouseUpHandler);
        this._canvas.removeEventListener('mousemove', this._mouseMoveHandler);

        // Touch
        this._canvas.removeEventListener('touchstart', this._touchStartHandler);
        this._canvas.removeEventListener('touchmove', this._touchMoveHandler);
        this._canvas.removeEventListener('touchend', this._touchEndHandler);
    }

    _getTexture() {
        Preloader.load('rain-texture').then((resource) => {
            this._texture = resource;
            this._start();
        });
    }

    _start() {
        this._createDrops();
        this._setupDebugGui();
    }

    _createCenterDrops() {
        const drops = [];
        for (let i = 0; i < this._amount; i++) {
            drops.push(new Drop({
                context: this._context,
                texture: this._texture,
                speed: this._speed,
                boundries: {
                    p1: { x: this._width * 0.2, y: 0 },
                    p2: { x: this._width * 0.8, y: this._height }
                }
            }));
        }
        return drops;
    }

    _createFullSpreadDrops() {
        const drops = [];
        for (let i = 0; i < this._amount * 0.8; i++) {
            drops.push(new Drop({
                context: this._context,
                texture: this._texture,
                speed: this._speed,
                alpha: 0.15,
                boundries: {
                    p1: { x: 0, y: 0 },
                    p2: { x: this._width, y: this._height }
                }
            }));
        }
        return drops;
    }

    _createDrops() {
        this._dropsCenter = this._createCenterDrops();
        this._dropsSpread = this._createFullSpreadDrops();
    }

    _update() {
        if (!this._isEnabled) return;

        // CHANGING WIND DIRECTION
        // this._wind = (-1 + this._mousePosition.x / this._width * 2) * 5;
        // this._setWind();

        // STOP AT MOUSE POSITION
        // const mouse = new Vector2(this._mousePosition.x, this._mousePosition.y);
        // const radius = 70;

        // let item;
        // let distance;
        // let location;
        // for (let i = 0, len = this._dropsCenter.length; i < len; i++) {
        //     item = this._dropsCenter[i];
        //     location = item.location.clone().add(new Vector2(0, item.height));
        //     // distance = mouse.distanceTo(location);
        //     distance = location.sub(mouse)
            
        //     if (Math.abs(distance.x) < radius && Math.abs(distance.y) < radius * 4) { 
        //         // item._acceleration.current.y = -0.1;
        //         item._velocity.y = 2;
        //     } else {
        //         item._acceleration.current.y = item._acceleration.original.y;
        //     }
        // }

        this._clear();
        this._draw();
    }

    _clear() {
        this._context.clearRect(0, 0, this._width, this._height);
    }

    _draw() {
        // this._drawBackground();
        this._drawDropsCenter();
        this._drawDropsSpread();
    }

    _drawBackground() {
        this._context.fillStyle = '#090909';
        this._context.fillRect(0, 0, this._width, this._height);
    }

    _drawDropsCenter() {
        for (let i = 0, len = this._dropsCenter.length; i < len; i++) {
            this._dropsCenter[i].speed = this._speed;
            this._dropsCenter[i].update();
            this._dropsCenter[i].draw(this._context);
        }
    }

    _drawDropsSpread() {
        for (let i = 0, len = this._dropsSpread.length; i < len; i++) {
            this._dropsSpread[i].speed = this._speed;
            this._dropsSpread[i].update();
            this._dropsSpread[i].draw(this._context);
        }
    }

    _setWind() {
        const transform = `skew(${this._wind}deg, 0)`;
        this._canvas.style.transform = transform;
        this._canvas.style.webkitTransform = transform;
    }

    /**
     * Resize
     */
    _resize() {
        this._resetCanvasDimensions();
        if (this._useCanvasDimensions) {
            this._width = this._canvas.offsetWidth;
            this._height = this._canvas.offsetHeight;
        } else {
            this._width = window.innerWidth;
            this._height = window.innerHeight;
        }

        this._resizeCanvas();
        this._updateContextScale();
        this._updateDropCenterBoundries();
        this._updateDropSpreadBoundries();
    }

    _resetCanvasDimensions() {
        this._canvas.width = '';
        this._canvas.height = '';
    }

    _resizeCanvas() {
        this._dpr = device.dpr();

        this._canvas.width = this._width * this._dpr;
        this._canvas.height = this._height * this._dpr;

        if (!this._useCanvasDimensions) {
            this._canvas.style.width = `${this._width}px`;
            this._canvas.style.height = `${this._height}px`;
        }
    }

    _updateContextScale() {
        this._context.scale(this._dpr, this._dpr);
    }

    _updateDropCenterBoundries() {
        for (let i = 0, len = this._dropsCenter.length; i < len; i++) {
            this._dropsCenter[i].boundries = {
                p1: { x: this._width * 0.35, y: 0 },
                p2: { x: this._width * 0.65, y: this._height }
            };
        }
    }

    _updateDropSpreadBoundries() {
        for (let i = 0, len = this._dropsSpread.length; i < len; i++) {
            this._dropsSpread[i].boundries = {
                p1: { x: 0, y: 0 },
                p2: { x: this._width, y: this._height }
            };
        }
    }

    /**
     * Debug
     */
    _setupDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        this._debugGui = Debugger.gui.addFolder('Rain');
        this._debugGui.add(this, '_speed', 0, 10, 0.01);
        this._debugGui.open();
    }

    _removeDebugGui() {
        if (this._debugGui) Debugger.gui.removeFolder(this._debugGui);
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }

    _tickHandler() {
        this._update();
    }

    _mouseDownHandler() {
        this._isMouseDown = true;

        // TODO: Move to function
        for (let i = 0, len = this._dropsCenter.length; i < len; i++) {
            this._dropsCenter[i].slowDown();
        }

        for (let i = 0, len = this._dropsSpread.length; i < len; i++) {
            this._dropsSpread[i].slowDown();
        }

        if (this._delayedCall) this._delayedCall.kill();
        this._delayedCall = gsap.delayedCall(2.3, () => {
            // this._mouseUpHandler();
        });

        Events.dispatchEvent('mouse:down', 7);
    }

    _mouseUpHandler() {
        this._isMouseDown = false;

        for (let i = 0, len = this._dropsCenter.length; i < len; i++) {
            this._dropsCenter[i].speedUp();
        }

        for (let i = 0, len = this._dropsSpread.length; i < len; i++) {
            this._dropsSpread[i].speedUp();
        }

        Events.dispatchEvent('mouse:up');
    }

    _mouseMoveHandler(e) {
        this._mousePosition.x = e.clientX;
        this._mousePosition.y = e.clientY;
    }

    // Touch
    _touchStartHandler() {
        this._isMouseDown = true;

        // TODO: Move to function
        for (let i = 0, len = this._dropsCenter.length; i < len; i++) {
            this._dropsCenter[i].slowDown();
        }

        for (let i = 0, len = this._dropsSpread.length; i < len; i++) {
            this._dropsSpread[i].slowDown();
        }

        if (this._delayedCall) this._delayedCall.kill();
        this._delayedCall = gsap.delayedCall(2.3, () => {
            // this._mouseUpHandler();
        });

        Events.dispatchEvent('mouse:down', 7);
    }

    _touchMoveHandler(e) {
        const touch = e.touches[0];
        this._mousePosition.x = touch.clientX;
        this._mousePosition.y = touch.clientY;
    }

    _touchEndHandler() {
        this._isMouseDown = false;

        for (let i = 0, len = this._dropsCenter.length; i < len; i++) {
            this._dropsCenter[i].speedUp();
        }

        for (let i = 0, len = this._dropsSpread.length; i < len; i++) {
            this._dropsSpread[i].speedUp();
        }

        Events.dispatchEvent('mouse:up');
    }
}