<template>
  <button class="button-mute" @click="toggleMute" @mouseenter="mouseEnterHandler" @mouseleave="mouseLeaveHandler">
    <canvas class="canvas" ref="canvas" />
    <!--
    <svg viewBox="0 0 189 120.75">
      <path class="cls-1" d="M188.5,6.77C174.57,16,164.88,35.2,164.63,57.5h0l0,5.38c0,25.7-14.18,46.62-31.62,46.62s-31.62-20.92-31.62-46.6l0-5.4h0C101.22,26,82.27.5,59,.5c-23,0-41.81,25-42.36,56h-.06l0,5.38C16.62,79.64,10,95.54.5,104V117c15.27-8.71,26.44-29.71,26.86-53.71h.06l0-5.37c0-25.71,14.18-46.63,31.62-46.63s31.62,20.92,31.62,46.6l0,5.4h0c.15,31.46,19.1,57,42.37,57S175,95,175.37,63.75h0l0-5.37c0-15.54,5.18-29.31,13.12-37.78Z"/>
    </svg>
    -->
  </button>
</template>

<script>
import gsap from 'gsap';
import Preloader from '@/webgl/intro/Preloader';
import device from '@/webgl/utils/device';

const LINE_COLOR = '#48cecc';

export default {
  created() {
    this.isMuted = false
  },

  mounted() {
    this.time = 0;
    this.context = this.$refs.canvas.getContext('2d');
    this.width = this.$el.offsetWidth;
    this.height = this.$el.offsetHeight;

    this.frequency = 2.75;
    this.amplitude = 11;
    this.speed = 0.025;

    this.amplitudeMultiplier = 1;
    this.speedMultiplier = 1;
    this.frequencyMultiplier = 1;

    this.setCanvasSize();
    this.setupEventListeners();
  },

  onDestroy() {
    this.removeEventListeners();
  },

  methods: {
    /**
     * Public
     */
    fadeIn()
    {
      if (this._tweenFadeOut) this._tweenFadeOut.kill();
      this._tweenFadeIn = gsap.to(this.$el, 1, { alpha: 1, ease: 'sine.inOut' })
    },

    fadeOut()
    {
      if (this._tweenFadeIn) this._tweenFadeIn.kill();
      this._tweenFadeOut = gsap.to(this.$el, 0.7, { alpha: 0, ease: 'power2.out' })
    },

    show() {
      return gsap.to(this.$el, 0.7, { autoAlpha: 1, delay: 0.5, ease: 'sine.inOut' });
    },

    toggleMute() {
      this.isMuted = !this.isMuted;

      this.timelineToggle = new gsap.timeline();

      const backgroundSound = Preloader.get('background-sound')
      if (this.isMuted) {
        this.timelineToggle.to(backgroundSound, 1, { volume: 0, ease: 'none' }, 0);
        this.timelineToggle.to(this.$el, 1, { alpha: 0.2, ease: 'power3.out' }, 0);
        this.timelineToggle.to(this, 0.3, { amplitudeMultiplier: 0 }, 0);
      } else {
        this.timelineToggle.to(backgroundSound, 1, { volume: 0.5, ease: 'none' }, 0);
        this.timelineToggle.to(this.$el, 0.6, { alpha: 1, ease: 'sine.inOut' }, 0);
        this.timelineToggle.to(this, 0.3, { amplitudeMultiplier: 1 }, 0);
      }
    },

    /**
     * Private
     */
    setupEventListeners() {
      gsap.ticker.add(this.tickHandler);
    },

    removeEventListeners() {
      gsap.ticker.remove(this.tickHandler);
    },

    setCanvasSize() {
      const dpr = device.dpr();
      this.$refs.canvas.width = this.width * dpr;
      this.$refs.canvas.height = this.height * dpr;
      this.$refs.canvas.style.width = `${this.width}px`;
      this.$refs.canvas.style.height = `${this.height}px`;
      this.context.scale(dpr, dpr);
    },

    update() {
      this.time += this.speed * this.speedMultiplier;

      this.context.clearRect(0, 0, this.width, this.height);

      const baseY = this.height * 0.5 + (this.height * 0.25 * (1 - this.amplitudeMultiplier));
      const interval = 50;

      this.context.strokeStyle = LINE_COLOR;
      this.context.lineWidth = 1.5;
      this.context.beginPath();
      for (let i = 0; i <= interval; i++) {
        const x = this.width * (i / (interval));
        const a = ((i / interval) * this.frequency * this.frequencyMultiplier) + this.time;
        const remainder = a % 1;
        const progress = Math.floor(a) % 2 === 0 ? remainder : 1 - remainder;
        const y = baseY + this.easeInOutCubic(progress) * this.amplitude * this.amplitudeMultiplier;
        this.context.lineTo(x, y);
      }
      this.context.stroke();
    },

    easeInOutCubic(x) {
      return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
    },

    /**
     * Handlers
     */
    tickHandler() {
      this.update();
    },

    mouseEnterHandler() {
      if (this.timelineMouseLeave) this.timelineMouseLeave.kill();
      this.timelineMouseEnter = new gsap.timeline();
      if (this.isMuted) {
        this.timelineMouseEnter.to(this, 0.8, { amplitudeMultiplier: 0.3, ease: 'power1.out' }, 0);
        this.timelineMouseEnter.to(this, 0.8, { speedMultiplier: 0.6, ease: 'power1.out' }, 0);
        this.timelineMouseEnter.to(this.$el, 1, { alpha: 0.4, ease: 'power1.out' }, 0);
      } else {
        this.timelineMouseEnter.to(this, 0.8, { amplitudeMultiplier: 0.6, ease: 'power1.out' }, 0);
        this.timelineMouseEnter.to(this, 0.8, { speedMultiplier: 0.6, ease: 'power1.out' }, 0);
        this.timelineMouseEnter.to(this.$el, 1, { alpha: 0.4, ease: 'power1.out' }, 0);
      }
    },

    mouseLeaveHandler() {
      if (this.timelineMouseEnter) this.timelineMouseEnter.kill();
      this.timelineMouseLeave = new gsap.timeline();
      if (this.isMuted) {
        this.timelineMouseLeave.to(this, 0.8, { amplitudeMultiplier: 0, ease: 'power2.out' }, 0);
        this.timelineMouseLeave.to(this.$el, 1, { alpha: 0.2, ease: 'power2.out' }, 0);
      } else {
        this.timelineMouseLeave.to(this, 0.8, { amplitudeMultiplier: 1, ease: 'power2.out' }, 0);
        this.timelineMouseLeave.to(this, 0.8, { speedMultiplier: 1, ease: 'power2.out' }, 0);
        this.timelineMouseLeave.to(this, 0.8, { frequencyMultiplier: 1, ease: 'power2.out' }, 0);
        this.timelineMouseLeave.to(this.$el, 1, { alpha: 1, ease: 'power2.out' }, 0);
      }
    }
  }
}
</script>

<style lang="stylus">
.button-mute
  position absolute
  right 70px
  bottom 64px

  width 25px
  height 25px

  opacity 0
  visibility hidden

.button-mute svg
  width 100%

  fill #48cecc

.canvas 
  position absolute
</style>