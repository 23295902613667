<template>
    <section class="transition" :class="{ 'is-active': isActive }">
        <section class="transition__inner">
            <div class="transition__logo">
                <div class="transition__logo__container">
                    <img class="transition__logo__image" src="../assets/images/logo-tear-1.png" alt="logo tear" />
                </div>
                <span class="transition__logo__line"></span>
            </div>
        </section>
    </section>
</template>

<script>
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { gsap } from 'gsap';

import EventHub from '@/utils/EventHub';

export default {
    name: 'Transition',

    data() {
        return {
            // NOTE: Exception added to hide the Transition before the Intro (This one is super hacky, let me know if it causes any issues)
            isActive:
                window.location.pathname !== '/en' &&
                window.location.pathname !== '/fr' &&
                window.location.pathname !== '/ja' &&
                window.location.pathname !== '/ru' &&
                window.location.pathname !== '/',
        };
    },

    created() {
        this.transition = {
            duration: 1.5,
            vertical: true,
            ease: 'power4.inOut',
        };

        this.addListeners();
    },

    mounted() {},

    destroyed() {
        this.removeListeners();
        // clearAllBodyScrollLocks()
    },

    methods: {
        addListeners() {
            EventHub.$on('page:transition-in', this.onTransitionIn);
            EventHub.$on('page:transition-out', this.onTransitionOut);
            EventHub.$on('application:on-route-change', this.onRouteChange);
        },

        removeListeners() {
            EventHub.$off('page:transition-in', this.onTransitionIn);
            EventHub.$off('page:transition-out', this.onTransitionOut);
            EventHub.$off('application:on-route-change', this.onRouteChange);
        },

        onRouteChange(to, from) {
            if (to.meta.type !== from.meta.type) {
                switch (to.meta.type) {
                    case 'sculptures':
                        this.transition.vertical = true;
                        break;
                    case 'chocolate':
                        this.transition.vertical = false;
                        break;
                    default:
                        this.transition.vertical = true;
                }
            }
        },

        onTransitionIn() {
            this.isActive = true;

            const xPercent = this.transition.vertical ? -100 : 0;
            const yPercent = this.transition.vertical ? 0 : -100;

            const logoDuration = 0.6;
            const logoEase = 'power3.out';
            const logoDelay = 0.9;

            const lineDuration = 0.6;
            const lineEase = 'power3.out';
            const lineDelay = 1;

            // section container

            gsap.fromTo(
                '.transition',
                {
                    xPercent: xPercent,
                    yPercent: yPercent,
                },
                {
                    duration: this.transition.duration,
                    xPercent: 0,
                    yPercent: 0,
                    ease: this.transition.ease,
                }
            );

            gsap.fromTo(
                '.transition__inner',
                {
                    xPercent: xPercent * -1,
                    yPercent: yPercent * -1,
                },
                {
                    duration: this.transition.duration,
                    xPercent: 0,
                    yPercent: 0,
                    ease: this.transition.ease,
                }
            );

            // LOGO

            gsap.fromTo(
                '.transition__logo__container',
                {
                    yPercent: 100,
                },
                {
                    duration: logoDuration,
                    delay: logoDelay,
                    yPercent: 0,
                    ease: logoEase,
                }
            );

            gsap.fromTo(
                '.transition__logo__image',
                {
                    yPercent: -100,
                },
                {
                    duration: logoDuration,
                    delay: logoDelay,
                    yPercent: 0,
                    ease: logoEase,
                }
            );

            // Line
            gsap.fromTo(
                '.transition__logo__line',
                {
                    scaleX: 0,
                },
                {
                    duration: lineDuration,
                    delay: lineDelay,
                    transformOrigin: 'left top',
                    scaleX: 1,
                    ease: lineEase,
                }
            );
        },

        onTransitionOut() {
            const xPercent = this.transition.vertical ? 100 : 0;
            const yPercent = this.transition.vertical ? 0 : 100;

            gsap.to('.transition', {
                duration: this.transition.duration,
                xPercent: xPercent,
                yPercent: yPercent,
                ease: this.transition.ease,
            });

            gsap.to('.transition__inner', {
                duration: this.transition.duration,
                xPercent: xPercent * -1,
                yPercent: yPercent * -1,
                ease: this.transition.ease,
                onComplete: () => {
                    this.isActive = false;
                    EventHub.$emit('window:on-resize', { width: window.innerWidth, height: window.innerHeight });
                    EventHub.$emit('page:transition-end');
                },
            });
        },
    },
};
</script>

<style lang="stylus">
.transition
    z-index 99
    overflow hidden
    pointer-events none
    position fixed
    top 0
    left 0

    height 100vh
    width 100vw

    background $black

    opacity 0
    visibility hidden

    will-change transform

    &.is-active
        opacity 1
        visibility visible

    @media(max-width $breakpoints.md)
        height 100%

.transition__inner
    width 100%
    height 100%

    display flex
    justify-content center
    align-items center

    will-change transform

.transition__logo
    position relative

.transition__logo__container
    overflow hidden

    will-change transform

.transition__logo__image
    display block
    height 100px

    will-change transform

.transition__logo__line
    position absolute
    top 50%
    left 0

    height 1px
    width 100%

    background $green

    will-change transform
</style>
