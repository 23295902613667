// Three
import { Object3D, Mesh, Group, PlaneBufferGeometry, ShaderMaterial, AdditiveBlending, Color } from 'three';

// Utils
import Debugger from '@/webgl/utils/Debugger';
import WindowResizeObserver from '@/webgl/utils/WindowResizeObserver';

// Shaders
import vertexShader from '@/webgl/background/shaders/lines-horizontal/vertex.glsl';
import fragmentShader from '@/webgl/background/shaders/lines-horizontal/fragment.glsl';

// Config
const AMOUNT = 5;
const COLOR = '#21deb8';

export default class LinesHorizontalScene extends Object3D {
    constructor(domElement, domOptions = {}) {
        super();

        this.name = 'LinesHorizontalScene';

        this._domElement = domElement;
        this._domOptions = domOptions;

        this._width = null;
        this._height = null;

        this._lines = [];
        this._amplitude = this._domOptions.amplitude || 14;
        this._frequency = this._domOptions.frequency || 1.47;


        this._elements = new Group();
        this.add(this._elements);

        this._bindHandlers();
        this._createLines();
        this._resize();
        this._setupEventListeners();
        this._setupDebugGui();
    }

    destroy() {
        this._removeEventListeners();
    }

    /**
     * Public
     */
    update({ time }) {
        const children = this._elements.children;
        for (let i = 0, len = children.length; i < len; i++) {
            children[i].material.uniforms.uTime.value = time * 0.0001;
        }
    }

    updatePosition(scrollPosition) {
        this.position.x = -scrollPosition.y;
    }

    resize() {
        this._resize();
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._resizeHandler = this._resizeHandler.bind(this);
    }

    _setupEventListeners() {
        WindowResizeObserver.addEventListener('resize', this._resizeHandler);
    }

    _removeEventListeners() {
        WindowResizeObserver.removeEventListener('resize', this._resizeHandler);
    }

    _createLines() {
        const width = 1;
        const height = 1;

        const geometry = new PlaneBufferGeometry(width, height, 100, 1);
        const mainMaterial = new ShaderMaterial({
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            transparent: true,
            blending: AdditiveBlending,
            wireframe: false,
            uniforms: {
                uTime: { value: 0 },
                uTimeOffset: { value: null },
                uFrequency: { value: null },
                uAmplitude: { value: null },
                uGlobalAmplitude: { value: null },
                uColor: { value: new Color(COLOR) },
                uAlpha: { value: null }
            }
        });

        let material;
        let mesh;
        for (let i = 0; i < AMOUNT; i++) {
            material = mainMaterial.clone();
            material.uniforms.uTimeOffset.value = Math.random() * 100;
            material.uniforms.uFrequency.value = this._frequency;
            material.uniforms.uAmplitude.value = 0.2 + Math.random() * 0.8;
            material.uniforms.uGlobalAmplitude.value = this._amplitude;
            material.uniforms.uAlpha.value = 0.1 + Math.random() * 0.2;

            mesh = new Mesh(geometry, material);
            mesh.scale.y = Math.random() > 0.5 ? 2 : 1;
            this._elements.add(mesh);
        }
    }

    /**
     * Resize
     */
    _resize() {
        this._width = this._domElement.offsetWidth;
        this._height = this._domElement.offsetHeight;

        this._viewportWidth = WindowResizeObserver.width;
        this._documentWidth = WindowResizeObserver.documentWidth;
        this._viewportHeight = WindowResizeObserver.height;

        this._pageOffset = this._getElementGlobalOffsetLeft(this._domElement);

        this._positionElementsContainer();
        this._resizeElements();
    }

    _getPageOffset() {
        const bodyRect = document.body.getBoundingClientRect();
        const elemRect = this._domElement.getBoundingClientRect();
        return elemRect.left - bodyRect.top;
    }

    _getElementGlobalOffsetLeft(element) {
        let x = 0;
        const getOffset = function(element) {
            x += element.offsetLeft;
            if (element.offsetParent !== null) {
                getOffset(element.offsetParent);
            }
        }
        getOffset(element);
        return x;
    }

    _positionElementsContainer() {
        const offset = this._viewportHeight * 0.5 % 1 === 0 ? 0.5 : 0;
        this._elements.position.x = this._pageOffset;
        this._elements.position.y = offset;
    }

    _resizeElements() {
        const children = this._elements.children;
        for (let i = 0, len = children.length; i < len; i++) {
            children[i].scale.x = this._width;
            children[i].position.x = this._width * 0.5 - this._documentWidth * 0.5;
        }
    }

    /**
     * Debug
     */
    _setupDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        this._debugGui = gui.addFolder(this.name);
        this._debugGui.add(this, '_amplitude', 0, 100, 1).name('amplitude').onChange(() => {
            const children = this._elements.children;
            for (let i = 0, len = children.length; i < len; i++) {
                children[i].material.uniforms.uGlobalAmplitude.value = this._amplitude;
                
            }
        });
        this._debugGui.add(this, '_frequency', 0, 10, 0.01).name('frequency').onChange(() => {
            const children = this._elements.children;
            for (let i = 0, len = children.length; i < len; i++) {
                children[i].material.uniforms.uFrequency.value = this._frequency;
            }
        });
        this._debugGui.open();
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }
}
