<template>
<div class="stories-text" :class="classList">
	<p class="stories-text__paragraph text-chocolate is-white" :class="textClass" v-html="text"></p>
</div>
</template>

<script>
export default {
    name: 'StoriesText',

    props: {
		text: {
			type: String
		},

		position: {
			type: String
		},

		isSpaceLeft: {
			type: Boolean
		},

		isSpaceRight: {
			type: Boolean
		}
	},
	
	computed: {
		classList()
		{
			const classArray = []

			if(this.isSpaceLeft)
			{
				classArray.push('space-left')
			}

			if(this.isSpaceRight)
			{
				classArray.push('space-right')
			}

			return classArray
		},

		textClass()
		{
			const classArray = []

			if(this.position === 'bottom')
			{
				classArray.push('is-bottom')
			}
			else
			{
				classArray.push('is-top')
			}

			return  classArray
		}
	}
}
</script>

<style lang="stylus">

.stories-text

	&.space-left
		padding-left 135px

		@media(max-width $breakpoints.xl)
			padding-left 100px

	&.space-right
		padding-right 185px

		@media(max-width $breakpoints.xl)
			padding-right 130px

		@media(max-width $breakpoints.md)
			padding-right 50px

.stories-text__paragraph
	width 380px

	@media(max-width $breakpoints.xl)
		width 330px

	@media(max-width $breakpoints.md)
		width 300px

	&.is-bottom
		margin-top $pos-text-bot

		@media(max-width $breakpoints.md)
			margin-top $pos-text-bot-mobile

	&.is-top
		margin-top $pos-text-top
</style>