import { Vector2, ShaderMaterial } from 'three';

import vertexShader from './shaders/final.vert.glsl';
import fragmentShader from './shaders/final.frag.glsl';

const DUDV_SIZE = 1024;

export default class FinalPass extends ShaderMaterial {
    constructor({ waveStrength, tileScale, waveSpeed, noiseStrength, vignetteFalloff, vignetteAmount, brightness, contrast, gamma }) {
        super();

        // Properties
        this._waveStrength = waveStrength;
        this._tileScale = tileScale;
        this._waveSpeed = waveSpeed;
        this._noiseStrength = noiseStrength;
        this._vignetteFalloff = vignetteFalloff;
        this._vignetteAmount = vignetteAmount;
        this._brightness = brightness;
        this._contrast = contrast;
        this._gamma = gamma;

        this.transparent = true;

        // Shaders
        this.vertexShader = vertexShader;
        this.fragmentShader = fragmentShader;

        // Uniforms
        this.uniforms = this._createUniforms();
    }

    /**
     * Public
     */
    resize({ width, height }) {
        this.uniforms.uResolution.value = new Vector2(width, height);
        this.uniforms.uTiling.value = height / DUDV_SIZE;
    }

    /**
     * Private
     */
    _createUniforms() {
        return {
            uResolution: { value: new Vector2() },
            tDiffuse: { value: null },
            tDudv: { value: null },
            tDisplacement: { value: null },
            uWaveStrength: { value: this._waveStrength },
            uWaveSpeed: { value: this._waveSpeed },
            uTiling: { value: null },
            uTileScale: { value: this._tileScale },
            uTime: { value: null },
            uNoiseStrength: { value: this._noiseStrength },
            uVignetteFalloff: { value: this._vignetteFalloff },
            uVignetteAmount: { value: this._vignetteAmount },
            uBrightness: { value: this._brightness },
            uContrast: { value: this._contrast },
            uGamma: { value: this._gamma }
        };
    }
}
