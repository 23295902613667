<template>
    <div class="chocolate-vertical-card">
        <div class="chocolate-vertical-card__bg">
            <div v-if="$root.isMobile" class="chocolate-vertical-card__bg__elem" :style="{'background-image': `url(${image.url})`}"></div>
            <ParallaxeHorizontalElem
                v-else
                :amount="0.15"
                :isInner="true"
            >
                <div class="chocolate-vertical-card__bg__elem" :style="{'background-image': `url(${image.url})`}"></div>
            </ParallaxeHorizontalElem>
        </div>
        <div class="chocolate-vertical-card__content">
            <div class="chocolate-vertical-card__content__header">
                <h3 class="chocolate-vertical-card__title subtitle-chocolate" v-html="title" v-if="title"></h3>
                <p class="chocolate-vertical-card__date text-chocolate" v-html="date" v-if="date"></p>
            </div>
            <p class="chocolate-vertical-card__text text-chocolate" v-html="description" v-if="description"></p>
        </div>
        <div class="chocolate-vertical-card__btn text-chocolate" v-if="hasBtn">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import ParallaxeHorizontalElem from '@/components/ParallaxeHorizontalElem.vue'

export default {
    name: 'ChocolateVerticalCard',

    components: {
        ParallaxeHorizontalElem
    },

    props: {
        image: {
            type: Object
        },
        title: {
            type: String,
            default: null
        },
        date: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        hasBtn: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="stylus">
.chocolate-vertical-card
    position relative
    width 750px
    height 100vh

    @media(max-width $breakpoints.md)
        width 100vw
        height 100%

.chocolate-vertical-card__btn
	position absolute
	bottom 6.5vh
	left 0

	@media(max-height $breakpoints-h.xxl)
		bottom 4vh

	@media(max-width $breakpoints.md)
		bottom 5%
		padding-left 0px

.chocolate-vertical-card__bg
    overflow hidden
    position relative
    height 50vh

    @media(max-width $breakpoints.md)
        width 100%
        height 50%

.chocolate-vertical-card__bg__elem
    display block

    height 100%
    width 100%

    background-size cover
    background-position 50% 50%
    
    @media(max-width $breakpoints.md)
        height 100%
        width 100%

        background-size cover
        background-position 50% 50%

.chocolate-vertical-card__content
    position relative

    padding-top 8vh
    height 50vh

    font-size 18px

    @media(max-width $breakpoints.md)
        height 50%
        padding-top 5vh

.chocolate-vertical-card__content__header
    max-width 70%
    margin-bottom 3.5vh

    @media(max-width $breakpoints.md)
        max-width none
		
.chocolate-vertical-card__title
	margin-bottom 60px
	max-width 400px

.chocolate-vertical-card__date
	max-width 400px

.chocolate-vertical-card__text
	max-width 475px

	will-change opacity, transform
</style>