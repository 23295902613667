<template>
<div class="title-cover-duo">
	<h2 v-if="$root.isMobile" class="title-cover-duo__title quote-chocolate is-white">{{ title }}</h2>
	<div v-else class="title-cover-duo__wrapper">
		<div class="title-cover-duo__parallaxe" ref="elemParallaxe">
			<div class="title-cover-duo__parallaxe__inner" ref="innerParallaxe">
				<h2 class="title-cover-duo__title quote-chocolate is-white" ref="titleWhite">{{ title }}</h2>
				<h2 class="title-cover-duo__title quote-chocolate is-black">{{ title }}</h2>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import GetAbsoluteBoundingRect from '@/utils/GetAbsoluteBoundingRect.js'
import MathUtils from '@/utils/MathUtils'
import EventHub from '@/utils/EventHub'

export default {
    name: 'TitleCoverDuo',

    mixins: [],

    components: {
		
    },

    props: {
        title: {
            type: String
        }
	},

	data() {
        return {
            observer: null,
            intersected: false,
			isActive: true
        }
    },
	
	created()
	{
		//resize debounce
		this.timeout = false
		this.delay = 250

		this.direction = -1
		this.amount = 0.6
		this.isParallaxing = false
    
        this.addEventListeners()
	},

	mounted()
    {
        this.initParallaxe()
        this.addObserver()

        window.setTimeout(() => {
            this.setParallaxeValue(0)
            this.updateElemStyle()
            this.applyResize({width: window.innerWidth, height: window.innerHeight})
        }, 100)
	},
	
	destroyed()
    {
        this.removeEventListeners()
        this.removeActiveEventListeners()

        this.observer.disconnect()
	},
	
	methods:
	{
        /**
         * 
         * Listeners
         */

        addEventListeners()
        {
            EventHub.$on('window:on-resize', this.onResize)
        },

        addActiveEventListeners()
        {
            EventHub.$on('application:on-tick', this.onTick)
        },

        removeEventListeners()
        {
            EventHub.$off('window:on-resize', this.onResize)
        },

        removeActiveEventListeners()
        {
            EventHub.$off('application:on-tick', this.onTick)
        },

        /**
         * 
         * Observer
         */

        addObserver()
        {
            this.observer = new IntersectionObserver(entries => {
                this.handleObserver(entries)
            })

            this.observer.observe(this.$refs.elemParallaxe)
        },

        handleObserver(entries)
        {
            const elem = entries[0]

            if (elem.isIntersecting) 
            {
                this.setParallaxeActive()
            }
            else
            {
                this.setParallaxeInactive()
            }
        },

        /**
         * 
         * Parallaxe functions
         */

        setParallaxeActive()
        {
            this.isParallaxing = true
            
            this.addActiveEventListeners()
        },

        setParallaxeInactive()
        {
            this.isParallaxing = false

            this.removeActiveEventListeners()
        },

        initParallaxe()
        {
            this.currentScroll = 0
			this.progress = 0
			this.parallaxeValue = 0

            this.parallaxe = {
                amplitude: 0,
                current: 0,
                target: 0
            }

            this.limits = {}
		},
		
		getBounds()
		{
			this.initialBounds = GetAbsoluteBoundingRect(this.$refs.elemParallaxe)
			
			if(this.$root.smoothScroll.position)
			{
				this.initialBounds.left += this.$root.smoothScroll.position.x
				this.initialBounds.right += this.$root.smoothScroll.position.x
			}
		},

        updateBounds()
        {
            this.limits.min = this.initialBounds.left - this.windowObj.width
            this.limits.max = this.initialBounds.right

            this.width = Math.round(this.initialBounds.width * 100) / 100

            this.parallaxe.amplitude = this.width * this.amount
        },

        calcProgress()
        {
            this.progress = MathUtils.clamp((this.currentScroll - this.limits.min) / (this.limits.max - this.limits.min), 0, 1)
        },

        updateParallaxe(progress)
        {
            this.parallaxe.target = (this.parallaxe.amplitude / 2) - ( (1 - progress) * this.parallaxe.amplitude)
            this.parallaxe.current = MathUtils.lerp(this.parallaxe.current, this.parallaxe.target, 0.3)
        },

        setParallaxeValue(progress)
        {
            this.parallaxe.target = (this.parallaxe.amplitude / 2) - ( (1 - progress) * this.parallaxe.amplitude)
            this.parallaxe.current = this.parallaxe.target
        },

        updateElemStyle()
        {
            this.parallaxeValue = Math.round(this.direction * this.parallaxe.current * 100) / 100

            this.$refs.innerParallaxe.style.transform = `translate3d(${this.parallaxeValue}px, 0, 0)`

		},
		
		/**
		 * 
		 * Clip path functions
		 */

		updateTitleClip()
		{
			const clipValue = this.initialBounds.width - (this.windowObj.width - this.initialBounds.left - this.parallaxeValue)

			this.$refs.titleWhite.style.clipPath = `inset(0px ${clipValue}px 0px 0px)`
		},

        /**
         * 
         * Events
         */

        onResize(windowObj)
        {
			clearTimeout(this.timeout)

			this.timeout = setTimeout(this.applyResize(windowObj), this.delay)
		},
		
		applyResize(windowObj)
		{
			this.windowObj = windowObj

			this.getBounds()

			this.updateBounds()
		},

        onTick()
        {
            if(this.isParallaxing)
            {
                this.currentScroll = this.$root.smoothScroll.position.x

                this.calcProgress()
                this.updateParallaxe(this.progress)

				this.updateElemStyle()
				
				this.updateTitleClip()

                this.parallaxe.current = this.parallaxe.target
            }
        }
    }
}
</script>

<style lang="stylus">
.title-cover-duo__wrapper
	position relative

.title-cover-duo__title
	font-size 6.1rem

	@media(max-width $breakpoints.xl)
		font-size 5.2rem

	@media(max-width $breakpoints.md)
		font-size 2.5rem

	&.is-white
		position relative
		z-index 2

	&.is-black
		position absolute 
		z-index 1
		top 0
		left 0


</style>