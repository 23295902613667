<template>
<footer class="footer-chocolate" :class="globalClasses">
    <div class="footer-chocolate__inner" v-if="navigation">
        <CanvasBackground v-if="showBackgroundLines" scene="lines" type="horizontal" />
        <div class="footer-chocolate__site">
            <div class="footer-chocolate__site__container">
                <div class="footer-chocolate__site__brand">
                    <div class="footer-chocolate__site__brand__logo">
                        <router-link :to="`/${$i18n.locale}/chocolate`">
                            <img src="../assets/images/chocolate/logo-2.png" alt="Patrick roger logo 2" class="footer-chocolate__site__brand__logo__img">
                        </router-link>
                    </div>
                </div>
                <div class="footer-chocolate__site__nav">
                    <ul class="footer-chocolate__site__nav__links" v-for="(item, index) in navigation.footer_columns" :key="index">
                        <li class="footer-chocolate__site__nav__link" v-for="(item2, index2) in item.line" :key="index2">
                            <router-link v-if="item2.link_type === 'internal'" :to="`/${$i18n.locale}${item2.path}`">{{ item2.label }}</router-link>
                            <a v-else :href="item2.url">{{ item2.label }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-chocolate__social">
            <div class="footer-chocolate__social__wrapper">
                <div class="footer-chocolate__social__container">
                    <div class="footer-chocolate__social__links">
                        <a v-for="(item, index) in navigation.footer_social" :key="index" class="footer-chocolate__social__link" :href="item.url" target="_blank">{{ item.label }}</a>
                    </div>
                    <div class="footer-chocolate__lang__links">
                        <template v-for="(item, index) in localsLinks">
                        <a  class="footer-chocolate__lang__link" :key="`link-${index}`" :href="`/${item.lang}`">{{ item.lang }}</a> 
                        <span :key="`span-${index}`"  v-if="index < localsLinks.length - 1"> / </span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
import config from '@/config.js'

import NavigationMixin from '@/mixins/NavigationMixin.js'

import EventHub from '@/utils/EventHub.js'

import CanvasBackground from '@/components/CanvasBackground.vue'

export default {
    name: 'FooterChocolate',

    mixins: [NavigationMixin],

    components: {
        CanvasBackground
    },

    props: {
        showBackgroundLines: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            isFade: false,
            observer: null,
            intersecting: false,
        }
    },

    computed: {
        globalClasses() {
            return {
                'is-transparent': !this.showBackgroundLines,
                'js-white-header-section': this.showBackgroundLines,
                'horizontal-block': this.showBackgroundLines,
                'is-fade': this.isFade
            }
        },
        localsLinks()
        {
            const links = []

            for(let i = 0; i < config.langs.length; i++)
            {
                links.push(
                    {
                        lang: config.langs[i]
                    }
                )
            }
            return links
        }
    },

    mounted()
    {
        this.addEventListeners()
    },

    destroyed()
    {
        this.removeEventListeners()
    },

    methods: {
        /**
         * 
         * Listeners
         */

        addEventListeners()
        {
            if(!this.$root.isMobile)
            {
                EventHub.$on('nav-fixed-chocolate:on-toggle', this.onToggleFixedNav)
    
                const options = {
                    threshold: [0, 0.5]
                }
    
                this.observer = new IntersectionObserver(entries =>
                {
    
                    const footer = entries[0]
    
                    if (footer.isIntersecting)
                    {
                        this.intersecting = true
    
                        if(footer.intersectionRatio > 0.5)
                        {
                            //disable nav
                            EventHub.$emit('nav-fixed-chocolate:on-toggle-enable', false)
                        }
                        else
                        {
                            // enable nav
                            EventHub.$emit('nav-fixed-chocolate:on-toggle-enable', true)
                        }
                    }
                    else
                    {
                        this.intersecting = false
                    }
				}, options)
				
    
                this.observer.observe(this.$el)
            }
        },

        removeEventListeners()
        {
            if(!this.$root.isMobile)
            {
                EventHub.$off('nav-fixed-chocolate:on-toggle', this.onToggleFixedNav)
                this.observer.disconnect()
            }
        },

        /**
         * 
         * Events
         */

        onToggleFixedNav(isNavActive)
        {
            if(this.intersecting)
            {
                this.isFade = isNavActive
            }
            else
            {
                this.isFade = false
            }
        }


    }
}
</script>

<style lang="stylus">
.footer-chocolate
    display inline-block

.footer-chocolate__inner
    position relative
    overflow hidden


    display flex
    flex-direction column

    height 100vh
    width 100vw

    font-size 18px
    text-transform uppercase
    color $white

    background $black

    .is-transparent &
        background transparent

    @media(max-width $breakpoints.xl)
        font-size 17px

    @media(max-width $breakpoints.lg)
        font-size 16px

    @media(max-width $breakpoints.md)
        width auto
        height 100%

.footer-chocolate__site__container, .footer-chocolate__social__container
    display flex

.footer-chocolate__social__wrapper, .footer-chocolate__site__container
    width 100%
    padding 0 230px

    @media(max-width $breakpoints.xl)
        padding 0 150px

    @media(max-width $breakpoints.lg)
        padding 0 50px 0 100px


.footer-chocolate__site, .footer-chocolate__social
    height 50%

.footer-chocolate__site
    padding-top 14.8vh

    @media(max-width $breakpoints.md)
        padding-top 10vh

.footer-chocolate__social
    display flex
    align-items flex-end
    padding-bottom 12vh

    opacity 0.6

    transition opacity 0.4s $ease-out-quart

    .is-fade &
        opacity 0.05

    @media(max-width $breakpoints.md)
        padding-bottom 10vh

.footer-chocolate__site__brand
    grid-col(0, 3)

    @media(max-width $breakpoints.xl)
        grid-col(0, 2)

    @media(max-width $breakpoints.md)
        width auto
        margin 0
        padding-right 100px

.footer-chocolate__site__brand__logo
    width 128px
    opacity 1

    transition opacity 0.4s $ease-out-quart

    .is-fade &
        opacity 0.05

    @media(max-width $breakpoints.xl)
        width 110px

    @media(max-width $breakpoints.lg)
        width 100px


.footer-chocolate__site__brand__logo__img
    display block
    width 100%

.footer-chocolate__site__nav
    display flex
    grid-col(0, 6)

    opacity 0.6

    transition opacity 0.4s $ease-out-quart

    .is-fade &
        opacity 0.05

    @media(max-width $breakpoints.xl)
        grid-col(0, 7)

    @media(max-width $breakpoints.md)
        width auto
        margin 0

.footer-chocolate__site__nav__links
    display flex
    flex-direction column

    width 25%

    @media(max-width $breakpoints.md)
        width auto
        padding-right 0px

        // &:last-child
        //    padding-left 50px

.footer-chocolate__site__nav__link
    padding 0 10px
    margin-bottom 40px
    max-width 80%

    @media(max-width $breakpoints.md)
        max-width: 100%
        width 8rem

.footer-chocolate__social__text
    grid-col(0, 3)

    margin-bottom 40px

    line-height 1.33

    opacity 0
    visibility hidden

    @media(max-width $breakpoints.md)
        width auto
        max-width 400px
        margin 0 0 20px 0

.footer-chocolate__social__newsletter
    opacity 0
    visibility hidden

.footer-chocolate__social__newsletter__field
    display flex

.footer-chocolate__social__newsletter__input
    height 80px
    width 380px
    padding-left 25px
    border 1px solid $grey-border

    line-height 80px
    text-transform uppercase

    &::placeholder
        color $white

    @media(max-width $breakpoints.md)
        width calc(100vw - 80px)

.footer-chocolate__social__newsletter__btn
    cursor pointer

    display flex
    align-items center
    justify-content center

    height 80px
    width 80px
    border 1px solid $grey-border
    border-left none

    text-transform uppercase

.footer-chocolate__social__container
    justify-content space-between
    align-items center

    @media(max-width $breakpoints.md)
        justify-content flex-start

.footer-chocolate__social__links
    @media(max-width $breakpoints.md)
        padding-left 150px

.footer-chocolate__social__link
    padding-left 30px

    &:first-child
        padding-left 0

.footer-chocolate__lang__link
    padding 0 10px
</style>