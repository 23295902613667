<template>
    <section class="all-news" v-if="contentLoaded">
        <div class="all-news__container" :class="{'scroll-wrapper': !$root.isMobile}">
            <div class="horizontal-wrapper" :class="{'js-scroll-elem': !$root.isMobile}">

                <div class="all-news__titles horizontal-block">
                    <NavMobileChocolate v-if="$root.isMobile"/>
                    <ChocolateCover
                        :url="content.acf.cover_image.url"
                    />
					<div class="all-news__titles__title">
						<ChocolateTitle
							class="all-news__titles__title--elem"
							:title="content.acf.title"
							:amount="0.8"
						/>
					</div>
                </div>

                <div class="horizontal-block">
                    <section class="all-news__list pad-l-md pad-r-xs green-line">
                        <ChocolateVerticalCard
                            v-for="(item, index) in content.news" :key="index"
                            class="all-news__item"
                            :image="item.acf['chocolate-news_preview_image']"
                            :title="item.acf['chocolate-news_title']"
                            :date="item.acf['chocolate-news_date']"
                            :description="item.acf['chocolate-news_cover_description']"
                        >
                            <ArrowBtn
                                :href="`${basePath}/${item.id}`"
                                :text="content.acf.single_news_label"
                            />
                        </ChocolateVerticalCard>
                    </section>
                </div>

                <FooterChocolate :showBackgroundLines="true" />
            </div>
        </div>
    </section>
</template>

<script>
import EventHub from '@/utils/EventHub.js'

import SmoothScrollHorizontalMixin from '@/mixins/SmoothScrollHorizontalMixin.js'
import API from '@/mixins/API.js'
import Page from '@/mixins/Page.js'
import MobileScroll from '@/mixins/MobileScroll.js'

import ChocolateCover from '@/components/ChocolateCover.vue'
import FooterChocolate from '@/components/FooterChocolate.vue'
import ArrowBtn from '@/components/ArrowBtn.vue'
import NavMobileChocolate from '@/components/NavMobileChocolate.vue'
import ChocolateVerticalCard from '@/components/ChocolateVerticalCard.vue'
import ChocolateTitle from '@/components/ChocolateTitle.vue'

export default {
    name: 'ChocolateAllNews',

    mixins: [SmoothScrollHorizontalMixin, API, Page, MobileScroll],

    components: {
        ChocolateCover,
        FooterChocolate,
        ArrowBtn,
        NavMobileChocolate,
		ChocolateVerticalCard,
		ChocolateTitle
	},
	
	computed:
	{
		basePath()
		{
			let path = ''
	
			if(this.$i18n.locale === 'fr')
			{
				path = '/chocolat/actualites'
			}
			else
			{
				path = '/chocolate/news'
			}
	
			return path
		}
	},

    data()
    {
        return {
            isHorizontal: true
        }
    },

    created()
    {
        const endpoints = [
            {
                url: `/pages/97?lang=${this.$i18n.locale}`,
                label: null
            }, 
            {
                url: `/chocolatenews?per_page=100&page=1&lang=${this.$i18n.locale}`,
                label: 'news'
            }
        ]
        this.fetchMultiple(endpoints, () => {
            this.contentLoaded = true

            this.onMounted()
            
            this.$nextTick(() => {

                EventHub.$emit('nav-fixed-chocolate:init')

                if(!this.$root.isMobile)
                {
                    this.SmoothScrollHorizontalMixin_init()
                }
                else
                {
                    this.MobileScroll_init()
                }
            })
        })
    }
}
</script>

<style lang="stylus" scoped>
.all-news
    background-color $grey

    @media(max-width $breakpoints.md)
        overflow-y hidden
        position relative

        height 100%

.all-news__titles
	position relative

.all-news__titles__title
	z-index 2
	position absolute
	right 0
	top $pos-text-top
	transform translateX(20%)

	@media(max-width $breakpoints.md)
		top 45%
		transform translateX(10%)

.all-news__list
    position relative

    display flex

    @media(max-width $breakpoints.md)
        height 100%

.all-news__item
    margin-right 250px

    &:last-child
        margin-right 0

    @media(max-width $breakpoints.md)
        width 100vw
        margin-right 100px

</style>