<template>
<section class="chocolate-hero">
    <div v-if="layout !== 'media' || $root.isMobile" class="chocolate-hero__bg" :style="{'background-image': `url(${backgroundSrc}`}"></div>
    <div v-else-if="!data.video && !data.video.url" class="chocolate-hero__bg" :style="{'background-image': `url(${data.image.url}`}"></div>
    <VideoBox v-else :data="data.video" :youtube="data.youtube_url" :label="data.youtube_label" class="chocolate-hero__bg--video"/>

    <router-link :to="`/${$i18n.locale}/chocolate`" class="chocolate-hero__logo"  v-if="!$root.isMobile">
        <img class="chocolate-hero__logo__img" src="../assets/images/chocolate/logo-2.png" alt="Logo Patrick Roger green" ref="logo">
    </router-link>
    <h1 class="chocolate-hero__title title-chocolate is-white" ref="title">{{ title }}</h1>
</section>
</template>
<script>
import { gsap } from 'gsap'

import EventHub from '@/utils/EventHub'
import VideoBox from '@/components/VideoBox.vue'

export default {
    name: 'ChocolateHero',

    components: {
	  VideoBox
    },

    props: {
        title: {
            type: String
        },
        backgroundSrc: {
            type: String
        },
        data: {
            type: Object,
            default: () => {}
        },
        layout: {
          type: String,
          default: 'default'
        }
    },

    mounted()
    {
        this.$nextTick(() => {
            this.playTimeline()
        })
    },

    methods:
    {
        playTimeline()
        {
            this.tl = gsap.timeline({
				onComplete: () => {
					EventHub.$emit('window:on-resize', {width: window.innerWidth, height: window.innerHeight})
				}
			})

            this.tl.to(
                this.$refs.logo,
                {
                    opacity: 1,
                    duration: 1,
                    ease: 'power3.in'
                },
                0.3
			)
			
			this.tl.to(
                this.$refs.title,
                {
                    opacity: 1,
                    duration: 1,
                    ease: 'power3.in'
                },
                0.3
            )
        }
    }
}
</script>
<style lang="stylus">
.chocolate-hero
    position relative

    display flex
    align-items center
    justify-content center

    width 100vw
    height 100vh

    @media(max-width $breakpoints.md)
        height 100%
        flex 0 0 100vw

.chocolate-hero__bg
    position absolute
    width 100%
    height 100%
    top 0
    left 0

    background-size cover
    background-position 50% 50%

    transform scale(1.1)
    transform-origin 50% 50%

.chocolate-hero__bg--video
    position absolute
    width 100%
    height 100%
    top 0
    left 0

    background-size cover
    background-position 50% 50%

.chocolate-hero__logo
    z-index 3
    position absolute
    top 80px
    left 85px
    width 128px

    @media(max-width $breakpoints.md)
        top 30px
        left 15vw

        height 100px

.chocolate-hero__logo__img
    display block
    width 100%

    opacity 0

    will-change opacity

.chocolate-hero__title
	position relative
	z-index 2
	opacity 0

	will-change transform, opacity

</style>