<template>
    <ul class="quotes">
        <li ref="quote" v-for="(quote, index) in quotes" v-bind:key="index">
            <span v-for="(word, index) in quote" v-bind:key="index">{{ word }}</span>
        </li>
    </ul>
</template>

<script>
import gsap from 'gsap';
import Events from '@/webgl/intro/Events';

export default {
    created() {
        this.currentQuote = null;
    },

    mounted() {
        Events.addEventListener('mouse:down', this.mouseDownHandler);
        Events.addEventListener('mouse:up', this.mouseUpHandler);
    },

    beforeDestroy() {
        Events.removeEventListener('mouse:down', this.mouseDownHandler);
        Events.removeEventListener('mouse:up', this.mouseUpHandler);
    },

    computed: {
        quotes() {
            const quotes = this.$t('intro.quotes');
            const splitted = [];

            let item;
            let words;
            for (let i = 0, len = quotes.length; i < len; i++) {
                item = quotes[i];
                words = item.replace(/([ .,;]+)/g,'$1§sep§').split('§sep§');
                splitted.push(words);
            }
            return splitted;
        }
    },

    methods: {
        hideCurrent() {
            const prevQuote = this.currentQuote;
            if (this._timelineGoto) this._timelineGoto.kill();
            this._timelineHideCurrent = new gsap.timeline();
            if (prevQuote) this._timelineHideCurrent.to(prevQuote.children, 0.8, { alpha: 0, ease: 'sine.inOut' }, 0);
        },

        goto(index) {
            if (this._timelineHideCurrent) this._timelineHideCurrent.kill();
            const quote = this.$refs.quote[index];
            this._timelineGoto = new gsap.timeline();
            this._timelineGoto.to(quote.children, 1.6, { alpha: 1, stagger: { each: 0.08 }, ease: 'sine.inOut' }, 0.1);
            this.currentQuote = quote;
        },

        mouseDownHandler(index) {
            this.goto(index);
        },

        mouseUpHandler() {
            this.hideCurrent();
        }
    }
}
</script>

<style lang="stylus">
.quotes
    position absolute
    bottom 165px
    right 0
    left 0
    
    width 100%
    height 20px

    margin 0 auto

    text-align center

    pointer-events none
    user-select none

    @media(max-width $breakpoints.md)
        bottom 160px

.quotes li 
    position absolute
    top 0
    left 0

    width 100%
    height 100%

    padding: 0 50px

    font-family 'Overpass'
    font-size 14px
    font-weight 600
    line-height 1.3em
    color rgba(#48cecc, 0.55)
    letter-spacing 0.12em

	.is-ru &
		font-family 'Oswald'

    @media(max-width $breakpoints.md)
        font-size 14px

.quotes li span 
    opacity 0

</style>