import { Vector2 } from 'three';

const MIN_WIDTH = 1;
const MAX_WIDTH = 4;

const MIN_HEIGHT = 1;
const MAX_HEIGHT = 800;

const START_OFFSET = -MAX_HEIGHT;

export default class Drop {
    constructor({ context, texture, boundries, speed, alpha = 1 }) {
        this._context = context;
        this._texture = texture;
        this._boundries = boundries;
        this._speed = speed;
        this._globalAlpha = alpha;

        this._isSlowedDown = false;

        // Start location
        const x = this._boundries.p1.x + (this._boundries.p2.x - this._boundries.p1.x) * Math.random();
        const y = START_OFFSET + (this._boundries.p2.y + -START_OFFSET * 2) * Math.random();
        this._location = new Vector2(x, y);

        this._velocity = this._calcStartVelocity();
        this._acceleration = this._calcAcceleration();
        this._width = this._calcWidth();
        this._height = this._calcHeight();
        this._alpha = this._calcAlpha();
        this._texturePosition = this._calcTexturePosition();
        this._gradientScale = this._calcGradientScale();
        this._minVelocity = this._calcMinVelocity();
    }

    /**
     * Getters & Setters
     */
    set boundries(value) {
        this._boundries = value;
    }

    get type() {
        return this._type;
    }

    get speed() {
        return this._speed;
    }

    set speed(value) {
        this._speed = value;
    }

    get height() {
        return this._height;
    }

    get location() {
        return this._location;
    }

    /**
     * Public
     */
    slowDown() {
        this._isSlowedDown = true;
        this._acceleration.current.y = -0.15;
    }

    speedUp() {
        this._isSlowedDown = false;
        this._acceleration.current.y = this._acceleration.original.y;
    }

    update() {
        const acceleration = this._acceleration.current.clone();
        acceleration.multiplyScalar(this._speed);

        this._velocity.add(acceleration);
        this._velocity.clamp(this._minVelocity, new Vector2(0, 50));

        this._location.add(this._velocity);

        this._checkOutOfBounds();
    }

    draw() {
        this._drawDrop();
        this._drawGradient();
    }

    /**
     * Private
     */
    _reset() {
        this._location = this._calcStartLocation();
        this._velocity = this._calcStartVelocity();
        if (!this._isSlowedDown) this._acceleration = this._calcAcceleration();
        this._width = this._calcWidth();
        this._height = this._calcHeight();
        this._alpha = this._calcAlpha();
        this._texturePosition = this._calcTexturePosition();
        this._gradientScale = this._calcGradientScale();
        this._minVelocity = this._calcMinVelocity();
    }

    _calcStartLocation() {
        const x = this._boundries.p1.x + (this._boundries.p2.x - this._boundries.p1.x) * Math.random();
        const y = START_OFFSET;
        return new Vector2(x, y);
    }

    _calcStartVelocity() {
        const velocityX = 0;
        const velocityY = 0.2 + Math.random() * 10;
        return new Vector2(velocityX, velocityY);
    }

    _calcAcceleration() {
        const x = 0;
        let y = 0;
        if (Math.random() < 0.2) {
            y = 0.03 + 0.15 * Math.random();
        } else {
            y = 0.2 + 0.11 * Math.random();
        }
        const acceleration = new Vector2(x, y);
        return {
            original: acceleration,
            current: acceleration.clone()
        };
    }

    _calcWidth() {
        return MIN_WIDTH + (MAX_WIDTH - MIN_WIDTH) * Math.random();
    }

    _calcHeight() {
        if (Math.random() > 0.5) {
            return MIN_HEIGHT + (MAX_HEIGHT - MIN_HEIGHT) * Math.random();
        } else {
            return MIN_HEIGHT + (MAX_HEIGHT * 0.2 - MIN_HEIGHT) * Math.random();
        }
    }

    _calcAlpha() {
        return Math.random();
    }

    _calcTexturePosition() {
        const x = (this._texture.width - this._width) * Math.random();
        const y = (this._texture.height - this._height) * Math.random();
        return { x, y };
    }

    _calcGradientScale() {
        return 0.5 + Math.random() * 0.5;
    }

    _calcMinVelocity() {
        // return new Vector2(0, -0.2 -Math.random() * 4);
        return new Vector2(0, 0.2 + Math.random() * 3);
        // return new Vector2(0, 0);
    }

    _checkOutOfBounds() {
        if (this._location.y > this._boundries.p2.y) this._reset();
    }

    _drawDrop() {
        this._context.globalAlpha = this._alpha * this._globalAlpha;
        this._context.drawImage(this._texture, this._texturePosition.x, this._texturePosition.y, this._width, this._height, this._location.x, this._location.y, this._width, this._height);

        // this._context.fillStyle = '#000000';
        // this._context.beginPath();
        // this._context.fillRect(this._location.x, this._location.y, this._width, this._height);
        this._context.globalAlpha = 1;
    }

    _drawGradient() {
        const gradient = this._context.createLinearGradient(this._location.x, this._location.y, this._location.x, this._location.y + this._height);
        gradient.addColorStop(0, 'rgba(0, 0, 0, 1)');
        gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

        this._context.fillStyle = gradient;
        this._context.fillRect(this._location.x, this._location.y, this._width, this._height * this._gradientScale);
    }
}
