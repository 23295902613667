<template>
    <section class="legals" :class="{'scroll-wrapper': !$root.isMobile}" v-if="contentLoaded">
        <div class="scroll-elem" :class="{'js-scroll-elem': !$root.isMobile}">
            <NavSculptures/>

            <div class="legals__inner">
                <div class="container">
                    <div class="legals__title">
                        <h2 v-if="$root.isMobile" class="legals__title__elem title--sculptures" v-html="content.title"></h2>
                        <ParallaxeVerticalElem
                            v-else
                            :amount="0.5"
                        >
                            <h2 class="legals__title__elem title--sculptures" v-html="content.title"></h2>
                        </ParallaxeVerticalElem>
                    </div>

                    <div class="legals__section" v-for="(item, index) in content.section" :key="index">
                        <h4 class="legals__section__title" v-if="item.title" v-html="item.title"></h4>
                        <p class="legals__section__paragraph" v-for="(item2, index2) in item.paragraphs" :key="`paragraph-${index2}`" v-html="item2.paragraph"></p>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    </section>
</template>

<script>
import SmoothScrollVerticalMixin from '@/mixins/SmoothScrollVerticalMixin.js'
import API from '@/mixins/API.js'
import Page from '@/mixins/Page.js'

import NavSculptures from '@/components/NavSculptures.vue'
import Footer from '@/components/Footer.vue'
import ParallaxeVerticalElem from '@/components/ParallaxeVerticalElem.vue'

export default {
    name: 'Legals',

    mixins: [API, Page, SmoothScrollVerticalMixin],

    components: {
        Footer,
        NavSculptures,
        ParallaxeVerticalElem
    },

    created()
    {
        this.fetchLang(`/pages/1520?lang=${this.$i18n.locale}`, () => {
            this.contentLoaded = true

            this.onMounted()

            // we have 1500ms during transition
            window.setTimeout(() => {
                if(!this.$root.isMobile)
                {
                    this.SmoothScrollVerticalMixin_init()
                }
            }, 100)
        })
    }
}
</script>

<style lang="stylus" scoped>
.legals
    color $white

    background $black

.legals__inner
    margin-bottom 300px

    @media(max-width $breakpoints.md)
        margin-bottom 100px

.legals__title
    padding-top 330px
    padding-bottom 180px

    background $black

    @media(max-width $breakpoints.md)
        padding-top 400px
        padding-bottom 80px

.legals__title__elem
    grid-col(3.5, 4.5)

    color $white

    @media(max-width $breakpoints.md)
        width 100%
        margin 0

.legals__section
    grid-col(5, 2)
    max-width $sculpture-paragraph-mwidth
    margin-bottom 140px

    color $white

    @media(max-width $breakpoints.xl)
        grid-col(5, 3)

    @media(max-width $breakpoints.md)
        width 100%
        margin-left 0
        margin-bottom 60px

    &:last-of-type
        margin-bottom 0

.legals__section__title
    margin-bottom 40px
    text-transform uppercase

    @media(max-width $breakpoints.md)
        margin-bottom 30px

.legals__section__paragraph
    margin-bottom 40px
    opacity 0.3

    @media(max-width $breakpoints.md)
        margin-bottom 30px

    &:last-child
        margin-bottom 0
</style>