<template>
  <div class="home" :class="{'scroll-wrapper': !$root.isMobile}" v-if="contentLoaded">
    <div class="scroll-elem" :class="{'js-scroll-elem': !$root.isMobile}">

      <NavSculptures/>

      <section class="home__hero">
          <div class="home__hero__bg">
            <div class="home__hero__bg__elem" :style="{'background-image': `url(${content.cover_image.url})`}"></div>
          </div>
      </section>

      <section class="home__headline">
        <CanvasBackground scene="lines" type="vertical" />
        <div class="container">
          <h2 v-if="$root.isMobile" class="home__headline__title__elem title--sculptures" v-html="content.cover_title"></h2>
          <ParallaxeVerticalElem
            v-else
            class="home__headline__title"
            :amount="0.5"
          >
            <h2 class="home__headline__title__elem title--sculptures" v-html="content.cover_title"></h2>
          </ParallaxeVerticalElem>
          <p class="home__headline__description text-sculptures" v-html="content.cover_description"></p>
        </div>
      </section>

      <section class="home__sculptures">
        <div class="container">
			<SculptureCard class="home__sculpture" v-for="(item, index) in content.selected_sculptures" :key="index"
			:id="item.ID"
			:number="item.acf.number"
			:title="item.acf.name"
			:dimensions="item.acf.dimensions"
			:foundry="item.acf.foundry"
			:material="item.acf.material"
			:image="item.acf.images[0].image"
			/>
			<ArrowBtn class="home__sculptures__link"
				href="/sculptures/sculptures"
				:text="content.works_label"
			/>
        </div>
      </section>

      <section class="home__slider">
        <div class="home__slider__container">
          <Slider
            class="home__slider__elem"
            :items="content.selected_news"
            :prevLabel="content.news_prev_label"
            :nextLabel="content.news_next_label"
          />
		  <div class="home__slider__link-container container">
			<ArrowBtn class="home__slider__link"
				:href="`/sculptures/${newsPath}`"
				:text="content.news_all_label"
			/>
		  </div>
        </div>
      </section>
      
      <section class="home__expositions">
        <div class="container">
          <h2 v-if="$root.isMobile" class="home__expositions__title__elem title--sculptures" v-html="content.expositions_title"></h2>
          <ParallaxeVerticalElem
            v-else
            class="home__expositions__title"
            :amount="0.5"
          >
            <h2 class="home__expositions__title__elem title--sculptures" v-html="content.expositions_title"></h2>
          </ParallaxeVerticalElem>
        </div>
        <HomeCarousel class="home__expositions__slider"
          :items="content.selected_expositions"
          :prevLabel="content.expositions_prev_label"
          :nextLabel="content.expositions_next_label"
        >
          <ArrowBtn class="home-carousel__link"
            href="/sculptures/expositions"
            :text="content.expositions_all_label"
          />
        </HomeCarousel>
      </section>

      <section class="home__about">
        <CanvasBackground scene="lines" type="vertical" />
        <div class="container">
          <h2 v-if="$root.isMobile" class="home__about__title__elem title--sculptures" v-html="content.about_title"></h2>
          <ParallaxeVerticalElem
            v-else
            class="home__about__title"
            :amount="0.5"
          >
            <h2 class="home__about__title__elem title--sculptures" v-html="content.about_title"></h2>
          </ParallaxeVerticalElem>
          <ArrowBtn class="home__about__link"
            :href="`/sculptures/${aboutPath}`"
            :text="content.about_label"
            :isWhite="true"
          />
        </div>
      </section>

      <Footer/>
    </div>
  </div>
</template>

<script>
import SmoothScrollVerticalMixin from '@/mixins/SmoothScrollVerticalMixin.js'
import API from '@/mixins/API.js'
import Page from '@/mixins/Page.js'

import NavSculptures from '@/components/NavSculptures.vue'
import Footer from '@/components/Footer.vue'
import Slider from '@/components/Slider.vue'
import SculptureCard from '@/components/SculptureCard.vue'
import ArrowBtn from '@/components/ArrowBtn.vue'
import HomeCarousel from '@/components/HomeCarousel.vue'
import CanvasBackground from '@/components/CanvasBackground.vue'
import ParallaxeVerticalElem from '@/components/ParallaxeVerticalElem.vue'

export default {
  name: 'SculpturesHome',

  mixins: [API, Page, SmoothScrollVerticalMixin],

  components: {
    Slider,
    SculptureCard,
    ArrowBtn,
    HomeCarousel,
    CanvasBackground,
    Footer,
    NavSculptures,
    ParallaxeVerticalElem
  },

  data()
  {
    return {
      sliderItems: [
        {
            src: '/images/sculptures/slider/01.jpg'
        },
        {
            src: '/images/sculptures/slider/02.jpg'
        },
        {
            src: '/images/sculptures/slider/01.jpg'
        },
        {
            src: '/images/sculptures/slider/02.jpg'
        },
        {
            src: '/images/sculptures/slider/01.jpg'
        }
      ]
    }
  },

  computed: {
	newsPath()
	{
		let path = ''

		if(this.$i18n.locale === 'fr')
		{
			path = 'actualites'
		}
		else
		{
			path = 'news'
		}

		return path
	},
	aboutPath()
	{
		let path = ''
		if(this.$i18n.locale === 'fr')
		{
			path = 'a-propos'
		}
		else
		{
			path = 'about'
		}

		return path
	}
  },

  created()
  {
    this.fetchLang(`/pages/200`, () => {
      this.fetchAdditionalContent()
    })
  },

  methods: {
    fetchAdditionalContent()
    {
      const endpoints = []

        // Selected news
        for(let i = 0; i < this.content.selected_news.length; i++)
        {
          const endpoint = {
            exisitingPath: ['selected_news', i],
            url: `/sculpturenews/${this.content.selected_news[i].ID}?lang=${this.$i18n.locale}`
          }
          endpoints.push(endpoint)
        }

        // Selected news
        for(let i = 0; i < this.content.selected_expositions.length; i++)
        {
          const endpoint = {
            exisitingPath: ['selected_expositions', i],
            url: `/sculptureexposition/${this.content.selected_expositions[i].ID}?lang=${this.$i18n.locale}`
          }
          endpoints.push(endpoint)
        }

        // Selected Sculptures
        for(let i = 0; i < this.content.selected_sculptures.length; i++)
        {
          const endpoint = {
            exisitingPath: ['selected_sculptures', i],
            url: `/sculptureexposition/${this.content.selected_sculptures[i].ID}?lang=${this.$i18n.locale}`
          }
          endpoints.push(endpoint)
        }

        // Load all
        this.fetchMultiple(endpoints, () => {

          this.contentLoaded = true

          this.onMounted()

          // we have 1500ms during transition
          window.setTimeout(() => {
            if(!this.$root.isMobile)
            {
              this.SmoothScrollVerticalMixin_init()
            }
          }, 100)
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.home
  @media(max-width $breakpoints.md)
    overflow-x hidden

.home__hero
  display flex
  justify-content flex-end

  width 100%
  height 100vh

  background $black

  @media(max-width $breakpoints.md)
    height auto

.home__hero__bg
  width 50%
  height 100%

  @media(max-width $breakpoints.md)
    width 100%
    height 50vh

.home__hero__bg__elem
  width 100%
  height 100%

  background-size cover
  background-position 50%

.home__headline
  position relative
  overflow hidden

  padding-top 200px
  padding-bottom 200px

  color $white

  background $black

  @media(max-width $breakpoints.md)
    padding-top 100px
    padding-bottom 100px

.home__headline__title
	grid-col(1, 4)

	.is-ru &
		width calc((100% / 9) * 5)

	@media(max-width $breakpoints.md)
		width 100%
		margin-left 0

.home__headline__title__elem
  width 100%

  @media(max-width $breakpoints.md)
    margin-bottom 80px

    opacity 0.8

.home__headline__description
	grid-col(5, 2)
	padding-bottom 60px

	letter-spacing 0em
	opacity 0.4

	@media(max-width $breakpoints.md)
		width 100%
		margin-left 0
		padding-bottom 0

.home__sculptures
  position relative

  padding-top 60px
  padding-bottom 300px

  @media(max-width $breakpoints.xl)
    padding-bottom 320px

  @media(max-width $breakpoints.lg)
    padding-bottom 250px

  @media(max-width $breakpoints.md)
    padding-top 80px
    padding-bottom 40px

  &:after
    z-index 1
    content ''
    position absolute
    top 0
    left 50%
    
    width 1px
    height 100%

    background-color $grey

    @media(max-width $breakpoints.md)
      display none

.home__sculpture
  padding-top 120px

  @media(max-width $breakpoints.md)
    padding-top 100px

    &:first-child
      padding-top 0

.home__sculptures__link
  margin-top 180px
  grid-col(1, 0)

  @media(max-width $breakpoints.xl)
    margin-top 140px

  @media(max-width $breakpoints.lg)
    margin-top 100px

  @media(max-width $breakpoints.md)
    width 100%
    margin-left 0
    margin-top 40px

.home__expositions
  position relative
  padding-bottom 330px

  @media(max-width $breakpoints.md)
    padding-bottom 100px

  &:after
    z-index 1
    content ''
    position absolute
    top 0
    left 50%
    
    width 1px
    height 100%

    background-color $grey 

    @media(max-width $breakpoints.md)
      display none

.home__expositions__title
	z-index 2
	position relative
	grid-col(1, 0)

	@media(max-width $breakpoints.md)
		width 100%
		margin-left 0

.home__expositions__title__elem
  z-index 2
  position relative

  font-weight 400

  margin-bottom 100px

.home__slider
  overflow hidden
  position relative

  padding-bottom 300px

  @media(max-width $breakpoints.xl)
    padding-bottom 320px

  @media(max-width $breakpoints.lg)
    padding-bottom 250px

  @media(max-width $breakpoints.md)
    padding-bottom 100px

  &:after
    z-index 1
    content ''
    position absolute
    top 0
    left 50%
    
    width 1px
    height 100%

    background-color $grey

    @media(max-width $breakpoints.md)
      display none

.home__slider__container
	z-index 2
	position relative

	@media(max-width $breakpoints.md)
		padding-left 10%

.home__slider__elem.container
	padding 0

.home__slider__link
  margin-top 150px
  grid-col(1, 0)

  @media(max-width $breakpoints.md)
    width 100%
    margin-left 0
    margin-top 60px

.home__slider__link-container.container
	@media(max-width $breakpoints.md)
    width 100%
    padding 0

.home__about
  position relative
  overflow hidden

  width 100%
  padding 370px 0 500px 0

  color $white

  background $black
  background-image url('~@/assets/images/vegetal-bg.jpg')
  background-size cover

  @media(max-width $breakpoints.md)
    padding 120px 0px

.home__about__title
  grid-col(1, 5)

  @media(max-width $breakpoints.md)
    width 100%
    margin-left 0

.home__about__title__elem
  margin-bottom 150px

  @media(max-width $breakpoints.md)
    margin-bottom 80px

.home__about__link
  grid-col(1, 0)

  @media(max-width $breakpoints.md)
    width 100%
    margin-left 0
</style>