const config = {}

// langs
config.langs = ['fr', 'en', 'ja', 'ru']

// User agent
const USER_AGENT = navigator.userAgent.toLowerCase()

config.support = {}
config.support.touch = ('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)
config.support.android = USER_AGENT.indexOf('android') > -1
config.support.ios = /iPad|iPhone|iPod|ipad|iphone|ipod/.test(USER_AGENT) && !window.MSStream
config.support.ie = document.documentMode || /Edge/.test(USER_AGENT)
config.support.firefox = (/Firefox/i.test(navigator.userAgent))

// Mobile
config.mobileLimit = 1024

config.breakpoints = {}
config.breakpoints.mobile = {}
config.breakpoints.mobile.xsmall = 768
config.breakpoints.mobile.small = 1024

// Environment
config.environment = 'prod'

const environments = {}
environments.dev = ['localhost', '0.0.0.0']
environments.remoteDev = ['192.168.1.140', '0.0.0.0']
environments.preprodig = ['http://patrick-roger.imm-g-prod.com']
environments.prod = ['https://experience.patrickroger.com']

for(const _environment in environments)
{
    const domains = environments[_environment]

    for(const _domain of domains)
    {
        if(window.location.href.indexOf(_domain) !== -1)
        {
            config.environment = _environment
        }
    }
}

// API
config.api = {}

const apiUrls = {
    dev: 'http://patrick-roger-api.imm-g-prod.com',
    remoteDev: 'http://patrick-roger-api.imm-g-prod.com',
	preprodig: 'http://patrick-roger-api.imm-g-prod.com',
	prod: 'https://experience-api.patrickroger.com',
}

config.api.url = apiUrls[config.environment]
config.api.path = '/wp-json/acf/v3'

export default config