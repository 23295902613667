<template>
<div class="chocolate-carousel">

	<div class="chocolate-carousel__bg">
		<ParallaxeHorizontalElem
			v-if="!$root.isMobile"
			:isInner="true"
			:amount="0.1"
		>
			<template v-for="(item, index) in items">
				<div :key="index" class="chocolate-carousel__bg__elem js-carousel-image" :style="{'background-image': `url(${item.image.url})`}" :class="{'is-active': index === currentIndex}"></div>
			</template>
		</ParallaxeHorizontalElem>
		<template v-else>
			<div v-for="(item, index) in items" :key="index" class="chocolate-carousel__bg__elem js-carousel-image" :style="{'background-image': `url(${item.image.url})`}" :class="{'is-active': index === currentIndex}"></div>
		</template>
	</div>

	<div class="chocolate-carousel__text">

		<div class="chocolate-carousel__events">

			<div class="chocolate-carousel__nav" v-if="items.length > 1">
				<p class="chocolate-carousel__prev text-chocolate" @click="onClickPrev" :class="{'is-disabled': disablePrev}"> {{ prevLabel }} </p>
				<span class="chocolate-carousel__nav__line"></span>
				<p class="chocolate-carousel__next text-chocolate" @click="onClickNext" :class="{'is-disabled': disableNext}"> {{ nextLabel }} </p>
			</div>

			<div class="chocolate-carousel__event js-carousel-info" v-for="(item, index) in items" :key="index" :class="{'is-active': index === currentIndex}">
				<div class="chocolate-carousel__event__header" v-if="item.title || item.date">
					<p class="chocolate-carousel__title subtitle-chocolate" v-html="item.title"></p>
					<p class="chocolate-carousel__date text-chocolate" v-html="item.date"></p>
				</div>
				<p class="chocolate-carousel__description text-chocolate js-carousel-description" v-html="item.description" :class="{'is-header': !item.title && !item.date}"></p>
			</div>
			<div class="chocolate-carousel__link" v-if="hasLink">
				<slot></slot>
			</div>
		</div>
	</div>

	<div class="chocolate-carousel__product-links" v-if="hasProductLinks">
		<template v-for="(item, index) in items">
			<ArrowBtnExternal
				v-if="item.product_link && currentIndex === index"
				:key="index"
				class="chocolate-carousel__product-link"
				:class="{'is-active': index === currentIndex}"
				:href="item.product_link"
				:text="productLabel"
			/>
		</template>
	</div>

</div>
</template>

<script>
import Carousel from '@/mixins/Carousel.js'

import ParallaxeHorizontalElem from '@/components/ParallaxeHorizontalElem.vue'
import ArrowBtnExternal from '@/components/ArrowBtnExternal.vue'

export default {
	name: 'ChocolateCarousel',

	mixins: [Carousel],

	components: {
		ParallaxeHorizontalElem,
		ArrowBtnExternal
	},

	props: {

	  items: {
		type: Array
	  },
	  prevLabel: {
		  type: String
	  },
	  nextLabel: {
		  type: String
	  },
	  hasLink: {
		  type: Boolean,
		  default: true
	  },
	  hasProductLinks: {
		  type: Boolean,
		  defaault: false
	  },
	  productLabel: {
		  type: String
	  }
	},

	data() {
	  return {

	  }
	},

	methods: {

	},

	mounted()
	{
		this.$nextTick(() => {
			this.init()
		})
	}
}
</script>

<style lang="stylus">
.chocolate-carousel
	width 48vw
	height 100vh

	@media(max-width $breakpoints.md)
		width 100vw
		height 100%

.chocolate-carousel__bg
	overflow hidden
	position relative
	height 50vh

	@media(max-width $breakpoints.md)
		width 100%
		height 50%

.chocolate-carousel__bg__elem
	position absolute
	top 0
	left 0

	width 100%
	height 100%

	background-size cover
	background-position 50% 50%

	opacity 0

	transform scale(1.05)

	will-change opacity, transform

	&.is-active
		position relative

		opacity 1

.chocolate-carousel__text
	display flex

	height 50vh
	padding-top 8vh

	@media(max-width $breakpoints.md)
		height 50%
		padding-top 5vh

.chocolate-carousel__events
	position relative
	width 100%
	height 100%

	@media(max-width $breakpoints.md)
		max-width none
		height 100%
		padding-left 0px
		padding-right 10px

.chocolate-carousel__event
	position absolute 
	top 0
	left 0

	font-size 18px

	opacity 0

	will-change opacity, transform

	&.is-active
		position relative

		opacity 1

.chocolate-carousel__event__header
	max-width 70%
	margin-bottom 3.5vh

	@media(max-width $breakpoints.md)
		max-width none

.chocolate-carousel__date
	margin-bottom 2rem
	max-width 400px

.chocolate-carousel__title
	margin-bottom 10vh
	max-width 400px

.chocolate-carousel__description
	max-width 475px

	will-change opacity, transform

.chocolate-carousel__nav
	z-index 2
	position absolute
	top 0
	right 0

	display flex
	align-items center

	line-height 0

	@media(max-width $breakpoints.md)
		flex-direction column
		align-items flex-start

.chocolate-carousel__nav__line
	display block

	height 1px
	width 30px
	margin 0 15px

	background $black

	@media(max-width $breakpoints.md)
		display none


.chocolate-carousel__prev, .chocolate-carousel__next
	user-select none 

	cursor pointer
	display inline-block

	font-size 18px
	line-height 1

	&.is-disabled
		opacity 0.3

	@media(max-width $breakpoints.md)
		margin-bottom 0px
		padding-bottom 10px


.chocolate-carousel__link
	position absolute
	bottom 6.5vh
	left 0

	@media(max-height $breakpoints-h.xxl)
		bottom 4vh

	@media(max-width $breakpoints.md)
		bottom 5%
		padding-left 0px

.chocolate-carousel__product-links
	position absolute
	bottom 10vh
	left 0

	@media(max-height $breakpoints-h.xxl)
		bottom 14vh

	@media(max-width $breakpoints.md)
		bottom 6%
		padding-left 0px

</style>