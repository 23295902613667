<template>
<div class="shop-card">
	<!-- router link -->
	<div v-if="internal" class="shop-card__item">
		<div class="shop-card__item__wrapper" ref="wrapper">
			<router-link :to="`/${$i18n.locale}${endpoint}`">
				<div class="shop-card__item__bg" :style="{'background-image': `url(${image.url})`}"></div>
			</router-link>
		</div>
		<router-link :to="`/${$i18n.locale}${endpoint}`">
			<p class="shop-card__item__title title-chocolate" v-html="title"></p>
		</router-link>
	</div>
	<!-- external link -->
	<div v-else class="shop-card__item">
		<div class="shop-card__item__wrapper">
			<a :href="link" target="_blank">
				<div class="shop-card__item__bg" :style="{'background-image': `url(${image.url})`}">
				</div>
			</a>
		</div>
		<a :href="link" target="_blank">
			<p class="shop-card__item__title title-chocolate" v-html="title"></p>
		</a>
	</div>
</div>
</template>

<script>
import { gsap } from 'gsap'

export default {
    name: 'ShopCard',

    components: {

    },

    props: {
        image: {
            type: Object
		},
		internal: {
			type: Boolean,
			default: true
		},
		link: {
			type: String
		},
        title: {
            type: String
        }
	},

	computed:
	{
		endpoint()
		{
			let path = ''
			if(this.$i18n.locale === 'fr')
			{
				path = '/chocolat/boutiques'
			}
			else
			{
				path = '/chocolate/shops'
			}

			return path
		}
	},

	created()
	{
		this.clip = {}
		this.clip.x = {}
		this.clip.y = {}
		this.clip.x.value = 12.5
		this.clip.y.value = 14
        this.isClicked = false
	},
	
	methods: {

		// Animation
		        playTimeline()
        {
            this.tl = gsap.timeline()

            const titleDuration = 0.4

            // Title out
            this.tl.to(
                this.$refs.title,
                {
                    opacity: 0,
                    y: -20,
                    duration: titleDuration
                },
                0
            )

            // Background out
            this.tl.to(
                this.$refs.bg,
                {
                    scale: 1.1,
                    opacity: 1,
                    ease: 'power4.inOut',
                    duration: 0.9,
                    onComplete: this.onCompleteAnim
                },
                0.1
            )

			// Clip out
			this.tl.to(
                this.clip.y,
                {
                    value: 0,
                    ease: 'power4.inOut',
                    duration: 0.9
                },
                0.1
			)
			
            this.tl.to(
                this.clip.x,
                {
                    value: 0,
                    ease: 'power4.inOut',
                    duration: 0.9,
                    onUpdate: () => {
                        this.$refs.wrapper.style.clipPath = `inset(${this.clip.y.value}% ${this.clip.x.value}% ${this.clip.y.value}% ${this.clip.x.value}%)`
                    }
                },
                0.1
            )
        },

        onCompleteAnim()
        {
            this.$router.push({ path: this.link, query: { transition: 'cover' } })
        },

		// Events
		onClick()
        {
            if(this.$root.isMobile)
            {
                this.$router.push({ path: this.link })
            }
            else
            {
                this.playTimeline()
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
.shop-card
    width 100vw
    height 100vh

    @media(max-width $breakpoints.md)
        width 100vw
        height 100%

.shop-card__item
	position relative
	display block
	width 100%
	height 100%

.shop-card__item__wrapper
	overflow hidden
	position relative

	height 100%
	width 100%

	transform scale(1)
	clip-path inset(25% 20% 25% 20%)

	@media(max-width $breakpoints.md)
		background-color transparent
		clip-path none

.shop-card__item__bg
	cursor pointer
	position relative

	height 100%
	width 100%

	background-size cover
	background-position 50% 50%

	transform-origin 50% 50%
	transform scale(0.61)
	opacity 1

	@media(max-width $breakpoints.md)
		opacity 1
		transform scale(0.8)

.shop-card__item__title
	cursor pointer
	position absolute
	bottom 15%
	left 50%

	text-align center

	transform translate3d(-50%, 0, 0)
</style>