import { Object3D, RingGeometry, Mesh, ShaderMaterial, Color } from 'three';
import gsap from 'gsap';

import Debugger from '@/webgl/utils/Debugger';

import vertexShader from './shaders/ring.vert.glsl';
import fragmentShader from './shaders/ring.frag.glsl';

export default class Ripples extends Object3D {
    constructor(amount) {
        super();

        this._amount = amount;
        this._pool = [];
        this._speed = 1;

        this._geometry = this._createGeometry();
        this._material = this._createMaterial();

        this._bindHandlers();
        this._setupDebugGui();
    }

    /**
     * Getters & Setters
     */
    get speed() {
        return this._speed;
    }

    set speed(value) {
        this._speed = value;
    }

    /**
     * Public
     */
    show() {
        let ripple;
        let position = 0;

        const ripples = this._getRipples();
        const timeline = new gsap.timeline({ onComplete: this._showCompleteHandler, onCompleteParams: [ ripples ] });
        for (let i = 0, len = ripples.length; i < len; i++) {
            ripple = ripples[i];
            position += i * (0.03 + Math.random() * 0.05);
            timeline.to(ripple.material.uniforms.uAlpha, 0.2, { value: 0.6 }, position);
            timeline.fromTo(ripple.scale, 3.5, { x: 0.5, y: 0.5 }, { x: 4.5, y: 4.5, ease: 'expo.out' }, position);
            timeline.to(ripple.material.uniforms.uAlpha, 0.8, { value: 0 }, 1.7 + position);
        }

        return timeline;
    }

    update(delta) {
        let item;
        for (let i = 0, len = this.children.length; i < len; i++) {
            item = this.children[i];
            item.material.uniforms.uTime.value += delta * 0.31 * this._speed;
        }
    }

    /**
     * Private
     */
    _bindHandlers() {
        this.show = this.show.bind(this);
        this._showCompleteHandler = this._showCompleteHandler.bind(this);
    }

    _createGeometry() {
        return new RingGeometry(0.8, 0.8015, 80); //0.801
    }

    _createMaterial() {
        const material = new ShaderMaterial({
            vertexShader,
            fragmentShader,
            transparent: true,
            uniforms: {
                uColor: { value: new Color(0x35d6cb) },
                uAlpha: { value: 0. },
                uNoiseScale: { value: 157 },
                uNoiseOffset: { value: 0 }, 
                uNoiseStrength: { value: 0.6 },
                uNoiseBase: { value: 1.0 },
                uTime: { value: 0 }
            }
        });
        return material;
    }

    _getRipples() {
        if (this._pool.length > 0) {
            return this._pool.pop();
        }
        return this._create();
    }

    _create() {
        const ripples = [];
        for (let i = 0; i < this._amount; i++) {
            const mesh = new Mesh(this._geometry, this._material.clone());
            mesh.rotation.x = Math.PI * -0.5;
            mesh.scale.x = 4.5;
            mesh.scale.y = 4.5;
            mesh.material.uniforms.uNoiseOffset.value = Math.random() * 1000;
            ripples.push(mesh);
            this.add(mesh);
        }

        return ripples;
    }
    
    _recycle(ripples) {
        this._pool.push(ripples);
    }

    /**
     * Debug
     */
    _setupDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        const debugGuiFoot = Debugger.gui.getFolder('Foot');
        const debugGui = debugGuiFoot.addFolder('Ripples');
        debugGui.add(this._material.uniforms.uNoiseScale, 'value', 0, 200).name('noiseScale').onChange(() => {
            const ring = this._pool[0][0];
            ring.material.uniforms.uNoiseScale.value = this._material.uniforms.uNoiseScale.value;
        });
        debugGui.add(this._material.uniforms.uNoiseStrength, 'value', 0, 10).name('noiseStrength').onChange(() => {
            const ring = this._pool[0][0];
            ring.material.uniforms.uNoiseStrength.value = this._material.uniforms.uNoiseStrength.value;
        });
        debugGui.add(this._material.uniforms.uNoiseBase, 'value', 0, 1).name('noiseBase').onChange(() => {
            const ring = this._pool[0][0];
            ring.material.uniforms.uNoiseBase.value = this._material.uniforms.uNoiseBase.value;
        });
        debugGui.add(this._material.uniforms.uTime, 'value', 0, 200).name('uTime').onChange(() => {
            const ring = this._pool[0][0];
            ring.material.uniforms.uTime.value = this._material.uniforms.uTime.value;
        });
        // debugGui.open();
    }

    /**
     * Handlers
     */
    _showCompleteHandler(ripples) {
        this._recycle(ripples);
    }
}