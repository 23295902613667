<template>
    <div class="chocolate-title">
        <template v-if="title">
            <ParallaxeHorizontalElem
                v-if="!$root.isMobile"
                :amount="amount"
				:direction="-1"
            >
                <h2 class="chocolate-title__title quote-chocolate" v-html="title"></h2>
            </ParallaxeHorizontalElem>
            <h2 v-else class="chocolate-title__title quote-chocolate" v-html="title"></h2>
        </template>
    </div>
</template>

<script>
import ParallaxeHorizontalElem from '@/components/ParallaxeHorizontalElem.vue'

export default {
    name: 'ChocolateTitle',

    props: {
        title: {
            type: String
        },
        amount: {
            type: Number,
            default: 1  
        }
    },

    components: {
        ParallaxeHorizontalElem
    }
}
</script>

<style lang="stylus">

.chocolate-title
    position relative
    width 100%
    height 100%

    .parallaxe-elem
        height auto

.chocolate-title__title
	width 700px
	max-width none

	@media(max-width $breakpoints.md)
		width 350px

	@media(max-width $breakpoints.md)
		width 350px
        

</style>