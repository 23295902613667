// Three
import { Object3D, Mesh, Group, PlaneBufferGeometry, ShaderMaterial, Color, AdditiveBlending } from 'three';

// Utils
import Debugger from '@/webgl/utils/Debugger';
import WindowResizeObserver from '@/webgl/utils/WindowResizeObserver';

// Shaders
import vertexShader from '@/webgl/background/shaders/lines-vertical/vertex.glsl';
import fragmentShader from '@/webgl/background/shaders/lines-vertical/fragment.glsl';

// Config
const AMOUNT = 5;
const COLOR = '#ffffff';

export default class LinesVerticalScene extends Object3D {
    constructor(domElement) {
        super();

        this.name = 'LinesVerticalScene';

        this._domElement = domElement;

        this._width = null;
        this._height = null;
        this._pageOffset = 0;

        this._lines = [];
        this._amplitude = 14;
        this._frequency = 1.47;

        this._elements = new Group();
        this.add(this._elements);

        this._bindHandlers();
        this._createLines();
        this._resize();
        this._setupEventListeners();
        this._setupDebugGui();
    }

    destroy() {
        this._removeEventListeners();
        this._removeDebugGui();
    }

    /**
     * Public
     */
    update({ time }) {
        const children = this._elements.children;
        for (let i = 0, len = children.length; i < len; i++) {
            children[i].material.uniforms.uTime.value = time * 0.0001;
        }
    }

    updatePosition(scrollPosition) {
        this.position.y = scrollPosition.y;
    }

    resize() {
        this._resize();
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._resizeHandler = this._resizeHandler.bind(this);
    }

    _setupEventListeners() {
        WindowResizeObserver.addEventListener('resize', this._resizeHandler);
        WindowResizeObserver.addEventListener('resize:complete', this._resizeHandler);
    }

    _removeEventListeners() {
        WindowResizeObserver.removeEventListener('resize', this._resizeHandler);
        WindowResizeObserver.removeEventListener('resize:complete', this._resizeHandler);
    }

    _createLines() {
        const width = 1;
        const height = 1;

        const geometry = new PlaneBufferGeometry(width, height, 1, 100);
        this._material = new ShaderMaterial({
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            transparent: true,
            blending: AdditiveBlending,
            wireframe: false,
            uniforms: {
                uTime: { value: 0 },
                uTimeOffset: { value: null },
                uFrequency: { value: null },
                uAmplitude: { value: null },
                uGlobalAmplitude: { value: null },
                uColor: { value: new Color(COLOR) },
                uAlpha: { value: null }
            }
        });

        let material;
        let mesh;
        for (let i = 0; i < AMOUNT; i++) {
            material = this._material.clone();
            material.uniforms.uTimeOffset.value = Math.random() * 100;
            material.uniforms.uFrequency.value = this._frequency;
            material.uniforms.uAmplitude.value = 0.2 + Math.random() * 0.8;
            material.uniforms.uGlobalAmplitude.value = this._amplitude;
            material.uniforms.uAlpha.value = 0.1 + Math.random() * 0.2;

            mesh = new Mesh(geometry, material);
            mesh.scale.x = Math.random() > 0.5 ? 2 : 1;
            this._elements.add(mesh);
        }
    }

    _getElementGlobalOffset(element) {
        let x = 0
        let y = 0
        const getOffset = function(element) {
            x += element.offsetLeft
            y += element.offsetTop
            if (element.offsetParent !== null) {
                getOffset(element.offsetParent)
            }
        }
        getOffset(element)
        return { x, y }
    }

    /**
     * Resize
     */
    _resize() {
        this._width = this._domElement.offsetWidth;
        this._height = this._domElement.offsetHeight;

        this._viewportWidth = WindowResizeObserver.width;
        this._viewportHeight = WindowResizeObserver.height;

        this._pageOffset = this._getPageOffset();

        this._positionElementsContainer();
        this._resizeElements();
    }

    _getPageOffset() {
        // const bodyRect = document.body.getBoundingClientRect();
        // const elemRect = this._domElement.getBoundingClientRect();
        // return elemRect.top - bodyRect.top;
        const globalOffset = this._getElementGlobalOffset(this._domElement);
        return globalOffset.y;
    }

    _positionElementsContainer() {
        const offset = this._viewportWidth * 0.5 % 1 === 0 ? 0.5 : 0;
        this._elements.position.x = offset;
        this._elements.position.y = (this._height - this._viewportHeight) * -0.5 - this._pageOffset;
    }

    _resizeElements() {
        const children = this._elements.children;
        for (let i = 0, len = children.length; i < len; i++) {
            children[i].scale.y = this._height;
        }
    }

    /**
     * Debug
     */
    _setupDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        this._debugGui = gui.addFolder(`${this.name}-${this.uuid.substr(0, 4)}`);
        this._debugGui.add(this, '_amplitude', 0, 100, 1).name('amplitude').onChange(() => {
            const children = this._elements.children;
            for (let i = 0, len = children.length; i < len; i++) {
                children[i].material.uniforms.uGlobalAmplitude.value = this._amplitude;
                
            }
        });
        this._debugGui.add(this, '_frequency', 0, 10, 0.01).name('frequency').onChange(() => {
            const children = this._elements.children;
            for (let i = 0, len = children.length; i < len; i++) {
                children[i].material.uniforms.uFrequency.value = this._frequency;
            }
        });
        this._debugGui.open();
    }

    _removeDebugGui() {
        if (this._debugGui) Debugger.gui.removeFolder(this._debugGui);
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }
}
