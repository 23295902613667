<template>
  <div class="home">
    <div class="container" ref="container"></div>
    <PreloaderHome ref="preloader" v-if="showPreloader" />
    <MenuHome v-if="!showPreloader" ref="menu" />
    <QuotesHome ref="quotes" />
    <HomeSceneButton ref="sceneButton" />
    <CursorHome ref="cursorHome" v-if="!isTouch" />
    <HomeMuteButton ref="muteButton" v-if="!isTouch" />
  </div>
</template>

<script>
import gsap from 'gsap';
import Pizzicato from 'pizzicato';
import Page from '@/mixins/Page.js'
import Preloader from '@/webgl/intro/Preloader';
import PreloaderHome from '@/components/PreloaderHome'
import MenuHome from '@/components/MenuHome'
import CursorHome from '@/components/CursorHome'
import HomeSceneButton from '@/components/HomeSceneButton'
import QuotesHome from '@/components/QuotesHome'
import HomeMuteButton from '@/components/HomeMuteButton'
import Intro from '@/webgl/intro'
import Events from '@/webgl/intro/Events';

export default {
  name: 'Home',

  mixins: [Page],

  components: {
    PreloaderHome,
    MenuHome,
    CursorHome,
    HomeSceneButton,
    QuotesHome,
    HomeMuteButton
  },

  data() {
    return {
      showPreloader: !this.$root.introPreloaderComplete,
      isTouch: 'ontouchstart' in window,
    };
  },

  mounted()
  {
    this.onMounted()
    this.setupEventListeners()

    if (this.$root.introPreloaderComplete) {
      setTimeout(() => {
        this.setupIntro()
      }, 1500)
    }
  },

  beforeDestroy() {
    if (this.intro) {
      this.intro.destroy()
      this.intro.removeEventListener('progress', this.introProgressHandler)
      this.intro.removeEventListener('scene:start', this.sceneStartHandler)
      this.intro.removeEventListener('scene:finished', this.sceneFinishedHandler)
    }
    this.stopBackgroundSound()
    this.$refs.sceneButton.$off('click', this.sceneButtonClickHandler)
    Events.removeEventListener('mouse:down', this.mouseDownHandler)
    Events.removeEventListener('mouse:up', this.mouseUpHandler)
  },

  methods: {
    setupEventListeners()
    {
      if (!this.$root.introPreloaderComplete) {
        this.$refs.preloader.$once('complete', this.preloaderCompleteHandler)
        this.$refs.preloader.$once('cursor:show-label-enter', this.preloaderCursorShowLabelEnterHandler)
        this.$refs.preloader.$once('cursor:hide-label-enter', this.preloaderCursorHideLabelEnterHandler)
      }
      this.$refs.sceneButton.$on('click', this.sceneButtonClickHandler)
      Events.addEventListener('mouse:down', this.mouseDownHandler)
      Events.addEventListener('mouse:up', this.mouseUpHandler)
    },

    setupIntro()
    {
      this.intro = new Intro({
        container: this.$refs.container
      });
      this.intro.addEventListener('progress', this.introProgressHandler)
      this.intro.addEventListener('scene:start', this.sceneStartHandler)
      this.intro.addEventListener('scene:finished', this.sceneFinishedHandler)
    },

    playBackgroundSound()
    {
      const lowPassFilter = new Pizzicato.Effects.LowPassFilter()
      const backgroundSound = Preloader.get('background-sound')
      backgroundSound.addEffect(lowPassFilter, {
        frequency: 350,
        peak: 20
      })
      backgroundSound.volume = 0.5
      backgroundSound.loop = true
      backgroundSound.attack = 2
      backgroundSound.play()
    },

    stopBackgroundSound()
    {
      const backgroundSound = Preloader.get('background-sound')
      gsap.to(backgroundSound, 2, { volume: 0, ease: 'none' });
    },

    /**
     * Handlers
     */
    preloaderCursorShowLabelEnterHandler()
    {
      if (this.$refs.cursorHome) this.$refs.cursorHome.showLabelEnter()
      this.$refs.preloader.showLabelEnter()
    },

    preloaderCursorHideLabelEnterHandler()
    {
      if (this.$refs.cursorHome) this.$refs.cursorHome.hideLabelEnter()
      this.$refs.preloader.hideLabelEnter()
      this.playBackgroundSound()
    },

    preloaderCompleteHandler()
    {
      this.setupIntro()
      this.$root.introPreloaderComplete = true
      this.showPreloader = false
      
      gsap.delayedCall(2.3, () => {
        this.$refs.sceneButton.show()
        if (this.$refs.muteButton) this.$refs.muteButton.show()
      })
    },

    introProgressHandler(progress)
    {
      this.$refs.sceneButton.progress(progress)
    },

    sceneStartHandler(index)
    {
      // this.$refs.quotes.goto(index)
    },

    sceneFinishedHandler()
    {
      this.$refs.sceneButton.shrink()
      // this.$refs.quotes.hideCurrent()
    },

    sceneButtonClickHandler()
    {
      this.intro.next()
    },

    mouseDownHandler()
    {
      this.$refs.menu.fadeOut()
      if (this.$refs.muteButton) this.$refs.muteButton.fadeOut()
      this.$refs.sceneButton.fadeOut()
    },

    mouseUpHandler()
    {
      this.$refs.menu.fadeIn()
      if (this.$refs.muteButton) this.$refs.muteButton.fadeIn()
      this.$refs.sceneButton.fadeIn()
    },
  }
}
</script>

<style lang="stylus" scoped>
.home
  overflow hidden
  position relative

  height 100vh

  color $white

  background $black

.container
  overflow hidden
  position fixed

  width 100vw
  height 100vh
  max-width none

  padding 0

  // opacity 0

</style>