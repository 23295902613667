<template>
    <section class="slider" ref="slider" :class="{'container': items.length === 1}">
        <div class="slider__nav" v-if="$root.isMobile">
			<template v-if="items.length > 1">
				<span class="slider__nav--prev" @click="onClickPrev">{{ prevLabel }}</span>
				<span class="slider__nav--next" @click="onClickNext">{{ nextLabel }}</span>
			</template>
        </div>
        <div class="container" v-else>
            <div class="slider__nav" v-if="items.length > 1">
                <span class="slider__nav--prev" @click="onClickPrev">{{ prevLabel }}</span>
                <span class="slider__nav--next" @click="onClickNext">{{ nextLabel }}</span>
            </div>
        </div>
        <div class="slider__content" ref="content" :class="{'is-solo': items.length === 1}">
            <div v-for="(item, index) in items" :key="index" class="slider__item" ref="item">
                <div class="slider__item__image">
                    <img class="slider__item__image__elem" :src="item.acf.preview_image.url" alt="" data-object-fit="cover">
                </div>
                <router-link class="slider__item__content" :to="`/${$i18n.locale}/sculptures/${newsPath}/${item.ID}`">
                    <p class="slider__item__date" v-html="item.acf.preview_date"> </p>
                    <p class="slider__item__title" v-html="item.acf.preview_title"></p>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import * as Hammer from 'hammerjs'

import MathUtils from '@/utils/MathUtils'
import EventHub from '@/utils/EventHub'
import GetAbsoluteBoundingRect from '@/utils/GetAbsoluteBoundingRect.js'

export default {

    name: 'Slider',

    props: 
    {
        items: {
            type: Array
        },
        prevLabel: {
            type: String
        },
        nextLabel: {
            type: String
        }
    },

    data() 
    {
        return {
            isActive: true
        }
	},
	
	computed: {
		newsPath()
		{
			let path = ''

			if(this.$i18n.locale === 'fr')
			{
				path = 'actualites'
			}
			else
			{
				path = 'news'
			}

			return path
		}
	},

    created()
    {
        this.addListeners()

        this.slider = {
            canDrag: true,
            maxItemWidth: null,
            width: 0,
            limits: {
                left: 0,
                right: 0
            },
            drag: {
                dragging: false,
                x: 0,
                start: {
                    x: 0
                },
                target: {
                    x: 0
                }
            },
            parallaxValue: 0.1,
            items: [],
            domItemsImages: []
        }
    },

    mounted()
    {
        this.windowObj = {
            width: window.innerWidth,
            height: window.innerHeight
		}
		
		if(this.items.length > 1)
		{
			this.$nextTick( () =>
			{
				this.onResize(this.windowObj)
	
				this.initSlider()
			})
	
			this.slider.itemWidthRatio = this.$root.isMobile ? 0.75 : 0.33
		}

    },

    destroyed()
    {
        this.removeListeners()
        this.destroySlider()
    },

    methods: {
        addListeners()
        {
            EventHub.$on('window:on-resize', this.onResize)
            EventHub.$on('application:on-tick', this.onTick)
        },

        removeListeners()
        {
            EventHub.$off('window:on-resize', this.onResize)
            EventHub.$off('application:on-tick', this.onTick)
        },

        getFullWidth()
        {
            let fWidth = 0

            for(let i = 0; i < this.$refs.item.length; i++)
            {
                const item = this.$refs.item[i]
                const marginRight = this.slider.itemMarginRight ? this.slider.itemMarginRight : parseInt(window.getComputedStyle(item).marginRight)

                let roundedWidth

                roundedWidth = Math.ceil(this.windowObj.width * this.slider.itemWidthRatio)

                if(this.slider.maxItemWidth)
                {
                    roundedWidth = roundedWidth > this.slider.maxItemWidth ? this.slider.maxItemWidth : roundedWidth
                }
            
                this.$refs.item[i].style.width = `${roundedWidth}px`

                if(this.slider.parallaxValue > 0)
                {
                    this.slider.items[i] = {}
                    this.slider.items[i].limits = {}
                    this.slider.items[i].left = fWidth
            
                    fWidth += roundedWidth + marginRight
            
                    this.slider.items[i].width = roundedWidth
                    this.slider.items[i].right = this.slider.items[i].left + roundedWidth

                    this.slider.items[i].limits.left = this.slider.items[i].left - this.slider.items[i].width
                    this.slider.items[i].limits.right = this.slider.items[i].left + this.windowObj.width + this.slider.items[i].width
                }
                else
                {
                    fWidth += roundedWidth + marginRight
                }

            }

            return fWidth
        },

        setSliderWidth(fWidth)
        {
            this.$refs.content.style.width = `${fWidth}px`
        },

        initSlider()
        {
			// console.log('init slider')
            this.sliderManager = new Hammer.Manager(this.$refs.content)
            this.sliderManager.add(new Hammer.Pan({ threshold: 0, pointers: 0 }))

            this.sliderManager.on('pan', (ev) => 
            {
                this.onPan(ev)
            })

            this.sliderManager.on('panstart', (ev) => 
            {
                this.onPanStart(ev)
            })

            this.domItemsImages = this.$el.querySelectorAll('.slider__item__image__elem')

            this.$refs.item.forEach((elem, index) => 
            {
                const hammertime = new Hammer(elem, {})

                const elemIndex = index

                hammertime.on('tap', (ev) => 
                {
                    this.onClickElem(ev, elemIndex)
                })
            })
        },

        destroySlider()
        {
            this.sliderManager.off('pan', (ev) => 
            {
                this.onPan(ev)
            })

            this.sliderManager.off('panstart', (ev) => 
            {
                this.onPanStart(ev)
            })
        },

        onClickElem(ev, index)
        {
            if(index + 1 === this.items.length)
            {
                this.slider.drag.x = this.slider.limits.left
                this.slider.drag.target.x = this.slider.limits.left
            }
            else
            {
                this.mooveSliderToIndex(index)
            }
        },

        onClickPrev()
        {
            for(let i = 0; i < this.slider.items.length; i++)
            {
                const item = this.slider.items[i]
                const currentDrag = Math.floor((this.slider.drag.x * -1))
                
                if(item.left >= currentDrag)
                {
                    let index = i - 1
                    index = MathUtils.clamp(index, 0, this.slider.items.length)

                    this.mooveSliderToIndex(index)
                    return
                }
            }
        },

        onClickNext()
        {
            for(let i = 0; i < this.slider.items.length; i++)
            {
                const item = this.slider.items[i]
                const currentDrag = Math.ceil((this.slider.drag.x * -1))

                if(item.left > currentDrag)
                {
                    this.mooveSliderToIndex(i)
                    return
                }
            }
        },

        mooveSliderToIndex(index)
        {
            let translateValue = this.slider.items[index].left * -1

            translateValue = MathUtils.clamp(translateValue, this.slider.limits.left, this.slider.limits.right)

            // this.slider.drag.x = translateValue
            this.slider.drag.target.x = translateValue
        },

        onPanStart(ev)
        {
            if(this.slider.canDrag)
            {
                this.slider.drag.dragging = true
                this.slider.drag.start.x = ev.deltaX + this.slider.drag.x
            }
        },

        onPan(ev)
        {
            if(this.slider.canDrag)
            {
                let xVal = this.slider.drag.start.x + ev.deltaX * 1.5
                xVal = MathUtils.clamp(xVal, this.slider.limits.left, this.slider.limits.right)
        
                this.slider.drag.target.x = xVal
        
                if(ev.isFinal)
                {
                    this.slider.drag.dragging = false
                }
            }
        },

        onMouseOverItem(ev, index)
        {
            // console.log(ev, index)
        },

        onMouseLeaveItem(ev, index)
        {
            // console.log(ev, index)
        },

        onTick()
        {
            if(this.isActive && this.items.length > 1)
            {
                this.slider.drag.x = MathUtils.lerp(this.slider.drag.x, this.slider.drag.target.x, 0.1)
                this.$refs.content.style.transform = `translateX(${ this.slider.drag.x }px)`
                
                if(this.slider.parallaxValue > 0)
                {
                    const scrollLeft = this.slider.drag.x * -1
                    const scrollRight = this.slider.drag.x * -1 + this.windowObj.width

                    for(let i = 0; i < this.slider.items.length; i++)
                    {
                        const item = this.slider.items[i]

                        if((item.left >= scrollLeft && item.left <= scrollRight) || (item.right >= scrollLeft && item.right <= scrollRight))
                        {
                            const progress = MathUtils.linearRange(scrollLeft, this.slider.items[i].left - this.windowObj.width, item.right, -1, 1)

                            let transformVal = progress * (item.width * this.slider.parallaxValue)
                            transformVal = Math.round(transformVal * 1000) / 1000

                            this.domItemsImages[i].style.transform = `scale(1.1) translate3d(${transformVal}px, 0, 0)`
                        }
                    }
                }
            }
        },

        onResize(windowObj)
        {
            if(this.isActive && this.items.length > 1)
            {
                this.windowObj = windowObj

                const marginLeft = Math.ceil(GetAbsoluteBoundingRect(this.$el).left)
        
                if(this.items.length > 0)
                {
                    this.slider.width = this.getFullWidth()
                    this.slider.itemMarginRight = parseInt(window.getComputedStyle(this.$refs.item[0]).marginRight)
                    this.slider.itemWidth = Math.ceil(this.windowObj.width * this.slider.itemWidthRatio)
                    // Lock when last item sticks on right
                    // this.slider.limits.left = -this.slider.width + this.windowObj.width - marginLeft
                    // lock when second last item hits left 
                    this.slider.limits.left = -this.slider.width - marginLeft + (2 * this.slider.itemWidth + this.slider.itemMarginRight)
                    this.slider.limits.right = 0
                    this.setSliderWidth(this.slider.width)
                }
            }
        }
    }

}
</script>

<style lang="stylus">
.slider
    overflow hidden
    position relative

    width 100%
    height 100%

.slider__nav
    position relative
    display flex

    padding-bottom 50px
    grid-col(1, 0)

    @media(max-width $breakpoints.md)
        margin 0

.slider__nav--prev, .slider__nav--next
    user-select none

    cursor pointer
    position relative
    display block

    padding 15px 35px

    line-height 1

    @media(max-width $breakpoints.md)
        padding 5px 25px

.slider__nav--prev
    padding-left 0

    &:after
        content ''
        position absolute
        top 0
        right 0
        
        height 100%
        width 1px

        background-color $black

    
.slider__content
	cursor grab

	font-size 0

	will-change transform

	&.has-transition
		transition transform 0.4s $ease-out-quad

	&.is-solo
		display flex

		grid-col(0, 7)
		margin 0 auto

		@media(max-width $breakpoints.md)
			width 100%


.slider__item
    overflow hidden
    position relative
    display inline-block

    margin-right 80px

    vertical-align top

    @media(max-width $breakpoints.md)
        margin-right 30px

    &:last-child
        margin-right 0

.slider__item__content
    display block
    padding-top 80px
    padding-left 0px

    @media(max-width $breakpoints.xl)
        padding-top 80px

    @media(max-width $breakpoints.lg)
        padding-top 70px

    @media(max-width $breakpoints.md)
        padding-top 60px
        padding-left 0

.slider__item__image
    overflow hidden
    position relative

    &:before
        content ''
        display block
        padding-top 123%

.slider__item__image__elem
	pointer-events none
	position absolute
	top 0
	left 0

	width 100%
	height 100%

	object-fit cover

	transform scale(1.1)
	transform-origin 50% 50%
	will-change transform

.slider__item__date
    padding-bottom 20px

    text-transform uppercase 
    font-size 36px
    line-height 1

    @media(max-width $breakpoints.xl)
        font-size 32px

    @media(max-width $breakpoints.lg)
        font-size 28px

    @media(max-width $breakpoints.md)
        font-size 28px

.slider__item__title
    font-size 36px
    line-height 1

    @media(max-width $breakpoints.xl)
        font-size 32px

    @media(max-width $breakpoints.lg)
        font-size 28px

    @media(max-width $breakpoints.md)
        font-size 28px

</style>