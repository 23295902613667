import { Mesh } from 'three';

export default class Ring extends Mesh {
    constructor({ geometry, material, borderWidth, rippleAlpha }) {
        super(geometry, material);

        this._scale = 1;
        this._alpha = 0;
        this._rippleAlpha = rippleAlpha;
        this._borderWidth = borderWidth;
        this._startTime = performance.now();

        this._bindHandlers();
        this._rotate();
    }

    /**
     * Getters & Setters
     */
    get scale2d() {
        return this._scale;
    }

    set scale2d(value) {
        this._scale = value;
        this.scale.x = this._scale;
        this.scale.y = this._scale;
    }

    get alpha() {
        return this._alpha;
    }

    set alpha(value) {
        this._alpha = value;
    }

    /**
     * Threejs callback
     */
    onBeforeRender(renderer, scene, camera, geometry, material) {
        // const time = performance.now() - this._startTime;
        material.uniforms.uAlpha.value = this._alpha;
        material.uniforms.uRippleAlpha.value = this._rippleAlpha;
        material.uniforms.uBorderWidth.value = this._borderWidth;
        // material.uniforms.uTime.value = time;
        // material.uniforms.uNoiseMapOffset.value = this._noiseMapOffset;
        material.uniformsNeedUpdate = true;
    }

    /**
     * Private
     */
    _bindHandlers() {
        this.onBeforeRender = this.onBeforeRender.bind(this);
    }

    _rotate() {
        // this.rotation.z = (Math.PI * 2) * Math.random();
    }

    _scale(scale) {
        this.scale.x = scale;
        this.scale.y = scale;
    }
}
