import store from '@/store/store.js'

import EventHub from '@/utils/EventHub.js'

export default {
    data()
    {
        return {
            navigation: null
        }
    },

    created()
    {
        if(!store.state.navigation)
        {
            store.getNavigationContent(this.$i18n.locale, this.onLoadedNav)
        }
        else
        {
            this.onLoadedNav()
        }
    },

    methods: 
    {
        onLoadedNav()
        {
            this.navigation = store.state.navigation

            if(this.onLoadedNavComponent)
            {
                this.onLoadedNavComponent()
            }
            
            this.$nextTick(() => {
                EventHub.$emit('window:on-resize', {height: window.innerHeight, width: window.innerWidth})
                EventHub.$emit('app:on-nav-ready')
            })
        },
    }
}