import normalizeWheel from 'normalize-wheel'
import * as Hammer from 'hammerjs'

import config from '@/config.js'

import GetAbsoluteBoundingRect from '@/utils/GetAbsoluteBoundingRect.js'
import MathUtils from '@/utils/MathUtils.js'
import EventHub from '@/utils/EventHub.js'

export default {
    name: 'SmoothScrollHorizontalMixin',

    data()
    {
        return {

        }
    },

    created()
    {
        this.$root.smoothScroll = {
            type: null,
            position: null
        }
    },

    mounted()
    {

    },

    destroyed()
    {
        this.destroy()
    },

    methods: {
        /**
         * 
         * Init & Destroy
         * 
         */
    
        SmoothScrollHorizontalMixin_init()
        {
            this.options = {
                lerpAmount: 0.1,
                arrowWidthAmount: 0.6,
                touchMultiplier: {
                    x: 2
                }
            }
    
            this.elems = {
                scrollElem: null,
                horizontalBlocks: null
            }
    
            this.page = {}
    
            this.scroll = {
                current : {
                    x: 0
                },
                target : {
                    x: 0
                },
                limit: 0
			}
			
			this.drag = {
                dragging: false,
                x: 0,
                start: {
                    x: 0
                },
                target: {
                    x: 0
                }
            },

            this.$root.smoothScroll.type = 'horizontal'
            this.$root.smoothScroll.position = this.scroll.current

            this.elems.horizontalBlocks = document.querySelectorAll('.horizontal-block')
            
            this.elems.scrollElem = document.querySelector('.js-scroll-elem')
    
            document.body.classList.add('is-scroll-stick', 'is-smooth-scroll')

            this.SmoothScrollHorizontalMixin_onResize({
                width: window.innerWidth,
                height: window.innerHeight
            })

            this.SmoothScrollHorizontalMixin_addListeners()
        },
    
        destroy()
        {
            document.body.classList.remove('is-scroll-stick', 'is-smooth-scroll')
            this.SmoothScrollHorizontalMixin_removeListeners()
        },
    
        /**
         * 
         * Add & remove listeners
         * 
         */
    
        SmoothScrollHorizontalMixin_addListeners()
        {
            EventHub.$on('window:on-resize', this.SmoothScrollHorizontalMixin_onResize)
            EventHub.$on('application:on-tick', this.SmoothScrollHorizontalMixin_onTick)
            EventHub.$on('application:on-click-arrow', this.SmoothScrollHorizontalMixin_onClickArrow)
    
            const mouseWheelEvent = config.support.firefox ? 'DOMMouseScroll' : 'mousewheel'
			document.addEventListener(mouseWheelEvent, this.SmoothScrollHorizontalMixin_onWheel)
			
			this.touchManager = new Hammer.Manager(this.elems.scrollElem)
            this.touchManager.add(new Hammer.Pan({ threshold: 0, pointers: 0 }))

            this.touchManager.on('pan', (ev) => 
            {
                this.SmoothScrollHorizontalMixin_onPan(ev)
            })

            this.touchManager.on('panstart', (ev) => 
            {
                this.SmoothScrollHorizontalMixin_onPanStart(ev)
            })
        },
    
        SmoothScrollHorizontalMixin_removeListeners()
        {
            EventHub.$off('window:on-resize', this.SmoothScrollHorizontalMixin_onResize)
            EventHub.$off('application:on-tick', this.SmoothScrollHorizontalMixin_onTick)
            EventHub.$off('application:on-click-arrow', this.SmoothScrollHorizontalMixin_onClickArrow)
            
            const mouseWheelEvent = config.support.firefox ? 'DOMMouseScroll' : 'mousewheel'
			document.removeEventListener(mouseWheelEvent, this.SmoothScrollHorizontalMixin_onWheel)
			
			this.touchManager.off('pan', (ev) => 
            {
                this.SmoothScrollHorizontalMixin_onPan(ev)
            })

            this.touchManager.off('panstart', (ev) => 
            {
                this.SmoothScrollHorizontalMixin_onPanStart(ev)
            })
        },
    
        /**
         * 
         * Utilities functions
         * 
         */

        setPageWidth()
        {
            let pageWidth = 0

            for(let i = 0; i < this.elems.horizontalBlocks.length; i++)
            {
                const elem = this.elems.horizontalBlocks[i]

                const bound = GetAbsoluteBoundingRect(elem)

                pageWidth += bound.width
            }

            this.page.width = pageWidth
        },
    
        setWrapperDimensions()
        {
            this.elems.scrollElem.style.width = `${this.page.width}px`
        },
    
        setScrollLimit(windowObj)
        {
            this.scroll.limit = this.page.width - windowObj.width
        },
    
        resetScrollTop()
        {
            this.scroll.target.y = 0
            this.scroll.current.y = 0
            window.scrollTo(0, 0)
        },
    
        /**
         * 
         * Events
         * 
         */
    
        SmoothScrollHorizontalMixin_onResize(windowObj)
        {
            this.setPageWidth()
    
            this.setWrapperDimensions()
            this.setScrollLimit(windowObj)

            EventHub.$emit('nav-fixed-chocolate:force-resize')  

        },
    
        SmoothScrollHorizontalMixin_onTick()
        {
            this.elems.scrollElem.style.transform = `translate3d(${-this.scroll.current.x}px, 0, 0)`

            this.scroll.current.x = MathUtils.lerp(this.scroll.current.x, this.scroll.target.x, this.options.lerpAmount)
            this.scroll.current.x = Math.round(this.scroll.current.x * 100) / 100

            // NOTE: Needed in the WebGL background
            this.$root.smoothScroll.position = this.scroll.current
        },
    
        SmoothScrollHorizontalMixin_onWheel(event)
        {
            const normalized = normalizeWheel(event)

            this.scroll.target.x += normalized.pixelY
            this.scroll.target.x += normalized.pixelX

            this.scroll.target.x = MathUtils.clamp(this.scroll.target.x, 0, this.scroll.limit)
        },

        SmoothScrollHorizontalMixin_onClickArrow()
        {
            this.scroll.target.x += (this.options.arrowWidthAmount * window.innerWidth)

            this.scroll.target.x = MathUtils.clamp(this.scroll.target.x, 0, this.scroll.limit)
		},
		
		SmoothScrollHorizontalMixin_onPanStart(event)
		{
			const deltaX = -1 * event.deltaX
			this.drag.dragging = true
            this.drag.start.x = deltaX + this.scroll.current.x
		},

		SmoothScrollHorizontalMixin_onPan(event)
		{
			const deltaX = -1 * event.deltaX
			let xVal = this.drag.start.x + deltaX * 1.5
			xVal = MathUtils.clamp(xVal, 0, this.scroll.limit)
	
			this.drag.target.x = xVal
			this.scroll.target.x = xVal
	
			if(event.isFinal)
			{
				this.drag.dragging = false
			}
		}
    }

}