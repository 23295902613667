<template>
    <section class="chocolate-nav">
        <div class="chocolate-nav__item" @click="onClick">
            <!-- <router-link :to="`/${$i18n.locale}/${endpoint}`"> -->
                <div class="chocolate-nav__item__wrapper" ref="wrapper">
                    <div class="chocolate-nav__item__bg" :style="{'background-image': `url(${image.url})`}" ref="bg">
                    </div>
                </div>
                <p class="chocolate-nav__item__title title-chocolate is-white" v-html="title" ref="title"></p>
            <!-- </router-link> -->
        </div>
    </section>
</template>

<script>
import { gsap } from 'gsap'

export default {
    name: 'ChocolateStoryNav',

    mixins: [],

    components: {

    },

    props: {
        image: {
            type: Object
        },
        endpoint: {
            type: String
        },
        title: {
            type: String
        }
    },

    data()
    {
        return {

        }
    },

    created()
    {
        this.clip = {}
        this.clip.value = 25
        this.isClicked = false
    },

    methods: {
        /**
         * 
         * Animation
         */

        playTimeline()
        {
            this.tl = gsap.timeline()

            const titleDuration = 0.4

            // Title out
            this.tl.to(
                this.$refs.title,
                {
                    opacity: 0,
                    y: -20,
                    duration: titleDuration
                },
                0
            )

            // Background out
            this.tl.to(
                this.$refs.bg,
                {
                    scale: 1.1,
                    opacity: 1,
                    ease: 'power4.inOut',
                    duration: 0.9,
                    onComplete: this.onCompleteAnim
                },
                0.1
            )

            // Clip out
            this.tl.to(
                this.clip,
                {
                    value: 0,
                    ease: 'power4.inOut',
                    duration: 0.9,
                    onUpdate: () => {
                        this.$refs.wrapper.style.clipPath = `inset(${this.clip.value}% ${this.clip.value}% ${this.clip.value}% ${this.clip.value}%)`
                    }
                },
                0.1
            )
        },

        onCompleteAnim()
        {
            this.$router.push({ path: this.endpoint, query: { transition: 'cover' } })
        },

        /**
         * 
         * Events
         */
        onClick()
        {
            if(this.$root.isMobile)
            {
                this.$router.push({ path: this.endpoint })
            }
            else
            {
                this.playTimeline()
            }
            // if(!this.isClicked)
            // {
            //     this.isClicked = true
            //     gsap.to(
            //         this.$refs.bg,
            //         {
            //             scale: 1.1,
            //             opacity: 1,
            //             ease: 'power4.inOut',
            //             duration: 0.9,
            //             onComplete: this.onCompleteAnim
            //         }
            //     )
            //     gsap.to(
            //         this.clip,
            //         {
            //             value: 0,
            //             ease: 'power4.inOut',
            //             duration: 0.9,
            //             onUpdate: () => {
            //                 this.$refs.wrapper.style.clipPath = `inset(${this.clip.value}% ${this.clip.value}% ${this.clip.value}% ${this.clip.value}%)`
            //             }
            //         }
            //     )
            // }
            // else
            // {
            //     gsap.set(
            //         this.$refs.bg,
            //         {
            //             scale: 0.65,
            //             opacity: 0.5
            //         }
            //     )

            //     this.isClicked = false
            // }
        },
    }
}
</script>

<style lang="stylus">
.chocolate-nav
    width 100vw
    height 100vh

    @media(max-width $breakpoints.md)
        width 200vw
        height 100%

.chocolate-nav__item
    position relative
    width 100%
    height 100%

.chocolate-nav__item__wrapper
    overflow hidden
    position relative

    height 100%
    width 100%

    background $black

    transform scale(1)
    clip-path inset(25% 25% 25% 25%)

.chocolate-nav__item__bg
    cursor pointer
    position relative

    height 100%
    width 100%

    background-size cover
    background-position 50% 50%

    transform-origin 50% 50%
    transform scale(0.65)
    opacity 0.5

.chocolate-nav__item__title
	z-index 2
	position absolute
	top 50%
	left 50%

	transform translate3d(-50%, -50%, 0)
</style>