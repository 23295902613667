import { gsap } from 'gsap';
import { WebGLRenderer, Clock, PerspectiveCamera, Vector3, Scene, Group, Fog, Color, PlaneBufferGeometry, ShaderMaterial, UniformsLib, Mesh, AdditiveBlending, Vector2 } from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import Debugger from '@/webgl/utils/Debugger';
import math from '@/webgl/utils/math';
import device from '@/webgl/utils/device';
import Events from '../../Events';

const AMOUNT = 50;
const FINAL_AMOUNT = 10;

import vertexShader from './shaders/line.vert.glsl';
import fragmentShader from './shaders/line.frag.glsl';

export default class Lines {
    constructor() {
        this._canvas = document.createElement('canvas');
        this._width = null;
        this._height = null;
        this._renderer = this._createRenderer();
        this._clock = new Clock({ autoStart: true });
        this._fog = new Fog(new Color(0x000000), 0, 11);
        this._scene = new Scene();
        this._scene.fog = this._fog;
        this._camera = this._createCamera();
        this._container = new Group();
        this._lines = [];
        this._cubicPulseOffset = { current: 0, target: 0 };
        this._rotation = {
            current: { x: 0.5, y: 0.5 },
            target: { x: 0.5, y: 0.5 }
        };
        this._isHiding = false;
        this._isMouseDown = false;
        this._isShowPositionComplete = false;
        this._isEnabled = false;
        this._noiseScale = new Vector2(2825.9, 1.4);
        this._noiseStrength = 3.2;
        // const controls = new OrbitControls(this._camera, this._renderer.domElement)

        this._params = {
            speed: 0.062,
            frequency: 0.12,
            amplitude: 2.2,
            cubicPulseSize: 2.25,
            color: '#29ab97',
            timeOffsetScale: 1
        };

        this._originalParams = {
            cubicPulseSize: this._params.cubicPulseSize,
            // frequency: this._params.frequency,
            speed: this._params.speed,
            timeOffsetScale: this._params.timeOffsetScale,
        };

        this._resize();
        this._bindHandlers();
        this._setupEventListeners();
        this._createLines();
        this._setupDebugGui();
    }

    destroy() {
        if (this._timelineShow) this._timelineShow.kill();
        if (this._timelineHide) this._timelineHide.kill();
        this._renderer.dispose();
        this._removeEventListeners();
        this._removeDebugGui();
    }

    /**
     * Public
     */
    getElement() {
        return this._canvas;
    }

    show() {
        this._isEnabled = true;

        let count = 0;

        this._timelineShow = new gsap.timeline({ delay: 0, paused: false });
        for (let i = 0, len = this._lines.length; i < len; i++) {
            if (i < len - FINAL_AMOUNT) {
                this._timelineShow.fromTo(this._lines[i].position, 4, { y: 1, z: -10 }, { y: -1, z: 10, ease: 'none' }, 0.15 * i);
            } else {
                this._timelineShow.fromTo(this._lines[i].position, 1.8, { y: 1, z: -10 }, { y: 0, z: count * -0.15, ease: 'sine.out' }, 0.2 + 0.15 * i);
                // this._timelineShow.fromTo(this._lines[i].position, 1.8, { y: 1, z: -10 }, { y: 0, z: count * -0.15, ease: 'sine.out' }, 6.6);
                count++;
            }

            if (i > 30) {
                this._timelineShow.fromTo(this._lines[i].material.uniforms.uGlobalAmplitude, 5, { value: 0 }, { value: this._params.amplitude }, 1.2 + 0.15 * i);
            } else {
                this._timelineShow.set(this._lines[i].material.uniforms.uAlpha, { value: 0 }, 10);
            }

            if (i > 30 && i < len - FINAL_AMOUNT) {
                this._timelineShow.to(this._lines[i].material.uniforms.uAlpha, 1, { value: 0, ease: 'sine.inOut' }, 7.8);
            }
        }

        this._timelineShow.to(this._fog, 3, { near: 4.01, far: 5.17, ease: 'sine.inOut' }, 6);
        this._timelineShow.call(() => {
            this._isShowPositionComplete = true;
        }, null, 2.5);
        this._timelineShow.fromTo(this._container.rotation, 2, { x: Math.PI * 0.1 }, { x: Math.PI * 0.04 }, 0);
        this._timelineShow.fromTo(this._container.rotation, 2, { y: Math.PI * -0.05 }, { y: 0 }, 0);
        this._timelineShow.fromTo(this._canvas, 0.2, { alpha: 0 }, { alpha: 1, ease: 'sine.inOut' }, 0.1);
        // timeline.to(this._container.rotation, 3, { y: Math.PI * 0.2, repeat: -1, yoyo: true, ease: 'power1.inOut' }, 3);
    }

    hide(callback) {
        let count = 0;

        this._isHiding = true;
        this._timelineHide = new gsap.timeline();
        this._timelineHide.to(this._params, 1, { amplitude: 0, ease: 'power1.inOut' }, 0);
        this._timelineHide.to(this._params, 1, { cubicPulseSize: 3, ease: 'power1.inOut' }, 0);
        this._timelineHide.to(this._container.rotation, 3, { z: Math.PI * 1, ease: 'power3.in' }, 0);
        for (let i = 0, len = this._lines.length; i < len; i++) {
            if (i >= len - FINAL_AMOUNT) {
                count++;
                this._timelineHide.to(this._lines[i].position, 2, { z: -2, ease: 'power2.inOut' }, 0.35 + 0.15 * count);
            }
        }
        this._timelineHide.call(() => {
            callback();
            this._isEnabled = false;
        }, null, 2.4);
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._resizeHandler = this._resizeHandler.bind(this);
        this._mouseMoveHandler = this._mouseMoveHandler.bind(this);
        this._tickHandler = this._tickHandler.bind(this);
        this._mouseDownHandler = this._mouseDownHandler.bind(this);
        this._mouseUpHandler = this._mouseUpHandler.bind(this);
        this._mouseUpHandler = this._mouseUpHandler.bind(this);
        this._touchStartHandler = this._touchStartHandler.bind(this);
        this._touchMoveHandler = this._touchMoveHandler.bind(this);
        this._touchEndHandler = this._touchEndHandler.bind(this);
        
    }

    _setupEventListeners() {
        window.addEventListener('resize', this._resizeHandler);
        window.addEventListener('mousemove', this._mouseMoveHandler);
        window.addEventListener('mousedown', this._mouseDownHandler);
        window.addEventListener('mouseup', this._mouseUpHandler);

        // Touch
        window.addEventListener('touchstart', this._touchStartHandler);
        window.addEventListener('touchmove', this._touchMoveHandler);
        window.addEventListener('touchend', this._touchEndHandler);

        gsap.ticker.add(this._tickHandler);
    }

    _removeEventListeners() {
        window.removeEventListener('resize', this._resizeHandler);
        window.removeEventListener('mousemove', this._mouseMoveHandler);
        window.removeEventListener('mousedown', this._mouseDownHandler);
        window.removeEventListener('mouseup', this._mouseUpHandler);

        // Touch
        window.removeEventListener('touchstart', this._touchStartHandler);
        window.removeEventListener('touchmove', this._touchMoveHandler);
        window.removeEventListener('touchend', this._touchEndHandler);
        
        gsap.ticker.remove(this._tickHandler);
    }

    _createRenderer() {
        const renderer = new WebGLRenderer({
            canvas: this._canvas,
            antialias: true
        });
        renderer.setClearColor('#090909', 1);
        return renderer;
    }

    _createCamera() {
        var camera = new PerspectiveCamera(50, 0, 0.01, 1000);
        camera.position.set(0, 0, 4);
        camera.lookAt(new Vector3());
        return camera;
    }

    _createLines() {
        const geometry = this._createGeometry();
        const material = this._createMaterial();

        let line;
        let clonedMaterial;
        for (let i = 0; i < AMOUNT; i++) {
            clonedMaterial = material.clone();
            clonedMaterial.uniforms.uTimeOffset.value = Math.random() * 100;
            clonedMaterial.uniforms.uAmplitude.value = 0.13 + Math.random() * 0.02;
            clonedMaterial.uniforms.uNoiseOffset.value = new Vector2(Math.random() * 1000, Math.random() * 1000);

            line = new Mesh(geometry, clonedMaterial);
            line.position.y = 0;
            line.position.z = (AMOUNT - i) * -0.1;

            this._lines.push(line);
            this._container.add(line);
        }
        this._scene.add(this._container);
        this._resizeLines();
    }

    _createGeometry() {
        const aspect = this._width / this._height;
        const width = 5 * aspect * 2;
        const geometry = new PlaneBufferGeometry(width, 0.01, 200);
        return geometry;
    }

    _createMaterial() {
        const material = new ShaderMaterial({
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            fog: true,
            blending: AdditiveBlending,
            transparent: true,
            uniforms: {
                ...UniformsLib.fog,

                uTexture: { value: null },

                uColor: { value: new Color(this._params.color) },
                uAlpha: { value: 1 },

                uTime: { value: 0 },
                uTimeOffset: { value: null },
                uTimeOffsetScale: { value: 1 },

                // uSpeed: { value: 0 },
                // uFrequency: { value: Math.random() * 2.5 },

                uFrequency: { value: 1 },
                uAmplitude: { value: null },

                uCubicPulseSize: { value: 0 },
                uCubicPulseOffset: { value: 0 },

                uGlobalFrequency: { value: 0 },
                uGlobalAmplitude: { value: 0 },

                uNoiseScale: { value: this._noiseScale },
                uNoiseOffset: { value: null },
                uNoiseStrength: { value: this._noiseStrength },
            }
        });
        return material;
    }

    _update() {
        if (!this._isEnabled) return;

        const delta = this._clock.getDelta();
        this._updateCubicPulseOffset();
        this._updateLinesRotation();
        this._updateLines(delta);
        this._render();
    }

    _updateCubicPulseOffset() {
        if (this._isHiding) return;
        this._cubicPulseOffset.current = math.hermite(this._cubicPulseOffset.current, this._cubicPulseOffset.target, 0.08);
    }

    _updateLinesRotation() {
        this._rotation.current.x = math.hermite(this._rotation.current.x, this._rotation.target.x, 0.08);
        this._rotation.current.y = math.hermite(this._rotation.current.y, this._rotation.target.y, 0.08);

        // console.log((-1 + this._rotation.current.x * 2));
        this._container.rotation.x = (-1 + this._rotation.current.y * 2) * -0.3;
        this._container.rotation.y = (-1 + this._rotation.current.x * 2) * 0.2;
    }

    _updateLines(delta) {
        let item
        for (let i = 0, len = this._lines.length; i < len; i++) {
            item = this._lines[i];
            item.material.color = new Color(this._params.color);
            item.material.uniforms.uTime.value += delta * this._params.speed;
            item.material.uniforms.uTimeOffsetScale.value = this._params.timeOffsetScale;
            // item.material.uniforms.uSpeed.value = this._params.speed;
            item.material.uniforms.uGlobalFrequency.value = this._params.frequency;
            // item.material.uniforms.uGlobalAmplitude.value = this._params.amplitude;
            item.material.uniforms.uCubicPulseSize.value = this._params.cubicPulseSize;
            item.material.uniforms.uCubicPulseOffset.value = this._cubicPulseOffset.current;
            item.material.uniforms.uNoiseScale.value = this._noiseScale;
            item.material.uniforms.uNoiseStrength.value = this._noiseStrength;
        }
    }

    _setCubicPulseOffsetTarget({ x }) {
        this._cubicPulseOffset.target = (-5 + (x / this._width) * 10) * 0.3;
    }

    _setLinesRotationTarget({ x, y }) {
        if (!this._isShowPositionComplete) return;
        this._rotation.target.x = x / this._width;
        this._rotation.target.y = y / this._height;
    }

    _render() {
        this._renderer.render(this._scene, this._camera);
    }

    _showMouseDownEffect() {
        if (this._timelineReverseMouseDownEffect) this._timelineReverseMouseDownEffect.kill();

        this._timelineMouseDownEffect = new gsap.timeline();
        this._timelineMouseDownEffect.to(this._params, 0.5, {
            cubicPulseSize: 4,
            speed: 0.3,
            // timeOffsetScale: 0,
            // frequency: 1.24,
        }, 0);
        this._timelineMouseDownEffect.to(this._camera.position, 1, { z: 3.1 }, 0);
    }

    _reverseMouseDownEffect() {
        if (!this._originalParams) return;

        if (this._timelineMouseDownEffect) this._timelineMouseDownEffect.kill();

        this._timelineReverseMouseDownEffect = new gsap.timeline();
        this._timelineReverseMouseDownEffect.to(this._params, 2, {
            cubicPulseSize: this._originalParams.cubicPulseSize,
            speed: this._originalParams.speed,
            // timeOffsetScale: this._originalParams.timeOffsetScale,
            ease: 'sine.inOut'
            // frequency: this._originalValues.frequency,
        }, 0);
        this._timelineReverseMouseDownEffect.to(this._camera.position, 2, { z: 4, ease: 'sine.inOut' }, 0);
    }

    /**
     * Resize
     */
    _resize() {
        this._width = window.innerWidth;
        this._height = window.innerHeight;
        this._dpr = device.dpr();

        this._resizeCanvas();
        this._resizeRenderer();
        this._resizeCamera();
        this._resizeLines();
    }

    _resizeCanvas() {
        this._canvas.width = this._width * this._dpr;
        this._canvas.height = this._height * this._dpr;
        this._canvas.style.width = `${this._width}px`;
        this._canvas.style.height = `${this._height}px`;
    }

    _resizeRenderer() {
        this._renderer.setPixelRatio(this._dpr);
        this._renderer.setSize(this._width, this._height, false);
    }

    _resizeCamera() {
        this._camera.aspect = this._width / this._height;
        this._camera.updateProjectionMatrix();
    }

    _resizeLines() {
        const scale = 800 / this._height;

        let item;
        for (let i = 0, len = this._lines.length; i < len; i++) {
            item = this._lines[i];
            item.scale.y = scale;
        }
    }

    /**
     * Debug
     */
    _setupDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        this._debugGui = Debugger.gui.addFolder('Lines');
        this._debugGui.add(this._fog, 'near', 0, 100, 0.01).listen();
        this._debugGui.add(this._fog, 'far', 0, 100, 0.01).listen();
        this._debugGui.add(this._params, 'speed', 0, 0.2, 0.001);
        this._debugGui.add(this._params, 'frequency', 0, 3, 0.01);
        this._debugGui.add(this._params, 'amplitude', 0, 3, 0.01).listen();
        this._debugGui.add(this._params, 'cubicPulseSize', 0, 10, 0.01);
        this._debugGui.add(this._noiseScale, 'x', 0, 3000, 0.1);
        this._debugGui.add(this._noiseScale, 'y', 0, 10, 0.1);
        this._debugGui.add(this, '_noiseStrength', 0, 10, 0.1);
        this._debugGui.addColor(this._params, 'color');
        this._debugGui.add(this, 'show');
        this._debugGui.add(this, 'hide');
        this._debugGui.open();
    }

    _removeDebugGui() {
        if (this._debugGui) Debugger.gui.removeFolder(this._debugGui);
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }

    _mouseMoveHandler(e) {
        const x = e.clientX;
        const y = e.clientY;
        this._setCubicPulseOffsetTarget({ x, y });
        this._setLinesRotationTarget({ x, y });
    }

    _mouseDownHandler() {
        this._isMouseDown = true;
        Events.dispatchEvent('mouse:down', 6);
        this._showMouseDownEffect();
    }

    _mouseUpHandler() {
        this._isMouseDown = false;
        Events.dispatchEvent('mouse:up');
        this._reverseMouseDownEffect();
    }

    _tickHandler() {
        this._update();
    }

    // Touch
    _touchStartHandler() {
        this._isMouseDown = true;
        Events.dispatchEvent('mouse:down', 6);
        this._showMouseDownEffect();
    }

    _touchMoveHandler(e) {
        const touch = e.touches[0];
        const x = touch.clientX;
        const y = touch.clientY;
        this._setCubicPulseOffsetTarget({ x, y });
        this._setLinesRotationTarget({ x, y });
    }

    _touchEndHandler() {
        this._isMouseDown = false;
        Events.dispatchEvent('mouse:up');
        this._reverseMouseDownEffect();
    }
}
