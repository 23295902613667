<template>
  <div class="nav-mobile container" :class="{'is-white': isWhite}" v-if="navigation">
    <div class="nav-mobile__header">
      <router-link :to="`/${$i18n.locale}/`" class="nav-mobile__logo">
        <img src="../assets/images/chocolate/logo-2.png" alt="Patrick roger logo chocolate green" class="nav-mobile__logo__img">
      </router-link>
      <router-link :to="`/${$i18n.locale}/sculptures`" class="nav-mobile__link--home">
        {{ navigation.header_chocolate_home_link }}
      </router-link>
    </div>
    <div class="nav-mobile__links">
      <router-link class="nav-mobile__link" :to="`/${$i18n.locale}${ item.url }`" v-for="(item, index) in navigation.header_chocolate_links" :key="index">{{ item.label }}</router-link>
      <a class="nav-mobile__link" :href="navigation.header_chocolate_eshop_url" target="_blank">{{ navigation.header_chocolate_eshop_label }}</a>
    </div>
  </div>
</template>

<script>
import NavigationMixin from '@/mixins/NavigationMixin.js'

export default {
    name: 'NavMobileChocolate',

	mixins: [NavigationMixin],
	
	props: {
		isWhite: {
			default: false,
			type: Boolean
		}
	},

    data()
    {
        return {

        }
    },

    created()
    {

    },

    mounted()
    {

    },

    destroyed()
    {

    },

    methods: {

    }
}
</script>

<style lang="stylus">
.nav-mobile
	z-index 2
	position absolute

	width 100vw
	padding 30px 25px 0 25px

	font-size 15px

	&.is-black
		color $black

	&.is-white
		color $white

.nav-mobile__header
  display flex
  justify-content space-between
  align-items flex-start

  width 100%
  margin-bottom 15px

.nav-mobile__link--home
	position relative

	padding 0px 20px 10px 0
	margin-right 30px


	color $black
	text-transform uppercase
	line-height 1

	.is-white &
		color $white

	&:after
		content ''
		position absolute
		top 7px
		right -30px

		height 1px
		width 30px

		background $green

.nav-mobile__logo
    width 20%

.nav-mobile__logo__img
    display block
    width 100%

.nav-mobile__links
  display flex
  flex-direction column

  text-transform uppercase
  color $black

.nav-mobile__link
	padding 5px 0

	.is-white &
		color $white
</style>