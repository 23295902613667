<template>
  <div id="app">
    <NavFixedChocolate v-if="pageType === 'chocolate' && !$root.isMobile" />
    <ChocolateCoverScroll v-if="pageType === 'chocolate' && !$root.isMobile"/>
    <router-view/>
    <Transition/>
  </div>
</template>

<script>
import config from '@/config.js'

import NavFixedChocolate from '@/components/NavFixedChocolate.vue'
import Transition from '@/components/Transition.vue'
import ChocolateCoverScroll from '@/components/ChocolateCoverScroll.vue'

import EventHub from '@/utils/EventHub.js'

export default {
  components: {
	NavFixedChocolate,
	ChocolateCoverScroll,
    Transition
  },

  data() {
    return {
      pageType: null,
      mobileDevice: null,
      mobileSize: null,
      isMobile: false
    }
  },

  watch:
  {
    $route: 'onRouteChange',
    $i18n: 'onChangeLang'
  },

  created()
  {
    this.windowObj = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    this.$root.firstPage = true

    this.addListeners()

    this.loadYoutubeAPI()
  },

  mounted()
  {
	this.onRouteChange(this.$route,  this.$route)
    this.onTick()
	this.onResize()
	
	setTimeout(() => {
		if(this.$i18n.locale === 'ru')
		{
			document.documentElement.classList.add('is-ru')
    }else if(this.$i18n.locale === 'ja')
		{
			document.documentElement.classList.add('is-ja')
    }
	}, 100)
  },

  methods: {
    /**
     * 
     * Listeners
     */
    addListeners() {
      window.addEventListener('resize', this.onResize)
    },

    /**
     * 
     * 
     * Check mobile
     */

    checkMobile()
    {
      this.mobileDevice = null
      this.mobileSize = null
      this.$root.isMobile = false

      this.mobileDevice = config.support.touch && (config.support.android || config.support.ios)

      const breakpoints = Object.keys(config.breakpoints.mobile)

      for(const breakpoint of breakpoints)
      {
        if(this.windowObj.width <= config.breakpoints.mobile[breakpoint])
        {
          this.mobileSize = breakpoint
          this.$root.isMobile = this.mobileDevice !== null && this.mobileSize !== null
          // console.log('check mobile APP', this.$root.isMobile)
          return
        }
      }
    },

    /**
     * 
     * Events
     */
    onRouteChange(to, from)
    {
      // Detect first page in website
      if(from.name)
      {
        this.$root.firstPage = false
      }

      this.pageType = to.meta.type

      EventHub.$emit('application:on-route-change', to, from)

      this.$store.dispatch('setCurrentRoute', to);
	},

    onResize()
    {
      this.windowObj.width  = window.innerWidth
      this.windowObj.height = window.innerHeight

      this.checkMobile()

      EventHub.$emit('window:on-resize', this.windowObj)
    },

    onTick()
    {
      EventHub.$emit('application:on-tick')
      requestAnimationFrame(this.onTick)
    },

    /**
     * 
     * External scripts
     */

    /**
     * 
     * Youtube
     */
    loadYoutubeAPI()
    {
      const tag = document.createElement('script')

      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }
  },
}
</script>

<style lang="stylus">
@import './assets/styles/index.styl'

</style>
