<template>
    <canvas class="canvas-background" ref="canvas-background"></canvas>
</template>

<script>
import EventHub from '@/utils/EventHub.js'
import Background from '@/webgl/background'

export default {
    name: 'CanvasBackground',

    props: [
        'scene',
        'type',
        'amplitude',
        'frequency',
        'line'
    ],

    created()
    {
        this.parentOffset = { x: 0, y: 0 }
        this.isVisible = false
        this.setupEventListeners()
    },

    mounted()
    {
        this.createCanvasBackground()
        this.createIntersectionObserver()
    },

    beforeDestroy()
    {
        this.removeEventListeners()
        if (this.intersectionObserver) this.intersectionObserver.disconnect()
        if (this.canvasBackground) this.canvasBackground.destroy()
    },

    methods: {
        setupEventListeners()
        {
            EventHub.$on('application:on-tick', this.tickHandler)
            EventHub.$on('window:on-resize', this.resizeHandler)
        },

        removeEventListeners()
        {
            EventHub.$off('application:on-tick', this.tickHandler)
            EventHub.$off('window:on-resize', this.resizeHandler)
        },

        createCanvasBackground()
        {
            this.canvasBackground = new Background({
                canvas: this.$refs['canvas-background']
            })

            const options = {
                frequency: Number(this.$props.frequency),
                amplitude: Number(this.$props.amplitude),
                line: Boolean(this.line)
            }
            this.sceneInstance = this.canvasBackground.createScene(`${this.$props.scene}-${this.$props.type}`, this.$el.parentElement, options)
        },

        createIntersectionObserver()
        {
            this.intersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    this.isVisible = entry.isIntersecting
                    if (this.isVisible) {
                        this.resize()
                        this.sceneInstance.resize()
                        this.canvasBackground.enable()
                    } else {
                        this.canvasBackground.disable()
                    }
                })
            })
            this.intersectionObserver.observe(this.$el.parentElement)
        },

        getElementGlobalOffset(element)
        {
            let x = 0
            let y = 0
            const getOffset = function(element) {
                x += element.offsetLeft
                y += element.offsetTop
                if (element.offsetParent !== null) {
                    getOffset(element.offsetParent)
                }
            }
            getOffset(element)
            return { x, y }
        },

        update()
        {
            if (!this.isVisible) return

            if (this.$root.smoothScroll) {
                if (this.$root.smoothScroll.type === 'horizontal') {
                    this.scrollTop = this.$root.smoothScroll.position.x
                } else if (this.$root.smoothScroll.type === 'vertical') {
                    this.scrollTop = this.$root.smoothScroll.position.y
                }
            } else {
                this.scrollTop = document.body.scrollTop || document.documentElement.scrollTop
            }

            this.updatePosition()
            this.canvasBackground.update()
        },

        updatePosition()
        {
            let offset
            if (this.$props.type === 'vertical') {
                offset = this.scrollTop - this.parentOffset.y
                this.$el.style.transform = `translate(0, ${offset}px)`
            } else {
                offset = this.scrollTop - this.parentOffset.x
                this.$el.style.transform = `translate(${offset}px, 0)`
            }

            if (typeof this.sceneInstance.updatePosition === 'function') {
                this.sceneInstance.updatePosition({ x: 0, y: this.scrollTop })
            }
        },

        /**
         * Resize
         */
        resize()
        {
            this.$nextTick(() => {
                this.parentOffset = this.getElementGlobalOffset(this.$el.parentElement)
            })
        },

        /**
         * Handlers
         */
        tickHandler()
        {
            this.update()
        },

        resizeHandler()
        {
            this.resize()
        }
    }
}
</script>

<style lang="stylus">
.canvas-background
    position absolute
    top 0
    left 0
    
    pointer-events none
</style>