import gsap from 'gsap';
import { Object3D } from 'three';

import Ring from './Ring';

export default class Ripple extends Object3D {
    constructor({ geometry, material, ringAmount, alpha = 1, minBorderWidth = 1,  maxBorderWidth = 2, growSpeed = 1, dontRespawn = false }) {
        super();

        this._geometry = geometry;
        this._material = material;
        this._ringAmount = ringAmount;
        this._alpha = alpha;
        this._minBorderWidth = minBorderWidth;
        this._maxBorderWidth = maxBorderWidth;
        this._growSpeed = growSpeed;
        this._dontRespawn = dontRespawn;

        this._isStarted = false;
        this._isFinished = false;
        this._rings = [];
        this._timeline = null;

        this._bindHandlers();
        this._createRings(geometry, material);
    }

    /**
     * Getters & Setters
     */
    get isStarted() {
        return this._isStarted;
    }

    get isFinished() {
        return this._isFinished;
    }

    get timeline() {
        return this._timeline;
    }

    get dontRespawn() {
        return this._dontRespawn;
    }

    /**
     * Public
     */
    play(delay, progress) {
        this._play(delay, progress);
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._playStartHandler = this._playStartHandler.bind(this);
        this._playCompleteHandler = this._playCompleteHandler.bind(this);
    }

    _play(delay = 0, progress = 0) {
        this._isFinished = false;

        if (this._timeline) this._timeline.kill();

        const duration = 3 + Math.random();
        this._timeline = new gsap.timeline({
            onStart: this._playStartHandler,
            onComplete: this._playCompleteHandler,
        });

        let item;
        let position;
        for (let i = 0, len = this._rings.length; i < len; i++) {
            item = this._rings[i];
            position = (i === 0) ? 0 : 0.3 * i + Math.random() * 0.3;
            this._timeline.fromTo(item, duration, {
                scale2d: 0.01,
                alpha: 1
            }, {
                scale2d: 1,
                alpha: 0,
                ease: 'sine.out',
                immediateRender: false,
                // paused: true
            }, delay + position);
        }

        if (progress > 0) this._timeline.progress(progress);
        // this._timeline.timeScale(50);
    }

    _createRings(geometry, material) {
        let ring;
        for (let i = 0; i < this._ringAmount; i++) {
            ring = new Ring({
                geometry, material,
                borderWidth: this._minBorderWidth + (this._maxBorderWidth - this._minBorderWidth) * Math.random(),
                rippleAlpha: this._alpha
            });
            this._rings.push(ring);
            this.add(ring);
        }
    }

    _radiusStaggerFunction(index) {
        if (index === 0) {
            return 0;
        } else {
            return 0.3 * index + Math.random() * 0.3;
        }
    }

    /**
     * Handlers
     */
    _playStartHandler() {
        this._isStarted = true;
    }

    _playCompleteHandler() {
        this._isFinished = true;
    }
}
