import { i18n } from '../plugins/i18n.js'

import Base from '../views/Base.vue'
import Home from '../views/Home.vue'

import SculpturesHome from '../views/sculptures/Home.vue'
import SculpturesAbout from '../views/sculptures/About.vue'
import Sculptures from '../views/sculptures/Sculptures.vue'
import Sculpture from '../views/sculptures/Sculpture.vue'
import SculpturesAllNews from '../views/sculptures/AllNews.vue'
import SculptureNews from '../views/sculptures/News.vue'
import SculpturesExpositions from '../views/sculptures/Expositions.vue'
import SculpturesExposition from '../views/sculptures/Exposition.vue'
import ChocolateHome from '../views/chocolate/Home.vue'
import Alchemist from '../views/chocolate/Alchemist.vue'
import Peasant from '../views/chocolate/Peasant.vue'
import Workshop from '../views/chocolate/Workshop.vue'
import AllNews from '../views/chocolate/AllNews.vue'
import News from '../views/chocolate/News.vue'
import Shops from '../views/chocolate/Shops.vue'
import Shop from '../views/chocolate/Shop.vue'
import Legals from '../views/Legals.vue'
import Contact from '../views/Contact.vue'

const languages = ['fr', 'en', 'ja', 'ru']

const getLocale = () => {
  const userLang = (navigator.language || navigator.userLanguage)
      .toLowerCase()
      .substring(0, 2)
//   console.log(navigator.language || navigator.userLanguage, userLang)
  const id = languages.indexOf(userLang)
  return id > -1 ? languages[id] : languages[1]
}

const routes = [
{
    path: '',
    beforeEnter (to, from, next) {
        return next(getLocale())
    }
},
{
    path: '/:lang',
    component: Base,
    beforeEnter (to, from, next) {
        const paramLang = to.params.lang

        // If locale doesn't exist in our app redirect to en locale
        if(!languages.includes(paramLang))
        {
            return next(getLocale())
        }

        if (i18n.locale === paramLang)
        {
            return next()
        }

        import(`@/locales/${paramLang}.json`).then((messages) => {
            i18n.setLocaleMessage(paramLang, messages.default || messages)
            i18n.locale = paramLang
            return next()
        })
    },
    children: [
        {
            path: '',
            name: 'Home',
            component: Home,
            meta: {
                
            }
        },

        // Sculptures
        {
            path: 'sculptures',
            name: 'SculpturesHome',
            component: SculpturesHome,
            meta: {
                type: 'sculptures'
            }
        },
        {
            path: 'sculptures/about',
            name: 'SculpturesAbout-en',
            component: SculpturesAbout,
            meta: {
                type: 'sculptures'
            }
		},
		{
            path: 'sculptures/a-propos',
            name: 'SculpturesAbout-fr',
            component: SculpturesAbout,
            meta: {
                type: 'sculptures'
            }
        },
        {
            path: 'sculptures/sculptures',
            name: 'Sculptures-fr',
            component: Sculptures,
            meta: {
                type: 'sculptures'
            }
		},
		{
            path: 'sculptures/works',
            name: 'Sculptures-en',
            component: Sculptures,
            meta: {
                type: 'sculptures'
            }
        },
        {
            path: 'sculptures/sculptures/:id',
            name: 'Sculpture-fr',
            component: Sculpture,
            meta: {
                type: 'sculptures'
            }
		},
		{
            path: 'sculptures/works/:id',
            name: 'Sculpture-en',
            component: Sculpture,
            meta: {
                type: 'sculptures'
            }
		},
        {
            path: 'sculptures/news',
            name: 'SculpturesAllNews-en',
            component: SculpturesAllNews,
            meta: {
                type: 'sculptures'
            }
		},
		{
            path: 'sculptures/actualites',
            name: 'SculpturesAllNews-fr',
            component: SculpturesAllNews,
            meta: {
                type: 'sculptures'
            }
        },
        {
            path: 'sculptures/news/:id',
            name: 'SculptureNews-en',
            component: SculptureNews,
            meta: {
                type: 'sculptures'
            }
		},
		{
            path: 'sculptures/actualites/:id',
            name: 'SculptureNews-fr',
            component: SculptureNews,
            meta: {
                type: 'sculptures'
            }
        },
        {
            path: 'sculptures/expositions',
            name: 'SculpturesExpositions-fr',
            component: SculpturesExpositions,
            meta: {
                type: 'sculptures'
            }
		},
		{
            path: 'sculptures/exhibitions',
            name: 'SculpturesExpositions-en',
            component: SculpturesExpositions,
            meta: {
                type: 'sculptures'
            }
        },
        {
            path: 'sculptures/expositions/:id',
            name: 'SculpturesExposition-fr',
            component: SculpturesExposition,
            meta: {
                type: 'sculptures'
            }
		},
		{
            path: 'sculptures/exhibitions/:id',
            name: 'SculpturesExposition-en',
            component: SculpturesExposition,
            meta: {
                type: 'sculptures'
            }
        },

        // Chocolate
        {
            path: 'chocolate',
            name: 'ChocolateHome-en',
            component: ChocolateHome,
            meta: {
                type: 'chocolate'
            }
		},
		{
            path: 'chocolat',
            name: 'ChocolateHome-fr',
            component: ChocolateHome,
            meta: {
                type: 'chocolate'
            }
        },
        {
            path: 'chocolate/alchemist',
            name: 'Alchemist-en',
            component: Alchemist,
            meta: {
                type: 'chocolate',
            }
		},
		{
            path: 'chocolat/alchimiste',
            name: 'Alchemist-fr',
            component: Alchemist,
            meta: {
                type: 'chocolate',
            }
        },
        {
            path: 'chocolate/garden',
            name: 'Peasant-en',
            component: Peasant,
            meta: {
                type: 'chocolate',
            }
		},
		{
            path: 'chocolat/jardin',
            name: 'Peasant-fr',
            component: Peasant,
            meta: {
                type: 'chocolate',
            }
        },
        {
            path: 'chocolate/factory',
            name: 'Workshop-en',
            component: Workshop,
            meta: {
                type: 'chocolate',
            }
		},
		{
            path: 'chocolat/atelier',
            name: 'Workshop-fr',
            component: Workshop,
            meta: {
                type: 'chocolate',
            }
        },
        {
            path: 'chocolate/news',
            name: 'AllNews-en',
            component: AllNews,
            meta: {
                type: 'chocolate'
            }
		},
		{
            path: 'chocolat/actualites',
            name: 'AllNews-fr',
            component: AllNews,
            meta: {
                type: 'chocolate'
            }
        },
        {
            path: 'chocolate/news/:id',
            name: 'News-en',
            component: News,
            meta: {
                type: 'chocolate'
            }
		},
		{
            path: 'chocolat/actualites/:id',
            name: 'News-fr',
            component: News,
            meta: {
                type: 'chocolate'
            }
        },
        {
            path: 'chocolate/shops',
            name: 'Shops-en',
            component: Shops,
            meta: {
                type: 'chocolate'
            }
		},
		{
            path: 'chocolat/boutiques',
            name: 'Shops-fr',
            component: Shops,
            meta: {
                type: 'chocolate'
            }
        },
        {
            path: 'chocolate/shops/:id',
            name: 'Shop-en',
            component: Shop,
            meta: {
                type: 'chocolate'
            }
		},
		{
            path: 'chocolat/boutiques/:id',
            name: 'Shop-fr',
            component: Shop,
            meta: {
                type: 'chocolate'
            }
        },

        // Legals, Contact
        {
            path: 'legals-mentions',
            name: 'Legals-en',
            component: Legals,
            meta: {
                type: 'sculptures'
            }
		},
		{
            path: 'mentions-legales',
            name: 'Legals-fr',
            component: Legals,
            meta: {
                type: 'sculptures'
            }
        },
        {
            path: 'contact',
            name: 'Contact',
            component: Contact,
            meta: {
                type: 'sculptures'
            }
        },
    ]
}
]

export default routes