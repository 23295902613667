<template>
    <section class="news" v-if="contentLoaded">
        <div class="news__container" :class="{'scroll-wrapper': !$root.isMobile}">
            <div class="horizontal-wrapper" :class="{'js-scroll-elem': !$root.isMobile}">

                <div class="news__titles horizontal-block">
                    <NavMobileChocolate v-if="$root.isMobile"/>
                    <ChocolateCover
                        :url="content['chocolate-news_cover_image'].url"
                    />
					<div class="news__titles__title">
						<ChocolateTitle
							class="news__titles__title--elem"
							:title="content['chocolate-news_title']"
							:amount="0.8"
						/>
					</div>
                </div>

                <div class="horizontal-block">
                    <section class="news__infos pad-l-md pad-r-sm green-line">
						<div class="news__infos__inner">
							<p class="news__infos__date text-chocolate" v-html="content['chocolate-news_date']"></p>
							<div class="news__infos__paragraphs">
								<p class="news__infos__paragraph text-chocolate" v-for="(item,index) in content['chocolate-news_paragraphs']" v-html="item.paragraph"></p>
							</div>
						</div>
                    </section>
                </div>

                <div class="horizontal-block">
                    <section class="news__product-carousel pad-r-sm green-line">
                        <ChocolateCarouselVertical
							class="news__product-carousel__elem"
                            :items="content.products_slider"
                            :prevLabel="content.prev_label"
                            :nextLabel="content.next_label"
                            :productLabel="content.see_product_label"
                            :hasLink="false"
                            :hasProductLinks="true"
                        >
                        </ChocolateCarouselVertical>
                    </section>
                </div>

                <FooterChocolate :showBackgroundLines="true" />
            </div>
        </div>
    </section>
</template>

<script>
import EventHub from '@/utils/EventHub.js'

import SmoothScrollHorizontalMixin from '@/mixins/SmoothScrollHorizontalMixin.js'
import API from '@/mixins/API.js'
import Page from '@/mixins/Page.js'
import MobileScroll from '@/mixins/MobileScroll.js'

import ChocolateCover from '@/components/ChocolateCover.vue'
import FooterChocolate from '@/components/FooterChocolate.vue'
import ChocolateCarouselVertical from '@/components/ChocolateCarouselVertical.vue'
import NavMobileChocolate from '@/components/NavMobileChocolate.vue'
import ChocolateTitle from '@/components/ChocolateTitle.vue'

export default {
    name: 'ChocolateNews',

    mixins: [SmoothScrollHorizontalMixin, API, Page, MobileScroll],

    components: {
        ChocolateCover,
        FooterChocolate,
        ChocolateCarouselVertical,
        NavMobileChocolate,
        ChocolateTitle
    },

    data()
    {
        return {
            isHorizontal: true
        }
    },

    created()
    {
        this.fetchLang(`/chocolatenews/${this.$route.params.id}`, () => {
            this.contentLoaded = true

            this.onMounted()
            
            this.$nextTick(() => {
                EventHub.$emit('nav-fixed-chocolate:init')

                if(!this.$root.isMobile)
                {
                    this.SmoothScrollHorizontalMixin_init()
                }
                else
                {
                    this.MobileScroll_init()
                }
            })
        })
    }
}
</script>

<style lang="stylus" scoped>
.news
    background-color $grey

    @media(max-width $breakpoints.md)
        overflow-y hidden
        position relative

        height 100vh

.news__titles
	position relative

.news__titles__title
	z-index 2
	position absolute
	right 0
	top $pos-text-top
	transform translateX(20%)

	@media(max-width $breakpoints.md)
		top 45%
		transform translateX(10%)

.news__infos
	position relative
	height 100vh

	@media(max-width $breakpoints.md)
        height 100%

.news__infos__date
	margin-bottom 2.5rem

.news__infos__paragraphs
	display flex

.news__infos__paragraph
	max-width 400px
	margin-left 80px

	&:first-child
		margin-left 0

.news__infos__inner
	// max-width 400px
	padding-top 57vh

.news__product-carousel
    position relative

    @media(max-width $breakpoints.md)
        height 100%

.news__product-carousel__elem
	position relative
	z-index 2
</style>