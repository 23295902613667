import { Mesh, Vector2, Vector3 } from 'three';
// import math from '@/webgl/utils/math';

export default class Drop extends Mesh {
    constructor({ geometry, material, texture, position, speed }) {
        super(geometry, material);

        // Properties
        this._texture = texture;
        this._width = null;
        this._height = null;

        // Depth
        // this._depth = this._sineIn(position.z);
        this._depth = this._sineIn(position.z);

        // Scale
        this.scale.x = 1 + (Math.random() > 0.5 ? 1 : 0);
        this.scale.y = this._depth * 800;

        // Offset
        this._textureOffset = new Vector2(Math.random(), Math.random());

        // Alpha
        this._alpha = position.z * 0.3;

        // Scroll speed
        this._startPosition = position.clone();

        // Velocity
        this._velocity = new Vector3(0, -.001, 0);

        // Speed
        this._speed = speed;

        this._updateUniforms();
    }

    /**
     * Getters & Setters
     */
    get startPosition() {
        return this._startPosition;
    }

    get speed() {
        return this._speed;
    }

    set speed(value) {
        this._speed = value;
    }

    /**
     * Public
     */
    update(y) {
        this._startPosition.y += this._velocity.y * this._depth * this._speed;

        this.position.x = Math.round(this._startPosition.x * this._width) + this.scale.x * 0.5;
        // this.position.y = math.lerp(this.position.y, this._startPosition.y * this._height + y * this._depth, 0.1);
        this.position.y = this._startPosition.y * this._height + y * this._depth;
        // this.position.y = this._startPosition.y * this._height;
    }

    resize(width, height) {
        this._width = width;
        this._height = height;
    }

    /**
     * Private
     */
    _updateUniforms() {
        this.material.uniforms.uSize.value = new Vector2(this.scale.x, this.scale.y);
        this.material.uniforms.uTextureOffset.value = new Vector2(this._textureOffset.x, this._textureOffset.y);
        this.material.uniforms.uTexture.value = this._texture;
        this.material.uniforms.uAlpha.value = this._alpha;
    }

    _sineIn(t) {
        return Math.sin((t - 1.0) * Math.PI * 0.5) + 1.0;
    }
}
