import normalizeWheel from 'normalize-wheel'

import config from '@/config.js'

import GetAbsoluteBoundingRect from '@/utils/GetAbsoluteBoundingRect.js'
import MathUtils from '@/utils/MathUtils.js'
import EventHub from '@/utils/EventHub.js'

export default {
    name: 'SmoothScrollVerticalMixin',

    data()
    {
        return {

        }
    },

    created()
    {
        this.$root.smoothScroll = {
            type: null,
            position: null
        }

        this.scroll = {
            current : {
                y: 0
            },
            target : {
                y: 0
            },
            limit: 0
        }
    },

    destroyed()
    {
        this.destroy()
    },

    methods: {
        /**
         * 
         * Init & Destroy
         * 
         */
        SmoothScrollVerticalMixin_init()
        {
            this.options = {
                lerpAmount: 0.1,
                touchMultiplier: {
                    y: 2
                }
            }
    
            this.elems = {
                scrollElem: null
            }
    
            this.page = {}
            
            this.$root.smoothScroll.type = 'vertical'
            this.$root.smoothScroll.position = this.scroll.current
    
            this.elems.scrollElem = document.querySelector('.js-scroll-elem')
    
            document.body.classList.add('is-scroll-stick', 'is-smooth-scroll')

            this.SmoothScrollVerticalMixin_onResize({
                height: window.innerHeight
            })

            this.SmoothScrollVerticalMixin_addListeners()
        },

        saveSmoothScrollPosition()
        {
            // this.$store.dispatch('setScroll', { scroll: this.scroll, pageName });
            this.$root.previouslySavedScrollPosition.smooth = this.$root.savedScrollPosition.smooth
            this.$root.savedScrollPosition.smooth = this.scroll.current.y;
        },

        setSavedSmoothScrollPosition(position)
        {
            if (!position) return;

            this.scroll.current.y = position;
            this.scroll.target.y = position;
        },
    
        destroy()
        {
            document.body.classList.remove('is-scroll-stick', 'is-smooth-scroll')
            this.SmoothScrollVerticalMixin_removeListeners()
        },
    
        /**
         * 
         * Add & remove listeners
         * 
         */
    
        SmoothScrollVerticalMixin_addListeners()
        {
            EventHub.$on('window:on-resize', this.SmoothScrollVerticalMixin_onResize)
            EventHub.$on('application:on-tick', this.SmoothScrollVerticalMixin_onTick)
    
            const mouseWheelEvent = config.support.firefox ? 'DOMMouseScroll' : 'mousewheel'
            document.addEventListener(mouseWheelEvent, this.SmoothScrollVerticalMixin_onWheel)
        },
    
        SmoothScrollVerticalMixin_removeListeners()
        {
            EventHub.$off('window:on-resize', this.SmoothScrollVerticalMixin_onResize)
            EventHub.$off('application:on-tick', this.SmoothScrollVerticalMixin_onTick)
    
            const mouseWheelEvent = config.support.firefox ? 'DOMMouseScroll' : 'mousewheel'
            document.removeEventListener(mouseWheelEvent, this.SmoothScrollVerticalMixin_onWheel)
        },
    
        /**
         * 
         * Utilities functions
         * 
         */

        setPageHeight()
        {
            this.elems.scrollElem.style.height = 'auto'

            const scrollElemBounds = GetAbsoluteBoundingRect(this.elems.scrollElem)

            this.page.height = scrollElemBounds.height
        },
    
        setWrapperDimensions()
        {
            this.elems.scrollElem.style.height = `${this.page.height}px`
        },
    
        setScrollLimit(windowObj)
        {
            this.scroll.limit = this.page.height - windowObj.height
        },
    
        resetScrollTop()
        {
            this.scroll.target.y = 0
            this.scroll.current.y = 0
            window.scrollTo(0, 0)
        },
    
        /**
         * 
         * Events
         * 
         */
    
        SmoothScrollVerticalMixin_onResize(windowObj)
        {
            this.setPageHeight()
    
            this.setWrapperDimensions()
            this.setScrollLimit(windowObj)

            EventHub.$emit('nav-fixed-chocolate:force-resize')  

        },
    
        SmoothScrollVerticalMixin_onTick()
        {
            this.elems.scrollElem.style.transform = `translate3d(0, ${-this.scroll.current.y}px, 0)`

            this.scroll.current.y = MathUtils.lerp(this.scroll.current.y, this.scroll.target.y, this.options.lerpAmount)
            this.scroll.current.y = Math.round(this.scroll.current.y * 100) / 100

            
            // NOTE: Needed in the WebGL background
            this.$root.smoothScroll.position = this.scroll.current
        },
    
        SmoothScrollVerticalMixin_onWheel(event)
        {
            const normalized = normalizeWheel(event)

            this.scroll.target.y += normalized.pixelY
            this.scroll.target.y = MathUtils.clamp(this.scroll.target.y, 0, this.scroll.limit)
        }
    }

}