<template>
<div class="stories-image">
	<template v-if="hasParallaxe && !$root.isMobile">
		<ParallaxeHorizontalElem
			:isInner="true"
			:amount="0.1"
		>
			<img class="stories-image__elem" :src="image.url" :alt="image.alt">
		</ParallaxeHorizontalElem>
	</template>
	<template v-else-if="!hasParallaxe || $root.isMobile">
		<img class="stories-image__elem" :src="image.url" :alt="image.alt">
	</template>
	<p v-if="text" class="stories-image__text text-chocolate" v-html="text" :class="textClass"></p>
</div>
</template>

<script>
import ParallaxeHorizontalElem from '@/components/ParallaxeHorizontalElem.vue'

export default {
    name: 'StoriesText',

    props: {
		image: {
			type: Object
		},

		text: {
			type: String
		},

		hasParallaxe: {
			type: Boolean,
			default: true
		},

		textPosX: {
			type: String
		},

		textPosY: {
			type: String
		},

		whiteText: {
			type: Boolean
		}
	},

	components: {
		ParallaxeHorizontalElem
	},
	
	computed: {
		textClass()
		{
			const classArray = []

			if(this.text) {
				if(this.textPosX === 'left')
				{
					classArray.push('is-left')
				}
				else
				{
					classArray.push('is-right')
				}
	
				if(this.textPosY === 'top')
				{
					classArray.push('is-top')
				}
				else
				{
					classArray.push('is-bottom')
				}

				if(this.whiteText)
				{
					classArray.push('is-white')
				}
			}


			return  classArray
		}
	}
}
</script>

<style lang="stylus">

.stories-image
	position relative
	height 100%

.stories-image__elem
	height 100%
	width auto

.stories-image__text
	position absolute
	z-index 2
	width 380px

	@media(max-width $breakpoints.xl)
		width 330px

	@media(max-width $breakpoints.md)
		width 280px

	&.is-left
		left 5%
	&.is-right
		right 5%
	&.is-top
		top 10%
	&.is-bottom 
		bottom 10%
</style>