<template>
    <section class="expositions" :class="{'scroll-wrapper': !$root.isMobile}" v-if="contentLoaded">

        <div class="scroll-elem" :class="{'js-scroll-elem': !$root.isMobile}">

            <NavSculptures/>
            
            <section class="expositions__hero">
                <CanvasBackground scene="rain" type="vertical" />
                <div class="expositions__hero__bg">
                    <LazyImg
                        v-if="$root.isMobile"
                        class="expositions__hero__bg__elem"
                        :url="content.acf.cover_image.url"
                        :alt="content.acf.cover_image.alt"
                        :width="content.acf.cover_image.width"
                        :height="content.acf.cover_image.height"
                    />
                    <ParallaxeVerticalElem
                        v-else
                        :isInner="true"
                        :amount="0.1"
                    >
                        <LazyImg
                            class="expositions__hero__bg__elem"
                            :url="content.acf.cover_image.url"
                            :alt="content.acf.cover_image.alt"
                            :width="content.acf.cover_image.width"
                            :height="content.acf.cover_image.height"
                        />
                    </ParallaxeVerticalElem>
                </div>
                <div class="container">

                    <div class="expositions__hero__title" v-if="content.acf.cover_title">
                        <h2 v-if="$root.isMobile" class="expositions__hero__title__elem title--sculptures" v-html="content.acf.cover_title"></h2>
                        <ParallaxeVerticalElem
                            v-else
                            :amount="0.5"
                        >
                            <h2 class="expositions__hero__title__elem title--sculptures" v-html="content.acf.cover_title"></h2>
                        </ParallaxeVerticalElem>
                    </div>
                    <p class="expositions__hero__text text-sculptures" v-html="content.acf.cover_description">
                    </p>
                    <ArrowBtn class="expositions__hero__btn"
                        href="/"
                        :text="content.acf.cover_label"
                        :isWhite="true"
                    />
                </div>
            </section>

            <section class="expositions__list">
                <div class="container">
                    <router-link :to="`/${$i18n.locale}/sculptures/expositions/${item.id}`" class="exposition__card" v-for="(item, index) in content.expositions" :key="index">
                        <div class="exposition__card__image">
                            <LazyImg
                                v-if="$root.isMobile"
                                class="exposition__card__image__elem"
                                :url="item.acf['preview_image'].url"
                                :alt="item.acf['preview_image'].alt"
                                :width="item.acf['preview_image'].width"
                                :height="item.acf['preview_image'].height"
                            />
                            <ParallaxeVerticalElem
                                v-else
                                :isInner="true"
                                :amount="0.1"
                            >
                                <LazyImg
                                    class="exposition__card__image__elem"
                                    :url="item.acf['preview_image'].url"
                                    :alt="item.acf['preview_image'].alt"
                                    :width="item.acf['preview_image'].width"
                                    :height="item.acf['preview_image'].height"
                                />
                            </ParallaxeVerticalElem>
                        </div>
                        <div class="exposition__card__infos">
                            <h3 class="exposition__card__infos__title" v-if="item.acf.preview_title" v-html="item.acf.preview_title"></h3>
                            <p class="exposition__card__infos__description text-sculptures" v-if="item.acf.preview_title_description" v-html="item.acf.preview_title_description">
                            </p>
                        </div>
                    </router-link>
                </div>
            </section>

            <section class="expositions__quote">
                <CanvasBackground scene="lines" type="vertical" />
                <div class="container">
                    <h2 v-if="$root.isMobile" class="expositions__quote__title title--sculptures" v-html="content.acf.footer_title"></h2>
                    <ParallaxeVerticalElem
                        v-else
                        :amount="0.5"
                    >
                        <h2 class="expositions__quote__title title--sculptures" v-html="content.acf.footer_title"></h2>
                    </ParallaxeVerticalElem>
                </div>
            </section>

            <Footer/>
        </div>
    </section>
</template>

<script>
import API from '@/mixins/API.js'
import Page from '@/mixins/Page.js'
import SmoothScrollVerticalMixin from '@/mixins/SmoothScrollVerticalMixin.js'

import NavSculptures from '@/components/NavSculptures.vue'
import Footer from '@/components/Footer.vue'
import ArrowBtn from '@/components/ArrowBtn.vue'
import CanvasBackground from '@/components/CanvasBackground.vue'
import LazyImg from '@/components/LazyImg.vue'
import ParallaxeVerticalElem from '@/components/ParallaxeVerticalElem.vue'

export default {
    name: 'Expositions',

    mixins: [API, Page, SmoothScrollVerticalMixin],

    components: {
        ArrowBtn,
        CanvasBackground,
        LazyImg,
        Footer,
        NavSculptures,
        ParallaxeVerticalElem
    },

    created()
    {
        const endpoints = [
            {
                url: `/pages/239?lang=${this.$i18n.locale}`,
                label: null
            }, 
            {
                url: `/sculptureexposition?per_page=100&page=1&lang=${this.$i18n.locale}`,
                label: 'expositions'
            }
        ]

        this.fetchMultiple(endpoints, () => {
            this.contentLoaded = true 

            this.onMounted()

            // we have 1500ms during transition
            window.setTimeout(() => {
                if(!this.$root.isMobile)
                {
                    this.SmoothScrollVerticalMixin_init()
                }
            }, 100)
        })
    }
}
</script>

<style lang="stylus" scoped>
.expositions
    @media(max-width $breakpoints.md)
        overflow-x hidden

.expositions__hero
    position relative
    overflow hidden

    width 100%
    padding-bottom 400px

    background $black

    @media(max-width $breakpoints.md)
        padding-bottom 100px

.expositions__hero__bg
    position relative
    
    width 50%
    margin-left 50%
    padding-top 360px
    padding-bottom 40px

    @media(max-width $breakpoints.md)
        width 100%
        margin 0
        padding-top 400px
        padding-bottom 100px

.expositions__hero__bg__elem
    display block

    width 100%

.expositions__hero__title
    padding-bottom 120px

    @media(max-width $breakpoints.md)
        padding-bottom 100px

.expositions__hero__title__elem
    grid-col(1, 4)

    color $white

    @media(max-width $breakpoints.md)
        width 100%
        margin 0

.expositions__hero__text, .expositions__hero__btn
    grid-col(5, 2)
    max-width $sculpture-paragraph-mwidth

    color $white

    opacity 0.3

    @media(max-width $breakpoints.md)
        width 100%
        margin 0

.expositions__hero__btn
    margin-top 70px

.expositions__list
    position relative

    padding 270px 0 100px 0

    @media(max-width $breakpoints.md)
        padding 100px 0px 0px 0px

    &:after
        z-index 1
        content ''
        position absolute
        top 0
        left 50%
        
        width 1px
        height 100%

        background-color $grey

        @media(max-width $breakpoints.md)
            display none

.exposition__card
    display flex

    grid-col(0, 7)
    margin 0 auto 170px auto

    flex-direction row-reverse

    @media(max-width $breakpoints.md)
        flex-direction column

        width 100%
        margin 0
        margin-bottom 60px

.exposition__card__image, .exposition__card__infos
    width 50%

    @media(max-width $breakpoints.md)
        width 100%

.exposition__card__image
    padding-left 50px

    @media(max-width $breakpoints.md)
        padding 0

.exposition__card__image__elem
    display block

    width 100%

.exposition__card__infos
    padding-left 50px

    @media(max-width $breakpoints.md)
        padding 0

.exposition__card__infos__title
	max-width 75%

	margin-top 50px
	margin-bottom 40px

	font-size 20px
	line-height 1.3
	text-transform uppercase

.exposition__card__infos__description
    max-width 66%

    padding-bottom 70px

    @media(max-width $breakpoints.md)
        padding-bottom 0px

.expositions__quote
    position relative
    overflow hidden

    padding 250px 0

    background $black

    @media(max-width $breakpoints.md)
        padding 120px 0px 120px 0px

.expositions__quote__title
    grid-col(3.5, 5)

    color $white

    @media(max-width $breakpoints.md)
        width 100%
        margin 0

</style>