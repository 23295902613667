<template>
    <div class="chocolate-cover-scroll" v-if="!$root.isMobile" @click="onClickScroll">
		<span class="chocolate-cover-scroll__arrow"></span>
	</div>
</template>

<script>
import EventHub from '@/utils/EventHub.js'

export default {
    name: 'ChocolateCoverScroll',

    data()
    {
        return {

        }
    },

    created() {

    },

    mounted() {

    },

    destroyed() {

    },

    methods: {
        /**
         * 
         * Events
         */

        onClickScroll()
        {
            EventHub.$emit('application:on-click-arrow')
        }
    }
}
</script>

<style lang="stylus">
.chocolate-cover-scroll
	z-index 3
	position absolute
	cursor pointer
	bottom 60px
	right 5vw
	width 40px
	height 30px
	font-size 18px
	text-transform uppercase
	color #fefefe

	opacity 0.5

.chocolate-cover-scroll__arrow
	position relative
	top 50%

	display block
	height 2px
	width 40px
	margin 0 auto

	background $white

	&:before,
	&:after
		content ''
		background $white
		position absolute
		height 2px
		width 15px

	&:before
		right -2px
		bottom -5px
		transform rotate(-45deg)

	&:after
		right -2px
		top -5px
		transform rotate(45deg)
</style>