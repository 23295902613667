<template>
<div class="workshop js-white-header-section" :class="{'scroll-wrapper': !$root.isMobile}" v-if="contentLoaded">
    <div class="horizontal-wrapper js-white-header-section" :class="{'js-scroll-elem': !$root.isMobile}">

        <div class="workshop__hero horizontal-block">
			<NavMobileChocolate v-if="$root.isMobile" :isWhite="true"/>
            <ChocolateHero
                :title="content.cover_title"
                :data="content.cover_image"
                layout="media"
            />
        </div>

		<div class="horizontal-block" v-for="(item, index) in content.blocs" :key="index">
			<StoriesQuote
				v-if="item.type === 'quote'"
				:title="item.title_group.title"
				:hasRain="item.title_group.hasrain"
				:subtitle="item.title_group.subtitle"
				:hasParallaxe="item.title_group.has_parallaxe"
				:parallaxeAmount="item.title_group.parallaxe_amount"
				:parallaxeDirection="item.title_group.parallaxe_direction"
				:isSpaceLeft="item.title_group.space_left"
				:isSpaceRight="item.title_group.space_right"
			/>
			<StoriesText
				v-else-if="item.type === 'text'"
				:text="item.text_group.text"
				:position="item.text_group.position"
				:isSpaceLeft="item.text_group.space_left"
				:isSpaceRight="item.text_group.space_right"
			/>
			<StoriesImage
				v-else-if="item.type === 'image'"
				:image="item.image_group.image"
				:hasParallaxe="item.title_group.has_parallaxe"
				:text="item.image_group.text"
				:whiteText="item.image_group.white_text"
				:textPosX="item.image_group.position_x"
				:textPosY="item.image_group.position_y"
			/>
		</div>

		<div class="horizontal-block">
            <ChocolateStoryNav
                :image="content.nav_image"
                :title="content.nav_title"
                :endpoint="endpoint"
            />
        </div>
    </div>
</div>
</template>

<script>
import { gsap } from 'gsap'

import EventHub from '@/utils/EventHub.js'

import store from '@/store/store.js'

import ChocolateHero from '@/components/ChocolateHero.vue'
import BackgroundRain from '@/components/BackgroundRain.vue'
import ChocolateStoryNav from '@/components/ChocolateStoryNav.vue'
import NavMobileChocolate from '@/components/NavMobileChocolate.vue'
// Stories
import StoriesQuote from '@/components/stories/StoriesQuote.vue'
import StoriesText from '@/components/stories/StoriesText.vue'
import StoriesImage from '@/components/stories/StoriesImage.vue'

import SmoothScrollHorizontalMixin from '@/mixins/SmoothScrollHorizontalMixin.js'
import API from '@/mixins/API.js'
import Page from '@/mixins/Page.js'
import MobileScroll from '@/mixins/MobileScroll.js'

export default {
    name: 'Workshop',

    mixins: [SmoothScrollHorizontalMixin, API, Page, MobileScroll],

    components: {
		StoriesQuote,
		StoriesText,
		StoriesImage,
        ChocolateHero,
		BackgroundRain,
		ChocolateStoryNav,
		NavMobileChocolate
	},
	
	computed: {
		endpoint()
		{
			let endpoint = ''

			if(this.$i18n.locale === 'fr')
			{
				endpoint = 'boutiques'
			}
			else
			{
				endpoint = 'shops'
			}

			return endpoint
		}
	},

    data()
    {
        return {
            isHorizontal: true,
            contentLoaded: false
        }
    },

    created()
    {
        if(!store.state.workshop)
        {
            store.getStories(this.$i18n.locale, this.onLoadedStories)
        }
        else
        {
            this.onLoadedStories()
        }
    },

    methods: {
        onLoadedStories()
        {   
            this.contentLoaded = true
            this.content = store.state.workshop

            if(!this.$route.query.transition || this.$root.firstPage)
            {
                this.onMounted()
            }

            this.$nextTick(() => {
                EventHub.$emit('nav-fixed-chocolate:init', true)

                if(!this.$root.isMobile)
                {
                    this.SmoothScrollHorizontalMixin_init()
                }
                else
                {
                    this.MobileScroll_init()
                }
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
.workshop
    background $black
    color $white

	.horizontal-wrapper
		background-color $black

.workshop__hero
	position relative
</style>