<template>
	<section class="home" v-if="contentLoaded">
		<div class="home__container" :class="{'scroll-wrapper': !$root.isMobile}">
			<div class="horizontal-wrapper" :class="{'js-scroll-elem': !$root.isMobile}">

				<div class="home__cover horizontal-block js-white-header-section">
					<NavMobileChocolate v-if="$root.isMobile" :isWhite="true"/>
					<router-link :to="`/${$i18n.locale}/${baseUrl}`" class="home__cover__logo" v-if="!$root.isMobile">
						<img src="../../assets/images/chocolate/logo-2.png" alt="Patrick roger new logo green">
					</router-link>

          			<VideoBox v-if="content.cover_media.video && content.cover_media.video.url && !$root.isMobile" :data="content.cover_media.video" class="home__cover__bg" ref="bg"/>
					<div v-else-if="content.cover_media.image" class="home__cover__bg" ref="bg" :style="{'background-image': `url(${content.cover_media.image.url}`}"></div>

					<h2 v-if="$root.isMobile" class="home__cover__title__1 quote-chocolate is-white">{{ content.title_line_1 }}</h2>
					<div v-else class="home__cover__title__1 quote-chocolate is-white">
						<ParallaxeHorizontalElem
							:direction="-1"
							:amount="0.4"
						>
							<h2>{{ content.title_line_1 }}</h2>
						</ParallaxeHorizontalElem>
					</div>

					<h2 v-if="$root.isMobile" class="home__cover__title__2 quote-chocolate is-white">{{ content.title_line_2 }}</h2>
					<TitleCoverDuo v-else class="home__cover__title__2" :title="content.title_line_2"/>
					<CanvasBackground class="home__cover__canvas" scene="lines" type="horizontal" amplitude="18" frequency="6" />
				</div>

				<div class="horizontal-block">
					<section class="home__actus pad-l-xxl pad-r-xl green-line">
						<ChocolateCarousel
							class="home__actus__carousel"
							:items="carouselItems"
							:prevLabel="content.news_slider_prev_label"
							:nextLabel="content.news_slider_next_label"
							:hasLink="true"
							:hasProductLinks="false"
						>
							<ArrowBtn
								:href="newsPath"
								:text="content.news_label"
							/>
						</ChocolateCarousel>
					</section>
				</div>

				<div class="horizontal-block js-white-header-section">
					<div class="home__art pad-l-xs pad-r-xl">

						<div class="home__art__text pad-r-sm">
							<div class="home__art__title" v-if="content.nav_title">
								<h2 v-if="$root.isMobile" class="quote-chocolate is-white" v-html="content.nav_title"></h2>
								<ParallaxeHorizontalElem
									v-else
									:direction="1"
									:amount="0.5"
								>
									<h2 class="quote-chocolate is-white" v-html="content.nav_title"></h2>
								</ParallaxeHorizontalElem>
							</div>
							<p class="home__art__description text-chocolate is-white" v-html="content.nav_description"></p>
						</div>
						
						<nav class="home__nav">
							<div class="home__nav__items">
								<router-link v-for="(item, index) in content.navigation_items" :key="index" :to="`/${$i18n.locale}/${baseUrl}${item.endpoint}`" class="home__nav__item">
									<div class="home__nav__item__title">
										<p v-if="$root.isMobile" class="title-chocolate is-white">{{ item.title }}</p>
										<ParallaxeHorizontalElem
											v-else
											:amount="1"
										>
											<p class="title-chocolate is-white">{{ item.title }}</p>
										</ParallaxeHorizontalElem>
									</div>
									<div class="home__nav__item__bg">
										<img class="home__nav__item__bg__elem" :src="item.image.url" :alt="item.image.alt" data-object-fit="cover">
									</div>
								</router-link>
							</div>
						</nav>

						<CanvasBackground class="home__nav__canvas" scene="lines" type="horizontal" amplitude="18" frequency="6" />
					</div>
				</div>

				<div class="horizontal-block">
					<section class="home__shops pad-l-xs pad-r-xs green-line">
						<ShopCard
							class="home__shops__online"
							:internal="false"
							:link="content.eshop_link"
							:image="content.eshop_image"
							:title="content.eshop_title"
						/>

						<ShopCard
							class="home__shops__physical"
							:internal="true"
							:image="content.shops_image"
							:title="content.shops_title"
						/>
					</section>
				</div>

				<FooterChocolate :showBackgroundLines="true" />
			</div>
		</div>
	</section>
</template>

<script>
import { gsap } from 'gsap'

import EventHub from '@/utils/EventHub.js'

import SmoothScrollHorizontalMixin from '@/mixins/SmoothScrollHorizontalMixin.js'
import API from '@/mixins/API.js'
import Page from '@/mixins/Page.js'
import MobileScroll from '@/mixins/MobileScroll.js'
import baseUrl from '@/mixins/baseUrl'

import FooterChocolate from '@/components/FooterChocolate.vue'
import ArrowBtn from '@/components/ArrowBtn.vue'
import ChocolateCarousel from '@/components/ChocolateCarousel.vue'
import CanvasBackground from '@/components/CanvasBackground.vue'
import ShopCard from '@/components/ShopCard.vue'
import NavMobileChocolate from '@/components/NavMobileChocolate.vue'
import ParallaxeHorizontalElem from '@/components/ParallaxeHorizontalElem.vue'
import TitleCoverDuo from '@/components/TitleCoverDuo.vue'
import VideoBox from '@/components/VideoBox.vue'

export default {
	name: 'ChocolateHome',

	mixins: [SmoothScrollHorizontalMixin, API, Page, MobileScroll, baseUrl],

	components: {
		ArrowBtn,
		FooterChocolate,
		ChocolateCarousel,
		CanvasBackground,
		ShopCard,
		NavMobileChocolate,
		ParallaxeHorizontalElem,
		TitleCoverDuo,
		VideoBox
	},

	data()
	{
		return {
			isHorizontal: true,
			eshopLink: 'https://google.fr'
		}
	},

	computed: {
		navLinks() {
			return this.$t('chocolate.nav.links')
		},
		carouselItems() {
			const items = []
			
			for(let i = 0; i < this.content.selected_news.length; i++)
			{
				const oldItem = this.content.selected_news[i]
				const item = {}

				item.title = oldItem.acf['chocolate-news_title']
				item.date = oldItem.acf['chocolate-news_date']
				item.description = oldItem.acf['chocolate-news_cover_description']
				item.image = oldItem.acf['chocolate-news_preview_image']

				items.push(item)
			}

			return items
		},
		newsPath()
		{
			let path = ''
			if(this.$i18n.locale === 'fr')
			{
				path = '/chocolat/actualites'
			}
			else
			{
				path = '/chocolate/news'
			}

			return path
		}
	},

	created()
	{
		this.fetchLang(`/pages/42`, () => {
			this.fetchAdditionalContent()
		})
	},

	methods: {
		fetchAdditionalContent()
		{
			const endpoints = []

			// Selected news
			for(let i = 0; i < this.content.selected_news.length; i++)
			{
				const endpoint = {
					exisitingPath: ['selected_news', i],
					url: `/chocolatenews/${this.content.selected_news[i].ID}?lang=${this.$i18n.locale}`
				}

				endpoints.push(endpoint)
			}

			// Load all
			this.fetchMultiple(endpoints, () => {
				this.contentLoaded = true

				this.onMounted()

				window.setTimeout(() => {
					EventHub.$emit('nav-fixed-chocolate:init')
					this.animateCover()

					if(!this.$root.isMobile)
					{
						this.SmoothScrollHorizontalMixin_init()
					}
					else
					{
						this.MobileScroll_init()
					}
				}, 100)
			})
		},

		animateCover()
		{
			gsap.to(this.$refs.bg, {
                scale: 1,
                duration: 3,
                delay: 0,
                ease: 'Power3.easeOut'
			})
			gsap.to('.home__cover__logo', {
                opacity: 1,
                duration: 1,
                delay: 0.3,
                ease: 'Power3.in'
            })
		}
	}
}
</script>

<style lang="stylus" scoped>
.home
	background $grey

	@media(max-width $breakpoints.md)
		overflow-y hidden
		position relative

.home__cover
	position relative
	width 100vw
	height 100vh

.home__cover__logo
	z-index 3
	position fixed
	top 80px
	left 85px
	width 128px

	opacity 0
	will-change opacity, transform

	@media(max-width $breakpoints.xl)
		left 50px
		width 110px

	@media(max-width $breakpoints.lg)
		width 100px

	img
		display block
		width 100%

.home__cover__bg
	position relative
	width 100%
	height 100%

	background-size cover
	background-position 50% 50%

	transform scale(1.15)
	will-change transform

.home__cover__title__1, .home__cover__title__2
	position absolute
	z-index 2
	top $pos-text-top
	font-size 6.1rem

	@media(max-width $breakpoints.xl)
		font-size 5.2rem

	@media(max-width $breakpoints.md)
		top 45%
		font-size 2.5rem


.home__cover__title__1
	right 6.5%

	@media(max-width $breakpoints.md)
		left 10%
		right auto

.home__cover__title__2
	margin-top 6.1rem
	right -360px

	@media(max-width $breakpoints.md)
		left 30%
		margin-top 7rem

.home__actus
	position relative
	height 100%

.home__actus__carousel
	@media(max-width $breakpoints.md)
		width 500px

.home__art__title
	max-width 450px
	margin-bottom 22vh

	@media(max-width $breakpoints.md)
		margin-bottom 12vh

.home__art__text
	margin-top $pos-text-top

.home__art__description
	max-width 350px
	padding-left 100px

.home__art
	position relative
	display flex
	background $black
	height 100%

	@media(max-width $breakpoints.md)
		padding-left 30vw
		paddinig-right 30vw

.home__nav
	position relative
	overflow hidden

	height 100%

.home__nav__canvas, .home__cover__canvas
	z-index 2

.home__nav__items
	z-index 1
	position relative

	display flex

	margin-top 17.5vh

	@media(max-height $breakpoints-h.lg)
		margin-top 20vh

	@media(max-height $breakpoints-h.md)
		margin-top 22.5vh

	@media(max-width $breakpoints.md)
		height 100%
		margin-top 0
		align-items center

.home__nav__item
	position relative

	width 75vw
	height 65vh

	text-align center

	@media(max-height $breakpoints-h.lg)
		height 60vh

	@media(max-height $breakpoints-h.md)
		height 55vh

	@media(max-width $breakpoints.md)
		width 100vw
		height 75%

.home__nav__item__title
	z-index 2
	position absolute 

	top 50%
	left 50%

	transform translate3d(-50%, -50%, 0)

.home__nav__item__bg
	z-index 1
	position absolute
	top 0
	left 0

	width 100%
	height 100%

	background-size cover
	background-position 50% 50%

.home__nav__item__bg__elem
	display block
	width 100%
	height 100%
	object-fit cover

.home__nav__item--alchemy
	background-image url('/images/chocolate/home/alchimie.jpg')

.home__nav__item--peasant
	background-image url('/images/chocolate/home/paysan.jpg')

.home__nav__item--workshop
	background-image url('/images/chocolate/home/atelier.jpg')


.home__shops
	position relative
	z-index 2

	display flex

	height 100%

	@media(max-width $breakpoints.md)
		padding 0 32vw

.home__shops__online, .home__shops__physical
	z-index 2
	position relative

.home__shops__online
	@media(max-width $breakpoints.md)
		margin-right 20vw
</style>