// Three
import { WebGLRenderer, Vector3, Scene, OrthographicCamera } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass';

// Utils
import device from '@/webgl/utils/device';
import WindowResizeObserver from '@/webgl/utils/WindowResizeObserver';

// Managers
import SceneManager from '@/webgl/background/scenes/SceneManager';

export default class Background {
    constructor({ canvas }) {
        this._canvas = canvas;

        this._width = null;
        this._height = null;
        this._components = [];
        this._startTime = performance.now();
        this._scrollPosition = { x: 0, y: 0 };
        this._isEnabled = false;

        this._scene = new Scene();
        this._sceneManager = this._createSceneManager();
        this._renderer = this._createRenderer();
        this._camera = this._createCamera();
        this._composer = this._createComposer();
        this._passes = this._createPasses();

        this._bindHandlers();
        this._setupEventListeners();
        this._resize();
    }

    destroy() {
        this._renderer.dispose();
        this._removeEventListeners();
    }

    /**
     * Public
     */
    createScene(type, element, options) {
        return this._sceneManager.createScene(type, element, options);
    }

    enable() {
        this._isEnabled = true;
    }

    disable() {
        this._isEnabled = false;
    }

    update() {
        this._update();
    }

    // createScenes(elements) {
    //     this._sceneManager.create(elements);
    // }
    
    // destroyScenes() {
    //     this._sceneManager.destroyAll();
    // }

    /**
     * Private
     */
    _bindHandlers() {
        this._resizeHandler = this._resizeHandler.bind(this);
        this._scrollHandler = this._scrollHandler.bind(this);
    }

    _setupEventListeners() {
        WindowResizeObserver.addEventListener('resize', this._resizeHandler);
        window.addEventListener('scroll', this._scrollHandler);
    }

    _removeEventListeners() {
        WindowResizeObserver.removeEventListener('resize', this._resizeHandler);
        window.removeEventListener('scroll', this._scrollHandler);
    }

    _createSceneManager() {
        const sceneManager = new SceneManager(this._scene);
        return sceneManager;
    }

    _createRenderer() {
        const renderer = new WebGLRenderer({
            canvas: this._canvas,
            antialias: true,
            alpha: true
        });
        // renderer.setClearColor('#090909', 1);
        renderer.setClearColor('#090909', 0);
        return renderer;
    }

    _createCamera() {
        const camera = new OrthographicCamera(0, 0, 0, 0, 1, 1000);
        camera.position.set(0, 0, 1000);
        camera.lookAt(new Vector3());
        return camera;
    }

    _createComposer() {
        const composer = new EffectComposer(this._renderer);
        return composer;
    }

    _createPasses() {
        const passes = {};

        // Render pass
        passes.renderPass = new RenderPass(this._scene, this._camera);
        this._composer.addPass(passes.renderPass);

        // After image pass
        passes.afterimagePass = new AfterimagePass(0.7);
        this._composer.addPass(passes.afterimagePass);

        return passes;
    }

    _updateScrollPosition() {
        const x = document.body.scrollLeft || document.documentElement.scrollLeft;
        const y = document.body.scrollTop || document.documentElement.scrollTop;
        this._scrollPosition.x = x;
        this._scrollPosition.y = y;
    }

    /**
     * Update cycle
     */
    _update() {
        if (!this._isEnabled) return;

        const time = performance.now() - this._startTime;
        this._sceneManager.update(time, this._scrollPosition);
        this._render();
    }

    _render() {
        this._renderer.render(this._scene, this._camera);
        // this._composer.render();
    }

    /**
     * Resize
     */
    _resize() {
        let documentWidth = WindowResizeObserver.documentWidth;
        // if (!Number.isInteger(documentWidth / 2)) documentWidth += 1;
        // console.log(documentWidth);

        this._width = documentWidth;
        this._height = WindowResizeObserver.height;

        this._resizeCanvas();
        this._resizeCamera();
        this._resizeRenderer();
        // this._resizeComposer();
    }

    _resizeCanvas() {
        this._canvas.style.width = `${this._width}px`;
        this._canvas.style.height = `${this._height}px`;
    }

    _resizeCamera() {
        this._camera.left = this._width / -2;
        this._camera.right = this._width / 2;
        this._camera.top = this._height / 2;
        this._camera.bottom = this._height / -2;
        this._camera.updateProjectionMatrix();
    }

    _resizeRenderer() {
        this._renderer.setPixelRatio(device.dpr());
        this._renderer.setSize(this._width, this._height, false);
    }

    _resizeComposer() {
        this._composer.setSize(this._width, this._height);
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }

    _scrollHandler() {
        this._updateScrollPosition();
    }

}
