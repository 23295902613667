<template>
<div class="stories-quote" :class="classList">
	<BackgroundRain v-if="hasRain" class="stories-quote__background" width="870px" height="100vh" wind="-7.8" amount="50" speed="0.56" />
	<template v-if="hasParallaxe && !$root.isMobile">
		<ParallaxeHorizontalElem
			:amount="parallaxeAmount"
		>
			<h2 class="stories-quote__title quote-chocolate is-white" v-html="title"></h2>
			<h3 v-if="subtitle" class="stories-quote__subtitle"  v-html="subtitle"></h3>
		</ParallaxeHorizontalElem>
	</template>

	<template v-else-if="!hasParallaxe || $root.isMobile">
		<h2 class="stories-quote__title quote-chocolate is-white" v-html="title"></h2>
		<h3 v-if="subtitle" class="stories-quote__subtitle"  v-html="subtitle"></h3>
	</template>
</div>
</template>

<script>
import ParallaxeHorizontalElem from '@/components/ParallaxeHorizontalElem.vue'
import BackgroundRain from '@/components/BackgroundRain.vue'

export default {
    name: 'StoriesQuote',

    props: {
		hasRain: {
			type: Boolean,
			default: false
		},
		title: {
			type: String
		},

		subtitle:  {
			type: String
		},

		hasParallaxe: {
			type: Boolean,
			default: true
		},

		parallaxeAmount: {
			type: String,
			default: 0.5
		},

		parallaxeDirection: {
			type: String,
			default: -1
		},

		isSpaceLeft: {
			type: Boolean
		},

		isSpaceRight: {
			type: Boolean
		}
	},

	components: {
		ParallaxeHorizontalElem,
		BackgroundRain
	},
	
	computed: {
		classList()
		{
			const classArray = []

			if(this.isSpaceLeft)
			{
				classArray.push('space-left')
			}

			if(this.isSpaceRight)
			{
				classArray.push('space-right')
			}

			return classArray
		}
	}
}
</script>

<style lang="stylus">

.stories-quote
	position relative
	padding-top $pos-text-top

	&.space-left
		padding-left 450px

		@media(max-width $breakpoints.xl)
			padding-left 350px

		@media(max-width $breakpoints.md)
			padding-left 100px

	&.space-right
		padding-right 280px

		@media(max-width $breakpoints.xl)
			padding-right 200px

		@media(max-width $breakpoints.md)
			padding-right 150px

.stories-quote__background
	position absolute
	top 0
	left 50%
	transform translateX(-50%)

.stories-quote__title
	max-width 750px

	@media(max-width $breakpoints.xl)
		max-width 650px

	@media(max-width $breakpoints.md)
		max-width 500px

.stories-quote__subtitle
	max-width 750px
	text-transform uppercase
	font-family 'Overpass'
	font-weight 600
	font-size 1.66rem
	line-height 1.2
	letter-spacing 0
	color $white
	opacity 0.3

	.is-ru &
		font-family 'Oswald'

	@media(max-width $breakpoints.xl)
		max-width 650px

	@media(max-width $breakpoints.md)
		max-width 500px
		font-size 18px
</style>