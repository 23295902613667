import gsap from 'gsap';
import { PlaneBufferGeometry, Object3D, Mesh, AdditiveBlending, Vector2, ShaderMaterial } from 'three';

import vertexShader from './shaders/clouds.vert.glsl';
import fragmentShader from './shaders/clouds.frag.glsl';

const IMAGE_WIDTH = 1024;
const IMAGE_HEIGHT = 1024;

export default class Clouds extends Object3D {
    constructor({ texture }) {
        super();

        this._texture = texture;

        this._create();
        this._bindHandlers();
        this._setupEventListeners();
        this._resize();
    }

    destroy() {
        this._removeEventListeners();
    }

    /**
     * Public
     */
    setPosition({ startPoint, endPoint }) {
        this._startPoint = startPoint;
        this._endPoint = endPoint;

        const centerPoint = (new Vector2()).lerpVectors(this._startPoint, this._endPoint, 0.5);
        const gradientOffset = centerPoint.x / this._width;
        const side = Math.floor(Math.random() * 4);
        const textureRotation = Math.PI * 0.5 * side;
        // const textureRotation = Math.PI * 2 * Math.random();
        const textureOffset = new Vector2(1000 * Math.random(), 1000 * Math.random());

        this._material.uniforms.uGradientOffset.value = gradientOffset;
        this._material.uniforms.uTextureRotation.value = textureRotation;
        this._material.uniforms.uTextureOffset.value = textureOffset;
    }

    show() {
        const timeline = new gsap.timeline({ onComplete: this._showCompleteHandler });
        timeline.to(this._material.uniforms.uAlpha, 0.2, { value: 0.8 });
        timeline.to(this._material.uniforms.uAlpha, 2, { value: 0, ease: 'sine.inOut' });
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._resizeHandler = this._resizeHandler.bind(this);
        this._showCompleteHandler = this._showCompleteHandler.bind(this);
    }

    _setupEventListeners() {
        window.addEventListener('resize', this._resizeHandler);
    }

    _removeEventListeners() {
        window.removeEventListener('resize', this._resizeHandler);
    }

    _create() {
        const geometry = new PlaneBufferGeometry(1, 1);
        this._material = new ShaderMaterial({
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            transparent: true,
            blending: AdditiveBlending,
            uniforms: {
                uTexture: { value: this._texture },
                uResolution: { value: new Vector2() },
                uImageSize: { value: new Vector2(IMAGE_WIDTH, IMAGE_HEIGHT) },
                uAlpha: { value: 1.0 },
                uGradientOffset: { value: null },
                uTextureRotation: { value: null },
                uTextureOffset: { value: null }
            }
        });

        this._mesh = new Mesh(geometry, this._material);
        this.add(this._mesh);
    }

    _resize() {
        this._width = window.innerWidth;
        this._height = window.innerHeight;

        this._mesh.scale.x = this._width;
        this._mesh.scale.y = this._height;

        this._material.uniforms.uResolution.value = new Vector2(this._width, this._height);
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }

    _showCompleteHandler() {
        this.dispatchEvent({ type: 'complete' });
    }
}
