import { Object3D, Vector2, Points, Color, ShaderMaterial, BufferGeometry, BufferAttribute, DynamicDrawUsage, AdditiveBlending } from 'three';
import Particle from './Particle';

import vertexShader from './shaders/particle.vert.glsl';
import fragmentShader from './shaders/particle.frag.glsl';
export default class Particles extends Object3D {
    constructor(amount) {
        super();

        this._amount = amount;
        this._particles = [];

        this._geometry = this._createGeometry();
        this._material = this._createMaterial();

        this._mesh = new Points(this._geometry, this._material)
        this.add(this._mesh);

        this.position.x = 0.1;
        this.position.z = -0.06;

        this._startPlaying = false;
        this.visible = false;

        this._speed = 1;

        this.play = this.play.bind(this);
    }

    /**
     * Getters & Setters
     */
    get speed() {
        return this._speed;
    }

    set speed(value) {
        this._speed = value;
    }

    /**
     * Public
     */
    play() {
        this._reset();
        this._startPlaying = true;
    }

    update() {
        if (!this._startPlaying) return;

        const position = this._geometry.getAttribute('position');
        const alpha = this._geometry.getAttribute('alpha');

        let particle;
        for (let i = 0, len = this._particles.length; i < len; i++) {
            particle = this._particles[i];
            particle.speed = this._speed;
            particle.update();

            position.array[i * 3 + 0] = particle.x;
            position.array[i * 3 + 1] = particle.y;
            position.array[i * 3 + 2] = particle.z;

            alpha.array[i] = particle.life;
        }

        position.needsUpdate = true;
        alpha.needsUpdate = true;
    }

    /**
     * Private
     */
    _reset() {
        this.visible = true;

        let position;
        for (let i = 0, len = this._particles.length; i < len; i++) {
            position = this._getRandomPosition();
            this._particles[i].reset(position.x, position.y, position.z, position.direction);
        }
    }

    _createGeometry() {
        const geometry = new BufferGeometry();
        geometry.setDrawRange(0, this._amount);
        geometry.setAttribute('position', new BufferAttribute(this._createParticles(), 3).setUsage(DynamicDrawUsage));
        geometry.setAttribute('alpha', new BufferAttribute(this._createAlpha(), 1).setUsage(DynamicDrawUsage));
        return geometry;
    }

    _createParticles() {
        let position;
        let particle;

        const particles = new Float32Array(this._amount * 3);
        
        for (let i = 0; i < this._amount; i++) {
            position = this._getRandomPosition();
            particle = new Particle(position.x, position.y, position.z, position.direction);
            this._particles.push(particle);

            particles[i * 3 + 0] = position.x;
            particles[i * 3 + 1] = position.y;
            particles[i * 3 + 2] = position.z;
        }

        return particles;
    }

    _getRandomPosition() {
        const radius = 0.44;
        const offset = 0.3;

        const angle = Math.PI * 2 * Math.random();
        const x = radius * Math.cos(angle) + offset * -0.5 + offset * Math.random();
        const y = 0.05 + Math.random() * 0.28;
        // const z = radius * 0.3 * Math.sin(angle) + offset * -0.5 + offset * Math.random();
        const z = radius * 1 * Math.sin(angle) + offset * -0.5 + offset * Math.random();
        const direction = new Vector2(x, z);
        direction.normalize();

        return { x, y, z, direction };
    }

    _createAlpha() {
        const alpha = new Float32Array(this._amount);
        for (let i = 0; i < this._amount; i++) {
            alpha[i] = 1;
        }
        return alpha;
    }

    _createMaterial() {
        // this._material = new PointsMaterial({
        //     // color: 0x29ab97,
        //     // color: 0xff0000,
        //     size: 4,
        //     sizeAttenuation: false,
        //     transparent: true,
        //     opacity: 0.9,
        //     // map: rainDrop,
        //     alphaTest: 0.5,
        //     vertexColors: VertexColors
        // });

        const material = new ShaderMaterial({
            vertexShader,
            fragmentShader,
            transparent: true,
            blending: AdditiveBlending,
            depthTest: true,
            uniforms: {
                uColor: { value: new Color('rgb(104, 252, 251)') }
                // uColor: { value: new Color('rgb(255, 0, 0)') }
            }
        });
        return material;
    }
}
