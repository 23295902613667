import { gsap } from 'gsap';

import { WebGLRenderer, Scene, Vector3, OrthographicCamera, PlaneBufferGeometry, ShaderMaterial, RepeatWrapping, Color, Texture, Vector2 } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';

import Debugger from '@/webgl/utils/Debugger';
import math from '@/webgl/utils/math';
import Ripple from '../shared/ripple/Ripple';
import Preloader from '../../Preloader';
import Events from '../../Events';

import vertexShader from '../shared/ripple/shader/vertex.glsl';
import fragmentShader from '../shared/ripple/shader/fragment.glsl';

import FinalPass from './FinalPass';

const RING_AMOUNT = 18;
// const VIEWPORT_OFFSET = 100;

export default class Ripples {
    constructor({ canvas, isPage, amount, autoUpdate, timeScale, minScale, maxScale, rows } = {}) {
        this._container = document.createElement('div');
        this._container.style.position = 'relative';
        this._container.style.width = '100%';
        this._container.style.height = '100%';
        this._container.style.perspective = '800px';

        this._canvas = canvas || document.createElement('canvas');
        this._canvas.style.position = 'absolute';
        this._canvas.style.transformOrigin = 'center center';
        this._container.appendChild(this._canvas);

        this._isPage = isPage;
        this._amount = amount || RING_AMOUNT;
        this._minScale = minScale || 0.2;
        this._maxScale = maxScale || 1;
        this._rows = rows || 4;

        this._width = null;
        this._height = null;
        this._startTime = performance.now();
        this._rings = [];
        this._ringColor = '#148270';
        this._growSpeed = 1;
        this._isMouseDown = false;
        this._zoom = 1;
        this._timeScale = timeScale || 1;
        this._contentWidth = 0;
        this._mousePosition = {
            viewport: new Vector2(),
            screen: new Vector2()
        }
        this._scene = new Scene();
        this._isStarted = false;
        this._autoUpdate = autoUpdate === undefined ? true : autoUpdate;
        this._grid = { x: 0, y: 0 };
        this._transformTranslate = { x: 0, y: 0 };

        this._rotation = {
            x: 0,
            y: 0,
            z: 0
        };
        this._rotationAmountIdleX = 0.1;
        this._rotationAmountActiveX = 0.15;
        this._rotationAmountX = this._rotationAmountIdleX;
        this._rotationAmountIdleY = 0.1;
        this._rotationAmountActiveY = 0.15;
        this._rotationAmountY = this._rotationAmountIdleY;
        this._rotationLerpAmount = 0.014;

        this._time = 0;

        this._bindHandlers();
        if (!this._isPage) this._getTextures();

        // gsap.to(this._scene.scale, 20, { x: 1.5, y: 1.5, ease: 'none' });
        // gsap.to(this._scene.rotation, 20, { z: Math.PI * 0.5, ease: 'none' });
    }

    destroy() {
        this._removeEventListeners();
        this._removeDebugGui();
    }

    /**
     * Public
     */
    getElement() {
        return this._container;
    }

    show() {
        this._timelineShow = new gsap.timeline();
        this._timelineShow.fromTo(this._canvas, 0.7, { alpha: 0 }, { alpha: 1, ease: 'sine.inOut' }, 0.2);
        // this._timelineShow.fromTo(this._scene.scale, 1, { x: 1, y: 1 }, { x: 1.3, y: 1.3, ease: 'power3.out' }, 0.4);
    }

    hide(callback) {
        this._timelineHide = new gsap.timeline({ onComplete: callback });
        this._timelineHide.to(this._canvas, 0.7, { alpha: 0, ease: 'sine.inOut'}, 0);
    }

    update() {
        if (!this._isStarted) return;
        this._update();
        this._render();
    }

    setContentWidth(width) {
        this._contentWidth = width;
        this._getTextures();
    }

    updatePosition(position) {
        if (position) this._scene.position.x = this._width * 0.5 + -position.x;
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._resizeHandler = this._resizeHandler.bind(this);
        this._tickHandler = this._tickHandler.bind(this);
        this._mouseDownHandler = this._mouseDownHandler.bind(this);
        this._mouseMoveHandler = this._mouseMoveHandler.bind(this);
        this._mouseUpHandler = this._mouseUpHandler.bind(this);
        this._touchStartHandler = this._touchStartHandler.bind(this);
        this._touchMoveHandler = this._touchMoveHandler.bind(this);
        this._touchEndHandler = this._touchEndHandler.bind(this);
    }

    _setupEventListeners() {
        window.addEventListener('resize', this._resizeHandler);
        if (this._autoUpdate) gsap.ticker.add(this._tickHandler);
        this._canvas.addEventListener('mousedown', this._mouseDownHandler);
        this._canvas.addEventListener('mousemove', this._mouseMoveHandler);
        this._canvas.addEventListener('mouseup', this._mouseUpHandler);

        // Touch
        this._canvas.addEventListener('touchstart', this._touchStartHandler);
        this._canvas.addEventListener('touchmove', this._touchMoveHandler);
        this._canvas.addEventListener('touchend', this._touchEndHandler);
    }

    _removeEventListeners() {
        window.removeEventListener('resize', this._resizeHandler);
        if (this._autoUpdate) gsap.ticker.remove(this._tickHandler);
        this._canvas.removeEventListener('mousedown', this._mouseDownHandler);
        this._canvas.removeEventListener('mousemove', this._mouseMoveHandler);
        this._canvas.removeEventListener('mouseup', this._mouseUpHandler);

        // Touch
        this._canvas.removeEventListener('touchstart', this._touchStartHandler);
        this._canvas.removeEventListener('touchmove', this._touchMoveHandler);
        this._canvas.removeEventListener('touchend', this._touchEndHandler);
    }

    _getTextures() {
        Promise.all([
            Preloader.load('waterdudv'),
            Preloader.load('ripples-refraction')
        ]).then(() => {
            this._start();
        });
    }

    _start() {
        if (this._isStarted) return;
        this._isStarted = true;

        this._renderer = this._createRenderer();
        this._camera = this._createCamera();
        this._composer = this._createComposer();
        this._passes = this._createPasses();

        this._resize();
        this._setupEventListeners();
        this._createRings();
        this._setupDebugGui();
    }

    _createRenderer() {
        const renderer = new WebGLRenderer({
            canvas: this._canvas,
            antialias: true
        });
        renderer.setClearColor("#090909", 1);
        return renderer;
    }

    _createCamera() {
        const camera = new OrthographicCamera(0, 0, 0, 0, 1, 1000);
        camera.position.set(0, 0, 700);
        camera.lookAt(new Vector3());
        return camera;
    }

    _createComposer() {
        const composer = new EffectComposer(this._renderer);
        return composer;
    }

    _createPasses() {
        const passes = {};

        // Render pass
        passes.renderPass = new RenderPass(this._scene, this._camera);
        this._composer.addPass(passes.renderPass);

        // Final pass
        const dudvTexture = new Texture(Preloader.get('waterdudv'));
        dudvTexture.needsUpdate = true;
        dudvTexture.wrapS = RepeatWrapping;
        dudvTexture.wrapT = RepeatWrapping;

        const displacementTexture = new Texture(Preloader.get('ripples-refraction'));
        displacementTexture.needsUpdate = true;
        displacementTexture.wrapS = RepeatWrapping;
        displacementTexture.wrapT = RepeatWrapping;

        this._finalPass = new FinalPass({
            waveStrength: 0.0009,
            waveSpeed: 0.152,
            tileScale: 1,
            vignetteFalloff: 0,
            vignetteAmount: 0.4,
            brightness: 0,
            contrast: 1,
            gamma: 1
        });
        this._finalPass.uniforms.tDudv.value = dudvTexture;
        this._finalPass.uniforms.tDisplacement.value = displacementTexture;

        passes.finalPass = new ShaderPass(this._finalPass);
        this._composer.addPass(passes.finalPass);

        return passes;
    }

    _createRings() {
        // Geometry & Materials
        this._ringGeometry = new PlaneBufferGeometry(600, 600);
        this._ringMaterial = new ShaderMaterial({
            vertexShader,
            fragmentShader,
            transparent: true,
            uniforms: {
                uAlpha: { value: 0 },
                uRippleAlpha: { value: 1 },
                uBorderWidth: { value: 1 },
                uColor: { value: new Color(this._ringColor) }
            }
        });

        let scale;
        let delay;
        let progress;
        for (let i = 0; i < this._amount; i++) {
            // Create
            const ripple = new Ripple({
                geometry: this._ringGeometry,
                material: this._ringMaterial,
                ringAmount: 3,
                minBorderWidth: 0.5,
                maxBorderWidth: 2.5,
                alpha: 1,
                growSpeed: this._growSpeed
            });

            // Position
            ripple.position.copy(this._calcRandomPosition(i));

            // Scale
            scale = this._minScale + Math.random() * (this._maxScale - this._minScale);
            ripple.scale.multiplyScalar(scale);

            // Add to scene
            this._scene.add(ripple);
            this._rings.push(ripple);

            if (this._isPage) {
                delay = Math.random() * 2;
            } else {
                if (i < this._amount * 0.5) {
                    delay = Math.random() * 2;
                } else {
                    delay = 2 + Math.random() * 8;
                }
            }

            progress = Math.random();

            ripple.play(delay, progress);
        }
    }

    _calcRandomPosition(index) {
        const offsetX = this._width * -0.5;
        const offsetY = this._height * -0.5;

        const x = offsetX + (index % this._grid.x) * this._grid.size + this._grid.size * Math.random();
        const y = offsetY + (Math.floor(index / this._grid.x) % this._grid.y) * this._grid.size + this._grid.size * Math.random();

        return new Vector3(x, y, 0);


        // let x = 0;
        // if (this._isPage) {
        //     x = -VIEWPORT_OFFSET;
        //     x += (this._contentWidth + VIEWPORT_OFFSET * 2) * Math.random();
        // } else {
        //     x = -VIEWPORT_OFFSET - this._width * 0.5;
        //     x += (this._width + VIEWPORT_OFFSET * 2) * Math.random();
        // }
        
        // let y = VIEWPORT_OFFSET + this._height * 0.5;
        // y -= (this._height + VIEWPORT_OFFSET * 2) * Math.random();

        // // console.log(x, y);

        // return new Vector3(x, y, 0);


    }

    _update() {
        const time = performance.now() - this._startTime;
        if (this._finalPass) this._finalPass.uniforms.uTime.value = time * 0.0001;

        if (!this._isPage) {
            // this._scene.position.x += 0.01;
            // this._scene.rotation.z += 0.002;
        }

        this._updateRings();
        this._updateTimeScale();
        this._spawnMouseCircles();
        this._updateRotation();

        // let item;
        // let distance;
        // for (let i = 0, len = this._rings.length; i < len; i++) {
        //     item = this._rings[i];
        //     distance = item.position.distanceTo(this._mousePosition);
        //     if (distance < 100) item._timeline.timeScale(3);
        // }
    }

    _updateRings() {
        let item;
        for (let i = 0, len = this._rings.length; i < len; i++) {
            item = this._rings[i];
            if (item.isFinished && !item.dontRespawn) {
                item.position.copy(this._calcRandomPosition(i));
                item.play();
            }
        }
    }

    _updateTimeScale() {
        let item;
        for (let i = 0, len = this._rings.length; i < len; i++) {
            item = this._rings[i];
            if (item.isStarted) {
                item.timeline.timeScale(this._timeScale);
            }
        }
    }

    _spawnMouseCircles() {
        this._time += 1;

        const interval = this._isMouseDown ? 40 : 20;

        if (this._time % interval === 0) {

            // Create
            const ripple = new Ripple({
                geometry: this._ringGeometry,
                material: this._ringMaterial,
                ringAmount: 3,
                minBorderWidth: 0.5,
                maxBorderWidth: 2.5,
                alpha: 1,
                growSpeed: this._growSpeed,
                dontRespawn: true
            });

            // Position
            const radius = 20 + 60 * Math.random();
            const angle = Math.PI * 2 * Math.random();
            const x = this._mousePosition.screen.x + radius * Math.cos(angle);
            const y = this._mousePosition.screen.y + radius * Math.sin(angle);
            ripple.position.x = x;
            ripple.position.y = y;

            // Scale
            const minScale = 0.1;
            const maxScale = 0.8;
            const scale = minScale + Math.random() * (maxScale - minScale);
            // const scale = 0.1;
            ripple.scale.multiplyScalar(scale);

            // Add to scene
            this._scene.add(ripple);
            this._rings.push(ripple);

            const progress = 0;

            ripple.play(0, progress);
        }
    }

    _updateRotation() {
        const halfViewportWidth = this._viewportWidth * 0.5;
        const halfViewportHeight = this._viewportHeight * 0.5;
        const offsetX = (this._mousePosition.viewport.x - halfViewportWidth) / halfViewportWidth;
        const offsetY = (this._mousePosition.viewport.y - halfViewportHeight) / halfViewportHeight;

        const rotationX = math.map(offsetY, -1, 1, -this._rotationAmountX, this._rotationAmountX);
        const rotationY = math.map(offsetX, -1, 1, this._rotationAmountY, -this._rotationAmountY);
        this._rotation.x = math.lerp(this._rotation.x, rotationX, this._rotationLerpAmount);
        this._rotation.y = math.lerp(this._rotation.y, rotationY, this._rotationLerpAmount);

        const transform = `translate(${this._transformTranslate.x}px, ${this._transformTranslate.y}px) rotateX(${this._rotation.x}rad) rotateY(${this._rotation.y}rad) rotateZ(${this._rotation.z}deg)`;
        this._canvas.style.transform = transform;
        this._canvas.style.webkitTransform = transform;
    }

    _slowDownTime() {
        if (this._speedUpTimeTimeline) this._speedUpTimeTimeline.kill();
        this._slownDownTimeTimeline = new gsap.timeline();
        this._slownDownTimeTimeline.to(this._scene.scale, 0.4, { x: 1.07, y: 1.07, ease: 'power3.out' }, 0);
        this._slownDownTimeTimeline.to(this, 0.4, { _timeScale: 0.25, ease: 'power3.out' }, 0);
        this._slownDownTimeTimeline.to(this, 0.4, {
            _timeScale: 0.25, 
            _rotationAmountX: this._rotationAmountActiveX,
            _rotationAmountY: this._rotationAmountActiveY,
            ease: 'power3.out'
        }, 0);
    }

    _speedUpTime() {
        if (this._slownDownTimeTimeline) this._slownDownTimeTimeline.kill();
        this._speedUpTimeTimeline = new gsap.timeline();
        this._speedUpTimeTimeline.to(this._scene.scale, 1, { x: 1.0, y: 1.0 }, 0);
        this._speedUpTimeTimeline.to(this, 1, {
            _timeScale: 1,
            _rotationAmountX: this._rotationAmountIdleX,
            _rotationAmountY: this._rotationAmountIdleY,
        }, 0);
    }

    _render() {
        // this._renderer.render(this._scene, this._camera);
        this._composer.render();
    }

    /**
     * Resize
     */
    _resize() {
        this._canvasScale = 1.2;

        this._viewportWidth = window.innerWidth;
        this._viewportHeight = window.innerHeight;

        this._width = this._viewportWidth * this._canvasScale;
        this._height = this._viewportHeight * this._canvasScale;

        this._transformTranslate = {
            x: Math.round((this._viewportWidth - this._width) * 0.5),
            y: Math.round((this._viewportHeight - this._height) * 0.5)
        };

        this._resizeCanvas();
        this._resizeCamera();
        this._resizeRenderer();
        this._resizeComposer();
        this._resizePasses();
        this._calcGrid();
        this._calcAmount();
    }

    _resizeCanvas() {
        this._canvas.width = this._width;
        this._canvas.height = this._height;
    }

    _resizeRenderer() {
        this._renderer.setPixelRatio(1);
        this._renderer.setSize(this._width, this._height, false);
    }

    _resizeComposer() {
        this._composer.setSize(this._width, this._height);
    }

    _resizePasses() {
        this._finalPass.resize({
            width: this._width,
            height: this._height
        });
    }

    _resizeCamera() {
        this._camera.left = this._width / -2;
        this._camera.right = this._width / 2;
        this._camera.top = this._height / 2;
        this._camera.bottom = this._height / -2;
        this._camera.updateProjectionMatrix();
    }

    _calcGrid() {
        const width = Math.max(this._width, this._contentWidth);
        const rows = this._rows;
        const gridSize = this._height / rows;
        const columns = Math.ceil(width / gridSize);
        this._grid = { size: gridSize, x: columns, y: rows };
    }

    _calcAmount() {
        this._amount = this._grid.x * this._grid.y;
    }

    /**
     * Debug
     */
    _setupDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        this._debugGui = Debugger.gui.addFolder('Ripples');
        this._debugGui.addColor(this, '_ringColor').name('ringColor').onChange(() => {
            this._ringMaterial.uniforms.uColor.value = new Color(this._ringColor);
        });
        this._debugGui.add(this, '_growSpeed', 0.1, 2, 0.01).name('growSpeed').onChange(() => {
            let item;
            for (let i = 0, len = this._rings.length; i < len; i++) {
                item = this._rings[i];
                item.tween.timeScale(this._growSpeed);
            }
        });
        // this._debugGui.add(this, '_rotationSpeedZ', -0.1, 0.1, 0.001);
        this._debugGui.add(this, '_rotationAmountActiveX', -0.5, 0.5, 0.001);
        this._debugGui.add(this, '_rotationAmountIdleX', -0.5, 0.5, 0.001);
        this._debugGui.add(this, '_rotationAmountActiveY', -0.5, 0.5, 0.001);
        this._debugGui.add(this, '_rotationAmountIdleY', -0.5, 0.5, 0.001);
        this._debugGui.add(this, '_rotationLerpAmount', 0, 0.5, 0.001);

        const post = this._debugGui.addFolder('post');
        post.add(this._finalPass.uniforms.uWaveStrength, 'value', 0, 1, 0.0001).name('waveStrength');
        post.add(this._finalPass.uniforms.uWaveSpeed, 'value', 0, 1, 0.001).name('waveSpeed');
        post.add(this._finalPass.uniforms.uTileScale, 'value', 0, 20, 0.001).name('tiling');
        post.add(this._finalPass.uniforms.uVignetteFalloff, 'value', 0, 10, 0.01).name('vignetteFalloff');
        post.add(this._finalPass.uniforms.uVignetteAmount, 'value', 0, 10, 0.01).name('vignetteAmount');
        post.add(this._finalPass.uniforms.uBrightness, 'value', - 1, 1).step(0.001).name('brightness')
        post.add(this._finalPass.uniforms.uContrast, 'value', 0, 2).step(0.001).name('contrast')
        post.add(this._finalPass.uniforms.uGamma, 'value', - 5, 5).step(0.001).name('gamma')
        post.open();

        this._debugGui.open();
    }

    _removeDebugGui() {
        if (this._debugGui) Debugger.gui.removeFolder(this._debugGui);
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }

    _tickHandler() {
        this._update();
        this._render();
    }

    _mouseDownHandler() {
        this._isMouseDown = true;
        Events.dispatchEvent('mouse:down', 0);
        this._slowDownTime();
    }

    _mouseUpHandler() {
        this._isMouseDown = false;
        Events.dispatchEvent('mouse:up');
        this._speedUpTime();
    }

    _mouseMoveHandler(e) {
        this._mousePosition.viewport.x = e.clientX;
        this._mousePosition.viewport.y = e.clientY;
        this._mousePosition.screen.x = this._width * -0.5 + e.clientX;
        this._mousePosition.screen.y = this._height * 0.5 - e.clientY;
    }

    // Touch
    _touchStartHandler() {
        this._isMouseDown = true;
        Events.dispatchEvent('mouse:down', 0);
        this._slowDownTime();
    }

    _touchMoveHandler(e) {
        const touch = e.touches[0];
        this._mousePosition.viewport.x = touch.clientX;
        this._mousePosition.viewport.y = touch.clientY;
        this._mousePosition.screen.x = this._width * -0.5 + touch.clientX;
        this._mousePosition.screen.y = this._height * 0.5 - touch.clientY;
    }

    _touchEndHandler() {
        this._isMouseDown = false;
        Events.dispatchEvent('mouse:up');
        this._speedUpTime();
    }
}
