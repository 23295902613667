<template>
    <section class="shop" v-if="contentLoaded">
        <div class="shop__container" :class="{'scroll-wrapper': !$root.isMobile}">
            <div class="horizontal-wrapper" :class="{'js-scroll-elem': !$root.isMobile}">

                <div class="shop__titles horizontal-block">
                    <NavMobileChocolate v-if="$root.isMobile"/>
                    <ChocolateCover
                        :url="content.cover_image.url"
                    />
					<div class="shop__titles__title">
						<ChocolateTitle
							class="shop__titles__title--elem"
							:title="content.cover_title"
							:titleBig="true"
							:amount="0.8"
						/>
					</div>
                </div>

                <div class="horizontal-block">
                    <section class="shop__map pad-l-md pad-r-xs green-line">
                        <ChocolateShopCard
                            :previewImage="content.map_image"
                            :street="content.street"
                            :zipcode="content.zipcode"
                            :city="content.city"
                            :phone="content.phone"
                            :localisationInfos="content.shop_localisation_infos"
                            :mapLink="content.shop_map_url"
							:infos="content.shop_infos"
                        />
                    </section>
                </div>

                <div class="horizontal-block">
                    <div class="shop__carousel green-line">
                        <ChocolateCarouselVertical
							class="shop__carousel__elem"
                            :items="content.slider"
                            :prevLabel="content.slider_prev_label"
                            :nextLabel="content.slider_next_label"
                            :hasLink="false"
                            :hasProductLinks="false"
                        >
                        </ChocolateCarouselVertical>
                    </div>
                </div>

                <div class="horizontal-block">
                    <section class="shop__shops pad-l-xs pad-r-xs green-line">
                        <ShopCard
                            class="shop__shops__online"
							:internal="false"
							:link="content2.eshop_link"
                            :image="content2.eshop_image"
                            :title="content2.eshop_title"
                        />

                        <ShopCard
                            class="shop__shops__physical"
							:internal="true"
                            :image="content2.shops_image"
                            :title="content2.shops_title"
                        />
                    </section>
                </div>

                <FooterChocolate :showBackgroundLines="true" />
            </div>
        </div>
    </section>
</template>

<script>
import config from '@/config.js'

import EventHub from '@/utils/EventHub.js'

import ChocolateCover from '@/components/ChocolateCover.vue'
import FooterChocolate from '@/components/FooterChocolate.vue'
import ChocolateCarouselVertical from '@/components/ChocolateCarouselVertical.vue'
import VerticalImg from '@/components/VerticalImg.vue'
import ChocolateShopCard from '@/components/ChocolateShopCard.vue'
import ShopCard from '@/components/ShopCard.vue'
import NavMobileChocolate from '@/components/NavMobileChocolate.vue'
import ParallaxeHorizontalElem from '@/components/ParallaxeHorizontalElem.vue'
import ChocolateTitle from '@/components/ChocolateTitle.vue'

import SmoothScrollHorizontalMixin from '@/mixins/SmoothScrollHorizontalMixin.js'
import API from '@/mixins/API.js'
import Page from '@/mixins/Page.js'
import MobileScroll from '@/mixins/MobileScroll.js'

export default {
    name: 'Shop',

    mixins: [SmoothScrollHorizontalMixin, API, Page, MobileScroll],

    components: {
        ChocolateCover,
        FooterChocolate,
        ChocolateCarouselVertical,
        VerticalImg,
        ChocolateShopCard,
        ShopCard,
        NavMobileChocolate,
        ParallaxeHorizontalElem,
        ChocolateTitle
    },

    data()
    {
        return {
            content2: null,
            isHorizontal: true
        }
    },

    created()
    {
        this.fetchLang(`/shops/${this.$route.params.id}`, () => {

            fetch(`${config.api.url}${config.api.path}/pages/42?lang=${this.$i18n.locale}`)
                .then((res) => res.json())
                .then((res) =>
                {
                    this.content2 = res.acf

                    this.contentLoaded = true

                    this.onMounted()

                    this.$nextTick(() => {
                        EventHub.$emit('nav-fixed-chocolate:init')

                        if(!this.$root.isMobile)
                        {
                            this.SmoothScrollHorizontalMixin_init()
                        }
                        else
                        {
                            this.MobileScroll_init()
                        }
                    })
                })
        })
    }
}
</script>

<style lang="stylus" scoped>
.shop
    background-color $grey

    @media(max-width $breakpoints.md)
        overflow-y hidden
        position relative

        height 100%

.shop__titles
	position relative

.shop__titles__title
	z-index 2
	position absolute
	right 0
	top $pos-text-top
	transform translateX(20%)

	@media(max-width $breakpoints.md)
		top 45%
		transform translateX(30%)

.shop__titles__parallaxe-elem
    height auto

.shop__titles__description
    max-width 380px
    margin-top 100px

    @media(max-width $breakpoints.md)
        margin-top 60px    

.shop__carousel
	position relative

	height 100%
	padding 0 100px 0 250px

	@media(max-width $breakpoints.md)
		padding-left 0
		padding-right 0px

.shop__carousel__elem
	position relative
	z-index 2

.shop__map
	position relative

	height 100%

.shop__full-img
    height 100vh

    @media(max-width $breakpoints.md)
        height 100%

.shop__shops
    position relative

    display flex

    height 100%

</style>