<template>
    <canvas class="background-ripples" ref="canvas"></canvas>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill'
import EventHub from '@/utils/EventHub.js'
import Ripples from '@/webgl/intro/scenes/ripples'

export default {
    name: 'BackgroundRain',

    props: [
    ],

    mounted()
    {
        this.create();
        this.setupResizeObserver()
        this.setupEventListeners()
    },

    beforeDestroy()
    {
        this.removeEventListeners()
        if (this.resizeObserver) this.resizeObserver.disconnect()
        if (this.ripples) this.ripples.destroy();
    },

    methods: {
        setupEventListeners()
        {
            EventHub.$on('application:on-tick', this.tickHandler)
        },

        removeEventListeners()
        {
            EventHub.$off('application:on-tick', this.tickHandler)
        },

        create() {
            this.ripples = new Ripples({
                canvas: this.$refs.canvas,
                isPage: true,
                autoUpdate: false,
                timeScale: 0.3,
                minScale: 0.8,
                maxScale: 1.5,
                rows: 2
            })
        },

        setupResizeObserver()
        {
            this.resizeObserver = new ResizeObserver(entries => {
                entries.forEach((entry) => {
                    this.ripples.setContentWidth(entry.contentRect.width)
                })
            })
            this.resizeObserver.observe(this.$el.parentElement)
        },

        update()
        {
            if (this.ripples) {
                this.ripples.updatePosition(this.$root.horizontalPageScrollPosition);
                this.ripples.update();
            }
        },

        /**
         * Handlers
         */
        tickHandler()
        {
            
            this.update()
        }
    }
}
</script>

<style lang="stylus">
.background-ripples
    position relative
    opacity 0.3
</style>