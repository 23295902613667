<template>
<div class="chocolate-carousel-vertical">

	<div class="chocolate-carousel-vertical__text">

		<div class="chocolate-carousel-vertical__events">

			<div class="chocolate-carousel-vertical__event js-carousel-info" v-for="(item, index) in items" :key="index" :class="{'is-active': index === currentIndex}">
				<div class="chocolate-carousel-vertical__event__header" v-if="item.title || item.date">
					<p class="chocolate-carousel-vertical__title subtitle-chocolate" v-html="item.title"></p>
					<p class="chocolate-carousel-vertical__date text-chocolate" v-html="item.date"></p>
				</div>
				<p class="chocolate-carousel-vertical__description text-chocolate js-carousel-description" v-html="item.description" :class="{'is-header': !item.title && !item.date}"></p>
			</div>
			<div class="chocolate-carousel-vertical__link" v-if="hasLink">
				<slot></slot>
			</div>
		</div>

		<div class="chocolate-carousel-vertical__nav" v-if="items.length > 1">
			<p class="chocolate-carousel-vertical__prev text-chocolate" @click="onClickPrev" :class="{'is-disabled': disablePrev}"> {{ prevLabel }} </p>
			<span class="chocolate-carousel-vertical__nav__line"></span>
			<p class="chocolate-carousel-vertical__next text-chocolate" @click="onClickNext" :class="{'is-disabled': disableNext}"> {{ nextLabel }} </p>
		</div>

		<div class="chocolate-carousel-vertical__product-links" v-if="hasProductLinks">
			<template v-for="(item, index) in items">
				<ArrowBtnExternal
					v-if="item.product_link && currentIndex === index"
					:key="index"
					class="chocolate-carousel-vertical__product-link"
					:class="{'is-active': index === currentIndex}"
					:href="item.product_link"
					:text="productLabel"
				/>
			</template>
		</div>
	</div>

	<div class="chocolate-carousel-vertical__bg">
		<ParallaxeHorizontalElem
			v-if="!$root.isMobile"
			:isInner="true"
			:amount="0.1"
		>
			<template v-for="(item, index) in items">
				<div :key="index" class="chocolate-carousel-vertical__bg__elem js-carousel-image" :style="{'background-image': `url(${item.image.url})`}" :class="{'is-active': index === currentIndex}"></div>
			</template>
		</ParallaxeHorizontalElem>
		<template v-else>
			<div v-for="(item, index) in items" :key="index" class="chocolate-carousel-vertical__bg__elem js-carousel-image" :style="{'background-image': `url(${item.image.url})`}" :class="{'is-active': index === currentIndex}"></div>
		</template>
	</div>


</div>
</template>

<script>
import Carousel from '@/mixins/Carousel.js'

import ParallaxeHorizontalElem from '@/components/ParallaxeHorizontalElem.vue'
import ArrowBtnExternal from '@/components/ArrowBtnExternal.vue'

export default {
	name: 'ChocolateCarouselVertical',

	mixins: [Carousel],

	components: {
		ParallaxeHorizontalElem,
		ArrowBtnExternal
	},

	props: {

	  items: {
		type: Array
	  },
	  prevLabel: {
		  type: String
	  },
	  nextLabel: {
		  type: String
	  },
	  hasLink: {
		  type: Boolean,
		  default: true
	  },
	  hasProductLinks: {
		  type: Boolean,
		  defaault: false
	  },
	  productLabel: {
		  type: String
	  }
	},

	data() {
	  return {

	  }
	},

	methods: {

	},

	mounted()
	{
		this.$nextTick(() => {
			this.init()
		})
	}
}
</script>

<style lang="stylus">
.chocolate-carousel-vertical
	display flex
	height 100vh

	@media(max-width $breakpoints.md)
		height 100%

.chocolate-carousel-vertical__bg
	overflow hidden
	position relative
	height 100vh
	width 80vw
	max-width 1100px

	@media(max-width $breakpoints.md)
		width 100vw
		height 100%

.chocolate-carousel-vertical__bg__elem
	position absolute
	top 0
	left 0

	width 100%
	height 100%

	background-size cover
	background-position 50% 50%

	opacity 0

	transform scale(1.05)

	will-change opacity, transform

	&.is-active
		position relative

		opacity 1

.chocolate-carousel-vertical__text
	display flex
	flex-direction column
	height 50vh
	width 450px
	margin-top 50vh
	padding-top 7vh

	@media(max-width $breakpoints.md)
		width 300px

.chocolate-carousel-vertical__events
	position relative
	width 100%
	margin-top 3.83rem

	@media(max-width $breakpoints.md)
		max-width none
		padding-left 0px
		padding-right 10px

.chocolate-carousel-vertical__event
	position absolute 
	top 0
	left 0

	opacity 0

	will-change opacity, transform

	&.is-active
		position relative

		opacity 1

.chocolate-carousel-vertical__event__header
	max-width 70%

	@media(max-width $breakpoints.md)
		max-width none

.chocolate-carousel-vertical__date
	margin-bottom 2rem

	@media(max-width $breakpoints.md)
		max-width 80%

.chocolate-carousel-vertical__title
	margin-bottom 2rem

	@media(max-width $breakpoints.md)
		max-width 80%

.chocolate-carousel-vertical__description
	max-width 75%
	margin-bottom 2.5rem

	will-change opacity, transform

	&.is-header
		@media(max-width $breakpoints.md)
			max-width 80%

	@media(max-height $breakpoints-h.lg)
		max-width 65%

	@media(max-height $breakpoints-h.lg)
		max-width 80%

	@media(max-width $breakpoints.md)
		max-width none

.chocolate-carousel-vertical__nav
	position relative
	z-index 2
	display flex
	align-items center

	line-height 0

	@media(max-width $breakpoints.md)
		flex-direction column
		align-items flex-start

.chocolate-carousel-vertical__nav__line
	display block

	height 1px
	width 30px
	margin 0 15px

	background $black

	@media(max-width $breakpoints.md)
		display none


.chocolate-carousel-vertical__prev, .chocolate-carousel-vertical__next
	user-select none 

	cursor pointer
	display inline-block

	line-height 1

	&.is-disabled
		opacity 0.3

	@media(max-width $breakpoints.md)
		margin-bottom 0px
		padding-bottom 10px


.chocolate-carousel-vertical__link
	position absolute
	bottom 3vh
	left 0

	@media(max-height $breakpoints-h.xxl)
		bottom 8vh

	@media(max-width $breakpoints.md)
		bottom 6%
		padding-left 0px

.chocolate-carousel-vertical__product-links
	position absolute
	bottom 10vh
	left 0

	@media(max-height $breakpoints-h.xxl)
		bottom 14vh

	@media(max-width $breakpoints.md)
		bottom 6%
		padding-left 0px

</style>