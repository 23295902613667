<template>
    <section class="contact" :class="{'scroll-wrapper': !$root.isMobile}" v-if="contentLoaded">
        <div class="scroll-elem" :class="{'js-scroll-elem': !$root.isMobile}">
            <NavSculptures/>

            <div class="contact__inner">
                <div class="container">
                    <div class="contact__title">
                        <h2 v-if="$root.isMobile" class="contact__title__elem title--sculptures" v-html="content.title"></h2>
                        <ParallaxeVerticalElem
                            v-else
                            :amount="0.5"
                        >
                            <h2 class="contact__title__elem title--sculptures" v-html="content.title"></h2>
                        </ParallaxeVerticalElem>
                    </div>
                </div>


                <div class="contact__sections">
                    <div class="contact__image" v-if="!$root.isMobile">
                        <ParallaxeVerticalElem
                            :isInner="true"
                            :amount="0.1"
                        >
                            <LazyImg
                                class="about__illustration__elem"
                                :url="content.image.url"
                                :alt="content.image.alt"
                                :width="content.image.width"
                                :height="content.image.height"
                            />
                        </ParallaxeVerticalElem>
                    </div>
                    <div class="contact__sections__inner">
                        <div class="contact__section" v-for="(item, index) in content.sections" :key="index">
                            <h4 class="contact__section__title" v-if="item.title" v-html="item.title"></h4>
                            <p class="contact__section__paragraph" v-for="(item2, index2) in item.paragraphs" :key="`paragraph-${index2}`" v-html="item2.paragraph"></p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    </section>
</template>

<script>
import SmoothScrollVerticalMixin from '@/mixins/SmoothScrollVerticalMixin.js'
import API from '@/mixins/API.js'
import Page from '@/mixins/Page.js'

import NavSculptures from '@/components/NavSculptures.vue'
import Footer from '@/components/Footer.vue'
import ParallaxeVerticalElem from '@/components/ParallaxeVerticalElem.vue'
import LazyImg from '@/components/LazyImg.vue'

export default {
    name: 'Contact',

    mixins: [API, Page, SmoothScrollVerticalMixin],

    components: {
        Footer,
        NavSculptures,
        ParallaxeVerticalElem,
        LazyImg
    },

    created()
    {
        this.fetchLang(`/pages/1522?lang=${this.$i18n.locale}`, () => {
            this.contentLoaded = true

            this.onMounted()

            // we have 1500ms during transition
            window.setTimeout(() => {
                if(!this.$root.isMobile)
                {
                    this.SmoothScrollVerticalMixin_init()
                }
            }, 100)
        })
    }
}
</script>

<style lang="stylus" scoped>
.contact
    color $white

    background $black

.contact__inner
    margin-bottom 300px

    @media(max-width $breakpoints.md)
        margin-bottom 100px

.contact__title
    padding-top 330px
    padding-bottom 180px

    background $black

    @media(max-width $breakpoints.md)
        padding-top 400px
        padding-bottom 80px

.contact__title__elem
    grid-col(3.5, 4.5)

    color $white

    @media(max-width $breakpoints.md)
        width 100%
        margin 0

.contact__sections
    position relative

    display flex
    align-items center

.contact__sections__inner
    width 50vw
    padding 0 100px

    @media(max-width $breakpoints.md)
        width 100%
        padding 0 10%

.contact__image
    width 50vw
    // position absolute
    // top 50%
    // left 0

    // width 50%

    // transform translateY(-50%)

.contact__section
    // grid-col(5, 2)
    max-width $sculpture-paragraph-mwidth
    margin-bottom 140px

    color $white

    @media(max-width $breakpoints.md)
        width 100%
        margin-left 0
        margin-bottom 60px

    &:last-of-type
        margin-bottom 0

.contact__section__title
    margin-bottom 40px
    text-transform uppercase

    @media(max-width $breakpoints.md)
        margin-bottom 30px

.contact__section__paragraph
    margin-bottom 40px
    opacity 0.3

    @media(max-width $breakpoints.md)
        margin-bottom 30px

    &:last-child
        margin-bottom 0
</style>