<template>
    <section class="footer" v-if="navigation">
        <div class="footer-site">
            <div class="footer-site__container container">
                <div class="footer-site__brand">
                    <div class="footer-site__brand__logo">
                        <img src="../assets/images/logo-black.png" alt="Patrick roger logo black" class="footer-site__brand__logo__img">
                    </div>
                </div>
                <div class="footer-site__nav">
                    <ul class="footer-site__nav__links" v-for="(item, index) in navigation.footer_columns" :key="index">
                        <li class="footer-site__nav__link" v-for="(item2, index2) in item.line" :key="index2">
                            <router-link v-if="item2.link_type === 'internal'" :to="`/${$i18n.locale}${item2.path}`">{{ item2.label }}</router-link>
                            <a v-else :href="item2.url">{{ item2.label }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-social">
            <div class="container">
                <!-- <p class="footer-social__text"> {{ $t('footer.newsletter.text') }}</p> -->
                <div class="footer-social__container">
                    <!-- <div class="footer-social__newsletter">
                        <div class="footer-social__newsletter__field">
                            <input class="footer-social__newsletter__input" :placeholder="$t('footer.newsletter.placeholder')" ref="input">
                            <button class="footer-social__newsletter__btn">
                                Ok
                            </button>
                        </div>
                    </div> -->
                    <div class="footer-social__links">
                        <a v-for="(item, index) in navigation.footer_social" :key="index" class="footer-social__link" :href="item.url" target="_blank">{{ item.label }}</a>
                    </div>
                    <div class="footer-lang__links">
                        <template v-for="(item, index) in localsLinks">
                        <a  class="footer-lang__link" :key="`link-${index}`" :href="`/${item.lang}`">{{ item.lang }}</a> 
                        <span :key="`span-${index}`"  v-if="index < localsLinks.length - 1"> / </span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import config from '@/config.js'

import NavigationMixin from '@/mixins/NavigationMixin.js'

export default {
    name: 'Footer',

    mixins: [NavigationMixin],

    computed: {
        localsLinks()
        {
            const links = []

            for(let i = 0; i < config.langs.length; i++)
            {
                links.push(
                    {
                        lang: config.langs[i]
                    }
                )
            }
            return links
        }
    }
}
</script>

<style lang="stylus">
.footer
	position relative
	padding-top 150px
	padding-bottom 120px
	border-top 1px solid $grey

	font-size 18px
	text-transform uppercase
	color $black

	background $white

	@media(max-width $breakpoints.xl)
		font-size 17px

	@media(max-width $breakpoints.lg)
		padding-top 100px
		padding-bottom 100px

		font-size 16px

	@media(max-width $breakpoints.md)
		padding-top 100px
		padding-bottom 100px

.footer-site__container, .footer-social__container
	display flex

.footer-site
	padding-bottom 80px
	border-bottom 1px solid $grey

.footer-site__container.container
	max-width none
	padding 0 100px

	@media(max-width $breakpoints.md)
		padding 0 10%
		display flex
		flex-direction column

.footer-site__brand
	grid-col(0, 4)

	@media(max-width $breakpoints.xl)
		grid-col(0, 3)

	@media(max-width $breakpoints.md)
		width 75%
		margin 0

.footer-site__brand__logo__img
	display block

	@media(max-width $breakpoints.xl)
		width 70%

	@media(max-width $breakpoints.md)
		width 100%

.footer-site__nav
	display flex
	grid-col(0, 5)

	@media(max-width $breakpoints.xl)
		grid-col(0, 6)

	@media(max-width $breakpoints.md)
		flex-direction column

		width 100%
		margin 0
		padding-top 80px

.footer-site__nav__links
	display flex
	flex-direction column

	width 25%

	@media(max-width $breakpoints.md)
		position relative

		width auto
		padding-bottom 50px

	// &:nth-child(2)
	// &:after
	// 	content ''
	// 	position absolute 
	// 	bottom 0
	// 	left -15vw

	// 	width 100vw
	// 	height 1px

	// 	background-color $grey

	// &:nth-child(3)
	// 	padding-top 50px

	&:last-child
		padding-bottom 0

.footer-site__nav__link
	padding 0 10px
	margin-bottom 40px

	line-height 1.5

	@media(max-width $breakpoints.md)
		padding 0
		margin-bottom 25px

.footer-social__text
	grid-col(0, 3)

	margin-bottom 40px

	line-height 1.33

	@media(max-width $breakpoints.md)
		width 100%
		margin 0

.footer-social__newsletter__field
	display flex

.footer-social__newsletter__input
	height 80px
	width 380px
	padding-left 25px
	border 1px solid $grey

	line-height 80px
	text-transform uppercase

	&::placeholder
		color $black

	@media(max-width $breakpoints.md)
		width 100%
		padding-left 15px

.footer-social__newsletter__btn
	cursor pointer

	display flex
	align-items center
	justify-content center

	height 80px
	width 80px
	border 1px solid $grey
	border-left none

	text-transform uppercase

.footer-social
	padding-top 60px

	@media(max-width $breakpoints.md)
		padding-top 80px

.container
	padding 0 100px
	max-width none

	@media(max-width $breakpoints.md)
		padding 0 10%

.footer-social__container
	justify-content space-between
	align-items center

	@media(max-width $breakpoints.md)
		flex-direction column

.footer-social__text
	@media(max-width $breakpoints.md)
		margin-bottom 50px

.footer-social__newsletter
	@media(max-width $breakpoints.md)
		width 100%
		margin-bottom 50px

.footer-social__links
	@media(max-width $breakpoints.md)
		width 100%

.footer-social__link
	padding-left 30px

	&:first-child
		padding-left 0

.footer-lang__links
	@media(max-width $breakpoints.md)
		width 100%
		margin-top 20px

.footer-lang__link
	padding 0 10px

	&:first-child
		@media(max-width $breakpoints.md)
			padding-left 0
</style>