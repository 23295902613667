<template>
  <div class="exposition" :class="{'scroll-wrapper': !$root.isMobile}" v-if="contentLoaded">

      <div class="scroll-elem" :class="{'js-scroll-elem': !$root.isMobile}">

        <NavSculptures/>

        <section class="exposition__hero">
            <CanvasBackground scene="lines" type="vertical" />
            <div class="container">

                <div class="exposition__hero__quote" v-if="content.cover_title">
                    <h2 v-if="$root.isMobile" class="exposition__hero__quote__elem title--sculptures" v-html="content.cover_title"></h2>
                    <ParallaxeVerticalElem
                        v-else
                        :amount="0.5"
                    >
                        <h2 class="exposition__hero__quote__elem title--sculptures" v-html="content.cover_title"></h2>
                    </ParallaxeVerticalElem>
                </div>
                <p class="exposition__hero__description" v-html="content.cover_description">
                </p>
            </div>
        </section>

        <section class="exposition__wrapper container">
            <p class="exposition__back" @click="$router.go(-1)">< Back</p>
            <div class="exposition__bloc--card" v-if="content.has_top_bloc">
                <div class="exposition__bloc--card__image">
                    <LazyImg
                        v-if="$root.isMobile"
                        class="exposition__bloc--card__image__elem"
                        :url="content.top_bloc_image.url"
                        :alt="content.top_bloc_image.alt"
                        :width="content.top_bloc_image.width"
                        :height="content.top_bloc_image.height"
                    />
                    <ParallaxeVerticalElem
                        v-else
                        :isInner="true"
                        :amount="0.1"
                    >
                        <LazyImg
                            class="exposition__bloc--card__image__elem"
                            :url="content.top_bloc_image.url"
                            :alt="content.top_bloc_image.alt"
                            :width="content.top_bloc_image.width"
                            :height="content.top_bloc_image.height"
                        />
                    </ParallaxeVerticalElem>
                    </div>
                    <div class="exposition__bloc--card__infos">
                        <h3 class="exposition__bloc--card__infos__title" v-if="content.top_bloc_title" v-html="content.top_bloc_title"></h3>
                        <p class="exposition__bloc--card__infos__description text-sculptures" v-if="content.top_bloc_description" v-html="content.top_bloc_description">
                        </p>
                    </div>
            </div>

            <template v-for="(item, index) in content.additional_blocs">
                <div class="exposition__bloc--image" :key="`image-${index}`" v-if="item.image">
                    <LazyImg
                        v-if="$root.isMobile"
                        class="exposition__bloc--image__elem"
                        :url="item.image.url"
                        :alt="item.image.alt"
                        :width="item.image.width"
                        :height="item.image.height"
                    />
                    <ParallaxeVerticalElem
                        v-else
                        :isInner="true"
                        :amount="0.1"
                    >
                        <LazyImg
                            class="exposition__bloc--image__elem"
                            :url="item.image.url"
                            :alt="item.image.alt"
                            :width="item.image.width"
                            :height="item.image.height"
                        />
                    </ParallaxeVerticalElem>
                </div>

                <div class="exposition__bloc--video" :key="`video-${index}`" v-else-if="item.mediaType === 'video'">
                    <YoutubePlayer
                        class="exposition__bloc--video__elem"
                        :cover="item.video.cover"
                        :videoID="item.video.videoID"
                    />
                </div>

                <p class="exposition__bloc--text" :key="`text-${index}`" v-if="item.paragraph" v-html="item.paragraph">
                </p>
            </template>
        </section>

        <Footer/>
    </div>
  </div>
</template>

<script>
import API from '@/mixins/API.js'
import Page from '@/mixins/Page.js'
import SmoothScrollVerticalMixin from '@/mixins/SmoothScrollVerticalMixin.js'

import NavSculptures from '@/components/NavSculptures.vue'
import Footer from '@/components/Footer.vue'
import CanvasBackground from '@/components/CanvasBackground.vue'
import LazyImg from '@/components/LazyImg.vue'
import ParallaxeVerticalElem from '@/components/ParallaxeVerticalElem.vue'
import YoutubePlayer from '@/components/YoutubePlayer.vue'

export default {
    name: 'Exposition',

  mixins: [API, Page, SmoothScrollVerticalMixin],

    components: {
        CanvasBackground,
        LazyImg,
        Footer,
        NavSculptures,
        ParallaxeVerticalElem,
        YoutubePlayer
    },

  created()
  {
    this.fetchLang(`/sculptureexposition/${this.$route.params.id}`, () => {
        this.contentLoaded = true 

        this.onMounted()

        // we have 1500ms during transition
        window.setTimeout(() => {
            if(!this.$root.isMobile)
            {
                this.SmoothScrollVerticalMixin_init()
            }
        }, 100)
    })
  }
}
</script>

<style lang="stylus" scoped>
.exposition
    @media(max-width $breakpoints.md)
        overflow-x hidden
.exposition__hero
    position relative
    overflow hidden

    padding-top 330px
    padding-bottom 220px

    background $black

    @media(max-width $breakpoints.md)
        padding-top 420px
        padding-bottom 100px

.exposition__hero__quote
    padding-bottom 140px

    @media(max-width $breakpoints.md)
        padding-bottom 80px

.exposition__hero__quote__elem
    grid-col(3.5, 4.5)

    color $white

    @media(max-width $breakpoints.md)
        width 100%
        margin 0

.exposition__hero__description
    grid-col(5, 2.5)
    max-width $sculpture-paragraph-mwidth
    color $white

    opacity 0.3

    @media(max-width $breakpoints.md)
        width 100%
        margin 0

.exposition__wrapper
    position relative

    padding 150px 0px

    @media(max-width $breakpoints.md)
        padding 70px 10% 20px 15%

    &:after
        z-index 1
        content ''
        position absolute
        top 0
        left 50%
        
        width 1px
        height 100%

        background-color $grey

        @media(max-width $breakpoints.md)
            display none

.exposition__back
	cursor pointer
	position relative

	display inline-block
	margin-left calc((100% / 9) * 1)

	margin-bottom 100px

	font-size 22px
	line-height 1

	@media(max-width $breakpoints.md)
		margin-bottom 70px

	&:after
		content ''
		position absolute
		left 20px
		bottom -3px
		height 2px
		width calc(100% - 20px)
		background-color $black

		transform scaleX(0)
		transition transform .6s cubic-bezier(.23,1,.32,1)
		transform-origin top left

		will-change transform

	&:hover
		&:after
			transform scaleX(1)

.exposition__bloc--card
    display flex

    grid-col(0, 7)
    margin 0 auto 200px auto

    @media(max-width $breakpoints.md)
        flex-direction column

        width 100%
        margin 0
        margin-bottom 50px

.exposition__bloc--card__image, .exposition__bloc--card__infos
    width 50%

    @media(max-width $breakpoints.md)
        width 100%

.exposition__bloc--card__image
    padding-right 50px

    @media(max-width $breakpoints.md)
        padding-right 0

.exposition__bloc--card__image__elem
    display block

    width 100%

.exposition__bloc--card__infos

    padding-left 50px

    @media(max-width $breakpoints.md)
        padding-left 0

.exposition__bloc--card__infos__title
	margin-top 0px
	margin-bottom 40px

	font-size 20px
	line-height 1.3
	text-transform uppercase

.exposition__bloc--card__infos__description
    max-width 66%

    padding-bottom 70px

    @media(max-width $breakpoints.md)
        max-width 100%
        padding-bottom 100px

.exposition__bloc--image, .exposition__bloc--video
    position relative
    z-index 2

    grid-col(0, 7)
    margin 0 auto 200px auto

    @media(max-width $breakpoints.md)
        width 100%
        margin 0
        padding-bottom 50px

.exposition__bloc--image__elem, .exposition__bloc--video__elem
    display block

    width 100%

.exposition__bloc--text
	grid-col(5, 2.5)
	margin-bottom 200px

	line-height 1.3

	@media(max-width $breakpoints.md)
		width 100%
		margin 0
		padding-bottom 80px

</style>