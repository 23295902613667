<template>
    <div class="nav" :class="{'is-active': isActive, 'is-enabled': enabled}" v-if="navigation">
        <div class="nav__inner" @mouseover="onHoverNav" @mouseleave="onLeaveNav">
            <div class="nav__wrapper nav--white js-nav-wrapper">

                <div class="nav__links__wrapper">
                    <ul class="nav__links">
                        <router-link class="nav__link" :to="`/${$i18n.locale}${ item.url }`" v-for="(item, index) in navigation.header_chocolate_links" :key="index">{{ item.label }}</router-link>
                        <a class="nav__link" :href="navigation.header_chocolate_eshop_url" target="_blank">{{ navigation.header_chocolate_eshop_label }}</a>
                        <router-link :to="`/${$i18n.locale}${navigation.header_chocolate_home_link_path}`" class="nav__link nav__link--menu">{{ navigation.header_chocolate_home_link }}</router-link>
                    </ul>
                </div>

                <span class="nav__tear">
                    <div class="nav__tear__image-container" @click="handleClick">
                        <img class="nav__tear__image" src="../assets/images/logo-tear-1.png" alt="logo tear">
                    </div>
                    <span class="nav__tear__bar"></span>
                </span>
            </div>

            <div class="nav__wrapper js-nav-wrapper">
                <div class="nav__links__wrapper">
                    <ul class="nav__links">
                        <router-link class="nav__link" :to="`/${$i18n.locale}${ item.url }`" v-for="(item, index) in navigation.header_chocolate_links" :key="index">{{ item.label }}</router-link>
                        <a class="nav__link" :href="navigation.header_chocolate_eshop_url" target="_blank">{{ navigation.header_chocolate_eshop_label }}</a>
                        <router-link :to="`/${$i18n.locale}${navigation.header_chocolate_home_link_path}`" class="nav__link nav__link--menu">{{ navigation.header_chocolate_home_link }}</router-link>
                    </ul>
                </div>

                <router-link :to="`/${$i18n.locale}`" class="nav__tear">
                    <div class="nav__tear__image-container">
                        <img class="nav__tear__image" src="../assets/images/logo-tear-1-black.png" alt="logo tear">
                    </div>
                    <span class="nav__tear__bar"></span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
// import { gsap } from 'gsap'

import config from '@/config.js'

import EventHub from '@/utils/EventHub.js'
import GetAbsoluteBoundingRect from '@/utils/GetAbsoluteBoundingRect.js'

import NavigationMixin from '@/mixins/NavigationMixin.js'

export default {
    name: 'NavFixedChocolate',
    
	mixins: [NavigationMixin],

    data()
    {
        return {
            isActive: true,
            enabled: true
        }
    },

    created()
    {
        this.forceWhite = false
        this.blackSections = null

        this.addListeners()
    },

    mounted()
    {
        
    },

    destroyed()
    {
        this.removeListeners()
    },

    methods: {
        /**
         * 
         * Mixin functions
         */
        onLoadedNavComponent()
        {
            this.$nextTick(() => {
                this.init()
            })
        },


        /**
         * Init functions
         */

        addListeners() {
            // EventHub.$on('window:on-resize', this.onResize)
            EventHub.$on('nav-fixed-chocolate:force-resize', this.onResizeBounds)
            EventHub.$on('application:on-tick', this.onTick)
            EventHub.$on('application:on-route-change', this.onRouteChange)
            EventHub.$on('nav-fixed-chocolate:init', this.onInitNavFixed)
            EventHub.$on('nav-fixed-chocolate:on-toggle-enable', this.onToggleEnable)
        },

        removeListeners() {
            // EventHub.$off('window:on-resize', this.onResize)
            EventHub.$off('nav-fixed-chocolate:force-resize', this.onResizeBounds)
            EventHub.$off('application:on-tick', this.onTick)
            EventHub.$off('application:on-route-change', this.onRouteChange)
            EventHub.$off('nav-fixed-chocolate:init', this.onInitNavFixed)
            EventHub.$off('nav-fixed-chocolate:on-toggle-enable', this.onToggleEnable)

            const mouseWheelEvent = config.support.firefox ? 'DOMMouseScroll' : 'mousewheel'
            document.removeEventListener(mouseWheelEvent, this.onFirstScroll)
        },

        init()
        {
            this.$navWrappers = this.$el.querySelectorAll('.js-nav-wrapper')

            this.initBlackSections()

            this.isActive = true
            this.enabled = true

            const mouseWheelEvent = config.support.firefox ? 'DOMMouseScroll' : 'mousewheel'
            document.addEventListener(mouseWheelEvent, this.onFirstScroll)
        },


        /**
         *  Clip path functions
         */

        getWrapperBounds()
        {
            if(this.$navWrappers)
                this.navBounds = GetAbsoluteBoundingRect(this.$navWrappers[1])
        },

        updateWrapperClip()
        {
            if(!this.blackSections || !this.isActive || !this.$root.smoothScroll.position || this.forceWhite)
                return

            let clipRight =  0
            let clipLeft = 0

            for(let i = 0; i < this.blackSections.length; i++)
            {
                const section = this.blackSections[i]

                const navLeft = this.$root.smoothScroll.position.x + this.navBounds.left
                const navRight = this.$root.smoothScroll.position.x + this.navBounds.right

                if((navLeft >= section.left && navLeft <= section.right) && (navRight > section.right))
                {
                    clipLeft = this.navBounds.width - (navRight - section.right)
                    clipLeft = Math.round(clipLeft * 100) / 100
                }
                else if((navLeft >= section.left && navLeft <= section.right) && (navRight >= section.left && navRight <= section.right))
                {
                    clipRight = Math.round(this.navBounds.width * 100) / 100
                }
                else if((navRight >= section.left && navRight <= section.right) && (navLeft < section.left))
                {
                    clipRight = Math.round((navRight - section.left) * 100) / 100
                }
            }

            this.$navWrappers[1].style.clipPath = `inset(0px ${clipRight}px 0px ${clipLeft}px)`
        },

        setClipWhite()
        {
            this.$navWrappers[1].style.clipPath = `inset(0px ${Math.round(this.navBounds.width * 100) / 100}px 0px 0px)`
        },

        initBlackSections()
        {
            this.blackSections = []
            const $blackSections = document.querySelectorAll('.js-white-header-section')

            for(let i = 0; i < $blackSections.length; i++)
            {
                const obj = {}
                const section = $blackSections[i]

                obj.$el = section

                this.blackSections.push(obj)
            }
        },

        getBlackSectionsBounds()
        {
            this.blackSections.map((section) => {
                const bounds = GetAbsoluteBoundingRect(section.$el)
                section.left = Math.floor(bounds.left)
                section.right = Math.ceil(bounds.right)
            })
        },

        /**
         * Events functions
         */

        onTick()
        {
            this.updateWrapperClip()
        },

        onRouteChange(to)
        {
            if(to.meta.type === 'chocolate')
            {
                window.setTimeout(() => {
                    this.init()
                }, 200)
            }
        },

        onResizeBounds()
        {
            this.getWrapperBounds()
            this.getBlackSectionsBounds()
        },

        onInitNavFixed(forceWhite)
        {
            this.initBlackSections()

            if(forceWhite)
            {
                this.forceWhite = true
                this.getWrapperBounds()
                this.setClipWhite()
            }
            else
            {
                this.forceWhite = false
            }
        },

        onToggleEnable(enabled)
        {
            this.enabled = enabled
        },

        onFirstScroll()
        {
            this.isActive = false
            
            const mouseWheelEvent = config.support.firefox ? 'DOMMouseScroll' : 'mousewheel'
            document.removeEventListener(mouseWheelEvent, this.onFirstScroll)
        },

        onHoverNav()
        {
            if(!this.enabled)
                return

            this.isActive = true
            EventHub.$emit('nav-fixed-chocolate:on-toggle', this.isActive)
        },

        onLeaveNav()
        {
            this.isActive = false
            EventHub.$emit('nav-fixed-chocolate:on-toggle', this.isActive)
        },

        handleClick()
        {
            this.$router.push({ name: 'Home' })
        }
    }
}
</script>

<style lang="stylus" scoped>
.nav
    cursor pointer
    z-index 2
    position fixed
    top 50px
    right 100px

    width auto

    color $white
    font-weight 100
    letter-spacing 0.060em

    // opacity 0
    // will-change opacity

    pointer-events none

    &.is-enabled
        pointer-events auto

    @media(max-width $breakpoints.xl)
        right 50px

    @media(max-width $breakpoints.md)
        top 0

        height 50vh
        padding 0 10%
        

.nav__inner
    position relative
    display flex
    justify-content flex-end
    

    width 100%

    @media(max-width $breakpoints.md)
        flex-direction column

        height 100%

.nav__wrapper
    display flex
    align-items center

    padding 30px 0

    will-change clip-path

    @media(max-width $breakpoints.md)
        padding 30px 0px 15px 0px

    &.nav--white
        position absolute
        top 0
        left 0

.nav__links
    opacity 0

    transform translate3d(20px, 0, 0)

    transition transform 0.6s $ease-out-quart, opacity 0.4s $ease-out-quart

    will-change transform, opacity

    .is-active &
        opacity 1

        transform translate3d(0, 0, 0)

    @media(max-width $breakpoints.md)
        display flex
        flex-direction column

.nav__link
    display inline-block

    padding 0px 20px

    text-transform uppercase
    font-size 18px

    color #676767

    transition opacity 0.3s $ease-out-quart

    .nav--white &
        color #9b9b9b

    &:hover
        color #2c2c2c

        .nav--white &
            color #d0d0d0

    &.router-link-active
        color $black

        .nav--white &
            color $white

    @media(max-width $breakpoints.xl)
        font-size 17px

    @media(max-width $breakpoints.lg)
        font-size 16px

    @media(max-width $breakpoints.md)
        padding 0 0 15px 0

        &:last-child
            padding-bottom 0

.nav__link--menu
  position relative

  padding-left 80px

  @media(max-width $breakpoints.xl)
      padding-left 20px

  @media(max-width $breakpoints.md)
    padding 0

.nav__tear
    position relative
    overflow hidden

    width 50px
    margin-left 10px

    @media(max-width $breakpoints.lg)
        width 40px
        margin-left 0

    @media(max-width $breakpoints.lg)
        width 30px

.nav__tear__image
    display block
    width 100%

    transform translate3d(0, -100%, 0)

    transition transform 0.5s $ease-out-quart

    .is-active &
        transform translate3d(0, 0, 0)

.nav__tear__image-container
    overflow hidden
    position relative
    transform translate3d(0, 100%, 0)

    transition transform 0.5s $ease-out-quart

    .is-active &
        transform translate3d(0, 0, 0)

.nav__tear__bar
    position absolute
    top 50%
    left 0

    display block
    height 1px
    width 100%

    background $green

    transform translateY(-50%)
</style>