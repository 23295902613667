<template>
    <div class="youtube-player">
        <div class="youtube-player__ctn"></div>
        <div class="youtube-player__cover" :class="{'is-active': isCoverActive}" v-if="$root.isMobile" >
            <div class="youtube-player__cover__inner" :style="{'background-image': `url(${cover.url})`}" @click="onClickCover">
            </div>
        </div>
        <ParallaxeVerticalElem
            v-else
            class="youtube-player__cover"
            :class="{'is-active': isCoverActive}"
            :isInner="true"
            :amount="0.1"
        >
            <div class="youtube-player__cover__inner" :style="{'background-image': `url(${cover.url})`}" @click="onClickCover">
            </div>
        </ParallaxeVerticalElem>
        <div class="youtube-player__play" :class="{'is-active': isCoverActive}">
            <svg viewBox="0 0 126.2 126.2">
                <polygon points="54.2,63.1 54.2,50.1 65.4,56.6 76.7,63.1 65.4,69.6 54.2,76.1" class="st0"></polygon>
                <circle cx="63.1" cy="63.1" r="60.6" class="st1"></circle>
            </svg>
        </div>
        <div class="youtube-player__elem">
            <youtube 
                ref="youtube"
                :video-id="videoID"
                :fitParent="true"
                @playing="onPlaying">
            </youtube>
        </div>
    </div>
</template>

<script>
import ParallaxeVerticalElem from '@/components/ParallaxeVerticalElem.vue'

export default {
    name: 'YoutubePlayer',

    props: {
        cover: {
            type: Object
        },
        videoID: {
            type: String
        }
    },

    components: {
        ParallaxeVerticalElem
    },

    data()
    {
        return {
            isCoverActive: true
        }
    },

    computed: {
        player() {
          return this.$refs.youtube.player
        }
    },

    created()
    {

    },

    mounted()
    {

    },

    methods: {
        /**
         * 
         * Events
         */

        onClickCover()
        {
            this.isCoverActive = false

            this.playVideo()
        },

        /**
         * 
         * Youtube video
         */

        playVideo()
        {
            this.player.playVideo()
        }
    }
}
</script>

<style lang="stylus">
.youtube-player
    position relative
    display block
    width 100%
    height 100%

.youtube-player__elem
    z-index 1
    position absolute 
    top 0
    left 0
    height 100%
    width 100%

.youtube-player__cover
    cursor pointer
    z-index 2
    position absolute 
    top 0
    left 0
    height 100%
    width 100%

    &.is-active
        opacity 1
        visibility visible

    opacity 0
    visibility hidden
    will-change opacity

    transition opacity 1s, visibility 0s 1s

.youtube-player__cover__inner
    position relative
    width 100%
    height 100%
    background-size cover
	background-position 50% 50%

.youtube-player__play
    z-index 3
    pointer-events none
    position absolute
    left 50%
    top 50%
    width 100px
    height 100px
    margin-left -50px
    margin-top -50px

    &.is-active
        opacity 1
        visibility visible

    opacity 0
    visibility hidden
    will-change opacity

    transition opacity 1s, visibility 0s 1s

    .st0
        fill $white

    .st1
        fill none
        stroke $white
        stroke-width 5

    @media(max-width $breakpoints.md)
        width 50px
        height 50px
        margin-left -25px
        margin-top -25px

.youtube-player__ctn
    padding-top 56.25%
    background red
</style>