import { Vector3 } from 'three';

export default class Particle extends Vector3 {
    constructor(x, y, z, direction) {
        super(x, y, z);

        this._isCompleted = false;

        this._direction = direction.clone();
        
        this._life = 0;
        this._lifeSpeed = 0.005 + Math.random() * 0.007;

        this._fadeInTime = 0;
        this._fadeInLimit = 0.05 + Math.random() * 0.5;
        this._fadeInSpeed = 0.01;

        this._originStartPoint = new Vector3(x, y, z);

        this._startTime = performance.now();

        this._velocity = this._calcStartVelocity();
        this._acceleration = this._calcAcceleration();

        this._speed = 1;
    }

    /**
     * Getters & Setters
     */
    get life() {
        return this._life;
    }

    set speed(value) {
        this._speed = value;
    }

    /**
     * Public
     */
    update() {
        
        this._velocity.add(this._acceleration.clone().multiplyScalar(this._speed));

        this._fadeInTime += this._fadeInSpeed * this._speed;
        if (this._fadeInTime < this._fadeInLimit) {
            // this._life -= this._lifeSpeed;
            this._life = this._totalLife * (this._fadeInTime / this._fadeInLimit);
        } else {
            this._life -= this._lifeSpeed * this._speed * 2.4;
        }

        // if (this.y >= -0.01) {
            this.add(this._velocity.clone().multiplyScalar(this._speed));
        // }
    }

    reset(x, y, z, direction) {
        this.x = x;
        this.y = y;
        this.z = z;

        this._direction = direction.clone();
        // this._direction.multiplyScalar(0.012);
        this._direction.multiplyScalar(0.009);

        this._isCompleted = false;
        this._velocity = this._calcStartVelocity();
        this._totalLife = 0.5 + Math.random() * 0.3;
        this._fadeInTime = 0;
    }

    /**
     * Private
     */
    _calcStartVelocity() {
        const x = this._direction.x;
        const y = 0.007 + Math.random() * 0.002;
        // const y = 0.002 + Math.random() * 0.002;
        const z = this._direction.y;
        return new Vector3(x, y, z);
    }

    _calcAcceleration() {
        const x = 0;

        const sign = Math.random() > 0.5 ? -1 : 1;
        const y = (0.0001 * Math.random()) * sign;

        const z = 0;
        return new Vector3(x, y, z);
    }
}
