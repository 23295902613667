<template>
    <div class="parallaxe-elem" :class="{'is-inner': isInner}">
        <div class="parallaxe-elem__inner" ref="innerParallaxe">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import GetAbsoluteBoundingRect from '@/utils/GetAbsoluteBoundingRect.js'
import MathUtils from '@/utils/MathUtils'
import EventHub from '@/utils/EventHub'

export default {
    name: 'ParallaxeVerticalElem',

    props:
    {
        direction: {
            type: Number,
            default: -1
        },
        amount: {
            type: Number,
            default: 0.1
        },
        isInner: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            observer: null,
            intersected: false,
            isActive: true
        }
    },

    created()
    {
		//resize debounce
		this.timeout = false
		this.delay = 250

        this.canParallaxe = false
        this.isParallaxing = false
    
        this.addEventListeners()
    },

    mounted()
    {
        this.initParallaxe()
        this.addObserver()

        window.setTimeout(() => {
            this.canParallaxe = true
            this.setParallaxeValue(0)
            this.updateElemStyle()
            this.applyResize({width: window.innerWidth, height: window.innerHeight})
        }, 100)
    },

    destroyed()
    {
        this.removeEventListeners()
        this.removeActiveEventListeners()

        this.observer.disconnect()
    },

    methods: {
        /**
         * 
         * Listeners
         */

        addEventListeners()
        {
            EventHub.$on('window:on-resize', this.onResize)
            // EventHub.$on('page:transition-end', this.onTransitionEnd)
        },

        addActiveEventListeners()
        {
            EventHub.$on('application:on-tick', this.onTick)
        },

        removeEventListeners()
        {
            EventHub.$off('window:on-resize', this.onResize)
            // EventHub.$off('page:transition-end', this.onTransitionEnd)
        },

        removeActiveEventListeners()
        {
            EventHub.$off('application:on-tick', this.onTick)
        },

        /**
         * 
         * Observer
         */

        addObserver()
        {
            this.observer = new IntersectionObserver(entries => {
                this.handleObserver(entries)
            })

            this.observer.observe(this.$el)
        },

        handleObserver(entries)
        {
            const elem = entries[0]

            if (elem.isIntersecting) 
            {
                this.setParallaxeActive()
            }
            else
            {
                this.setParallaxeInactive()
            }
        },

        /**
         * 
         * Parallaxe functions
         */

        setParallaxeActive()
        {
            this.isParallaxing = true
            
            this.addActiveEventListeners()
        },

        setParallaxeInactive()
        {
            this.isParallaxing = false

            this.removeActiveEventListeners()
        },

        initParallaxe()
        {
            this.currentScroll = 0
            this.progress = 0

            this.parallaxe = {
                amplitude: 0,
                current: 0,
                target: 0
            }

            this.scale = this.isInner ? 1 + this.amount : 1

            this.limits = {}
		},
		
		getBounds()
		{
			this.initialBounds = GetAbsoluteBoundingRect(this.$el)
			
			if(this.$root.smoothScroll.position)
			{
				this.initialBounds.top += this.$root.smoothScroll.position.y
				this.initialBounds.bottom += this.$root.smoothScroll.position.y
			}
		},

        updateBounds()
        {
            this.limits.min = this.initialBounds.top - this.windowObj.height
            this.limits.max = this.initialBounds.bottom

            this.height = Math.round(this.initialBounds.height * 100) / 100

            this.parallaxe.amplitude = this.height * this.amount
        },

        calcProgress()
        {
            this.progress = MathUtils.clamp((this.currentScroll - this.limits.min) / (this.limits.max - this.limits.min), 0, 1)
        },

        updateParallaxe(progress)
        {
            this.parallaxe.target = (this.parallaxe.amplitude / 2) - ( (1 - progress) * this.parallaxe.amplitude)
            this.parallaxe.current = MathUtils.lerp(this.parallaxe.current, this.parallaxe.target, 0.3)
        },

        setParallaxeValue(progress)
        {
            this.parallaxe.target = (this.parallaxe.amplitude / 2) - ( (1 - progress) * this.parallaxe.amplitude)
            this.parallaxe.current = this.parallaxe.target
        },

        updateElemStyle()
        {
            const val = Math.round(this.direction * this.parallaxe.current * 100) / 100

            this.$refs.innerParallaxe.style.transform = `scale(${this.scale}) translate3d(0, ${val}px, 0)`

        },

        /**
         * 
         * Events
         */

        onResize(windowObj)
        {
            clearTimeout(this.timeout)

			this.timeout = setTimeout(this.applyResize(windowObj), this.delay)
		},
		
		applyResize(windowObj)
		{
			this.windowObj = windowObj

			this.getBounds()

			this.updateBounds()
		},

        onTick()
        {
            if(this.isParallaxing && this.canParallaxe)
            {
                if (this.$root.smoothScroll.position) {
                    this.currentScroll = this.$root.smoothScroll.position.y
                }

                this.calcProgress()
                this.updateParallaxe(this.progress)

                this.updateElemStyle()

                this.parallaxe.current = this.parallaxe.target
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.parallaxe-elem
    width 100%
    height 100%

    &.is-inner
        overflow hidden


.parallaxe-elem__inner
    overflow hidden

    width 100%
    height 100%

    transform-origin 50% 50%
    will-change transform
</style>