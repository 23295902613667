<template>
    <div class="alchemist js-white-header-section" :class="{'scroll-wrapper': !$root.isMobile}" v-if="contentLoaded">
        <div class="horizontal-wrapper js-white-header-section" :class="{'js-scroll-elem': !$root.isMobile}">

            <div class="alchemist__hero horizontal-block">
				<NavMobileChocolate v-if="$root.isMobile" :isWhite="true"/>
                <ChocolateHero
                    :title="content.cover_title"
                    :backgroundSrc="content.cover_image.url"
                />
            </div>

			<div class="horizontal-block" v-for="(item, index) in content.blocs" :key="index">
				<StoriesQuote
					v-if="item.type === 'quote'"
					:title="item.title_group.title"
					:subtitle="item.title_group.subtitle"
					:hasParallaxe="item.title_group.has_parallaxe"
					:parallaxeAmount="item.title_group.parallaxe_amount"
					:parallaxeDirection="item.title_group.parallaxe_direction"
					:isSpaceLeft="item.title_group.space_left"
					:isSpaceRight="item.title_group.space_right"
				/>
				<StoriesText
					v-else-if="item.type === 'text'"
					:text="item.text_group.text"
					:position="item.text_group.position"
					:isSpaceLeft="item.text_group.space_left"
					:isSpaceRight="item.text_group.space_right"
				/>
				<StoriesImage
					v-else-if="item.type === 'image'"
					:image="item.image_group.image"
					:hasParallaxe="item.title_group.has_parallaxe"
					:text="item.image_group.text"
					:textPosX="item.image_group.position_x"
					:textPosY="item.image_group.position_y"
				/>
			</div>
			
			<!-- The 7 T -->
			<div class="horizontal-block">
				<div class="seven-t">
					<div class="seven-t__inner">
						<h3 class="seven-t__title quote-chocolate is-white" v-html="content.t_title"></h3>
						<div class="seven-t__items">
							<div v-for="(item, index) in content.t_repeater" class="seven-t__item" :key="index">
								<p class="seven-t__item__number">{{ item.number }}</p>
								<div>
									<h4 class="seven-t__item__title  title-chocolate is-white" v-html="item.title"></h4>
									<p class="seven-t__item__description text-chocolate is-white" v-html="item.text"></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="horizontal-block" v-for="(item, index) in content.second_blocs" :key="`2-${index}`">
				<StoriesQuote
					v-if="item.type === 'quote'"
					:title="item.title_group.title"
					:subtitle="item.title_group.subtitle"
					:hasParallaxe="item.title_group.has_parallaxe"
					:parallaxeAmount="item.title_group.parallaxe_amount"
					:parallaxeDirection="item.title_group.parallaxe_direction"
					:isSpaceLeft="item.title_group.space_left"
					:isSpaceRight="item.title_group.space_right"
				/>
				<StoriesText
					v-else-if="item.type === 'text'"
					:text="item.text_group.text"
					:position="item.text_group.position"
					:isSpaceLeft="item.text_group.space_left"
					:isSpaceRight="item.text_group.space_right"
				/>
				<StoriesImage
					v-else-if="item.type === 'image'"
					:image="item.image_group.image"
					:hasParallaxe="item.title_group.has_parallaxe"
					:text="item.image_group.text"
					:whiteText="item.image_group.white_text"
					:textPosX="item.image_group.position_x"
					:textPosY="item.image_group.position_y"
				/>
			</div>

            <div class="alchemist__nav-story horizontal-block">
                <ChocolateStoryNav
                    :image="content.nav_image"
                    :title="content.nav_title"
                    :endpoint="endpoint"
                />
            </div>
            <CanvasBackground scene="lines" type="horizontal" amplitude="18" frequency="6" />
        </div>
    </div>
</template>

<script>
import EventHub from '@/utils/EventHub.js'

import store from '@/store/store.js'

import ChocolateHero from '@/components/ChocolateHero.vue'
import CanvasBackground from '@/components/CanvasBackground.vue'
import ChocolateStoryNav from '@/components/ChocolateStoryNav.vue'
import NavMobileChocolate from '@/components/NavMobileChocolate.vue'
// Stories
import StoriesQuote from '@/components/stories/StoriesQuote.vue'
import StoriesText from '@/components/stories/StoriesText.vue'
import StoriesImage from '@/components/stories/StoriesImage.vue'

import SmoothScrollHorizontalMixin from '@/mixins/SmoothScrollHorizontalMixin.js'
import API from '@/mixins/API.js'
import Page from '@/mixins/Page.js'
import MobileScroll from '@/mixins/MobileScroll.js'

export default {
    name: 'Alchemist',

    mixins: [SmoothScrollHorizontalMixin, API, Page, MobileScroll],

    components: {
		StoriesQuote,
		StoriesText,
		StoriesImage,
        ChocolateHero,
        ChocolateStoryNav,
		CanvasBackground,
		NavMobileChocolate
    },

    data()
    {
        return {
            isHorizontal: true,
            contentLoaded: false
        }
	},
	
	computed: {
		endpoint()
		{
			let endpoint = ''

			if(this.$i18n.locale === 'fr')
			{
				endpoint = 'jardin'
			}
			else
			{
				endpoint = 'garden'
			}

			return endpoint
		}
	},

    created()
    {
        if(!store.state.peasant)
        {
            store.getStories(this.$i18n.locale, this.onLoadedStories)
        }

        this.fetchLang('/pages/99', () => {
            this.contentLoaded = true

            this.onMounted()
            
            this.$nextTick(() => {

                EventHub.$emit('nav-fixed-chocolate:init', true)

                if(!this.$root.isMobile)
                {
                    this.SmoothScrollHorizontalMixin_init()
                }
                else
                {
                    this.MobileScroll_init()
                }
            })
        })
    }
}
</script>

<style lang="stylus" scoped>
.alchemist
    background-color $black
    color $white

	.horizontal-wrapper
		background-color $black

.alchemist__hero
	position relative

.seven-t
	padding-left 350px
	padding-right 350px
	height 100%
	display flex
	align-items center

	@media(max-width $breakpoints.xl)
		padding-left 300px
		padding-right 300px

	@media(max-width $breakpoints.md)
		padding-left 150px
		padding-right 150px

.seven-t__title
	padding-top 1rem

.seven-t__items
	display flex

.seven-t__inner
	display flex

.seven-t__item
	max-width 500px
	display flex
	margin-left -75px

	&:nth-child(odd)
		padding-top 350px

		@media(max-width $breakpoints.md)
			padding-top 250px

.seven-t__item__title
	margin-top 1.3rem
	margin-bottom 1.25rem
	opacity 0.8

.seven-t__item__number
	margin-right 40px
	font-size 11.11rem
	line-height 1
	color  $green

.seven-t__item__description
	max-width 300px

.alchemist__nav
    padding-left 320px
    padding-right 500px

    @media(max-width $breakpoints.md)
        padding-left 150px
        padding-right 150px

.alchemist__nav__item
    position relative

    margin-top 25vh
    height 50vh

    @media(max-width $breakpoints.md)
        height 50%

.alchemist__nav__item__img
    display block

    height 100%
    width auto

.alchemist__nav__item__title
    position absolute
    top 50%
    left 50%

    transform translate3d(-50%, -50%, 0)

.alchemist__nav-story
    position relative
    z-index 2
</style>