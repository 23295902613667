<template>
    <router-link :to="`/${$i18n.locale}/chocolate/shops/${shopID}`" class="shop-card">
        <div class="shop-card__image">
            <div class="shop-card__image__bg" :style="{'background-image': `url(${previewImage.url})`}" v-if="$root.isMobile"></div>
            <ParallaxeHorizontalElem
                v-else
                :isInner="true"
                :amount="0.1"
            >
                <div class="shop-card__image__bg" :style="{'background-image': `url(${previewImage.url})`}"></div>
            </ParallaxeHorizontalElem>
        </div>

        <div class="shop-card__content">
            <p class="shop-card__title subtitle-chocolate is-white" v-html="title" v-if="title"></p>
            <div class="shop-card__description text-chocolate is-white">
                <div>
                    <p class="shop-card__street" v-html="street" v-if="street"></p>
                    <p class="shop-card__city">
                        <span class="shop-card__zipcode" v-if="zipcode"> {{ zipcode }} </span>
                        <span v-if="city"> {{ city }} </span>
                    </p>
                    <p class="shop-card__phone" v-if="phone">{{ phone }}</p>
                </div>
                <div>
                    <p class="shop-card__infos" v-html="infos" v-if="infos">
                    </p>
                </div>
            </div>
            <ArrowBtn
                v-if="btnLabel"
                class="shop-card__btn"
                :href="`${endpoint}/${shopID}`"
                :isWhite="true"
                :text="btnLabel"
            />
        </div>

    </router-link>
</template>

<script>
import ArrowBtn from '@/components/ArrowBtn.vue'
import ParallaxeHorizontalElem from '@/components/ParallaxeHorizontalElem.vue'


export default {
    name: 'ChocolateShopsCard',

    props: {
        previewImage: {
            type: Object
        },
        title: {
            type: String
        },
        street: {
            type: String
        },
        zipcode: {
            type: String
        },
        city: {
            type: String
        },
        phone: {
            type: String
        },
        infos: {
            type: String
        },
        btnLabel: {
            type: String
        },
        shopID: {
            type: Number
        }
    },

    components: {
        ArrowBtn,
        ParallaxeHorizontalElem
	},
	
	computed:
	{
		endpoint()
		{
			let path = ''
			if(this.$i18n.locale === 'fr')
			{
				path = '/chocolat/boutiques'
			}
			else
			{
				path = '/chocolate/shops'
			}

			return path
		}
	},
}
</script>

<style lang="stylus" scoped>
.shop-card
    width 750px
    height 100vh

    display block

    color $white

    @media(max-width $breakpoints.md)
        height 100%
        width 100vw

.shop-card__image
    height 50vh

    @media(max-width $breakpoints.md)
        height 50%
        width 100%

.shop-card__image__bg
    width 100%
    height 100%

    background-position 50% 50%

    background-size cover

.shop-card__content
	position relative
	padding-top 9vh

	@media(max-height $breakpoints.lg)
		padding-top 5vh

	@media(max-width $breakpoints.md)
		height 50%
		padding-left 0px

.shop-card__title
	padding-bottom 2rem
	text-transform uppercase

	&.subtitle-chocolate.is-white
		opacity 1
	
	@media(max-height $breakpoints.lg)
		padding-bottom 1rem

.shop-card__infos
	padding-top 2rem
	padding-bottom 2rem

	@media(max-height $breakpoints.lg)
		padding-top 1.5rem
		padding-bottom 1.5rem

	@media(max-width $breakpoints.md)
		width 90%

.shop-card__zipcode
    display inline-block
    padding-right 0px



</style>