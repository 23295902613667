<template>
    <router-link :to="`/${$i18n.locale}/sculptures/sculptures/${id}`" class="sculpture-card">
        <div class="sculpture-card__infos">
            <p class="sculpture-card__infos__number">{{ number }}</p>
            <div class="sculpture-card__infos__text">
                <h3 class="sculpture-card__infos__title">{{ title }}</h3>
                <div class="sculpture-card__infos__description">
                    <p class="sculpture-card__infos__material" v-if="material" v-html="material"></p>
                    <p class="sculpture-card__infos__foundry" v-if="foundry" v-html="foundry"></p>
                    <p class="sculpture-card__infos__dimensions" v-if="dimensions" v-html="dimensions"></p>
                </div>
            </div>
        </div>
        <div class="sculpture-card__image">
            <LazyImg
                v-if="$root.isMobile"
                class="sculpture-card__image__elem"
                :url="image.url"
                :alt="image.alt"
                :width="image.width"
                :height="image.height"
            />
            <ParallaxeVerticalElem
                v-else
                :isInner="true"
                :amount="0.1"
            >
                <LazyImg
                    class="sculpture-card__image__elem"
                    :url="image.url"
                    :alt="image.alt"
                    :width="image.width"
                    :height="image.height"
                />
            </ParallaxeVerticalElem>
        </div>
    </router-link>
</template>

<script>
import ParallaxeVerticalElem from '@/components/ParallaxeVerticalElem.vue'
import LazyImg from '@/components/LazyImg.vue'

export default {
name: 'SculptureCard',

components: {
    ParallaxeVerticalElem,
    LazyImg
},

props: {
    id: {
        type: Number,
        required: true
	},
    number: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    dimensions: {
        type: String
    },
    foundry: {
        type: String
    },
    material: {
        type: String
    },
    image: {
        type: Object,
        required: true
    }
}
}
</script>

<style lang="stylus">
.sculpture-card
    display flex

    grid-col(0, 7)
    margin 0 auto

    @media(max-width $breakpoints.md)
        flex-direction column-reverse

        width 100%
        margin-left 0

.sculpture-card__infos, .sculpture-card__image
    width 50%

    @media(max-width $breakpoints.md)
        width 100%

.sculpture-card__infos
    display flex
    flex-direction column
    justify-content space-between

    @media(max-width $breakpoints.md)
        padding-left 0px

.sculpture-card__image
    padding-left 50px

    @media(max-width $breakpoints.md)
        padding-left 0

.sculpture-card__image__elem
    display block

    width 100%

.sculpture-card__infos__number
	font-size 180px
	text-transform uppercase
	font-family $font-numbers
	font-weight 400
	line-height 1

	.is-ru &
		font-family $font-numbers-ru	

	@media(max-width $breakpoints.xl)
		font-size 160px

	@media(max-width $breakpoints.lg)
		font-size 140px

	@media(max-width $breakpoints.md)
		margin-top 60px
		margin-bottom 60px

		font-size 90px

.sculpture-card__infos__title
    width 50%
    margin-bottom 40px

    font-size 36px

    @media(max-width $breakpoints.md)
        width 100%

        font-size 28px
        line-height 1.25

.sculpture-card__infos__description
    width 50%
    margin-bottom 50px

    @media(max-width $breakpoints.md)
        width 100%
        margin-bottom 0

.sculpture-card__infos__material, .sculpture-card__infos__foundry
	padding-bottom 10px

</style>