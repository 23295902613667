<template>
    <div class="background-rain">
        <canvas class="background-rain__canvas" ref="canvas"></canvas>
    </div>
</template>

<script>
import Rain from '@/webgl/intro/scenes/rain'
import EventHub from '@/utils/EventHub.js'

export default {
    name: 'BackgroundRain',

    props: [
        'width',
        'height',
        'wind',
        'amount',
        'speed'
    ],

    mounted()
    {
        this.setDimensions()
        this.create();
        this.createIntersectionObserver();
        this.setupEventListeners()
    },

    beforeDestroy()
    {
        this.removeEventListeners()
        if (this.intersectionObserver) this.intersectionObserver.disconnect()
        if (this.rain) this.rain.destroy();
    },

    methods: {
        setupEventListeners()
        {
            EventHub.$on('application:on-tick', this.tickHandler)
        },

        removeEventListeners()
        {
            EventHub.$off('application:on-tick', this.tickHandler)
        },

        setDimensions()
        {
            this.$refs.canvas.style.width = this.$props.width;
            this.$refs.canvas.style.height = this.$props.height;
        },
        
        create()
        {
            this.rain = new Rain({
                canvas: this.$refs.canvas,
                useCanvasDimensions: true,
                amount: this.$props.amount,
                wind: this.$props.wind,
                speed: this.$props.speed,
                autoUpdate: false
            });
        },

        createIntersectionObserver()
        {
            this.intersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    this.isVisible = entry.isIntersecting;
                    if (this.isVisible) {
                        this.rain.enable();
                    } else {
                        this.rain.disable();
                    }
                });
            });
            this.intersectionObserver.observe(this.$el);
        },

        update()
        {
            if (this.rain) this.rain.update();
        },

        /**
         * Handlers
         */
        tickHandler()
        {
            this.update()
        }
    }
}
</script>

<style lang="stylus">
.background-rain
    overflow hidden

.background-rain__canvas
    opacity 0.5
</style>