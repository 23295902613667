import GetAbsoluteBoundingRect from '@/utils/GetAbsoluteBoundingRect.js'
import EventHub from '@/utils/EventHub.js'

export default {
    name: 'MobileScroll',

    data()
    {
        return {

        }
    },

    created()
    {

    },

    mounted()
    {

    },

    destroyed()
    {
        this.MobileScroll_destroy()
    },

    methods: {
        /**
         * 
         * Init & Destroy
         * 
         */
    
        MobileScroll_init()
        {
            this.MobileScroll_addListeners()

            this.elems = {}

            this.elems.horizontalBlocks = document.querySelectorAll('.horizontal-block')
            this.elems.horizontalWrapper = document.querySelector('.horizontal-wrapper')

            // console.log('get horizontal blocks')
            
            window.setTimeout(() => {
                this.MobileScroll_onResize({
                    width: window.innerWidth,
                    height: window.innerHeight
                })
            }, 10)
        },
    
        MobileScroll_destroy()
        {
            this.MobileScroll_removeListeners()
        },
    
        /**
         * 
         * Add & remove listeners
         * 
         */
    
        MobileScroll_addListeners()
        {
            EventHub.$on('window:on-resize', this.MobileScroll_onResize)
            EventHub.$on('app:on-nav-ready', this.MobileScroll_init)
        },
    
        MobileScroll_removeListeners()
        {
            EventHub.$off('window:on-resize', this.MobileScroll_onResize)
            EventHub.$off('app:on-nav-ready', this.MobileScroll_init)
        },
    
        /**
         * 
         * Utilities functions
         * 
         */

        MobileScroll_setPageWidth(windowObj)
        {
            let pageWidth = 0

            this.elems.horizontalWrapper.style.width = `100000px`

            for(let i = 0; i < this.elems.horizontalBlocks.length; i++)
            {
                const elem = this.elems.horizontalBlocks[i]

                const bound = GetAbsoluteBoundingRect(elem)

                pageWidth += bound.width
            }

            this.elems.horizontalWrapper.style.width = `${pageWidth}px`
            this.elems.horizontalWrapper.style.height = `${windowObj.height}px`
        },
    
        /**
         * 
         * Events
         * 
         */
    
        MobileScroll_onResize(windowObj)
        {
            this.MobileScroll_setPageWidth(windowObj)    
        }
    }

}