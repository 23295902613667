// Three
import { Object3D, PlaneBufferGeometry, Group, TextureLoader, ShaderMaterial, Vector2, RepeatWrapping, Vector3, AdditiveBlending } from 'three';

// Utils
import Debugger from '@/webgl/utils/Debugger';
import WindowResizeObserver from '@/webgl/utils/WindowResizeObserver';

// Elements
import Drop from '@/webgl/background/scenes/RainScene/Drop';
import CenterLine from '@/webgl/background/scenes/RainScene/CenterLine';

// Shaders
import vertexShader from '@/webgl/background/shaders/rain/vertex.glsl';
import fragmentShader from '@/webgl/background/shaders/rain/fragment.glsl';

// Config
const AMOUNT = 50;
const TEXTURE_PATH = '/images/canvas-background/rain-texture.jpg';
const TEXUTRE_SIZE = 512;

export default class RainScene extends Object3D {
    constructor(domElement) {
        super();

        this.name = 'RainScene';

        this._domElement = domElement;
        
        this._width = null;
        this._height = null;

        this._scrollPosition = new Vector2();
        this._drops = [];
        this._speed = 1;

        this._elements = new Group();
        this.add(this._elements);

        this._loadTexture();
        this._createElements();
        this._createCenterLine();
        this._setupDebugGui();
        this._resize();
    }

    /**
     * Public
     */
    resize() {
        this._resize();
    }

    update() {
        //
    }

    updatePosition(scrollPosition) {
        if (!this._width || !this._height) return;
        this._positionElements(scrollPosition.y);
        this._centerLine.update(scrollPosition.y);
    }

    /**
     * Private
     */
    _loadTexture() {
        this._texture = new TextureLoader().load(TEXTURE_PATH);
        this._texture.wrapS = RepeatWrapping;
        this._texture.wrapT = RepeatWrapping;
    }

    _createElements() {
        const width = 1;
        const height = 1;

        const geometry = new PlaneBufferGeometry(width, height);
        const material = new ShaderMaterial({
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            transparent: true,
            blending: AdditiveBlending,
            uniforms: {
                uTexture: { value: null },
                uTextureSize: { value: TEXUTRE_SIZE },
                uTextureOffset: { value: new Vector2() },
                uSize: { value: new Vector2() },
                uAlpha: { value: null },
            }
        });

        let mesh;
        let x;
        let y;
        let z;
        for (let i = 0; i < AMOUNT; i++) {
            x = 0.2 + 0.6 * Math.random();
            y = Math.random();
            z = 0.3 + Math.random() * 0.7;
            mesh = new Drop({
                geometry: geometry,
                material: material.clone(),
                texture: this._texture,
                position: new Vector3(x, y, z),
                speed: this._speed
            });
            this._drops.push(mesh);
            this._elements.add(mesh);
        }
    }

    _positionElements(y) {
        let element;
        for (let i = 0, len = this._drops.length; i < len; i++) {
            element = this._drops[i];
            element.update(y);

            // Out of bounds check
            if (element.startPosition.y < element.scale.y / this._height * -0.5) {
                element.startPosition.x = 0.2 + 0.6 * Math.random();
                element.startPosition.y = 1 + element.scale.y / this._height;
            }
        }
    }

    _createCenterLine() {
        const width = 1;
        const height = 10;

        const geometry = new PlaneBufferGeometry(width, height);
        const material = new ShaderMaterial({
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            transparent: true,
            uniforms: {
                uTexture: { value: null },
                uTextureSize: { value: TEXUTRE_SIZE },
                uSize: { value: new Vector2() },
                uOffset: { value: new Vector2() },
                uAlpha: { value: null }
            }
        });
        this._centerLine = new CenterLine({
            geometry: geometry,
            material: material.clone(),
            texture: this._texture
        });
        this.add(this._centerLine);
    }

    /**
     * Resize
     */
    _resize() {
        this._width = Math.min(this._domElement.offsetWidth, 1500);
        this._height = this._domElement.offsetHeight;

        this._documentWidth = WindowResizeObserver.documentWidth;
        this._documentHeight = WindowResizeObserver.height;

        this._resizeElements();
        this._resizeCenterLine();
        this._positionCenterLine();
        this._positionElementsContainer();
    }

    _resizeElements() {
        for (let i = 0, len = this._drops.length; i < len; i++) {
            this._drops[i].resize(this._width, this._height);
        }
    }

    _resizeCenterLine() {
        this._centerLine.resize(this._height);
    }

    _positionCenterLine() {
        const offset = (this._domElement.offsetWidth * 0.5) % 1 === 0 ? 0.5 : 0;
        this._centerLine.startPosition.x = offset;
        this._centerLine.startPosition.y = this._height * -0.5 + this._documentHeight * 0.5;
    }

    _positionElementsContainer() {
        const offset = this._domElement.offsetWidth * 0.5 % 1 === 0 ? 0 : 0.5;
        this._elements.position.x = Math.round(this._width * -0.5) + offset;
        this._elements.position.y = -this._height + this._documentHeight * 0.5;
    }

    /**
     * Debug
     */
    _setupDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        const componentsFolder = Debugger.gui.getFolder('Scenes');
        this._debugGui = componentsFolder.addFolder(this.name);
        this._debugGui.add(this, '_speed', 0, 50, 0.01).name('speed').onChange(() => {
            for (let i = 0, len = this._drops.length; i < len; i++) {
                this._drops[i].speed = this._speed;
            }
        });
        this._debugGui.open();
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }
}
