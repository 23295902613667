import { Mesh, Vector2 } from 'three';

export default class CenterLine extends Mesh {
    constructor({ geometry, material, texture }) {
        super(geometry, material);

        // Properties
        this._texture = texture;

        // Offset
        this._offset = new Vector2(Math.random(), Math.random());

        // Alpha
        this._alpha = 0.3;

        // Scroll speed
        this._startPosition = new Vector2();

        this._updateUniforms();
    }

    /**
     * Getters & Setters
     */
    get startPosition() {
        return this._startPosition;
    }

    /**
     * Public
     */
    update(y) {
        this.position.x = this._startPosition.x;
        this.position.y = this._startPosition.y + y;
    }

    resize(height) {
        this.scale.y = height;
        this._updateUniforms();
    }

    /**
     * Private
     */
    _updateUniforms() {
        this.material.uniforms.uSize.value = new Vector2(this.scale.x, this.scale.y);
        this.material.uniforms.uOffset.value = new Vector2(this._offset.x, this._offset.y);
        this.material.uniforms.uTexture.value = this._texture;
        this.material.uniforms.uAlpha.value = this._alpha;
    }
}
