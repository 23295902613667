<template>
  <div class="sculpture" :class="{'scroll-wrapper': !$root.isMobile}" v-if="contentLoaded">

    <div class="scroll-elem" :class="{'js-scroll-elem': !$root.isMobile}">

      <NavSculptures :black="true" class="sculpture__nav"/>

      <div class="container">

        <section class="sculpture__infos">
          <div class="sculpture__infos__wrapper">
            <div class="sculpture__link__wrapper">
				<router-link v-if="!isBack" class="sculpture__infos__back" :to="`/${$i18n.locale}/sculptures/sculptures`">
					{{ `< Back` }}
				</router-link>
				<span v-else @click="goBack" class="sculpture__infos__back" >
					{{ `< Back` }}
				</span>
			</div>
            <!-- <div class="sculpture__infos__inner">
              <p class="sculpture__infos__number">{{ content.number }}</p>
              <div class="sculpture__infos__description">
                  <h3 class="sculpture__infos__title" v-html="content.name"></h3>
                  <p class="sculpture__infos__foundry" v-html="content.foundry"></p>
                  <p class="sculpture__infos__material" v-html="content.material"></p>
                  <p class="sculpture__infos__dimensions" v-html="content.dimensions"></p>
              </div>
            </div> -->
          </div>
        </section>

        <!-- <section class="sculpture__paragraphs" v-if="content.paragraphs.length > 0">
          <p class="sculpture__paragraph" v-for="(item, index) in content.paragraphs" :key="index" v-html="item.paragraph"></p>
        </section> -->
        <section class="sculpture__img" v-for="(item, index) in content.images" :key="index">
          <!-- <ParallaxeVerticalElem
              :isInner="true"
              :amount="0.1"
          > -->
		  	<div class="sculpture__cover" v-if="index === 0">
				<LazyImg
					class="sculpture__img__elem"
					:url="item.image.url"
					:alt="item.image.alt"
					:width="item.image.width"
					:height="item.image.height"
				/>
				<div class="sculpture__cover__infos">
					<p class="sculpture__cover__infos__number">{{ content.number }}</p>
					<div class="sculpture__cover__infos__description">
						<h3 class="sculpture__cover__infos__title" v-html="content.name"></h3>
						<p class="sculpture__cover__infos__foundry" v-html="content.foundry"></p>
						<p class="sculpture__cover__infos__material" v-html="content.material"></p>
						<p class="sculpture__cover__infos__dimensions" v-html="content.dimensions"></p>
					</div>
				</div>
			</div>
            <LazyImg
			  v-else
              class="sculpture__img__elem"
              :url="item.image.url"
              :alt="item.image.alt"
              :width="item.image.width"
              :height="item.image.height"
            />
          <!-- </ParallaxeVerticalElem> -->
		  <p class="sculpture__img__description" v-if="item.description" v-html="item.description"></p>
        </section>

		<div class="sculpture__paragraphs" v-if="(content.paragraphs_left && content.paragraphs_left.length > 0) || (content.paragraphs_right && content.paragraphs_right.length > 0)">
			<div class="sculpture__paragraph__left">
				<div class="sculpture__paragraph" v-for="(item, index) in content.paragraphs_left" :key="index">
					<p class="sculpture__paragraph__title" v-html="item.title"></p>
					<p class="sculpture__paragraph__text" v-html="item.paragraph"></p>
				</div>
			</div>
			<div class="sculpture__paragraph__right">
				<div class="sculpture__paragraph" v-for="(item, index) in content.paragraphs_right" :key="index">
					<p class="sculpture__paragraph__title" v-if="item.title" v-html="item.title"></p>
					<p class="sculpture__paragraph__text" v-if="item.paragraph" v-html="item.paragraph"></p>
				</div>
			</div>
		</div>

      </div>


      <Footer/>
    </div>
  </div>
</template>

<script>
import Page from '@/mixins/Page.js'
import API from '@/mixins/API.js'
import SmoothScrollVerticalMixin from '@/mixins/SmoothScrollVerticalMixin.js'

import NavSculptures from '@/components/NavSculptures.vue'
import Footer from '@/components/Footer.vue'
import LazyImg from '@/components/LazyImg.vue'

export default {
  name: 'Sculpture',

  mixins: [Page, API, SmoothScrollVerticalMixin],

  components: {
    LazyImg,
    Footer,
    NavSculptures,
    // ParallaxeVerticalElem
  },

  computed:
  {
	isBack()
	{
		return this.$store.state.router.previous && this.$store.state.router.previous.meta.type === 'sculptures'
	}
  },

  created()
  {
    this.fetchLang(`/sculptures/${this.$route.params.id}`, () => {
        this.contentLoaded = true 

        this.onMounted()

        // we have 1500ms during transition
        window.setTimeout(() => {
            if(!this.$root.isMobile)
            {
                this.SmoothScrollVerticalMixin_init()
            }
        }, 100)
    })
  },

  methods: 
  {
	goBack()
	{
		this.$router.back();
	}
  }
}
</script>

<style lang="stylus" scoped>
.sculpture
	@media(max-width $breakpoints.md)
		overflow-x hidden

	.container
		padding 0 100px

		@media(max-width $breakpoints.md)
			padding 0 10%

.sculpture__nav
  z-index 3

.sculpture__infos
  position relative

  width 100%

  &:after
    z-index 1
    content ''
    position absolute
    top 0
    left 50%
    
    width 1px
    height 100%

    background-color $grey

    @media(max-width $breakpoints.md)
      display none

.sculpture__infos__wrapper
	position relative
	z-index 2
	display flex
	justify-content space-between

	width 100%
	padding-bottom 150px
	padding-top 250px

	@media(max-width $breakpoints.xl)
		padding-top 220px
		padding-bottom 130px

	@media(max-width $breakpoints.lg)
		padding-top 190px
		padding-bottom 110px

	@media(max-width $breakpoints.md)
		flex-direction column

		padding-top 360px
		padding-bottom 60px

.sculpture__infos__back
	cursor pointer
	position relative

	display inline-block

	margin-top 20px

	font-size 22px
	line-height 1

	&:after
		content ''
		position absolute
		left 20px
		bottom -3px
		height 2px
		width calc(100% - 20px)
		background-color $black

		transform scaleX(0)
		transition transform .6s cubic-bezier(.23,1,.32,1)
		transform-origin top left

		will-change transform

	&:hover
		&:after
			transform scaleX(1)

.sculpture__img
	position relative

	z-index 2
	width 100%
	padding-bottom 200px

	&:after
		z-index 1
		content ''
		position absolute
		top 0
		left 50%

		width 1px
		height 100%

		background-color $grey

		@media(max-width $breakpoints.md)
			display none

	@media(max-width $breakpoints.xl)
		padding-bottom 170px

	@media(max-width $breakpoints.lg)
		padding-bottom 130px

	@media(max-width $breakpoints.md)
		padding-bottom 50px

	&:last-child
		@media(max-width $breakpoints.md)
			padding-bottom 100px

.sculpture__img__elem
	position relative
	z-index 2

	display block

	width 100%

.sculpture__cover
	position relative

.sculpture__cover__infos
	position absolute
	z-index 2
	top 0
	left 0
	width 100%
	height 100%

	padding 70px 60px

	display flex
	flex-direction column
	justify-content space-between

	@media(max-width $breakpoints.md)
		position relative
		padding 0

.sculpture__cover__infos__number
	font-size 180px
	text-transform uppercase
	font-family $font-numbers
	font-weight 400
	line-height 1

	.is-ru &
		font-family $font-numbers-ru

	@media(max-width $breakpoints.xl)
		font-size 160px

	@media(max-width $breakpoints.lg)
		font-size 140px

	@media(max-width $breakpoints.md)
		margin-top 60px
		margin-bottom 60px

		font-size 90px

.sculpture__cover__infos__title
	width 50%
	margin-bottom 40px

	font-size 36px

	@media(max-width $breakpoints.md)
		width 100%

		font-size 28px
		line-height 1.25

.sculpture__cover__infos__material, .sculpture__cover__infos__foundry
	padding-bottom 10px

.sculpture__img__description
	position relative
	z-index 2
	padding-top 40px
	padding-left 60px

	@media(max-width $breakpoints.md)
		padding-left 0
		padding-top 50px
		line-height 1.2

.sculpture__paragraphs
	position relative
	width 100%
	padding-bottom 350px
	display flex

	&:after
		z-index 1
		content ''
		position absolute
		top 0
		left 50%

		width 1px
		height 100%

		background-color $grey

		@media(max-width $breakpoints.md)
			display none

	@media(max-width $breakpoints.xl)
		padding-bottom 280px

	@media(max-width $breakpoints.lg)
		padding-bottom 200px

	@media(max-width $breakpoints.md)
		padding-bottom 50px
		flex-direction column

.sculpture__paragraph__left, .sculpture__paragraph__right
	padding-left 60px
	width 50%

	@media(max-width $breakpoints.md)
		padding-left 0
		width 100%

.sculpture__paragraph
	margin-bottom 60px

	&:last-child
		margin-bottom 0

	@media(max-width $breakpoints.md)
		&:last-child
			margin-bottom 60px

.sculpture__paragraph__title, .sculpture__paragraph__text
	width 75%

	@media(max-width $breakpoints.md)
		width 100%

.sculpture__paragraph__text
	line-height 1.33

.sculpture__paragraph__title
	margin-bottom 35px

	text-transform uppercase
	font-size 20px

</style>