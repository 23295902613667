import EventHub from '@/utils/EventHub.js'

export default {
    name: 'Page',

    data()
    {
        return {
            contentLoaded: false,
            metaTitle: 'Patrick Roger',
            metaDescription: 'Patrick roger, artisan chocolatier mais aussi sculpteur'
        }
    },

    metaInfo () {
        return {
            title: this.metaTitle,
            meta: [
                {name: 'description', content: this.metaDescription}
            ]
        }
    },

    created()
    {
        this.transition = {
            duration: 1500
        }
    },

    mounted()
    {
        
    },

    destroyed()
    {

    },

    beforeRouteLeave (to, from, next) {
        if (this.saveSmoothScrollPosition) this.saveSmoothScrollPosition();

        if(!to.query.transition)
        {
            EventHub.$emit('page:transition-in', from, to)
    
            window.setTimeout(() => {
                next()
            }, this.transition.duration)
        }
        else
        {
            next()
        }
    },

    methods: {
        onMounted()
        {
            // metas
            if(this.content && this.content.seo)
            {
                this.metaTitle = this.content.seo.title
                this.metaDescription = this.content.seo.description
            }

            // NOTE: Exception added to hide the Transition before the Intro
            if (this.$root.isNotFirstPageLoad) {
                EventHub.$emit('page:transition-out')
            } else if (this.$route.name !== 'Home') {
                EventHub.$emit('page:transition-out')
                this.$root.isNotFirstPageLoad = true
            }

            /**
             * Hack to restore scroll position
             * See ~/src/main.js
             */
            if (this.$root.isBackNavigation && this.$root.previouslySavedScrollPosition.native) {
                this.$root.$el.scroll({
                  top: this.$root.previouslySavedScrollPosition.native,
                  left: 0,
                });
            }

            if (this.$root.isBackNavigation && this.$root.previouslySavedScrollPosition.smooth) {
                this.setSavedSmoothScrollPosition(this.$root.previouslySavedScrollPosition.smooth);
            }
        },

        onTransitionEnd()
        {

        }
    }
}