import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import Pizzicato from 'pizzicato';

import EventDispatcher from '@/webgl/utils/EventDispatcher';

import config from './config.json';

const STATE_LOADING = 'loading';
const STATE_LOADED = 'loaded';

class Preloader extends EventDispatcher {
    constructor() {
        super();

        this._resources = [...config.resources];
    }

    /**
     * Public
     */
    start() {
        this._loadResources();
    }

    get(name) {
        const resource = this._getResourceByName(name);
        return resource.data;
    }

    load(name) {
        const resource = this._getResourceByName(name);
        return new Promise((resolve) => {
            switch (resource.state) {
                case STATE_LOADED:
                    resolve(resource.data);
                    break;
                case STATE_LOADING:
                    resource.promise.then((resource) => {
                        resolve(resource.data);
                    });
                    break;
                default:
                    this._loadResource(resource).then((resource) => {
                        resolve(resource.data);
                    });
            }
        });
    }

    /**
     * Private
     */
    _loadResources() {
        for (let i = 0, len = this._resources.length; i < len; i++) {
            this._loadResource(this._resources[i], true);
        }
    }

    _loadResource(resource, isQueue) {
        switch (resource.type) {
            case 'image': {
                resource.state = STATE_LOADING;
                resource.promise = this._loadImage(resource, isQueue);
                return resource.promise;
            }
            case 'gltf': {
                resource.state = STATE_LOADING;
                resource.promise = this._loadGltf(resource, isQueue);
                return resource.promise;
            }
            case 'sound': {
                resource.state = STATE_LOADING;
                resource.promise = this._loadSound(resource, isQueue);
                return resource.promise;
            }
        }
    }

    _checkResourcesStatus() {
        for (let i = 0, len = this._resources.length; i < len; i++) {
            if (this._resources[i].state === STATE_LOADING) {
                return;
            }
        }
        // console.log('dispatch complete', this._resources);
        this.dispatchEvent('complete');
    }
    
    _getResourceByName(name) {
        for (let i = 0, len = this._resources.length; i < len; i++) {
            if (this._resources[i].name === name) return this._resources[i];
        }
    }

    /**
     * Loaders
     */
    _loadImage(resource, isQueue) {
        return new Promise((resolve) => {
            const image = new Image();
            image.onload = () => {
                resource.state = STATE_LOADED;
                if (isQueue) this._checkResourcesStatus();
                resolve(resource);
            };
            image.src = resource.path;
            resource.data = image;
        });
    }

    _loadGltf(resource, isQueue) {
        return new Promise((resolve) => {
            const loader = new GLTFLoader();
            loader.load(resource.path, (gltf) => {
                resource.data = gltf;
                resource.state = STATE_LOADED;
                if (isQueue) this._checkResourcesStatus();
                resolve(resource);
            });
        })
    }

    _loadSound(resource, isQueue) {
        return new Promise((resolve) => {
            const sound = new Pizzicato.Sound(resource.path, () => {
                resource.data = sound;
                resource.state = STATE_LOADED;
                if (isQueue) this._checkResourcesStatus();
                resolve(resource);
            });
        })
    }
}

export default new Preloader();