<template>
    <div class="chocolate-cover">
        <router-link :to="`/${$i18n.locale}/${baseUrl}`" class="chocolate-cover__logo" v-if="!$root.isMobile">
            <img src="../assets/images/chocolate/logo-2.png" alt="Patrick roger new logo green" class="chocolate-cover__logo__img" ref="logo">
        </router-link>

        <div class="chocolate-cover__container">
            <div class="chocolate-cover__bg" :style="{'background-image': `url(${url})`}" ref="bg">
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap'

import EventHub from '@/utils/EventHub.js'

import baseUrl from '@/mixins/baseUrl'

export default {
    name: 'ChocolateCover',

    props: {
        url: {
            type: String
        }
	},
	
	mixins: [baseUrl],

    data()
    {
        return {

        }
    },

    created() {
        this.limit = 0
        this.baseScale = 1.1
        this.progressScale = 0
        this.canTick = false
    },

    mounted() {
        this.addEventListeners()

        if(!this.$root.isMobile)
        {
            this.animateEntryCover()
        }

        this.onResize({width: window.innerWidth})
    },

    destroyed() {
        this.removeEventListeners()
    },

    methods: {
        /**
         * 
         * Event listeners
         */
        addEventListeners()
        {
            EventHub.$on('application:on-tick', this.onTick)
            EventHub.$on('window:on-resize', this.onResize)
        },

        removeEventListeners()
        {
            EventHub.$off('application:on-tick', this.onTick)
            EventHub.$off('window:on-resize', this.onResize)
        },

        /**
         * 
         * Animations
         */

        animateEntryCover()
        {
            const onCompleteFn = () => {
                this.canTick = true
            }
            gsap.to(this.$refs.bg, {
                scale: 1.1,
                duration: 1.2,
                delay: 0.4,
                ease: 'Power3.easeOut',
                onComplete: onCompleteFn
            })
        },

        /**
         * 
         * Events
         */

        onResize(windowObj)
        {
            this.limit = windowObj.width * 1.1
        },

        onTick()
        {
            if(this.$root.isMobile)
                return

            if(this.$root.smoothScroll.position.x <= this.limit && this.canTick)
            {
                const progress = (this.limit - this.$root.smoothScroll.position.x) / this.limit
                const opacity = 1 - ((1 - progress) * 3)
                this.progressScale = 0.1 * (1 - progress)

                this.$refs.logo.style.opacity = `${opacity}`
                this.$refs.logo.style.transform = `translate3d(${this.$root.smoothScroll.position.x}px, 0, 0)`
                this.$refs.bg.style.transform = `translate3d(${this.$root.smoothScroll.position.x * 0.5}px, 0, 0) scale(${this.baseScale + this.progressScale})`
            }
        }
    }
}
</script>

<style lang="stylus">
.chocolate-cover
    position relative
    width 110vw
    height 100vh

    @media(max-width $breakpoints.md)
        height 100%
        width 100vw

.chocolate-cover__container
    position relative
    top 50vh

    height 50vh
    width 110vw

    @media(max-width $breakpoints.md)
        top 50%

        width 100vw
        height 50%

.chocolate-cover__container
    overflow hidden
    position relative
    width 100%
    height 100%

.chocolate-cover__bg
    position relative
    
    height 100%
    width 100%

    background $grey
    background-size cover
    background-position 50% 50%

    transform-origin 50% 50%
    will-change transform

.chocolate-cover__logo
    z-index 3
    position fixed
    top 80px
    left 85px
    width 128px

    opacity 1
    will-change opacity, transform

    @media(max-width $breakpoints.xl)
        left 50px
        width 110px

    @media(max-width $breakpoints.lg)
        width 100px

.chocolate-cover__logo__img
    display block
    width 100%
</style>