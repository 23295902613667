<template>
    <div class="shop-card">
        <a v-if="mapLink" :href="mapLink" target="_blank">
            <div class="shop-card__image">
                <div class="shop-card__image__bg" :style="{'background-image': `url(${previewImage.url})`}" v-if="$root.isMobile"></div>
                <ParallaxeHorizontalElem
                    v-else
                    :isInner="true"
                    :amount="0.1"
                >
                    <VerticalImg
                        :heightBase="50"
                        :src="previewImage.url"
                        :width="previewImage.width"
                        :height="previewImage.height"
                    />
                </ParallaxeHorizontalElem>
            </div>

            <div class="shop-card__content text-chocolate">
                <p class="shop-card__title" v-html="title" v-if="title"></p>
                <div class="shop-card__description">
					<div class="shop-card__description__column">
						<p class="shop-card__street" v-html="street" v-if="street"></p>
						<p class="shop-card__city">
							<span class="shop-card__zipcode" v-if="zipcode"> {{ zipcode }} </span>
							<span v-if="city"> {{ city }} </span>
						</p>
						<p class="shop-card__phone" v-if="phone">{{ phone }}</p>
						<p class="shop-card__infos" v-html="localisationInfos" v-if="localisationInfos"></p>
					</div>
                    <p class="shop-card__schedule shop-card__description__column" v-html="infos" v-if="infos">
                    </p>
                </div>
            </div>
        </a>

        <router-link v-else :to="`/${this.$i18n.locale}/chocolate/shops/${this.shopID}`">
            <div class="shop-card__image">
                <div class="shop-card__image__bg" :style="{'background-image': `url(${previewImage.url})`}" v-if="$root.isMobile"></div>
                <ParallaxeHorizontalElem
                    v-else
                    :isInner="true"
                    :amount="0.1"
                >
                    <VerticalImg
                        :heightBase="50"
                        :src="previewImage.url"
                        :width="previewImage.width"
                        :height="previewImage.height"
                    />
                </ParallaxeHorizontalElem>
            </div>

            <div class="shop-card__content text-chocolate">
                <p class="shop-card__title" v-html="title" v-if="title"></p>
                <div class="shop-card__description">
					<div class="shop-card__description__column">
						<p class="shop-card__street" v-html="street" v-if="street"></p>
						<p class="shop-card__city">
							<span class="shop-card__zipcode" v-if="zipcode"> {{ zipcode }} </span>
							<span v-if="city"> {{ city }} </span>
						</p>
						<p class="shop-card__phone" v-if="phone">{{ phone }}</p>
						<p class="shop-card__infos" v-html="localisationInfos" v-if="localisationInfos"></p>
					</div>
                    <p class="shop-card__schedule shop-card__description__column" v-html="infos" v-if="infos">
                    </p>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import VerticalImg from '@/components/VerticalImg.vue'
import ParallaxeHorizontalElem from '@/components/ParallaxeHorizontalElem.vue'

export default {
    name: 'ChocolateShopCard',

    props: {
        previewImage: {
            type: Object
        },
        title: {
            type: String
        },
        street: {
            type: String
        },
        zipcode: {
            type: String
        },
        city: {
            type: String
        },
        phone: {
            type: String
        },
        infos: {
            type: String
        },
        localisationInfos: {
            type: String
        },
        shopID: {
            type: Number
        },
        mapLink: {
            type: String,
            default: null
        }
    },

    components: {
        VerticalImg,
        ParallaxeHorizontalElem
    }
}
</script>

<style lang="stylus" scoped>
.shop-card
    color $black

    @media(max-width $breakpoints.md)
        height 100%
        width 100vw

.shop-card__image
    height 50vh

    @media(max-width $breakpoints.md)
        height 50%
        width 100%

.shop-card__image__bg
    width 100%
    height 100%

    background-size cover

.shop-card__content
    position relative
    padding-top 8vh

    @media(max-width $breakpoints.md)
        height 50%
        padding-top 5vh
        padding-left 0px

.shop-card__description
	display flex
	justify-content space-between

	@media(max-width $breakpoints.md)
		flex-direction column
	
.shop-card__title
    padding-bottom 30px
    text-transform uppercase

    @media(max-width $breakpoints.md)
        padding-bottom 3vh


.shop-card__infos
	padding-top 2rem

	@media(max-width $breakpoints.md)
		padding-bottom 2vh

.shop-card__zipcode
    display inline-block
    padding-right 0px

.shop-card__btn
    position absolute
    bottom 0
    right 0

    @media(max-width $breakpoints.md)
        left 0px
        right auto
        bottom 3vh

</style>