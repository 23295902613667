import EventDispatcher from './EventDispatcher';

const THROTTLE_TIME = 250;
class WindowResizeObserver extends EventDispatcher {
    constructor() {
        super();

        this._width = null;
        this._height = null;

        this._ghostElement = this._createGhostElement();

        this._bindHandlers();
        this._setupEventListeners();

        setTimeout(() => {
            this._updateDimensions();
            this._updateDocumentDimensions();
            this._dispatchResizeEvent();
        }, 16);
    }

    /**
     * Getters
     */
    get width() {
        return this._width;
    }

    get height() {
        return this._height;
    }

    get documentWidth() {
        return this._documentWidth;
    }

    get documentHeight() {
        return this._documentHeight;
    }

    /**
     * Public
     */
    update() {
        this._updateDimensions();
        this._updateDocumentDimensions();
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._windowResizeHandler = this._windowResizeHandler.bind(this);
        this._throttleTimeoutHandler = this._throttleTimeoutHandler.bind(this);
    }

    _setupEventListeners() {
        window.addEventListener(this._getResizeEvent(), this._windowResizeHandler);
    }

    _getResizeEvent() {
        return ('onorientationchange' in window) ? 'orientationchange' : 'resize';
    }

    _createGhostElement() {
        const element = document.createElement('div');
        element.style.width = '100vw';
        element.style.height = '100vh';
        element.style.position = 'absolute';
        element.style.top = 0;
        element.style.left = 0;
        element.style.pointerEvents = 'none';
        return element;
    }

    _updateDimensions() {
        document.body.appendChild(this._ghostElement);
        this._width = this._ghostElement.offsetWidth;
        this._height = this._ghostElement.offsetHeight;
        document.body.removeChild(this._ghostElement);
    }

    _updateDocumentDimensions() {
        const body = document.body;
        const html = document.documentElement;
        this._documentWidth = body.clientWidth;
        this._documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    }

    _dispatchResizeEvent() {
        this.dispatchEvent('resize');
    }

    _dispatchResizeCompleteEvent() {
        this.dispatchEvent('resize:complete');
    }

    _throttleResizeEvent() {
        if (this._timeoutThrottle) clearTimeout(this._timeoutThrottle);
        this._timeoutThrottle = setTimeout(this._throttleTimeoutHandler, THROTTLE_TIME);
    }

    /**
     * Handlers
     */
    _windowResizeHandler() {
        this._updateDimensions();
        this._updateDocumentDimensions();
        this._dispatchResizeEvent();
        this._throttleResizeEvent();
    }

    _throttleTimeoutHandler() {
        this._updateDimensions();
        this._updateDocumentDimensions();
        this._dispatchResizeCompleteEvent();
    }
}

export default new WindowResizeObserver();
