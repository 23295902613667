import Vue from 'vue'
import store from './store/index.js';
import VueMeta from 'vue-meta'
import VueYoutube from 'vue-youtube'
import { i18n } from './plugins/i18n.js'
import App from './App.vue'
import router from './router/index.js'

Vue.use(VueMeta)
Vue.use(VueYoutube)
Vue.config.productionTip = false

const vue = new Vue({
  router, 
  store,
  i18n,
  render: h => h(App),
  data() {
    return {
      isMobile: false
    }
  }
}).$mount('#app')


/**
 * Store navigation in $root
 */
vue.$root.savedScrollPosition = { native: null, smooth: null };
vue.$root.previouslySavedScrollPosition = { native: null, smooth: null };
vue.$root.isBackNavigation = false;

/**
 * Watch Pop state
 */
window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

/**
 * Guards
 */
router.beforeEach((to, from, next) => {
  vue.$root.previouslySavedScrollPosition.native = vue.$root.savedScrollPosition.native
  vue.$root.savedScrollPosition.native = vue.$el.scrollTop
  next()
});

router.afterEach(() => {
  vue.$root.isBackNavigation = window.popStateDetected
  window.popStateDetected = false
});
