// Vendor
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = () => ({
    router: {
        previous: null,
        current: null,
    }
});

const mutations = {
    /**
     * Router
     */
    SET_PREVIOUS_ROUTE(state, route) {
        state.router.previous = route;
    },

    SET_CURRENT_ROUTE(state, route) {
        state.router.previous = state.router.current;
        state.router.current = route;
    },
};

const actions = {
    /**
     * Router
     */
    setPreviousRoute({ commit }, previous) {
        commit('SET_PREVIOUS_ROUTE', previous);
    },

    setCurrentRoute({ commit }, current) {
        commit('SET_CURRENT_ROUTE', current);
    },
}

const getters = {
    /**
     * Router
     */
    previousRoute(state) {
        return state.router.previous;
    },

    currentRoute(state) {
        return state.router.current;
    },
};

const store = new Vuex.Store({ state, mutations, actions, getters });

export default store;