<template>
    <div class="cursor-home">
        <canvas class="cursor-home__circles" ref="circles" width="100" height="100"></canvas>
        <span class="cursor-home__label" ref="label">
            <span v-for="(letter, index) in label" v-bind:key="index">{{ letter }}</span>
        </span>
        <span class="cursor-home__dot" ref="dot"></span>
    </div>
</template>

<script>
import SimplexNoise from 'simplex-noise'
import gsap from 'gsap'
import EventHub from '@/utils/EventHub.js'
import Preloader from '@/webgl/intro/Preloader'

const STATE_ACTIVE = 'STATE_ACTIVE'
const STATE_IDLE = 'STATE_IDLE'

export default {
    name: 'CursorHome',

    computed: {
        label()
        {
            const label = this.$t('intro.misc.enter')
            return label.split('')
        },
    },

    created()
    {
        this.mousePosition = { x: 0, y: 0 }
        this.circlesPosition = { x: 0, y: 0 }
        this.labelPosition = { x: 0, y: 0 }
        this.dotPosition = { x: 0, y: 0 }
        this.isVisible = false
    },

    mounted()
    {
        this.context = this.$refs.circles.getContext('2d')
        this.width = this.$refs.circles.width
        this.height = this.$refs.circles.height
        this.center = { x: this.width * 0.5, y: this.height * 0.5 }
        this.simplex = new SimplexNoise()
        this.time = 0
        this.state = STATE_IDLE

        this.ringCanvas = document.createElement('canvas')
        this.ringCanvas.width = this.width
        this.ringCanvas.height = this.height
        this.ringContext = this.ringCanvas.getContext('2d')

        this.points = 150
        this.noiseScale = 0.015 // 0.04
        this.amplitude = 2
        this.ringAmount = 3
        this.circlesLerpAmount = 0.1
        this.dotLerpAmount = 0.5
        this.labelLerpAmount = 0.085
        this.dotSize = 2
        this.radius = 10
        this.alpha = 0.5
        this.isEnterLabelVisible = true

        this.rings = this.createRings()
        this.letters = this.createLetterPositions()
        
        this.hideNativeCursor()
        this.setupEventListeners()
    },

    beforeDestroy()
    {
        this.showNativeCursor()
        this.removeEventListeners()
    },

    methods: {
        /**
         * Public
         */
        showLabelEnter()
        {
            const letters = this.$refs.label.children
            this.tweenShowLabelEnter = gsap.to(letters, 0.4, { alpha: 1, stagger: 0.1, ease: 'sine.inOut' })
        },

        hideLabelEnter() {
            const letters = this.$refs.label.children
            if (this.tweenShowLabelEnter) this.tweenShowLabelEnter.kill();
            this.tweenHideLabelEnter = gsap.to(letters, 0.4, { alpha: 0, stagger: -0.1, ease: 'sine.inOut', onComplete: () => {
                this.isEnterLabelVisible = false;
            } })
        },
        
        /**
         * Private
         */
        setupEventListeners()
        {
            EventHub.$on('application:on-tick', this.tickHandler)
            window.addEventListener('mousemove', this.mouseMoveHandler)
            window.addEventListener('mousedown', this.mouseDownHandler)
            window.addEventListener('mouseup', this.mouseUpHandler)
            window.addEventListener('blur', this.windowBlurHandler)
            window.addEventListener('focus', this.windowFocusHandler)
            document.body.addEventListener('mouseleave', this.windowMouseLeaveHandler)
        },

        removeEventListeners()
        {
            EventHub.$off('application:on-tick', this.tickHandler)
            window.removeEventListener('mousemove', this.mouseMoveHandler)
            window.removeEventListener('mousedown', this.mouseDownHandler)
            window.removeEventListener('mouseup', this.mouseUpHandler)
            window.removeEventListener('blur', this.windowBlurHandler)
            window.removeEventListener('focus', this.windowFocusHandler)
            window.removeEventListener('mouseleave', this.windowMouseLeaveHandler)
        },

        createLetterPositions()
        {
            const letterElements = this.$refs.label.children
            const letters = [];
            for (let i = 0, len = letterElements.length; i < len; i++) {
                letters.push({
                    element: letterElements[i],
                    x: 0, y: 0
                });
            }
            return letters;
        },

        show()
        {
            if (this.isVisible) return
            this.isVisible = true

            this.updateCirclesPosition(true)
            this.updateDotPosition(true)
            this.updateLabelPosition(true)
            gsap.to(this.$el, 0.3, { alpha: 1, ease: 'sine.inOut' })
        },

        hide()
        {
            this.isVisible = false
            
            gsap.to(this.$el, 0.2, { alpha: 0, ease: 'sine.inOut' })
        },

        createRings()
        {
            const rings = []
            for (let i = 0; i < this.ringAmount; i++) {
                rings.push({
                    rotation: Math.PI * 2 * Math.random()
                })
            }
            return rings;
        },

        showNativeCursor()
        {
            document.body.classList.remove('hide-cursor')
        },

        hideNativeCursor()
        {
            document.body.classList.add('hide-cursor')
        },

        showActiveState()
        {
            this.state = STATE_ACTIVE
            this.amplitude = 2

            const backgroundSound = Preloader.get('background-sound')

            if (this.tweenIdleState) this.tweenIdleState.kill()
            this.tweenActiveState = new gsap.timeline()
            this.tweenActiveState.to(this, 1, { radius: 30, alpha: 1, ease: 'power4.out' }, 0)

            if (backgroundSound) {
                const backgroundEffect = backgroundSound.effects[0]
                if (backgroundEffect) {
                    this.tweenActiveState.to(backgroundEffect, 2, { frequency: 5000, ease: 'none' }, 0)
                }
            }
            
        },

        showIdleState()
        {
            this.state = STATE_IDLE

            const backgroundSound = Preloader.get('background-sound')

            if (this.tweenActiveState) this.tweenActiveState.kill()
            this.tweenIdleState = new gsap.timeline()
            this.tweenIdleState.to(this, 0.5, { radius: 10, amplitude: 2, alpha: 0.5, ease: 'power4.out' }, 0)

            if (backgroundSound) {
                const backgroundEffect = backgroundSound.effects[0]
                if (backgroundEffect) {
                    this.tweenIdleState.to(backgroundEffect, 1, { frequency: 300, ease: 'none' }, 0)
                }
            }
        },

        /**
         * Update & Draw
         */
        update()
        {
            this.time += 0.005
            this.updateCirclesPosition()
            this.updateDotPosition()
            this.updateLabelPosition()
            this.clear()
            this.draw()
        },
        
        updateCirclesPosition(force = false)
        {
            const x = this.mousePosition.x - this.width * 0.5
            const y = this.mousePosition.y - this.height * 0.5
            
            if (force) {
                this.circlesPosition.x = x
                this.circlesPosition.y = y
            } else {
                this.circlesPosition.x = this.lerp(this.circlesPosition.x, x, this.circlesLerpAmount)
                this.circlesPosition.y = this.lerp(this.circlesPosition.y, y, this.circlesLerpAmount)
            }
            
            this.$refs.circles.style.transform = `translate(${this.circlesPosition.x}px, ${this.circlesPosition.y}px)`
        },

        updateDotPosition(force = false)
        {
            const x = this.mousePosition.x - this.dotSize * 0.5
            const y = this.mousePosition.y - this.dotSize * 0.5

            if (force) {
                this.dotPosition.x = x
                this.dotPosition.y = y
            } else {
                this.dotPosition.x = this.lerp(this.dotPosition.x, x, this.dotLerpAmount)
                this.dotPosition.y = this.lerp(this.dotPosition.y, y, this.dotLerpAmount)
            }

            this.$refs.dot.style.transform = `translate(${this.dotPosition.x}px, ${this.dotPosition.y}px)`
        },

        updateLabelPosition(force = false)
        {
            const x = this.mousePosition.x - this.width * 0.5
            const y = this.mousePosition.y - this.height * 0.5
            
            let item;
            let itemX;
            for (let i = 0, len = this.letters.length; i < len; i++) {
                item = this.letters[i];

                itemX = this.lerp(item.x, x, 0.09 - i * 0.004);
                if (i !== 0 && itemX < this.letters[i-1].x) {
                    item.x = this.lerp(item.x, x, 0.09);
                } else {
                    item.x = itemX;
                }
                
                item.y = this.lerp(item.y, y, 0.09 - i * 0.004)
                item.element.style.transform = `translate(${item.x}px, ${item.y}px)`
            }
        },

        draw()
        {
            this.drawRing()
            this.drawRings()
        },

        clear()
        {
            this.ringContext.clearRect(0, 0, this.width, this.height)
            this.context.clearRect(0, 0, this.width, this.height)
        },

        drawRing()
        {
            let angle;
            let x;
            let y;
            let value2d;

            for (let i = 0; i < this.points; i++) {
                angle = i / this.points * Math.PI * 2
                x = this.center.x + this.radius * Math.cos(angle)
                y = this.center.y + this.radius * Math.sin(angle)
                value2d = this.simplex.noise2D(x * this.noiseScale + this.time, y * this.noiseScale + this.time) * this.amplitude
                x = this.center.x + (this.radius + value2d) * Math.cos(angle)
                y = this.center.y + (this.radius + value2d) * Math.sin(angle)

                this.ringContext.globalAlpha = (1 - this.clamp(value2d, 0, 0.7)) * this.alpha

                // this.ringContext.globalAlpha = 0.2
                this.ringContext.fillStyle = '#25aea0'
                this.ringContext.beginPath()
                this.ringContext.fillRect(x, y, 0.8, 0.8)
            }
        },

        drawRings()
        {
            this.context.save();
            this.context.translate(this.width * 0.5, this.height * 0.5)
            this.context.globalAlpha = 1
            this.context.globalCompositeOperation = 'lighter'

            for (let i = 0, len = this.rings.length; i < len; i++) {
                this.context.rotate(this.rings[i].rotation) //if (this.state === STATE_IDLE) 
                this.context.drawImage(this.ringCanvas, this.width * -0.5, this.height * -0.5)
            }

            this.context.globalAlpha = 1
            this.context.globalCompositeOperation = 'source-over'
            this.context.restore();
        },

        /**
         * Helpers
         */
        lerp(v0, v1, t)
        {
            return v0 * (1 - t) + v1 * t
        },

        clamp(value, min, max)
        {
            return Math.min(Math.max(value, min), max);
        },

        /**
         * Handlers
         */
        tickHandler()
        {
            this.update()
        },

        mouseMoveHandler(e)
        {
            this.mousePosition.x = e.clientX
            this.mousePosition.y = e.clientY

            this.show()
        },

        mouseDownHandler() 
        {
            this.showActiveState()

            if (this.isEnterLabelVisible) {
                clearTimeout(this.introTimeout);
                this.introTimeout = setTimeout(() => {
                    this.showIdleState()
                }, 80);
            }
        },

        mouseUpHandler()
        {
            clearTimeout(this.introTimeout);
            this.showIdleState()
        },

        windowBlurHandler()
        {
            this.hide()
        },

        windowFocusHandler()
        {
            this.show()
        },

        windowMouseLeaveHandler()
        {
            this.hide()
        }
    }
}
</script>

<style lang="stylus">
.cursor-home
    pointer-events none
    opacity 0

.cursor-home__circles
    position fixed
    top 0
    left 0

.cursor-home__dot
    display block

    position fixed
    top 0
    left 0

    width 2px
    height 2px

    border-radius 25%

    background #4cfffe
    opacity 0.8

.cursor-home__label
    position absolute
    top 45px
    left 74px

    font-family $font-titles
    font-weight 400
    font-size 11px
    text-transform uppercase
    letter-spacing 0.2em
    color $green

    .is-ru &
        font-family $font-titles-ru


.cursor-home__label span 
    opacity 0

    display inline-block

</style>