<template>
    <div class="lazy-img" :class="{'has-intersected': intersected}">
        <div class="lazy-img__ctn" :style="{paddingTop: `${ratio * 100}%`}"></div>
        <img class="lazy-img__elem" :src="srcImage" :alt="alt" :class="imageClass">
    </div>
</template>

<script>
import EventHub from '@/utils/EventHub.js'

export default {
  name: 'LazyImg',

  props: {
      alt: {
        type: String
      },
      url: {
        type: String
      },
      width: {
        type: Number
      },
      height: {
        type: Number
      }
  },

  computed: {
    imageClass() {
      return this.height > this.width ? 'is-vertical' : 'is-horizontal'
    },

    srcImage() {
      return this.intersected ? this.url : ''
    },

    ratio() {
      return Math.round(this.height / this.width * 10000) / 10000
    }
  },

  data() {
    return {
        observer: null,
        intersected: false,
        windowObj: {
          width: 0,
          height: 0
        }
    }
  },

  created()
  {

  },

  mounted()
  {
    this.addEventListeners()
    this.onResize({width: window.innerWidth, height: window.innerHeight})
  },

  destroyed()
  {
    this.removeEventListeners()
  },

  methods: {
    addEventListeners()
    {
      EventHub.$on('window:on-resize', this.onResize)

      this.observer = new IntersectionObserver(entries =>
      {

          const image = entries[0]

          if (image.isIntersecting)
          {
              const image = new Image()

              image.onload = () =>
              {
                this.intersected = true
              }

              image.src = this.url

              this.observer.disconnect()
          }
      })

      this.observer.observe(this.$el)
    },

    removeEventListeners()
    {
      EventHub.$off('window:on-resize', this.onResize)
      this.observer.disconnect()
    },

    onResize(windowObj)
    {
      this.windowObj.height = windowObj.height
      this.windowObj.width = windowObj.width
    }
  }
}
</script>

<style lang="stylus">
.lazy-img
  position relative
  opacity 0
  visibility hidden
  transition visibility 0s, opacity 0.4s $ease-out-quad

  will-change opacity, visibility

  &.has-intersected
    opacity 1
    visibility visible

.lazy-img__ctn
  width 100%
  display block

.lazy-img__elem
  position absolute
  top 50%
  left 50%
  transform translate3d(-50%, -50%, 0)

  &.is-vertical
    height 100%
    width auto

  &.is-horizontal
    width 100%
    height auto
</style>