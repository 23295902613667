<template>
<div class="peasant" :class="{'scroll-wrapper': !$root.isMobile}" v-if="contentLoaded">
    <BackgroundRipples class="peasant__background-ripples" ref="background-ripples" />
    <div class="horizontal-wrapper js-white-header-section" :class="{'js-scroll-elem': !$root.isMobile}">

        <div class="peasant__hero horizontal-block">
			<NavMobileChocolate v-if="$root.isMobile" :isWhite="true"/>
            <ChocolateHero
                :title="content.cover_title"
                :backgroundSrc="content.cover_image.url"
            />
        </div>

        <!-- <div class="horizontal-block">
            <section class="peasant-2">
                <div class="peasant-2__img">
                    <VerticalImg
                        v-if="$root.isMobile"
                        :heightBase="68.5"
                        :src="content.first_image.url"
                        :width="content.first_image.width"
                        :height="content.first_image.height"
                    />
                    <ParallaxeHorizontalElem
                        v-else
                        :isInner="true"
                        :amount="0.1"
                    >
                        <VerticalImg
                            :heightBase="68.5"
                            :src="content.first_image.url"
                            :width="content.first_image.width"
                            :height="content.first_image.height"
                        />
                    </ParallaxeHorizontalElem>
                </div>
                <div class="peasant-2__title" ref="title">
                    <h2 v-if="$root.isMobile" class="title--chocolate" v-html="content.first_title">
                    </h2>
                    <ParallaxeHorizontalElem
                        v-else
                        :amount="0.5"
                    >
                        <h2 class="title--chocolate" v-html="content.first_title">
                        </h2>
                    </ParallaxeHorizontalElem>
                </div>
                <p class="peasant-2__text text--chocolate" v-html="content.first_paragraph">
                </p>
            </section>
        </div>

        <div class="horizontal-block">
            <section class="peasant-3">
                <div class="peasant-3__img">
                    <VerticalImg
                        v-if="$root.isMobile"
                        :heightBase="50"
                        :src="content.second_image.url"
                        :width="content.second_image.width"
                        :height="content.second_image.height"
                    />
                    <ParallaxeHorizontalElem
                        v-else
                        :isInner="true"
                        :amount="0.1"
                    >
                        <VerticalImg
                            :heightBase="50"
                            :src="content.second_image.url"
                            :width="content.second_image.width"
                            :height="content.second_image.height"
                        />
                    </ParallaxeHorizontalElem>
                </div>
                <div class="peasant-3__title">
                    <h2 v-if="$root.isMobile" class="title--chocolate" v-html="content.second_title">
                    </h2>
                    <ParallaxeHorizontalElem
                        v-else
                        :amount="0.5"
                    >
                        <h2 class="title--chocolate" v-html="content.second_title">
                        </h2>
                    </ParallaxeHorizontalElem>
                </div>
                <p class="peasant-3__text text--chocolate" v-html="content.second_paragraph">
                </p>
            </section>
        </div>

        <div class="horizontal-block">
            <section class="peasant-4">
                <div class="peasant-4__img">
                    <VerticalImg
                        v-if="$root.isMobile"
                        :heightBase="100"
                        :src="content.third_image.url"
                        :width="content.third_image.width"
                        :height="content.third_image.height"
                    />
                    <ParallaxeHorizontalElem
                        v-else
                        :isInner="true"
                        :amount="0.1"
                    >
                        <VerticalImg
                            :heightBase="100"
                            :src="content.third_image.url"
                            :width="content.third_image.width"
                            :height="content.third_image.height"
                        />
                    </ParallaxeHorizontalElem>
                    <p class="peasant-4__text text--chocolate" v-html="content.third_paragraph">
                    </p>
                </div>
            </section>
        </div> -->
		<div class="horizontal-block" v-for="(item, index) in content.blocs" :key="index">
			<StoriesQuote
				v-if="item.type === 'quote'"
				:title="item.title_group.title"
				:subtitle="item.title_group.subtitle"
				:hasParallaxe="item.title_group.has_parallaxe"
				:parallaxeAmount="item.title_group.parallaxe_amount"
				:parallaxeDirection="item.title_group.parallaxe_direction"
				:isSpaceLeft="item.title_group.space_left"
				:isSpaceRight="item.title_group.space_right"
			/>
			<StoriesText
				v-else-if="item.type === 'text'"
				:text="item.text_group.text"
				:position="item.text_group.position"
				:isSpaceLeft="item.text_group.space_left"
				:isSpaceRight="item.text_group.space_right"
			/>
			<StoriesImage
				v-else-if="item.type === 'image'"
				:image="item.image_group.image"
				:hasParallaxe="item.title_group.has_parallaxe"
				:text="item.image_group.text"
				:textPosX="item.image_group.position_x"
				:textPosY="item.image_group.position_y"
			/>
		</div>

		<!-- The full text -->
		<div class="horizontal-block">
			<div class="peasant__fullquote">
				<p class="peasant__fullquote__text" v-html="content.full_text_bloc"></p>
			</div>
		</div>

		<div class="horizontal-block" v-for="(item, index) in content.second_blocs" :key="`2-${index}`">
			<StoriesQuote
				v-if="item.type === 'quote'"
				:title="item.title_group.title"
				:subtitle="item.title_group.subtitle"
				:hasParallaxe="item.title_group.has_parallaxe"
				:parallaxeAmount="item.title_group.parallaxe_amount"
				:parallaxeDirection="item.title_group.parallaxe_direction"
				:isSpaceLeft="item.title_group.space_left"
				:isSpaceRight="item.title_group.space_right"
			/>
			<StoriesText
				v-else-if="item.type === 'text'"
				:text="item.text_group.text"
				:position="item.text_group.position"
				:isSpaceLeft="item.text_group.space_left"
				:isSpaceRight="item.text_group.space_right"
			/>
			<StoriesImage
				v-else-if="item.type === 'image'"
				:image="item.image_group.image"
				:hasParallaxe="item.title_group.has_parallaxe"
				:text="item.image_group.text"
				:whiteText="item.image_group.white_text"
				:textPosX="item.image_group.position_x"
				:textPosY="item.image_group.position_y"
			/>
		</div>

        <div class="horizontal-block">
            <ChocolateStoryNav
                :image="content.nav_image"
                :title="content.nav_title"
                :endpoint="endpoint"
            />
        </div>
    </div>
</div>
</template>

<script>
import { gsap } from 'gsap'

import EventHub from '@/utils/EventHub.js'

import store from '@/store/store.js'

import ChocolateHero from '@/components/ChocolateHero.vue'
import BackgroundRipples from '@/components/BackgroundRipples.vue'
import ChocolateStoryNav from '@/components/ChocolateStoryNav.vue'
import NavMobileChocolate from '@/components/NavMobileChocolate.vue'
// Stories
import StoriesQuote from '@/components/stories/StoriesQuote.vue'
import StoriesText from '@/components/stories/StoriesText.vue'
import StoriesImage from '@/components/stories/StoriesImage.vue'

import SmoothScrollHorizontalMixin from '@/mixins/SmoothScrollHorizontalMixin.js'
import API from '@/mixins/API.js'
import Page from '@/mixins/Page.js'
import MobileScroll from '@/mixins/MobileScroll.js'

export default {
    name: 'Peasant',

    mixins: [SmoothScrollHorizontalMixin, API, Page, MobileScroll],

    components: {
		StoriesQuote,
		StoriesText,
		StoriesImage,
        ChocolateHero,
        ChocolateStoryNav,
		BackgroundRipples,
		NavMobileChocolate
	},

	computed: {
		endpoint()
		{
			let endpoint = ''

			if(this.$i18n.locale === 'fr')
			{
				endpoint = 'atelier'
			}
			else
			{
				endpoint = 'workshop'
			}

			return endpoint
		}
	},

    data()
    {
        return {
            isHorizontal: true,
            contentLoaded: false
        }
    },

    created()
    {
        if(!store.state.peasant)
        {
            store.getStories(this.$i18n.locale, this.onLoadedStories)
        }
        else
        {
            this.onLoadedStories()
        }
    },

    methods: {
        onLoadedStories()
        {
            this.contentLoaded = true
            this.content = store.state.peasant

            if(!this.$route.query.transition || this.$root.firstPage)
            {
                this.onMounted()
            }

            this.$nextTick(() => {
                EventHub.$emit('nav-fixed-chocolate:init', true)

                if(!this.$root.isMobile)
                {
                    this.SmoothScrollHorizontalMixin_init()
                }
                else
                {
                    this.MobileScroll_init()
                }
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
.peasant
	background-color $black
	color $white

	@media(max-width $breakpoints.md)
		overflow-y hidden
		position relative

		height 100%

.peasant__hero
	position relative

.peasant__fullquote
	padding-left 500px
	padding-right 500px
	height 100%

	display flex
	align-items center

	@media(max-width $breakpoints.xl)
		padding-left 380px
		padding-right 380px

	@media(max-width $breakpoints.md)
		padding-left 200px
		padding-right 200px

.peasant__fullquote__text
	max-width 900px
	font-size 3rem
	line-height 1.35
	text-transform uppercase
	color $white
	opacity 0.7
	font-family 'Overpass'
	font-weight 400

	.is-ru &
		font-family 'Oswald'

	@media(max-width $breakpoints.xl)
		max-width 550px
		font-size 2rem

	@media(max-width $breakpoints.md)
		max-width 350px
		font-size 1.6rem

.peasant__background-ripples
    position fixed
    top 0
    left 0
</style>