<template>
  <button class="button" @mouseenter="mouseEnterHandler" @mouseleave="mouseLeaveHander" @click="clickHandler">
    <div class="button__background" ref="background"></div>
    <div class="button__progress" ref="progress"></div>
    <svg width="50" height="50" class="button__ring">
      <circle cx="25" cy="25" r="24" vector-effect="non-scaling-stroke" ref="ring" />
    </svg>
    <div class="button__hover" ref="hover"></div>
  </button>
</template>

<script>
import gsap from 'gsap';

export default {
  methods: {
    fadeIn()
    {
      if (this._tweenFadeOut) this._tweenFadeOut.kill();
      this._tweenFadeIn = gsap.to(this.$el, 1, { alpha: 1, ease: 'sine.inOut' })
    },

    fadeOut()
    {
      if (this._tweenFadeIn) this._tweenFadeIn.kill();
      this._tweenFadeOut = gsap.to(this.$el, 0.7, { alpha: 0, ease: 'power2.out' })
    },
    
    /**
     * Public
     */
    show() {
      gsap.fromTo(this.$el, 1.4, { scale: 0 }, { scale: 1, ease: 'power4.out' });
    },

    hide() {
      gsap.to(this.$el, 1.4, { scale: 1, ease: 'power2.out' });
    },
    
    progress(value) {
      this.$refs.progress.style.transform = `scale(${value})`;
    },

    shrink() {
      this.timelineShrink = new gsap.timeline();
      this.timelineShrink.fromTo(this.$refs.ring, 3.3, { scale: 1 }, { scale: 4, transformOrigin: 'center center', ease: 'power3.out' }, 0);
      this.timelineShrink.fromTo(this.$refs.ring, 1.45, { alpha: 1 }, { alpha: 0, ease: 'sine.inOut' }, 0);
      this.timelineShrink.fromTo(this.$refs.progress, 0.2, { scale: 1 }, { scale: 0, ease: 'power2.in' }, 0);
    },

    /**
     * Handlers
     */
    mouseEnterHandler() {
      if (this.timelineMouseLeave) this.timelineMouseLeave.kill();
      this.timelineMouseEnter = new gsap.timeline();
      this.timelineMouseEnter.to(this.$refs.background, 0.6, { scale: 0.7, ease: 'power3.out' }, 0);
      this.timelineMouseEnter.to(this.$refs.hover, 0.6, { scale: 1, ease: 'power3.out' }, 0);
    },

    mouseLeaveHander() {
      if (this.timelineMouseEnter) this.timelineMouseEnter.kill();
      this.timelineMouseLeave = new gsap.timeline();
      this.timelineMouseLeave.to(this.$refs.background, 0.5, { scale: 1, ease: 'power2.out' }, 0);
      this.timelineMouseLeave.to(this.$refs.hover, 0.5, { scale: 0, ease: 'power2.out' }, 0);
    },

    clickHandler() {
      this.$emit('click');

      const timeline = new gsap.timeline()
      timeline.to(this.$refs.hover, 0.3, { backgroundColor: '#7ef3f2', ease: 'power4.out' }, 0);
      // timeline.to(this.$refs.ring, 0.3, { stroke: '#7ef3f2', ease: 'power4.out' }, 0);
      timeline.to(this.$refs.hover, 0.7, { backgroundColor: '#48cecc', ease: 'sine.inOut' }, 0.3);
      // timeline.to(this.$refs.ring, 0.7, { stroke: '#48cecc', ease: 'sine.inOut' }, 0.3);
    }
  }
}
</script>

<style lang="stylus">
  .button
    position absolute

    right 0
    bottom 50px
    left 0

    display block

    width 50px
    height 50px

    margin 0 auto

    outline none

    transform scale(0)

  .button__background
    position absolute
    top 0
    left 0

    width 100%
    height 100%

    border-radius 50%

    background rgba(#48cecc, 0.1)

  .button__progress 
    width 100%
    height 100%

    border-radius 50%

    background #48cecc

    transform scale(0)

  .button__ring
    position absolute
    top 0
    left 0

    overflow visible

    // width 100%
    // height 100%

    // border-radius 50%
    // border 1px solid 

    stroke #48cecc
    stroke-width 1.2px
    fill transparent

    pointer-events none

  .button__ring circle
    opacity 0

  .button__hover {
    position absolute
    top 0
    left 0

    width 100%
    height 100%

    border-radius 50%
    background #48cecc

    transform scale(0)
  }
</style>