<template>
    <div class="home-carousel">
        <div class="home-carousel__images">
          <router-link :to="`/${$i18n.locale}/sculptures/expositions/${item.ID}`" class="home-carousel__image js-carousel-image" v-for="(item, index) in items" :key="index" :class="{'is-active': index === currentIndex}" :style="{'background-image': `url(${item.acf.preview_image.url})`}"></router-link>
        </div>
        <div class="home-carousel__content container">
          <div class="home-carousel__inner">
            <div class="home-carousel__nav" v-if="items.length > 1">
              <span class="home-carousel__prev" @click="onClickPrev" :class="{'is-disabled': disablePrev}"> {{ prevLabel }} </span>
              <span class="home-carousel__nav__line"></span>
              <span class="home-carousel__next" @click="onClickNext" :class="{'is-disabled': disableNext}"> {{ nextLabel }} </span>
            </div>
            <div class="home-carousel__descriptions">
                <router-link :to="`/${$i18n.locale}/sculptures/expositions/${item.ID}`" class="home-carousel__description js-carousel-description" v-for="(item, index) in items" :key="index" :class="{'is-active': index === currentIndex}" v-html="item.acf.preview_title_description"></router-link>
            </div>
            <slot></slot>
          </div>
        </div>
    </div>
</template>

<script>
import Carousel from '@/mixins/Carousel.js'

export default {
    name: 'HomeCarousel',

    mixins: [Carousel],

    props: {

      items: {
        type: Array
      },
      prevLabel: {
          type: String
      },
      nextLabel: {
          type: String
      }

    },

    data() {
      return {

      }
    },

    mounted()
    {
      this.init()
    },

    methods: {

    }
}
</script>

<style lang="stylus">
.home-carousel
  position relative

  height 100vh

  @media(max-width $breakpoints.md)
    height auto

.home-carousel__images
    overflow hidden
    position relative
    width 50%
    height 100%
    left: 50%

    @media(max-width $breakpoints.md)
      width 80%
      margin-left 10%
      left: 0

.home-carousel__image
    position absolute 
    top 0
    left 0
    display block

    width 100%
    height 100%

    background-size cover
    background-image url('/images/sculptures/sculpture-05.jpg')

    opacity 0

    transform scale(1.05)

    will-change opacity, transform

    @media(max-width $breakpoints.md)
      height 50vh

    &.is-active
      position relative

      opacity 1

.home-carousel__content
  position absolute
  top 0
  left 0%
  transform translateX(-50%)
  width 100%
  height 100%

  display flex
  align-items center

  @media(max-width $breakpoints.md)
    position relative
    left 50%

.home-carousel__inner
  grid-col(5, 4)
  // margin-top 250px

  @media(max-width $breakpoints.md)
    width 100%
    margin 0

.home-carousel__nav
  cursor pointer
  display flex
  align-items center

  padding-left 10vw

  @media(max-width $breakpoints.md)
    margin-top 50px
    padding-left 0

.home-carousel__nav__line
  height 40px
  width 1px
  margin 0 30px

  background $black

.home-carousel__prev, .home-carousel__next
  user-select none

  position relative
  cursor pointer
  opacity 1

  &.is-disabled
    opacity 0.3

.home-carousel__descriptions
  position relative
  overflow hidden

  padding-left 10vw
  padding-right 5vw

  display flex
  align-items center
  height 70vh

  @media(max-width $breakpoints.xl)
    height 75vh

  @media(max-width $breakpoints.lg)
    height 80vh

  @media(max-width $breakpoints.md)
    height auto
    padding 0

.home-carousel__description
  position absolute
  top 0
  left 0
  padding-top 80px
  padding-bottom 80px
  
  font-size 32px
  line-height 1.25

  opacity 0

  will-change opacity, transform

  @media(max-width $breakpoints.xl)
    font-size 28px

  @media(max-width $breakpoints.lg)
    font-size 26px

  @media(max-width $breakpoints.md)
    padding-top 50px
    padding-bottom 50px
    margin-top 0px

    font-size 22px

  &.is-active
    position relative

    opacity 1

.home-carousel__link
  padding-left 10vw

  @media(max-width $breakpoints.md)
    padding-left 0
</style>