/* eslint-disable */

import { gsap } from 'gsap';
import { WebGLRenderer, Clock, PerspectiveCamera, Vector3, Scene, PlaneGeometry, MeshBasicMaterial, Mesh, CanvasTexture, ShaderMaterial, DoubleSide, AdditiveBlending, Group, MeshLambertMaterial, AmbientLight, HemisphereLight, HemisphereLightHelper, DirectionalLight, DirectionalLightHelper, MeshPhongMaterial, BackSide, Color, Vector2, PlaneBufferGeometry, Texture, Matrix4, Object3D } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import SimplexNoise from 'simplex-noise';

import Debugger from '@/webgl/utils/Debugger';
import Preloader from '../../Preloader';
import Ripples from './Ripples';
import Flowfield from './flowfield/Flowfield';
import Events from '../../Events';
import math from '@/webgl/utils/math';

import vertexShader from './shaders/foot.vert.glsl';
import fragmentShader from './shaders/foot.frag.glsl';

import data from './data.json';
import Particles from './Particles';

const ANGLES = [
    { 
        position: {
            x: -0.2,
            y: 0,
            z: -0.28
        },
        rotation: {
            x: 0.2,
            y: 0,
            y: -0.72
        },
    },
    { 
        position: {
            x: 0.22,
            y: 0,
            z: -0.23
        },
        rotation: {
            x: 0.2,
            y: 0.13
        },
    },
    { 
        position: {
            x: 0.42,
            y: 0.23,
            z: 0.12
        },
        rotation: {
            x: -0.1,
            y: 0.78
        },
    },
    { 
        position: {
            x: 0.01,
            y: 0.31,
            z: 1.53
        },
        rotation: {
            x: 0.21,
            y: -0.11
        },
    }
    // { x: 0.52, y: 0.64 },
    // { x: 0, y: 0 },
    // { x: 0.52, y: 0.64 }
];

// const ANGLES = [
//     0, // 3th
//     -0.7, // move screen to the left
//     -1.05, // first time, move to the left and zoom a bit more
//     // x: 0.52, y: 064 zoom out, second one
// ];

export default class Foot {
    constructor() {
        this._width = null;
        this._height = null;
        this._isEnabled = true;
        this._angleIndex = -1;

        this._debugGui = this._createDebugGui();
        this._canvas = this._createCanvas();
        this._clock = this._createClock();
        this._renderer = this._createRender();
        this._scene = this._createScene();
        this._cameraTarget = this._createCameraTarget();
        this._camera = this._createCamera();
        // this._controls = this._createControls();
        this._container = this._createContainer();

        this._elements = new Group();
        this._container.add(this._elements);

        this._flowfield = this._createFlowfield();
        this._texture = this._createTexture();
        // this._floor = this._createFloor();
        this._particles = this._createParticles();
        this._ripples = this._createRipples();
        this._footPrint = this._createFootPrint();
        this._feet = this._createFeet();

        this._simplex = new SimplexNoise();
        this._time = 0;

        this._shakeContainer = new Object3D();
        this._shakeContainer.add(this._camera);
        this._scene.add(this._shakeContainer);

        this._cameraShake = {
            strength: 0.12,
            rotationStrength: 0.076,
            speed: 0.085,
            totalStrength: 0
        }

        this._randomizeCameraAngle();
        
        this._timeline = this._createTimeline();
        this._timelineControl = this._createTimelineControl();

        this._bindHandlers();
        this._setupEventListeners();
        this._resize();

        // Debug
        // this._createFlowfieldDebugPlane();
        
        this._hemisphereLight = new HemisphereLight(0xffffff, 0xffffff, 0.7);
        this._hemisphereLight.position.set(0, 5, 0);
        this._scene.add(this._hemisphereLight);

        const hemiLightHelper = new HemisphereLightHelper(this._hemisphereLight, 1);
        // this._scene.add(hemiLightHelper);

        this._directionalLight = new DirectionalLight( 0xffffff, 1 );
        this._directionalLight.position.set(0, 1.75, 1);
        this._directionalLight.position.multiplyScalar(3);
        this._scene.add(this._directionalLight);

        // const dirLightHeper = new DirectionalLightHelper(this._directionalLight, 1);
        // this._scene.add(dirLightHeper);

        this._setupDebugGui();
    }

    destroy() {
        // this._removeFlowfieldDebugPlane();
        this._renderer.dispose();
        this._removeEventListeners();
        this._removeDebugGui();
        this._flowfield.destroy();
        this._timeline.kill();
    }

    /**
     * Public
     */
    getElement() {
        return this._canvas;
    }

    show() {
        this._timelineShow = new gsap.timeline();
        this._timelineShow.fromTo(this._canvas, 0.7, { alpha: 0 }, { alpha: 1, ease: 'sine.inOut'}, 0);
        
        this._timeline.play();
        this._timelineControl.play();
    }

    hide(callback) {
        this._timelineShow = new gsap.timeline({ onComplete: callback });
        this._timelineShow.to(this._canvas, 0.7, { alpha: 0, ease: 'sine.inOut'}, 0);
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._resizeHandler = this._resizeHandler.bind(this);
        this._mouseDownHandler = this._mouseDownHandler.bind(this);
        this._mouseUpHandler = this._mouseUpHandler.bind(this);
        this._tickHandler = this._tickHandler.bind(this);
        this._touchStartHandler = this._touchStartHandler.bind(this);
        this._touchEndHandler = this._touchEndHandler.bind(this);
    }

    _setupEventListeners() {
        window.addEventListener('resize', this._resizeHandler);
        this._canvas.addEventListener('mousedown', this._mouseDownHandler);
        this._canvas.addEventListener('mouseup', this._mouseUpHandler);

        // Touch
        this._canvas.addEventListener('touchstart', this._touchStartHandler);
        this._canvas.addEventListener('touchend', this._touchEndHandler);

        gsap.ticker.add(this._tickHandler);
    }

    _removeEventListeners() {
        window.removeEventListener('resize', this._resizeHandler);
        this._canvas.removeEventListener('mousedown', this._mouseDownHandler);
        this._canvas.removeEventListener('mouseup', this._mouseUpHandler);

        // Touch
        this._canvas.removeEventListener('touchstart', this._touchStartHandler);
        this._canvas.removeEventListener('touchend', this._touchEndHandler);

        gsap.ticker.remove(this._tickHandler);
    }

    _createCanvas() {
        return document.createElement('canvas');
    }

    _createClock() {
        return new Clock({ autoStart: true });
    }

    _createRender() {
        const renderer = new WebGLRenderer({
            canvas: this._canvas,
            antialias: true
        });
        renderer.setClearColor('#090909', 1);
        return renderer;
    }

    _createScene() {
        return new Scene();
    }

    _createCameraTarget() {
        return new Vector3();
    }

    _createCamera() {
        var camera = new PerspectiveCamera(50, 0, 0.01, 1000);
        camera.position.set(0, 0, 4.5);
        camera.lookAt(this._cameraTarget);
        return camera;
    }

    _createControls() {
        const orbitControls = new OrbitControls(this._camera, this._renderer.domElement);
        return orbitControls;
    }

    _createContainer() {
        const container = new Group();
        container.position.y = -0.8;
        this._scene.add(container);
        return container;
    }

    _createFlowfield() {
        return new Flowfield();
    }

    _createTexture() {
        const texture = new CanvasTexture(this._flowfield.getElement());
        texture.needsUpdate = true;
        texture.center = new Vector2(0.5, 0.5);
        return texture;
    }

    _createFloor() {
        const size = 100;
        const geometry = new PlaneGeometry(size, size);
        const material = new MeshBasicMaterial({
            color: 0x050505,
            // wireframe: true,
            transparent: true,
            // opacity: 0.1
        });
        const mesh = new Mesh(geometry, material);
        mesh.rotation.x = Math.PI * -0.5;
        this._scene.add(mesh); 
        return mesh;
    }

    _createParticles() {
        const particles = new Particles(20);
        this._elements.add(particles);
        return particles;
    }

    _createRipples() {
        const ripples = new Ripples(3);
        this._elements.add(ripples);
        return ripples;
    }

    _createFeet() {
        const feet = [];

        const gltf = Preloader.get('model-foot');
        const foot = gltf.scene.getObjectByName('Foot').clone();

        foot.scale.multiplyScalar(0.2);
        // foot.material = new ShaderMaterial({
        //     vertexShader,
        //     fragmentShader,
        //     transparent: true,
        //     // wireframe: true,
        //     side: DoubleSide,
        //     blending: AdditiveBlending,
        //     depthTest: false,
        //     uniforms: {
        //         uTexture: { value: null },
        //         uRotation: { value: null },
        //         uAlpha: { value: 1 }
        //     }
        // });

        foot.material = new MeshPhongMaterial({
            color: 0xffffff,
            // opacity: 0.5,
            transparent: true,
            specular: 0x163d3d,
            emissive: 0x70707
        });

        let item;
        for (let i = 0, len = data.feet.length; i < len; i++) {
            item = data.feet[i];

            const rotation = Math.PI * 2 * Math.random();

            const mesh = foot.clone();
            mesh.material = foot.material.clone();
            mesh.renderOrder = 1;
            mesh.material.onBeforeCompile = (shader) => {

                shader.uniforms.uRotation = { value: rotation };

                const functions = `
                    uniform float uRotation;

                    vec2 rotateUV(vec2 uv, float rotation) {
                        float mid = 0.5;
                        return vec2(
                            cos(rotation) * (uv.x - mid) + sin(rotation) * (uv.y - mid) + mid,
                            cos(rotation) * (uv.y - mid) - sin(rotation) * (uv.x - mid) + mid
                        );
                    }

                    float blendSoftLight(float base, float blend) {
                        return (blend<0.5)?(2.0*base*blend+base*base*(1.0-2.0*blend)):(sqrt(base)*(2.0*blend-1.0)+2.0*base*(1.0-blend));
                    }
                    
                    vec3 blendSoftLight(vec3 base, vec3 blend) {
                        return vec3(blendSoftLight(base.r,blend.r),blendSoftLight(base.g,blend.g),blendSoftLight(base.b,blend.b));
                    }
                    
                    vec3 blendSoftLight(vec3 base, vec3 blend, float opacity) {
                        return (blendSoftLight(base, blend) * opacity + base * (1.0 - opacity));
                    }
                `;

                shader.fragmentShader = functions + shader.fragmentShader;
    
                shader.fragmentShader = shader.fragmentShader.replace(
                    '#include <map_fragment>',
                    `
                    #ifdef USE_MAP

                        vec2 uv = rotateUV(vUv, uRotation);
                        vec4 texelColor = texture2D( map, uv );
                        texelColor = mapTexelToLinear( texelColor );

                        texelColor.rgb = blendSoftLight(texelColor.rgb, texelColor.rgb);
                        texelColor.rgb = blendSoftLight(texelColor.rgb, texelColor.rgb);

                        diffuseColor *= texelColor;
                    
                    #endif
                    `
                );

            };
            mesh.material.map = this._texture;
            mesh.position.x = item.current.position.x;
            mesh.position.y = item.current.position.y;
            mesh.position.z = item.current.position.z;
            mesh.rotation.x = Math.PI * item.current.rotation.x;
            mesh.rotation.y = Math.PI * item.current.rotation.y;
            mesh.rotation.z = Math.PI * item.current.rotation.z;
            this._elements.add(mesh);

            // Flip
            const meshFlipped = foot.clone();
            meshFlipped.material = foot.material.clone();
            meshFlipped.material.onBeforeCompile = (shader) => {
                
                shader.uniforms.uRotation = { value: rotation };

                const functions = `
                    uniform float uRotation;

                    vec2 rotateUV(vec2 uv, float rotation) {
                        float mid = 0.5;
                        return vec2(
                            cos(rotation) * (uv.x - mid) + sin(rotation) * (uv.y - mid) + mid,
                            cos(rotation) * (uv.y - mid) - sin(rotation) * (uv.x - mid) + mid
                        );
                    }

                    float blendSoftLight(float base, float blend) {
                        return (blend<0.5)?(2.0*base*blend+base*base*(1.0-2.0*blend)):(sqrt(base)*(2.0*blend-1.0)+2.0*base*(1.0-blend));
                    }
                    
                    vec3 blendSoftLight(vec3 base, vec3 blend) {
                        return vec3(blendSoftLight(base.r,blend.r),blendSoftLight(base.g,blend.g),blendSoftLight(base.b,blend.b));
                    }
                    
                    vec3 blendSoftLight(vec3 base, vec3 blend, float opacity) {
                        return (blendSoftLight(base, blend) * opacity + base * (1.0 - opacity));
                    }
                `;

                shader.fragmentShader = functions + shader.fragmentShader;
    
                shader.fragmentShader = shader.fragmentShader.replace(
                    '#include <map_fragment>',
                    `
                    #ifdef USE_MAP

                        vec2 uv = rotateUV(vUv, uRotation);
                        vec4 texelColor = texture2D( map, uv );
                        texelColor = mapTexelToLinear( texelColor );

                        texelColor.rgb = blendSoftLight(texelColor.rgb, texelColor.rgb);
                        texelColor.rgb = blendSoftLight(texelColor.rgb, texelColor.rgb);

                        diffuseColor *= texelColor;
                    
                    #endif
                    `
                );

            };
            meshFlipped.material.map = this._texture;
            meshFlipped.material.opacity = 0.2;
            // meshFlipped.material.uniforms.uTexture.value = this._texture;
            meshFlipped.position.x = item.current.position.x;
            meshFlipped.position.y = -item.current.position.y;
            meshFlipped.position.z = item.current.position.z;
            meshFlipped.rotation.x = Math.PI * item.current.rotation.x;
            meshFlipped.rotation.y = Math.PI * item.current.rotation.y;
            meshFlipped.rotation.z = Math.PI * item.current.rotation.z;
            meshFlipped.scale.y = -0.2;
            this._elements.add(meshFlipped);

            feet.push({
                mesh,
                meshFlipped,
                data: item
            });
            
        }

        this._foot = this._elements.children[6];

        return feet;
    }

    _createFootPrint() {
        const image = Preloader.get('footprint');
        const aspect = image.width / image.height;
        const texture = new Texture(image);
        texture.needsUpdate = true;
        const size = 1.65;
        const width = size * aspect;
        const height = size;
        const geometry = new PlaneBufferGeometry(width, height);
        const material = new MeshBasicMaterial({
            map: texture,
            transparent: true,
            opacity: 0,
            depthWrite: false
        });
        const mesh = new Mesh(geometry, material);

        const matrix = new Matrix4();
        mesh.rotation.x = Math.PI * -0.5;
        mesh.rotation.z = Math.PI * -0.5;
        mesh.position.x = 0.13;
        mesh.position.z = -0.05;
        mesh.position.y = 0;
        this._elements.add(mesh);
        return mesh;
    }

    _createTimeline() {
        let item;
        let position;

        let delay = 0.5;
        const timeline = new gsap.timeline({
            // repeat: -1,
            repeatDelay: 0,
            paused: true,
            // onComplete: this._onCompleteHandler,
            callbackScope: this
        });
        
        // Feet
        for (let i = 0, len = this._feet.length; i < len; i++) {
            item = this._feet[i];
            position = delay + i * 0.3;
            timeline.to(item.mesh.position, 1.5, { x: item.data.target.position.x, y: item.data.target.position.y, ease: 'none' }, position - 0.2);
            timeline.fromTo(item.mesh.material, 0.6, { opacity: 0 }, { opacity: 1, ease: 'sine.inOut' }, position);
            timeline.to(item.mesh.material, 1, { opacity: 0, ease: 'sine.out' }, position + 0.6);
            timeline.fromTo(item.meshFlipped.material, 0.2, { opacity: 0 }, { opacity: 0.15, ease: 'sine.inOut' }, position);
            timeline.to(item.meshFlipped.material, 1, { opacity: 0, ease: 'sine.inOut' }, position + 0.6);
        }

        // Footprint
        timeline.to(this._footPrint.material, 0.18, { opacity: 1, ease: 'power4.in' }, delay + 0.6 - 0.05);
        timeline.to(this._footPrint.material, 0.25, { opacity: 0.15, ease: 'power4.Out' }, delay + 0.78 - 0.05);
        timeline.to(this._footPrint.material, 1.3, { opacity: 0, ease: 'sine.inOut' }, delay + 1.03 - 0.05);

        // Particles
        timeline.call(this._particles.play, null, delay + 0.65);

        this._onCompleteHandler = this._onCompleteHandler.bind(this);
        this._shakeCamera = this._shakeCamera.bind(this);

        timeline.call(this._shakeCamera, null, delay + 0.87);

        // Camera
        // const params = { 
        //     rotation: 0
        // };
        // timeline.fromTo(params, 3, { rotation: 0 }, { rotation: 1, ease: 'none', onUpdate: () => {
        //     const index = this._angleIndex % ANGLES.length;
        //     const offset = Math.PI * 0.05;
        //     const y = ANGLES[index].rotation.y;
        //     const start = y + offset;
        //     const end = y - offset;
        //     this._elements.rotation.y = math.lerp(start, end, params.rotation);
        // }}, 0);

        delay += 0.015;

        timeline.fromTo(this._container.position, 0.85, { z: 0 }, { z: 0.7, ease: 'none' }, delay + -0.2);
        timeline.to(this._container.position, 0.7, { z: -0.4, ease: 'expo.out' }, delay + 0.7);
        // timeline.to(this._camera.rotation, 0.3, { z: Math.PI * 0.02, ease: 'expo.inOut' }, delay + 0.55);
        // timeline.to(this._camera.rotation, 2.5, { z: 0, ease: 'power1.inOut' }, delay + 0.85);
        timeline.to(this._camera.position, 0.2, { y: 0.13, ease: 'expo.inOut' }, delay + 0.6);
        timeline.to(this._camera.position, 2.5, { y: 0, ease: 'power1.inOut' }, delay + 0.9);
        // timeline.fromTo(this._container.rotation, 3, { y: -0.1 }, { y: 0.1, ease: 'none' }, 0);
        timeline.call(this._onCompleteHandler, null, 3.5);
        
        // Ripples
        timeline.add(this._ripples.show(), delay + 0.6);
        
        timeline.timeScale(2.6);

        return timeline;
    }

    _createTimelineControl() {
        const slowDownSpeed = 0.9;
        const slowDownStart = 1;
        const speedUpStart = 1.4;

        const timeline = new gsap.timeline({ paused: true });

        // In
        timeline.to(this._timeline, 0.3, { timeScale: slowDownSpeed, ease: 'power4.out' }, slowDownStart);
        timeline.to(this._particles, 0.3, { speed: slowDownSpeed, ease: 'power4.out' }, slowDownStart);
        timeline.to(this._ripples, 0.3, { speed: slowDownSpeed, ease: 'power4.out' }, slowDownStart);

        // Out
        timeline.to(this._timeline, 1.3, { timeScale: 1, ease: 'power2.in' }, speedUpStart);
        timeline.to(this._particles, 1.3, { speed: 1, ease: 'power2.in' }, speedUpStart);
        timeline.to(this._ripples, 1.3, { speed: 1, ease: 'power2.in' }, speedUpStart);

        // timeline.timeScale(100);

        return timeline;
    }

    _randomizeCameraAngle() {
        this._angleIndex++;
        const index = this._angleIndex % ANGLES.length;
        const angle = ANGLES[index];
        this._elements.position.x = angle.position.x;
        this._elements.position.y = angle.position.y;
        this._elements.position.z = angle.position.z;
        this._elements.rotation.x = angle.rotation.x;
        this._elements.rotation.y = angle.rotation.y;
    }

    _slowDown() {
        const speed = 0.2;
        if (this._timelineSpeedUp) this._timelineSpeedUp.kill();
        this._timelineSlowDown = new gsap.timeline();
        // this._timelineSlowDown.to(this._flowfield, 0.2, { _trail: 0.008, ease: 'power3.out' }, 0)
        this._timelineSlowDown.to(this._flowfield, 0.2, { _speed: 0.08, ease: 'power3.out' }, 0)
        // this._timelineSlowDown.to(this._particles, 0.3, { speed: speed, ease: 'power4.out' }, 0);
        // this._timelineSlowDown.to(this._ripples, 0.3, { speed: speed, ease: 'power4.out' }, 0);
        // this._timelineSlowDown.to(this._timeline, 0.3, { timeScale: speed, ease: 'power4.out' }, 0);
        this._timelineSlowDown.to(this._scene.position, 0.5, { z: 0.2, ease: 'power3.out' }, 0);
    }

    _speedUp() {
        const speed = 1;
        if (this._timelineSlowDown) this._timelineSlowDown.kill();
        this._timelineSpeedUp = new gsap.timeline();
        // this._timelineSpeedUp.to(this._flowfield, 0.5, { _trail: 0.01, ease: 'power3.out' }, 0)
        this._timelineSpeedUp.to(this._flowfield, 0.5, { _speed: 0.72, ease: 'power3.out' }, 0)
        // this._timelineSpeedUp.to(this._particles, 0.2, { speed: speed, ease: 'power4.out' }, 0);
        // this._timelineSpeedUp.to(this._ripples, 0.2, { speed: speed, ease: 'power4.out' }, 0);
        // this._timelineSpeedUp.to(this._timeline, 0.2, { timeScale: speed, ease: 'power4.out' }, 0);
        this._timelineSpeedUp.to(this._scene.position, 1, { z: 0, ease: 'power2.out' }, 0);
    }

    _shakeCamera() {
        const timeline = new gsap.timeline();
        timeline.to(this._cameraShake, 0.01, { totalStrength: 1 }, 0);
        timeline.to(this._cameraShake, 0.4, { totalStrength: 0 }, 0.1);
    }
    
    _update() {
        if (!this._isEnabled) return;
        
        this._time += 1;

        this._updateTexture();
        this._updateParticles();
        this._updateRipples();
        this._updateShake();
        this._render();
    }

    _updateTexture() {
        if (this._texture) this._texture.needsUpdate = true;
    }

    _updateShake() {
        const time = this._time * this._cameraShake.speed;
        const x = this._simplex.noise2D(time, time) * this._cameraShake.strength;
        const y = this._simplex.noise2D(time, -time) * this._cameraShake.strength;
        const z = this._simplex.noise2D(-time, -time) * this._cameraShake.strength * 0.5;
        const rotation = this._simplex.noise2D(-time, time) * this._cameraShake.rotationStrength;

        this._shakeContainer.position.x = x * this._cameraShake.totalStrength;
        this._shakeContainer.position.y = y * this._cameraShake.totalStrength;
        this._shakeContainer.position.z = z * this._cameraShake.totalStrength;
        this._shakeContainer.rotation.z = rotation * this._cameraShake.totalStrength;
    }

    _updateParticles() {
        this._particles.update();
    }

    _updateRipples() {
        this._ripples.update(this._clock.getDelta());
    }

    _render() {
        this._renderer.render(this._scene, this._camera);
    }

    /**
     * Resize
     */
    _resize() {
        this._width = window.innerWidth;
        this._height = window.innerHeight;
        // this._dpr = device.dpr();
        this._dpr = 1;

        this._resizeCanvas();
        this._resizeRenderer();
        this._resizeCamera();
    }

    _resizeCanvas() {
        this._canvas.width = this._width * this._dpr;
        this._canvas.height = this._height * this._dpr;
        this._canvas.style.width = `${this._width}px`;
        this._canvas.style.height = `${this._height}px`;
    }

    _resizeRenderer() {
        this._renderer.setPixelRatio(this._dpr);
        this._renderer.setSize(this._width, this._height, false);
    }

    _resizeCamera() {
        this._camera.aspect = this._width / this._height;
        this._camera.updateProjectionMatrix();
    }

    /**
     * Debug
     */
    _createFlowfieldDebugPlane() {
        const canvas = this._flowfield.getElement();
        canvas.style.position = 'fixed';
        canvas.style.top = '0';
        canvas.style.left = '0';
        canvas.style.width = '300px';
        canvas.style.width = '300px';
        document.body.appendChild(canvas);
    }

    _removeFlowfieldDebugPlane() {
        const canvas = this._flowfield.getElement();
        canvas.parentElement.removeChild(canvas);
    }

    _createDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        const debugGui = Debugger.gui.addFolder('Foot');
        debugGui.open();

        return debugGui;
    }

    _setupDebugGui() {
        if (!this._debugGui) return;

        const footerMateiralParams = {
            color: this._foot.material.color.getHex(),
            emissive: this._foot.material.emissive.getHex(),
            specular: this._foot.material.specular.getHex()
        }
        const folderFeetMaterial = this._debugGui.addFolder('Feet material');
        folderFeetMaterial.add(this._foot.material, 'shininess', 0, 1, 0.01);
        folderFeetMaterial.addColor(footerMateiralParams, 'color').onChange(() => {
            this._foot.material.color = new Color(footerMateiralParams.color);
        });
        folderFeetMaterial.addColor(footerMateiralParams, 'emissive').onChange(() => {
            this._foot.material.emissive = new Color(footerMateiralParams.emissive);
        });
        folderFeetMaterial.addColor(footerMateiralParams, 'specular').onChange(() => {
            this._foot.material.specular = new Color(footerMateiralParams.specular);
        });

        const directionalLightParams = {
            color: this._directionalLight.color.getHex()
        }
        const folderDirectionalLight = this._debugGui.addFolder('Directional light');
        folderDirectionalLight.addColor(directionalLightParams, 'color').onChange(() => {
            this._directionalLight.color = new Color(directionalLightParams.color);
        });
        folderDirectionalLight.add(this._directionalLight, 'intensity', 0, 1, 0.1);

        const hemisphereLightParams = {
            skyColor: this._hemisphereLight.color.getHex(),
            groundColor: this._hemisphereLight.groundColor.getHex()
        }
        const folderHemisphereLight = this._debugGui.addFolder('Hemisphere light');
        folderHemisphereLight.addColor(hemisphereLightParams, 'skyColor').onChange(() => {
            this._hemisphereLight.color = new Color(hemisphereLightParams.skyColor);
        });
        folderHemisphereLight.addColor(hemisphereLightParams, 'groundColor').onChange(() => {
            this._hemisphereLight.groundColor = new Color(hemisphereLightParams.groundColor);
        });
        folderHemisphereLight.add(this._hemisphereLight, 'intensity', 0, 1, 0.1);

        const cameraShake = this._debugGui.addFolder('Camera shake');
        cameraShake.add(this._cameraShake, 'strength', 0, 1, 0.001)
        cameraShake.add(this._cameraShake, 'rotationStrength', 0, 1, 0.001)
        cameraShake.add(this._cameraShake, 'speed', 0, 1, 0.001)
        cameraShake.add(this._cameraShake, 'totalStrength', 0, 1, 0.001)

        this._debugGui.add(this._ripples, 'show').name('showRipples');
        this._debugGui.add(this._particles, 'play').name('playParticles');
        this._debugGui.add(this, '_shakeCamera').name('shakeCamera');

        this._debugGui.add(this._elements.position, 'x', -Math.PI, Math.PI, 0.01).name('Camera position X');
        this._debugGui.add(this._elements.position, 'y', -Math.PI, Math.PI, 0.01).name('Camera position Y');
        this._debugGui.add(this._elements.position, 'z', -Math.PI, Math.PI, 0.01).name('Camera position Z');
        this._debugGui.add(this._elements.rotation, 'x', -Math.PI, Math.PI, 0.01).name('Camera angle X');
        this._debugGui.add(this._elements.rotation, 'y', -Math.PI, Math.PI, 0.01).name('Camera angle Y');
    }

    _removeDebugGui() {
        if (this._debugGui) Debugger.gui.removeFolder(this._debugGui);
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }

    _mouseDownHandler() {
        this._isMouseDown = true;
        Events.dispatchEvent('mouse:down', 4);
        this._slowDown();
    }

    _mouseUpHandler() {
        this._isMouseDown = false;
        Events.dispatchEvent('mouse:up');
        this._speedUp();
    }

    _tickHandler() {
        this._update();
    }

    _onCompleteHandler() {
        this._timeline.play(0);
        this._timelineControl.play(0);
        this._randomizeCameraAngle();
    }

    // Touch
    _touchStartHandler() {
        this._isMouseDown = true;
        Events.dispatchEvent('mouse:down', 4);
        this._slowDown();
    }

    _touchEndHandler() {
        this._isMouseDown = false;
        Events.dispatchEvent('mouse:up');
        this._speedUp();
    }
}
