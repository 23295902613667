<template>
<div class="arrow-btn" :class="{'is-white': isWhite}">
    <router-link :to="`/${$i18n.locale}${href}`" class="arrow-btn__inner">
        <span class="arrow-btn__icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.66" height="14.66" viewBox="0 0 14.66 14.66">
            <path class="arrow-btn__icon__path" d="M3441,4716h10v2h-10v-2Zm10-10h2v12h-2v-12Zm-12.66-1.24,1.42-1.42,11.9,11.9-1.42,1.42Z" transform="translate(-3438.34 -4703.34)"/>
            </svg>
        </span>
        <p class="arrow-btn__text text-chocolate" :class="{'is-white': isWhite}">{{ text }}</p>
    </router-link>
</div>
</template>

<script>
export default {
    name: 'ArrowBtn',

    props: {
        href: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        isWhite: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="stylus">
.arrow-btn
    position relative

    display flex

    &.is-white
        color $white

.arrow-btn__text
	position relative

	display inline-block
	margin-left 15px
	padding-bottom 5px

	&.text-chocolate
		opacity 1

	&:after
		content ''
		position absolute
		left 0
		bottom 0

		width 100%
		height 2px

		background $black

		will-change opacity
		opacity 0
		transition opacity 0.3s

		.is-white &
			background $white

.arrow-btn__inner
    @media(max-width $breakpoints.md)
        display flex

.arrow-btn__inner:hover
    .arrow-btn__text:after
        opacity 1


.arrow-btn__icon__path
    fill-rule evenodd

    .is-white &
        fill $white
</style>