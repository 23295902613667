import ResizeObserver from 'resize-observer-polyfill';

// Scenes
import RainScene from '@/webgl/background/scenes/RainScene';
import LinesVerticalScene from '@/webgl/background/scenes/LinesVerticalScene';
import LinesHorizontalScene from '@/webgl/background/scenes/LinesHorizontalScene';

export default class SceneManager {
    constructor(scene) {
        this._scene = scene;

        this._scenes = [];
    }

    /**
     * Public
     */
    // create(elements) {
    //     let element;
    //     let type;
    //     for (let i = 0, len = elements.length; i < len; i++) {
    //         element = elements[i];
    //         type = element.dataset.backgroundType;
    //         switch (type) {
    //             case 'rain-vertical': {
    //                 this._scenes.push(this._createScene(element, RainScene));
    //                 break;
    //             }
    //             case 'lines-vertical': {
    //                 this._scenes.push(this._createScene(element, LinesVerticalScene));
    //                 break;
    //             }
    //             case 'lines-horizontal': {
    //                 this._scenes.push(this._createScene(element, LinesHorizontalScene));
    //                 break;
    //             }
    //         }
    //     }
    // }

    createScene(type, element, options) {
        let scene;
        switch (type) {
            case 'rain-vertical': {
                scene = this._createScene(element, RainScene, options);
                this._scenes.push(scene);
                return scene.instance;
            }
            case 'lines-vertical': {
                scene = this._createScene(element, LinesVerticalScene, options);
                this._scenes.push(scene);
                return scene.instance;
            }
            case 'lines-horizontal': {
                scene = this._createScene(element, LinesHorizontalScene, options);
                this._scenes.push(scene);
                return scene.instance;
            }
        }
    }

    update(time, scrollPosition) {
        this._updateScenes(time, scrollPosition);
    }

    destroyAll() {
        let scene;
        for (let i = 0, len = this._scenes.length; i < len; i++) {
            scene = this._scenes[i];
            this._scene.remove(scene.instance);
            scene.resizeObserver.disconnect();
            scene.intesectionObserver.disconnect();
        }
        this._scenes = [];
    }

    /**
     * Private
     */
    _createScene(element, sceneClass, options) {
        const scene = new sceneClass(element, options);
        this._scene.add(scene);

        return {
            instance: scene,
            resizeObserver: this._createResizeObserver(element, scene),
            // intesectionObserver: this._createIntersectionObserver(element, scene)
        }
    }

    _createResizeObserver(element, scene) {
        const resizeObserver = new ResizeObserver(entries => {
            entries.forEach(() => {
                if (typeof scene.resize === 'function') {
                    scene.resize();
                }
            });
        });
        resizeObserver.observe(element);
        return resizeObserver;
    }

    // _createIntersectionObserver(element, scene) {
    //     const intersectionObserver = new IntersectionObserver((entries) => {
    //         entries.forEach((entry) => {
    //             if (entry.isIntersecting) {
    //                 scene.visible = true;
    //                 if (typeof scene.show === 'function') {
    //                     scene.show();
    //                 }
    //             } else {
    //                 scene.visible = false;
    //                 if (typeof scene.hide === 'function') {
    //                     scene.hide();
    //                 }
    //             }
    //         });
    //     });
    //     intersectionObserver.observe(element);
    //     return intersectionObserver;
    // }

    _updateScenes(time, scrollPosition) {
        for (let i = 0, len = this._scenes.length; i < len; i++) {
            if (typeof this._scenes[i].instance.update === 'function') {
                this._scenes[i].instance.update({
                    time: time,
                    scrollPosition: scrollPosition
                });
            }
        }
    }
}
