<template>
    <div class="menu-home">
        <img src="../assets/images/home-menu-gradient.png" class="menu-home__gradient-background">
        <nav class="menu-home__navigation" ref="navigation" @mouseenter="navigationMouseEnterHandler" @mouseleave="navigationMouseLeaveHandler">
            <ul class="menu-home__navigation-list">
                <li class="menu-home__navigation-item js-navigation-item" @mouseenter="navigationItemMouseEnterHandler" @mouseleave="navigationItemMouseLeaveHandler">
                    <MenuHomeButton :to="`/${$i18n.locale}/sculptures`">{{ $t('intro.nav.links.sculptures') }}</MenuHomeButton>
                </li>
                <li class="menu-home__navigation-item js-navigation-item" @mouseenter="navigationItemMouseEnterHandler" @mouseleave="navigationItemMouseLeaveHandler">
                    <MenuHomeButton :to="`/${$i18n.locale}/chocolate`">{{ $t('intro.nav.links.chocolate') }}</MenuHomeButton>
                </li>
                <li class="menu-home__navigation-item js-navigation-item" @mouseenter="navigationItemMouseEnterHandler" @mouseleave="navigationItemMouseLeaveHandler">
                    <MenuHomeButton :href="eShopLink">{{ $t('intro.nav.links.eshop') }}</MenuHomeButton>
                </li>
            </ul>
        </nav>
        <button class="menu-home__logo-button" @mouseenter="logoButtonMouseEnterHandler" @mouseleave="logoButtonMouseLeaveHandler">
            <div class="menu-home__logo" ref="logo">

                <span class="menu-home__logo-line" ref="logoLine"></span>
                <div class="menu-home__logo-tear-mask" ref="logoTearMask">
                    <img src="../assets/images/logo-tear-home.png" class="menu-home__logo-tear" ref="logoTear" />
                </div>

                <span class="menu-home__logo-line" ref="logoLineHover"></span>
                <div class="menu-home__logo-tear-mask" ref="logoTearMaskHover">
                    <img src="../assets/images/logo-tear-home.png" class="menu-home__logo-tear" ref="logoTearHover" />
                </div>

                <span class="menu-home__logo-label" ref="logoLabel">Menu</span>
            </div>
        </button>
    </div>
</template>

<script>
import gsap from 'gsap';

import MenuHomeButton from '@/components/MenuHomeButton';

const HIDE_DELAY = 5; // seconds

export default {
    name: 'MenuHome',

    components: {
        MenuHomeButton
    },

    computed: {
        eShopLink() {
			if (this.$i18n.locale === 'ru')
			{
                return 'https://patrickroger.shop/';
			}
			else if (this.$i18n.locale === 'fr')
			{
				return 'https://www.patrickroger.com/';
			}
			else
			{
                return `https://www.patrickroger.com/${this.$i18n.locale}`;
            }
        }
    },

    created()
    {
        this.isMouseOnTop = false
        this.isShowComplete = false
    },

    mounted()
    {
        this.navigationItems = this.$el.querySelectorAll('.js-navigation-item')
        this.navigationItems = Array.prototype.slice.call(this.navigationItems)
        this.show()
    },

    beforeDestroy()
    {
        if (this.timelineShow) this.timelineShow.kill()
    },

    methods: {
        fadeIn()
        {
            if (this._tweenFadeOut) this._tweenFadeOut.kill();
            this._tweenFadeIn = gsap.to(this.$el, 1, { alpha: 1, ease: 'sine.inOut' })
        },

        fadeOut()
        {
            if (this._tweenFadeIn) this._tweenFadeIn.kill();
            this._tweenFadeOut = gsap.to(this.$el, 0.7, { alpha: 0, ease: 'power2.out' })
        },

        show()
        {
            this.timelineShow = new gsap.timeline({ delay: 1, onComplete: this.showCompleteHandler })
            this.timelineShow.fromTo(this.$refs.logoTearMask, 1, { height: '0%' }, { height: '100%', ease: 'power3.out' }, 0)
            // this.timelineShow.fromTo(this.$refs.logoTear, 1, { y: '-100%' }, { y: '0%', ease: 'power3.out' }, 0)
            this.timelineShow.to(this.$refs.logoLine, 1, { scaleX: 1, ease: 'power3.out' }, 0.4)
            // this.timelineShow.to(this.$refs.logoLabel, 1, { alpha: 1, ease: 'sine.inOut' }, 0.45)
            this.timelineShow.set(this.$refs.navigation, { zIndex: 10 }, 0.5)
            this.timelineShow.to(this.navigationItems, 0.6, { autoAlpha: 1, stagger: 0.1, ease: 'sine.inOut' }, 0.5)
        },

        showNaviation()
        {
            if (!this.isShowComplete) return;
            if (this.timelineHideNavigation) this.timelineHideNavigation.kill()
            
            this.timelineShowNavigation = new gsap.timeline()
            this.timelineShowNavigation.to(this.$refs.logoLabel, 0.2, { alpha: 0, ease: 'sine.inOut' }, 0)
            this.timelineShowNavigation.to(this.navigationItems, 0.6, { autoAlpha: 1, stagger: 0.08, ease: 'sine.inOut' }, 0.1)
            this.timelineShowNavigation.set(this.$refs.navigation, { zIndex: 10 }, 0.5)
        },

        hideNavigation(timeScale = 1)
        {
            if (!this.isShowComplete || this.isMouseOnTop) return;
            if (this.timelineShowNavigation) this.timelineShowNavigation.kill()

            this.timelineHideNavigation = new gsap.timeline()
            this.timelineHideNavigation.set(this.$refs.navigation, { zIndex: 0 }, 0.5)
            this.timelineHideNavigation.to(this.navigationItems, 0.35, { autoAlpha: 0, ease: 'sine.inOut' }, 0)
            this.timelineHideNavigation.to(this.$refs.logoLabel, 0.5, { alpha: 1, ease: 'sine.inOut' }, 0.2)

            this.timelineHideNavigation.timeScale(timeScale)
        },

        showHoverState()
        {
            // console.log('showHoverState');
            if (this.tweenHideHoverState) this.tweenHideHoverState.kill()
            this.tweenShowHoverState = new gsap.timeline() 
            this.tweenShowHoverState.to([this.$refs.logoLine, this.$refs.logoTear], 0.4, { alpha: 0.3, ease: 'sine.inOut' }, 0)
            this.tweenShowHoverState.to([this.$refs.logoLineHover, this.$refs.logoTearHover], 0.4, { alpha: 0, ease: 'sine.inOut' }, 0)
        },

        hideHoverState()
        {
            // console.log('hideHoverState');
            if (this.tweenShowHoverState) this.tweenShowHoverState.kill()
            this.tweenHideHoverState = new gsap.timeline() 
            this.tweenHideHoverState.set([this.$refs.logoLineHover, this.$refs.logoTearHover], { alpha: 1 }, 0)
            this.tweenHideHoverState.fromTo(this.$refs.logoTearMaskHover, 1, { height: '0%' }, { height: '100%', ease: 'power3.out' }, 0)
            this.tweenHideHoverState.fromTo(this.$refs.logoLineHover, 1, { scaleX: 0 }, { scaleX: 1, ease: 'power3.out' }, 0.4)
            // this.tweenHideHoverState.set([this.$refs.logoLine, this.$refs.logoTear], { alpha: 1 }, 1.4)

            // this.tweenHideHoverState.to(this.$refs.logo, 0.3, { alpha: 1, delay: 0.3, ease: 'sine.inOut' })
        },

        startHideDelay(){
            gsap.delayedCall(HIDE_DELAY, this.hideNavigation, [ 0.62 ])
        },

        /**
         * Handlers
         */
        navigationMouseEnterHandler()
        {
            this.isMouseOnTop = true
        },

        navigationMouseLeaveHandler()
        {
            this.isMouseOnTop = false
            this.hideNavigation()
        },

        logoButtonMouseEnterHandler()
        {
            this.showNaviation()
            clearTimeout(this.logoButtonMouseLeaveTimeout)
            this.showHoverState()
        },

        logoButtonMouseLeaveHandler()
        {
            if (this.timelineHideNavigation) this.timelineHideNavigation.kill()
            if (this.tweenHideHoverState) this.tweenHideHoverState.kill()
            clearTimeout(this.logoButtonMouseLeaveTimeout)
            this.logoButtonMouseLeaveTimeout = setTimeout(() => {
                if (!this.isMouseOnTop) {
                    this.hideNavigation()
                    this.hideHoverState()
                }
            }, 30)
        },

        showCompleteHandler()
        {
            this.isShowComplete = true
            this.startHideDelay()
        },

        navigationItemMouseEnterHandler(e)
        {
            const index = this.navigationItems.indexOf(e.target);
            let item;
            for (let i = 0, len = this.navigationItems.length; i < len; i++) {
                item = this.navigationItems[i];
                if (index === i) {
                    gsap.to(item, 0.7, { alpha: 1, ease: 'sine.inOut' });
                } else {
                    gsap.to(item, 0.7, { alpha: 0.5, ease: 'sine.inOut' });
                }
            }
        },

        navigationItemMouseLeaveHandler(e)
        {

        }
    }
}
</script>

<style lang="stylus">
.menu-home
    position absolute
    top 0
    right 0

    width 400px
    height 100%

    user-select none

    pointer-events none

.menu-home__gradient-background
    position absolute
    top 0
    right 0

    pointer-events none

.menu-home__logo-button
    position absolute
    top 0
    right 0

    width 400px
    height 400px

    pointer-events auto

    border-bottom-left-radius 100%

.menu-home__logo
    display block
    opacity 1

    position absolute
    top 48px
    right 66px

    width 42px
    height 96px

    @media(max-width $breakpoints.md)
        top 20px
        right 25px

        width 38px
        height 95px

.menu-home__logo-tear-mask
    position absolute
    top 0
    right 0
    left 0

    height 0%
    width 30px
    overflow hidden

    margin 0 auto

.menu-home__logo-tear
    display block

    position absolute
    top 0
    right 0
    left 0

    height 60px

    margin 0 auto

.menu-home__logo-line
    display block

    position absolute
    top 31px
    right 0
    left 0

    width 36px
    height 1px

    margin 0 auto

    background-color $green

    transform-origin top left
    transform scaleX(0)

.menu-home__logo-label
	display block
	opacity 0 

	position absolute
	bottom 0
	left -1px

	font-family $font-titles
	font-size 12px
	text-transform uppercase
	letter-spacing 0.2em
	color $green
	text-align center

	.is-ru &
		font-family $font-titles-ru

	@media(max-width $breakpoints.md)
		left -2px
		font-size 11px

.menu-home__navigation 
    position absolute
    top 132px
    right 60px 

    text-align right

    pointer-events auto

    @media(max-width $breakpoints.md)
        top 100px
        right 22px 

.menu-home__navigation-item
    opacity 0
    visibility hidden

    position relative

    margin-bottom 4px
</style>