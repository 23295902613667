import distance from './distance';
import lerp from './lerp';
import hermite from './hermite';
import clamp from './clamp';
import angleBetweenPoints from './angleBetweenPoints';
import map from './map';

export default {
    distance,
    lerp,
    hermite,
    clamp,
    angleBetweenPoints,
    map
}
