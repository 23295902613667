import { gsap } from 'gsap';

import Debugger from '@/webgl/utils/Debugger';

import Particle from './Particle';
import { noise, noiseDetail } from './Noise';

const AMOUNT = 1700;

export default class Flowfield {
    constructor() {
        this._canvas = document.createElement('canvas');
        this._canvas.style.transformOrigin = 'center center';

        this._context = this._canvas.getContext('2d');

        this._width = null;
        this._height = null;
        this._transformOrigin = { x: 0, y: 0 };
        this._transformTranslate = { x: 0, y: 0 };
        this._mousePosition = {
            screen: { x: 0, y: 0 },
            transformed: { x: 0, y: 0 },
        };
        this._particles = [];
        this._isMouseDown = false;

        this._rotation = 0;
        this._rotationSpeed = 0.005;
        this._scale = 1;
        this._scaleSpeed = 0.00001;

        this._trail = 0.01; //0.02;
        this._speed = 0.72;
        this._lifeMultiplier = 3;
        this._gridSize = 3;
        this._gridIncrement = 0.032;
        this._noiseOctaves = 5;
        this._noiseFallof = 0.96;

        this._mouseParticleIntervalCount = 0;

        this._resize();
        this._bindHandlers();
        this._setupEventListeners();
        this._setupDebugGui();

        this._generate();
    }

    destroy() {
        this._removeEventListeners();
        this._removeDebugGui();
    }

    /**
     * Public
     */
    getElement() {
        return this._canvas;
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._resizeHandler = this._resizeHandler.bind(this);
        this._tickHandler = this._tickHandler.bind(this);
    }

    _setupEventListeners() {
        window.addEventListener('resize', this._resizeHandler);
        gsap.ticker.add(this._tickHandler);
    }

    _removeEventListeners() {
        window.removeEventListener('resize', this._resizeHandler);
        gsap.ticker.remove(this._tickHandler);
    }

    _setNoiseDetail() {
        noiseDetail(this._noiseOctaves, this._noise);
    }

    _generate() {
        this._context.fillStyle = 'rgb(9, 9, 9)';
        // this._context.fillStyle = 'red';
        this._context.fillRect(0, 0, this._width, this._height);

        this._setNoiseDetail();

        this._rows = Math.floor(this._height / this._gridSize) + 2;
        this._cols = Math.floor(this._width / this._gridSize) + 2;
        this._flowfield = new Array(this._rows * this._cols);
        // this._particles = this._createParticles();

        this._updateFlowfield();

        for (let i = 0; i < AMOUNT; i++) {
            this._createParticle(null, null, 0.4);
        }
    }

    _createParticles() {
        const particles = [];
        for (let i = 0; i < AMOUNT; i++) {
            particles.push(new Particle({
                canvasWidth: this._width,
                canvasHeight: this._height,
                row: this._rows,
                cols: this._cols,
                gridSize: this._gridSize,
                flowfield: this._flowfield,
                speed: this._speed,
                lifeMultiplier: this._lifeMultiplier,
            }));
        }
        return particles;
    }

    _createParticle(x, y, alpha) {
        this._particles.push(new Particle({
            x: x,
            y: y,
            alpha: alpha,
            canvasWidth: this._width,
            canvasHeight: this._height,
            row: this._rows,
            cols: this._cols,
            gridSize: this._gridSize,
            flowfield: this._flowfield,
            speed: this._speed,
            lifeMultiplier: this._lifeMultiplier,
        }));
    }

    _update() {
        this._time += 0.01;

        this._clear();

        // this._context.fillStyle = 'red';
        // this._context.fillRect(0, 0, this._width, this._height);

        this._checkParticlesLife();

        if (this._particles.length < AMOUNT) {
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
        }

        this._updateParticles();
    }
    _clear() {
        this._context.fillStyle = `rgba(9, 9, 9, ${this._trail})`;
        this._context.fillRect(0, 0, this._width, this._height);
    }

    _checkParticlesLife() {
        let item;

        for (let i = this._particles.length - 1; i >= 0; i--) {
            item = this._particles[i];
            if (item.isDead) {
                this._particles.splice(i, 1);
            }
        }
    }

    _updateFlowfield() {
        let yoff = 0;
        for (let y = 0; y <= this._rows; y++) {
            let xoff = 0;
            for (let x = 0; x <= this._cols; x++) {
                let angle = noise(xoff, yoff, this._time) * Math.PI * 3;
                let index = x + y * this._cols;

                this._flowfield[index] = angle;
                xoff += this._gridIncrement;
            }
            yoff += this._gridIncrement;
        }
    }

    _updateParticles() {
        let particle;
        for (let i = 0, len = this._particles.length; i < len; i++) {
            particle = this._particles[i];
            particle.update();
            particle.draw(this._context);
        }
    }

    /**
     * Resize
     */
    _resize() {
        this._viewportWidth = window.innerWidth;
        this._viewportHeight = window.innerHeight;

        const size = 1024;

        this._width = size;
        this._height = size;

        this._canvas.width = this._width;
        this._canvas.height = this._height;
    }

    /**
     * Debug
     */
    _setupDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        this._debugGui = Debugger.gui.addFolder('Flowfield');
        this._debugGui.add(this, '_trail', 0, 1, 0.001).name('trail');
        this._debugGui.add(this, '_speed', 0, 2, 0.02);
        this._debugGui.add(this, '_lifeMultiplier', 0, 5, 0.02);
        this._debugGui.add(this, '_gridSize', 1, 100, 0.1);
        this._debugGui.add(this, '_gridIncrement', 0, 0.2, 0.001);
        this._debugGui.add(this, '_noiseOctaves', 0, 10, 1);
        this._debugGui.add(this, '_noiseFallof', 0, 1, 0.01);
        this._debugGui.add(this, '_rotationSpeed', -0.1, 0.1, 0.001);
        this._debugGui.add(this, '_scaleSpeed', 0, 0.001, 0.00001);
        this._debugGui.add(this._particles, 'length').listen().name('particle amount');
        this._debugGui.add(this, '_generate');
        // this._debugGui.open();
    }

    _removeDebugGui() {
        if (this._debugGui) Debugger.gui.removeFolder(this._debugGui);
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }

    _tickHandler() {
        this._update();
    }

}
