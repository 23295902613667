<template>
	<div class="video-box">
		<video loop autoplay preload muted>
			<source :src="data.url" :type="data.mime_type">
		</video>
		<ArrowBtnExternal
			v-if="youtube"
			class="video-box__link"
			:href="youtube"
			:text="label"
			:isWhite="true"
		/>
	</div>
</template>

<script>
import ArrowBtnExternal from '@/components/ArrowBtnExternal.vue'

export default {
  name: 'VideoBox',

  components: {
		ArrowBtnExternal
	},

  props: {
      data: {
        type: Object,
        default: () => {}
	  },
	  youtube: {
		type: String,
		default: ''
	  },
	  label: {
		type: String,
		default: ''
	  }
  }
}
</script>

<style lang="stylus">
.video-box
	position relative
video
	display block
	width 100%
	heigth auto

.video-box__link
	z-index 2
	position absolute
	bottom 75px
	left 50%

	transform translateX(-50%)

</style>