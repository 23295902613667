<template>
    <div class="preloader-home">
        <div class="preloader-home__logo" ref="logo">
            <span class="preloader-home__logo-line-base" ref="line"></span>
            <span class="preloader-home__logo-line" ref="line"></span>
            <img class="preloader-home__logo-tear-base" src="../assets/images/logo-tear-1.png">
            <div class="preloader-home__logo-tear-mask" ref="tearMask">
                <img class="preloader-home__logo-tear" src="../assets/images/logo-tear-1.png">
            </div>
        </div>
        <span class="preloader-home__label-enter" ref="labelEnter" v-if="isTouch">Enter</span>
    </div>
</template>

<script>
import gsap from 'gsap'
import Preloader from '@/webgl/intro/Preloader'

export default {
    name: 'PreloaderHome',

    data()
    {
        return {
            isTouch: 'ontouchstart' in window
        };
    },

    created()
    {
        this.isTimelineShowComplete = false
        this.isPreloaderComplete = false

        Preloader.addEventListener('complete', this.preloaderCompleteHandler)
        Preloader.start()
    },

    mounted()
    {
        this.show()
        this.setupEventListeners()
    },

    beforeDestroy()
    {
        this.removeEventListeners()
    },

    methods: {
        /**
         * Public
         */
        showLabelEnter()
        {
            if (this.$refs.labelEnter) {
                gsap.to(this.$refs.labelEnter, 1, { alpha: 1, ease: 'sine.inOut' })
            }
        },

        hideLabelEnter()
        {
            if (this.$refs.labelEnter) {
                gsap.to(this.$refs.labelEnter, 1, { alpha: 0, ease: 'sine.inOut' })
            }
        },

        /**
         * Private
         */
        setupEventListeners()
        {
            window.addEventListener('click', this.windowClickHandler)
        },

        removeEventListeners()
        {
            window.removeEventListener('click', this.windowClickHandler)
        },

        show()
        {
            const delay = 0 // 1
            this.timelineShow = new gsap.timeline({ delay, onComplete: this.timelineShowCompleteHandler })
            this.timelineShow.to(this.$refs.logo, 1.3, { opacity: 1, ease: 'sine.inOut' }, 0)
            this.timelineShow.to(this.$refs.line, 1.5, { scaleX: 1, ease: 'power3.inOut' }, 1.3)
            this.timelineShow.to(this.$refs.line, 1.2, { backgroundColor: '#0fad89', ease: 'power3.inOut' }, 1.7)
            this.timelineShow.to(this.$refs.tearMask, 2, { height: '100%', ease: 'power4.inOut' }, 1.35)
            // this.timelineShow.timeScale(10);
        },

        hide()
        {
            this.timelineHide = new gsap.timeline()
            this.timelineHide.to(this.$refs.logo, 0.8, { alpha: 0, ease: 'sine.inOut' }, 0)
            this.timelineHide.call(this.timelineHideCompleteHandler, null, 0.4)
            // this.timelineHide.timeScale(10);
        },

        finished() 
        {
            this.hideLogo()
            gsap.delayedCall(0.6, () => {
                this.showCursorEnterLabel()
            })
        },

        hideLogo()
        {
            gsap.to(this.$refs.logo, 1.3, { alpha: 0.2, ease: 'power2.out' }, 0)
        },

        showCursorEnterLabel()
        {
            this.$emit('cursor:show-label-enter')
        },

        /**
         * Handlers
         */
        preloaderCompleteHandler()
        {
            this.isPreloaderComplete = true
            if (this.isTimelineShowComplete) this.finished()
        },

        timelineShowCompleteHandler()
        {
            this.isTimelineShowComplete = true
            if (this.isPreloaderComplete) this.finished()
        },

        timelineHideCompleteHandler()
        {
            this.$emit('complete')
        },

        windowClickHandler()
        {
            if (this.isTimelineShowComplete && this.isPreloaderComplete) {
                this.hide()
                this.$emit('cursor:hide-label-enter')
            }
        }
    }
}
</script>

<style lang="stylus">
.preloader-home
    width 100%
    height 100%

.preloader-home__logo
    opacity 0

    position absolute
    top 0
    right 0
    bottom 0
    left 0

    width 68px
    height 104px

    margin auto

.preloader-home__logo-line-base,
.preloader-home__logo-line
    display block

    position absolute
    top 50px
    left 0

    width 100%
    height 1px

    background-color $green

.preloader-home__logo-line-base
    opacity 0.05

.preloader-home__logo-line
    transform-origin top left
    transform scaleX(0)

    background-color #8fffe6

.preloader-home__logo-tear-base
.preloader-home__logo-tear-mask
    position absolute
    top 0
    left 0

    height 100%
    width 100%
    overflow hidden

.preloader-home__logo-tear-mask
    height 0%

.preloader-home__logo-tear-base
    opacity 0.1

.preloader-home__logo-tear
    height 104px

.preloader-home__label-enter
	opacity 0

	position absolute
	top: 0
	bottom -160px
	left 0

	width 100%
	height 15px

	margin auto 0

	font-family $font-titles
	font-size 12px
	text-transform uppercase
	letter-spacing 0.2em
	color $green
	text-align center

	.is-ru &
		font-family $font-titles-ru
</style>