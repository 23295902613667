export default {
    name: 'baseUrl',

    computed: {
		baseUrl()
		{
			let base = ''
			if(this.$i18n.locale === 'fr')
			{
				base = 'chocolat'
			}
			else
			{
				base = 'chocolate'
			}

			return base
		}
	}
}